import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import Typography from "@material-ui/core/Typography";

export default function OcMassRoleToolbar({
  handleCancel,
  handleFinish,
  dialogTitle,
  disabled
}) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile &&
        <Toolbar>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            flexGrow={1}
          >
            <Box>
              <Button
                id="my-hr-users-cancel-import-button"
                color="primary"
                onClick={handleCancel}
              >
                {t("INTERRUPT")}
              </Button>
            </Box>
            <Box>
              <Typography variant="h5">{dialogTitle}</Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFinish}
                disableElevation
                style={{ height: "36px" }}
                disabled={disabled}
              >
                {t("FINISH")}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      }
      {isMobile &&
        <Toolbar>
          <Box width="100%">
            <Box height="16px" />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
            >
              <Box>
                <Button
                  id="my-hr-users-cancel-import-button"
                  color="primary"
                  onClick={handleCancel}
                >
                  {t("INTERRUPT")}
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFinish}
                  disableElevation
                  style={{ height: "36px" }}
                  disabled={disabled}
                >
                  {t("FINISH")}
                </Button>
              </Box>
            </Box>
            <Box height="16px" />
            <Box textAlign="center">
              <Typography variant="h5">{dialogTitle}</Typography>
            </Box>
            <Box height="16px" />
          </Box>
        </Toolbar>
      }
    </>
  );
}
