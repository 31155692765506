import React from "react";
import { Box, Grid } from "@material-ui/core";
import MyTimePageHeader from "my-time/components/my-time-page-header";
import OcPageTransition from "oc/components/oc-page-transition";
import useCurrentUserState from "hooks/use-current-user-state";
import MyTimeExaminersCard from "my-time/components/my-time-examiners-card";
import MyTimeEmployeeRequestsCard from "my-time/components/my-time-employee-requests-card";
import MyTimeAdmissionCard from "my-time/components/my-time-admission-card";
import useActualDate from "my-time/hooks/use-actual-date";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import useExaminers from "my-time/hooks/use-examiners";
import MyTimeWorkingTimeFrame from "my-time/components/my-time-working-time-frame";
import MyTimeRemainsFrame from "my-time/components/my-time-remains-frame";
import MyTimeOvertimeFrame from "my-time/components/my-time-overtime-frame";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import MyTimeWorkTimeBalanceFrameBox from "my-time/components/my-time-work-time-balance-frame-box";
import useMonthlyWorkTimeBalance from "my-time/hooks/use-monthly-work-time-balance";

const MyTimeEmployeeSchedulePage = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { contractCode, employeeCode } = useCurrentUserState();

  const { date, formattedDate, year, month, handleDateChange } = useActualDate();

  const { data: changeMyTimeEnabledData } = useProperty({
    resource: "myTime",
    key: "changeMyTimeEnabled",
  });

  const { data, loading, error } = useExaminers({
    contractCode,
    locale: t("locale"),
  });

  const { data: changeWorkingTimeEnabledData } = useProperty({
    resource: "myTime",
    key: "changeWorkingTimeEnabled",
  });

  const changeMyTimeEnabled = changeMyTimeEnabledData === "true";
  const changeWorkingTimeEnabled = changeWorkingTimeEnabledData === "true";
  const isNotExaminer = data?.length === 0;

  const { data: monthlyWorkTimeBalanceData, loading: monthlyWorkTimeBalanceLoading } = useMonthlyWorkTimeBalance({
    contractCode,
    year,
    month,
  });

  const minHeight = monthlyWorkTimeBalanceData?.startBalance !== -1 ? "285px" : "178px";
  const gridItemStyle = { maxWidth: isMobile ? "100%" : "25%", padding: isMobile ? "0px 16px" : "8px" };

  return (
    <OcPageTransition>
      <Box padding={isMobile ? "16px 0px 0px 0px" : "16px"}>
        <MyTimePageHeader
          formattedDate={formattedDate}
          date={date}
          onDateChange={handleDateChange}
          isMobile={isMobile}
        />
        <Box padding="16px 0px">
          <Grid container spacing={isMobile ? 0 : 2} direction={isMobile ? "column" : "row"}>
            <Grid item xs={3} style={gridItemStyle}>
              <MyTimeWorkingTimeFrame contractCode={contractCode} year={year} month={month} minHeight={minHeight} />
            </Grid>
            {isMobile && <Box height="16px" />}
            <Grid item xs={3} style={gridItemStyle}>
              <MyTimeRemainsFrame contractCode={contractCode} year={year} month={month} minHeight={minHeight} />
            </Grid>
            {isMobile && <Box height="16px" />}
            <Grid item xs={3} style={gridItemStyle}>
              <MyTimeOvertimeFrame contractCode={contractCode} year={year} month={month} minHeight={minHeight} />
            </Grid>
            {monthlyWorkTimeBalanceData?.startBalance !== -1 && (
              <Grid item xs={3} style={gridItemStyle}>
                {isMobile && <Box height="16px" />}
                <MyTimeWorkTimeBalanceFrameBox
                  data={monthlyWorkTimeBalanceData}
                  loading={monthlyWorkTimeBalanceLoading}
                  minHeight={minHeight}
                />
              </Grid>
            )}
            {isMobile && <Box height="16px" />}
            <Grid item xs={3} style={{ maxWidth: isMobile ? "100%" : "25%" }}>
              <MyTimeExaminersCard data={data} loading={loading} error={error} />
            </Grid>
          </Grid>
          {changeMyTimeEnabled && (
            <>
              <Box marginY="16px">
                <MyTimeEmployeeRequestsCard
                  label={t("MY_ADMISSION")}
                  contractCode={contractCode}
                  employeeCode={employeeCode}
                  date={date}
                  examinerView={false}
                  userRole="user"
                />
              </Box>
            </>
          )}
          <MyTimeAdmissionCard
            contractCode={contractCode}
            employeeCode={employeeCode}
            year={year}
            month={month}
            changeMyTimeEnabled={changeMyTimeEnabled}
            changeWorkingTimeEnabled={changeWorkingTimeEnabled}
            userRole="user"
            isNotExaminer={isNotExaminer}
            isMobile={isMobile}
          />
        </Box>
      </Box>
    </OcPageTransition>
  );
};

export default MyTimeEmployeeSchedulePage;
