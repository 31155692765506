import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/styles";
import useClientState from "hooks/use-oc-client-state-2";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import CompanyFilter from "my-leave/components/company-filter";
import OrgUnitFilter from "my-leave/components/org-unit-filter";
import WorkingPlaceFilter from "my-leave/components/working-place-filter";
import JobClassGroupFilter from "my-leave/components/job-class-group-filter";
import PayOfficeFilter from "my-leave/components/payoffice-filter";
import EmployeeFilter from "my-leave/components/employee-filter";
import MyLeaveFilterDivider from "my-leave/components/my-leave-filter-divider";
import MyHrUserProfilesRolesItemHeader from "../my-hr-user-profiles-roles-item-header";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import accountIcon from "@iconify/icons-mdi/account";

const MyHrUserProfilesRolesItem = ({ role }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  let stateId = "showCollapse" + role?.product + role?.role + role?.contract;

  const [showCollapse, setShowCollapse] = useClientState(stateId, false);

  let showCollapseButton = true;
  if (
    role?.role === "user" ||
    (role?.product === "MyHR" && role?.role === "admin") ||
    (role?.product === "MyCafeteria" && role?.role === "admin") ||
    (role?.product === "MyLeave" && role?.role === "admin")
  ) {
    showCollapseButton = false;
  }

  const handleExpandClick = () => {
    setShowCollapse(!showCollapse);
  };

  const maxWidthStyle = { maxWidth: isMobile ? "100%" : "50%" };

  return (
    <Box
      key={`${role?.product?.toLowerCase()}-${role?.role?.toLowerCase()}`}
      style={{
        backgroundColor: theme.palette.gray20.main,
        borderRadius: "4px",
        border: "0px solid transparent",
        width: isMobile ? "100%" : "728px",
        marginBottom: "16px",
      }}
    >
      <MyHrUserProfilesRolesItemHeader
        product={role?.product}
        role={role?.role}
        showCollapseButton={showCollapseButton}
        onExpand={handleExpandClick}
        showCollapse={showCollapse}
        isMobile={isMobile}
        roleIcon={accountIcon}
      />
      <Collapse in={showCollapse} timeout="auto" unmountOnExit>
        <Box padding="0px 16px">
          <Box
            style={{
              borderTop: `2px solid ${theme.palette.gray40.main}`,
              padding: "16px 16px",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "unset"
              }}
            >
              <Grid item xs={6} style={maxWidthStyle}>
                <CompanyFilter
                  contract={role?.contract}
                  editable={false}
                  onUpdateRole={() => { }}
                  product={role?.product}
                  role={role?.role}
                />
              </Grid>
              <Grid item xs={6} style={maxWidthStyle}>
                <OrgUnitFilter
                  role={role?.role}
                  onUpdateRole={() => { }}
                  editable={false}
                  product={role?.product}
                  contract={role?.contract}
                />
              </Grid>
              <Grid item xs={6} style={maxWidthStyle}>
                <WorkingPlaceFilter
                  role={role?.role}
                  onUpdateRole={() => { }}
                  editable={false}
                  product={role?.product}
                  contract={role?.contract}
                />
              </Grid>
              <Grid item xs={6} style={maxWidthStyle}>
                <JobClassGroupFilter
                  role={role?.role}
                  onUpdateRole={() => { }}
                  editable={false}
                  product={role?.product}
                  contract={role?.contract}
                />
              </Grid>
              <Grid item xs={6} style={maxWidthStyle}>
                <PayOfficeFilter
                  role={role?.role}
                  onUpdateRole={() => { }}
                  editable={false}
                  product={role?.product}
                  contract={role?.contract}
                />
              </Grid>
            </Grid>
            <Box height="16px" />
            <MyLeaveFilterDivider />
            <Box height="16px" />
            <Grid item xs={6} style={maxWidthStyle}>
              <EmployeeFilter
                role={role?.role}
                onUpdateRole={() => { }}
                editable={false}
                product={role?.product}
                contract={role?.contract}
              />
            </Grid>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default MyHrUserProfilesRolesItem;
