import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import useProperty from "hooks/use-oc-property";
import OcSwitch from "oc/components/oc-form-switch";
import Collapse from "@material-ui/core/Collapse";
import { Typography } from "@material-ui/core";
import EmailForm from "./components/email-form";
import EmailByCompanyForm from "./components/email-by-company-form";

export default function MyLeaveEmailCard({ type, isMobile }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [emailByCompanyState, setEmailByCompanyState] = useState();

  const { data: emailByCompany } = useProperty({
    resource: "myLeave",
    key:
      type === "administrativeEmail"
        ? "administrativeEmailByCompany"
        : "payrollEmailByCompany",
    onCompleted: ({ value }) => {
      if (!emailByCompanyState && value) {
        const newState = value === "true" ? true : false;
        setEmailByCompanyState(newState);
      }
    },
  });

  let cardLabel =
    type === "administrativeEmail"
      ? t("ADMINISTRATIVE_EMAIL")
      : t("SUBSEQUENT_REQUESTS");

  let infoText =
    type === "administrativeEmail"
      ? t("MYLEAVE_ADMINISTRATIVE_EMAIL_INFO_TEXT")
      : t("MYLEAVE_PAYROLL_EMAIL_INFO_TEXT");

  return (
    <OcCard label={cardLabel} loading={loading}>
      <Box padding="24px 0px 0px 0px">
        <Box letterSpacing="0.44px" color="#4d4d4d" fontSize="16px" paddingLeft="16px">
          {infoText}
        </Box>
        <Box height="36px" />
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center" paddingLeft="16px">
            <OcSwitch
              checked={emailByCompanyState || false}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => setEmailByCompanyState(e.target.checked)}
              disabled={loading}
            />
            <Box width="16px" />
            <Typography variant="caption">
              {t("SEPARATE_EMAIL_COMPANY")}
            </Typography>
          </Box>
          <Box height="24px" />
          <Collapse
            in={emailByCompanyState || false}
            timeout="auto"
            unmountOnExit
          >
            <EmailByCompanyForm
              administrativeEmailByCompany={emailByCompanyState}
              type={type}
              setLoading={setLoading}
              emailByCompanyState={emailByCompanyState}
              emailByCompany={emailByCompany === "true" ? true : false}
              isMobile={isMobile}
            />
          </Collapse>
          <Collapse in={!emailByCompanyState || false} timeout="auto">
            <EmailForm
              setLoading={setLoading}
              type={type}
              emailByCompanyState={emailByCompanyState}
              emailByCompany={emailByCompany === "true" ? true : false}
              isMobile={isMobile}
            />
          </Collapse>
        </Box>
      </Box>
    </OcCard>
  );
}
