import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Table from "oc/components/oc-data-table";
import { useTranslation } from "react-i18next";
import { importErrorsDef, importErrorsDefIsMobile } from "my-hr/configs/my-hr-table-defs";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";

export default function MyHrAdminUsersImportResult({ importUsersResult, isMobile }) {
  const { t } = useTranslation();

  const tableId = "errorsListTable";

  initialTableData.sortField = "employeeName";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const [selectedRowId, setSelectedRowId] = useState(null);

  let showImportResult =
    importUsersResult && Object.keys(importUsersResult).length !== 0;

  const searchText = tableData?.searchText;
  const columName = tableData?.searchField;
  const filteredResult = importUsersResult?.importErrors.filter(
    (item) => searchText && new RegExp(searchText, "i").test(item?.[columName])
  );

  let importData = {
    rows:
      tableData?.searchText?.length === 0
        ? importUsersResult?.importErrors
        : filteredResult,
    total: importUsersResult?.importErrors.length,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
    searchField: tableData?.searchField,
    searchText: tableData?.searchText,
  };

  if (!showImportResult) return null;

  const tableDef = isMobile
    ? importErrorsDefIsMobile
    : importErrorsDef;

  const filteredTableDef = {
    id: importErrorsDef?.id,
    columns: importErrorsDef?.columns?.filter(column =>
      !importErrorsDefIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  const onSelectRow = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
    }
  };

  return (
    <Box>
      {importUsersResult?.failedUsersCount === 0 ? (
        <>
          <Box
            fontSize="48px"
            textAlign="center"
            paddingBottom="32px"
            style={{ color: "#2FA84F" }}
          >
            {t("IMPORT_HAS_DONE")}
          </Box>
          <Box>
            {importUsersResult?.totalProcessedRecordCount} {t("ITEM_PROCESSED")}
          </Box>
          <Box>
            {importUsersResult?.importedUsersCount} {t("EMPLOYEE_IMPORTED")}
          </Box>
        </>
      ) : (
        <Box>
          <Box
            fontSize="48px"
            textAlign={isMobile ? "left" : "center"}
            paddingBottom="32px"
            style={{ color: "#F3AA18" }}
          >
            {t("IMPORT_HAS_DONE_WITH_ERROR")}
          </Box>
          <Box>
            {importUsersResult?.totalProcessedRecordCount} {t("ITEM_PROCESSED")}
          </Box>
          <Box>
            {importUsersResult?.importedUsersCount} {t("EMPLOYEE_IMPORTED")}
          </Box>
          <Box>
            {importUsersResult?.failedUsersCount} {t("ERROR_COUNT_ON_IMPORT")}
          </Box>
          <Box height="32px" />
          <Table
            data={importData}
            loading={false}
            tableDef={tableDef}
            tableData={tableData}
            setTableData={setTableData}
            pagination={true}
            localPaging={true}
            onSelectRow={onSelectRow}
            filteredTableDef={filteredTableDef}
            selectedRowId={selectedRowId}
          />
          <Box padding="16px"></Box>
        </Box>
      )}
    </Box>
  );
}
