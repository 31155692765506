import React from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyHRErrorPage({
  errorState,
  errorStateDb,
  errorStateType,
  apiUrl,
}) {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <OcPageTransition>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          height: isMobile ? "auto" : "100vh",
          backgroundColor: theme.palette.gray20.main,
          fontSize: "34px",
        }}
      >
        <Box id="orgware-logo">
          <img
            alt="orgware-icon"
            src="/img/owIconLogoColored.png"
            style={{ marginBottom: "24px" }}
          />
        </Box>
        <Box id="orgware-text-logo">
          <img
            alt="orgware-icon"
            src="/img/owLogoColored.png"
            style={{ width: "350px", marginBottom: "15px" }}
          />
        </Box>
        {(errorState || errorStateDb) && errorStateType === "net"
          ? t("CONNECTION_ERROR")
          : t("DB_CONNECTION_ERROR")}

        <Box height="24px" />
        <Box fontSize="24px">{apiUrl}</Box>
      </Box>
    </OcPageTransition>
  );
}
