import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.gray20.main,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
}));

export default useStyles;
