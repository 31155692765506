import React, { createRef, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import useEmployee from "hooks/use-myhr-employee";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import MyHrUserContractCard from "my-hr/components/my-hr-user-contract-card";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import OcTabPanel from "oc/components/oc-tab-panel";
import MyHrPersonalDataPanel from "my-hr/components/my-hr-personal-data-panel";
import MyHrContactsDataPanel from "my-hr/components/my-hr-contacts-data-panel";
import MyHrContractsDataPanel from "my-hr/components/my-hr-contracts-data-panel";
import MyHrPayrollDataPanel from "my-hr/components/my-hr-payroll-data-panel";
import MyHrApplicationsDataPanel from "my-hr/components/my-hr-applications-data-panel";
import OcPageTransition from "oc/components/oc-page-transition";
import MyHrStickyPersonalDataHeader from "my-hr/components/my-hr-sticky-personal-data-header";
import useCurrentUserState from "hooks/use-current-user-state";
import { useRecoilState } from "recoil";
import { scrollTriggerState, selectedTabState, pageWidthState } from "states";
import useChangeRequests from "hooks/use-change-requests";
import OcFormEditor from "oc/components/oc-form-editor";
import {
  nameDialogDef,
  mothersNameDialogDef,
  taxNumberDialogDef,
  socialSecurityNumberDialogDef,
  identityCardNumberDialogDef,
  dateOfBirthDialogDef,
  placeOfBirthDialogDef,
  genderDialogDef,
  maritalStatusDialogDef,
  countryOfBirthDialogDef,
  nationalityDialogDef,
  permanentAddressDialogDef,
  invalidateAddressDialogDef,
  temporaryAddressDialogDef,
  serviceAddressDialogDef,
  phonesWorkDialogDef,
  phonesWorkMobileDialogDef,
  phonesPrivateDialogDef,
  emailsWorkDialogDef,
  emailsOtherDialogDef,
} from "oc/components/oc-form-editor/configs";
import useProperty from "hooks/use-oc-property";
import useChangeRequestsExaminersName from "hooks/use-change-requests-examiners-name";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import MyHrDocumentsDataPanel from "my-hr/components/my-hr-documents-data-panel/my-hr-documents-data-panel";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import useCustomerLicences from "hooks/use-customer-licences";

export default function MyHrUserHomePage() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation();
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);
  const [def, setDef] = useState(null);
  const [modifier, setModifier] = useState(null);
  const { licences } = useCustomerLicences();

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  let { contractCode, employeeCode } = useCurrentUserState();

  const { data: employeeData } = useEmployee({ employeeId: employeeCode });
  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  const { data: examinersData, loading: examinersLoading } =
    useChangeRequestsExaminersName({
      locale: i18n.language,
      contract: contractCode,
    });

  const { data: myDocumentsMenuEnabled } = useProperty({
    resource: "myDocuments",
    key: "myDocumentsMenuEnabled",
  });

  const myDocumentsEnabled = myDocumentsMenuEnabled === "false" ? false : true;

  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const isExaminer = rolesData?.find(
    (item) => item.product === "MyHR" && item.role === "examiner"
  )
    ? true
    : false;

  const hasExaminer = examinersData?.length > 0 && !examinersLoading;

  let isEditButtonDisabled = !hasExaminer;
  if (isExaminer && !hasExaminer) {
    isEditButtonDisabled = false;
  }

  const { data: changeRequestsData } = useChangeRequests(employeeCode);

  let selectedContract = contractsData?.find(
    (item) => item.code === contractCode
  );

  const [scrollTrigger, setScrollTrigger] = useRecoilState(scrollTriggerState);

  const pageRef = createRef();

  const [pageWidth, setPageWidth] = useRecoilState(pageWidthState);

  const { data: changeSettingsEnabled } = useProperty({
    resource: "system",
    key: "changeRequestsEnabled",
    fetchPolicy: "network-only",
  });

  const enabledEdit = changeSettingsEnabled === "true" ? true : false;

  useEffect(() => {
    function handleScroll() {
      let scrollTriggerPosition = 100;
      let scrollTop = document?.scrollingElement?.scrollTop;
      if (scrollTop >= scrollTriggerPosition) {
        setScrollTrigger("show");
      } else if (scrollTop < scrollTriggerPosition) {
        setScrollTrigger("hide");
      }
    }

    function handleResize() {
      let width = pageRef?.current?.clientWidth;
      if (width && width !== pageWidth) {
        setPageWidth(width);
      }
    }

    handleResize();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  });

  const handleCloseFormEditor = () => {
    setDef(null);
    setModifier(null);
  };

  const handleShowFormEditor = (type, modifier) => {
    if (modifier) {
      setModifier(modifier);
    }
    switch (type) {
      case "emails":
        if (modifier === "work") {
          setDef(emailsWorkDialogDef);
        } else {
          setDef(emailsOtherDialogDef);
        }
        break;
      case "phones":
        if (modifier === "work") {
          setDef(phonesWorkDialogDef);
        } else if (modifier === "workMobile") {
          setDef(phonesWorkMobileDialogDef);
        } else {
          setDef(phonesPrivateDialogDef);
        }
        break;
      case "mothersName":
        setDef(mothersNameDialogDef);
        break;
      case "name":
        setDef(nameDialogDef);
        break;
      case "taxNumber":
        setDef(taxNumberDialogDef);
        break;
      case "socialSecurityNumber":
        setDef(socialSecurityNumberDialogDef);
        break;
      case "identityCardNumber":
        setDef(identityCardNumberDialogDef);
        break;
      case "dateOfBirth":
        setDef(dateOfBirthDialogDef);
        break;
      case "placeOfBirth":
        setDef(placeOfBirthDialogDef);
        break;
      case "gender":
        setDef(genderDialogDef);
        break;
      case "maritalStatus":
        setDef(maritalStatusDialogDef);
        break;
      case "countryOfBirth":
        setDef(countryOfBirthDialogDef);
        break;
      case "nationality":
        setDef(nationalityDialogDef);
        break;
      case "permanentAddress":
        setDef(permanentAddressDialogDef);
        break;
      case "temporaryAddress":
        setDef(temporaryAddressDialogDef);
        break;
      case "serviceAddress":
        setDef(serviceAddressDialogDef);
        break;
      case "invalidateAddress":
        setDef(null);
        setDef(invalidateAddressDialogDef);
        break;
      case "newAddress":
        const newAddress = {
          isNewAddress: true,
        };
        if (!employeeData?.permanentAddress?.city) {
          const enhancedDef = { ...permanentAddressDialogDef, ...newAddress };
          setDef(enhancedDef);
          break;
        }
        if (!employeeData?.temporaryAddress?.city) {
          const enhancedDef = { ...temporaryAddressDialogDef, ...newAddress };
          setDef(enhancedDef);
          break;
        }
        if (!employeeData?.serviceAddress?.city) {
          const enhancedDef = { ...serviceAddressDialogDef, ...newAddress };
          setDef(enhancedDef);
          break;
        }
        break;
      default:
        break;
    }
  };

  const hasChangeRequest = ({ type, typeGroup }) => {
    if (isEditButtonDisabled) return true;
    if (typeGroup) {
      let isEdited = changeRequestsData?.find(
        (item) =>
          item.typeGroup === typeGroup && !item.approvedAt && !item.rejectedAt
      );
      isEdited = isEdited ? true : false;
      let isDeleted = changeRequestsData?.find(
        (item) =>
          item.type === typeGroup && !item.approvedAt && !item.rejectedAt
      );
      isDeleted = isDeleted ? true : false;
      return isEdited || isDeleted;
    }
    if (type === "mothersName") {
      let isDisabled = changeRequestsData?.find(
        (item) =>
          item.typeGroup === type && !item.approvedAt && !item.rejectedAt
      );
      return isDisabled ? true : false;
    }

    let isDisabled = changeRequestsData?.find(
      (item) => item.type === type && !item.approvedAt && !item.rejectedAt
    );
    const result = isDisabled ? true : false;
    return result;
  };
  return (
    <OcPageTransition>
      <Box ref={pageRef} minWidth={isMobile ? "auto" : "1024px"}>
        <MyHrStickyPersonalDataHeader
          pageWidth={pageWidth}
          employee={employeeData}
          contract={selectedContract}
          trigger={scrollTrigger === "show" ? true : false}
        />
        <Box id="personal-data-page" display="flex" flexDirection="column">
          <MyHrUserContractCard
            employee={employeeData}
            contract={selectedContract}
          />
          <Box padding={isMobile ? "0px" : "32px 24px 0px 24px"}>
            <Box bgcolor="#E3E5E5">
              <Tabs
                value={selectedTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChangeTab}
                aria-label="disabled tabs example"
              >
                <Tab label={t("PERSONAL_DATA")} />
                <Tab label={t("CONTACTS")} />
                <Tab label={t("PAYROLL")} />
                <Tab label={t("GET_CONTRACT")} />
                <Tab label={t("APPLICATIONS")} />
                {myDocumentsEnabled && licences?.myDocuments &&
                  <Tab label={t("DOCUMENTS")} />}
              </Tabs>
            </Box>
            <OcTabPanel value={selectedTab} index={0} style={{ padding: 0 }}>
              <MyHrPersonalDataPanel
                employee={employeeData}
                onEdit={handleShowFormEditor}
                hasChangeRequest={hasChangeRequest}
                editable={enabledEdit}
              />
            </OcTabPanel>
            <OcTabPanel value={selectedTab} index={1}>
              <MyHrContactsDataPanel
                employee={employeeData}
                onEdit={handleShowFormEditor}
                hasChangeRequest={hasChangeRequest}
                editable={enabledEdit}
              />
            </OcTabPanel>
            <OcTabPanel value={selectedTab} index={2}>
              <MyHrPayrollDataPanel contract={selectedContract} />
            </OcTabPanel>
            <OcTabPanel value={selectedTab} index={3}>
              <MyHrContractsDataPanel contracts={contractsData} />
            </OcTabPanel>
            <OcTabPanel value={selectedTab} index={4}>
              <MyHrApplicationsDataPanel
                employee={employeeData}
                label={t("AVAILABLE_APPLICATIONS")}
                isMobile={isMobile}
              />
            </OcTabPanel>
            {myDocumentsEnabled && licences?.myDocuments &&
              <OcTabPanel value={selectedTab} index={5}>
                <MyHrDocumentsDataPanel isUser={true} contractCode={contractCode} />
              </OcTabPanel>}
          </Box>
        </Box>
        {def && (
          <OcFormEditor
            open={true}
            onClose={handleCloseFormEditor}
            data={employeeData}
            def={def}
            onShowFormEditor={handleShowFormEditor}
            modifier={modifier}
          />
        )}
      </Box>
    </OcPageTransition>
  );
}
