import React from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useOcClientState from "hooks/use-oc-client-state-2";
import MyDocumentsSettingsCard from "my-documents/components/my-documents-settings-card/my-documents-settings-card";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

const menuDef = [
  {
    id: "myDocumentsGeneralSettings",
    label: "GENERAL_SETTINGS",
  },
];

function PageSideMenu() {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useOcClientState(
    "myDocumentsSettingsActiveItem",
    menuDef[0].id
  );

  function handleSelect(itemId) {
    setSelectedItem(itemId);
  }

  return (
    <Box>
      {menuDef.map((item) => (
        <Box
          id={item.id}
          key={item.id}
          width="240px"
          height="40px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="40px"
          onClick={() => handleSelect(item.id)}
          style={
            selectedItem === item.id
              ? {
                backgroundColor: "white",
                borderLeft: "4px solid #cc0033",
              }
              : { cursor: "pointer" }
          }
        >
          <Box style={{ fontSize: "14px", letterSpacing: "0.25px" }}>
            {t(item.label)}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default function MyDocumentsSettingsPage() {
  const [selectedItem] = useOcClientState(
    "myDocumentsSettingsActiveItem",
    menuDef[0].id
  );

  const isMobile = useIsMobile();

  return (
    <OcPageTransition>
      <Box>
        <Box
          id="my-hr-documents-settings"
          height="80px"
          style={{ backgroundColor: "#cc0033" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="14px"
        >
          <Box style={{ color: "white", fontSize: "24px" }}>MyDocuments</Box>
        </Box>
        <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
          <PageSideMenu />
          <Box id="my-time-admin-settings" flexGrow={1} padding={isMobile ? "16px 0px" : "16px"}>
            {selectedItem === "myDocumentsGeneralSettings" && (
              <Box>
                <MyDocumentsSettingsCard isMobile={isMobile} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
