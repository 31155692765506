import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const MyHrUserDataNewDataRequestBox = ({ label, value, isMobile }) => {
  const theme = useTheme();

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box minWidth={isMobile ? "auto" : "308px"} width="auto">
          {label}
        </Box>
        <Box minWidth="308px" width="16px" />
        <Box width="auto">
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "24px",
              height: isMobile ? "auto" : "41px",
              color: theme.palette.gray80.main,
              border: `2px solid ${theme.palette.gray80.main}`,
              borderBottomLeftRadius: "2px",
              borderBottomRightRadius: "2px",
              display: "flex",
              alignItems: "center",
              paddingLeft: "6px",
              backgroundColor: theme.palette.white.main,
              overflow: isMobile ? "hidden" : "unset"
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default MyHrUserDataNewDataRequestBox;
