import React from "react";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import deleteIcon from "@iconify/icons-mdi/delete";
import pencilIcon from "@iconify/icons-mdi/pencil";
import { useTheme } from "@material-ui/core/styles";

export default function MyDocumentDocumentEditorButtons({ setShowConfirmDialog, onEditDocument, row }) {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <InlineIcon
        id="deleteIcon"
        icon={deleteIcon}
        width="24px"
        style={{
          color: theme.palette.owGray.main,
          cursor: "pointer",
        }}
        onClick={() => setShowConfirmDialog(true)}
      />
      <Box width="8px" />
      <InlineIcon
        id="pencilIcon"
        icon={pencilIcon}
        width="24px"
        style={{
          color: theme.palette.owGray.main,
          cursor: "pointer",
        }}
        onClick={() => onEditDocument(row)}
      />
    </Box>
  );
}
