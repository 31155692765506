import React, { useEffect, useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import AllLeavesCard from "my-leave/components/my-leave-all-leaves-card";
import AllLeavesWithCoverage from "my-leave/components/my-leave-all-leaves-with-coverage-card";
import useClientState from "hooks/use-oc-client-state";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useUsersWithRequests from "hooks/use-my-leave-users-with-requests";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { InlineIcon } from "@iconify/react";
import alertIcon from "@iconify/icons-mdi/alert-circle-outline";
import useLeaveEvents from "hooks/use-my-leave-events";
import useCurrentUserState from "hooks/use-current-user-state";
import useRoleFilters from "hooks/use-role-filters.js";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import useLocalState from "hooks/use-local-state";

export default function MyLeaveViewLeaveRequests() {
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  let { contractCode: contract } = useCurrentUserState();

  const {
    roleFilters,
    prevRoleFilters,
    resetRoleFilters,
    setPrevRoleFilters,
    handleAddFilter,
    handleRemoveFilter,
  } = useRoleFilters({
    contract,
    product: "MyLeave",
    role: "viewer",
  });

  const actualDate = new Date();
  const [currentDate, setCurrentDate] = useState(actualDate);
  const [prevCurrentDate, setPrevCurrentDate] = useState(actualDate);

  const [showDialog, setShowDialog] = useState(false);

  //By request, useLocalState is used here. (OW-2024-10-301)
  const [onlyRequested, setOnlyRequested] = useLocalState(
    "showOnlyRequestedLeaves",
    false
  );

  const [prevOnlyRequested, setPrevOnlyRequested] = useClientState(
    "prevShowOnlyRequestedLeaves",
    true
  );

  const [filterable, setFilterable] = useClientState("allLeavesFilter", false);

  let tableId = "allLeavesViewerTableData";
  initialTableData.sortField = "name";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);
  const [prevTableData, setPrevTableData] = useClientState(
    `${tableId}Prev`,
    initialTableData
  );

  const [showRemoteWorks, setShowRemoteWorks] = useClientState(
    "showRemoteWorks",
    true
  );

  const [prevShowRemoteWorks, setPrevShowRemoteWorks] = useClientState(
    "prevShowRemoteWorks",
    true
  );

  let startDate = moment(currentDate).startOf("month").toDate();
  let endDate = moment(currentDate).endOf("month").toDate();

  const handleCompleted = (data) => {
    handleChangeFilterable(false);
  };

  const {
    loadQuery: loadUsers,
    data,
    loading,
  } = useUsersWithRequests({
    onlyRequested,
    roleFilters,
    currentDate,
    filterable,
    tableData,
    showRemoteWorks,
    adminView: true,
    role: "viewer",
    startDate,
    endDate,
    onCompleted: handleCompleted,
    contract,
  });

  const {
    loadEvents,
    data: leavesData,
    loading: leaveEventsLoading,
    eventsByDays,
  } = useLeaveEvents({
    roleFilters,
    currentDate: moment(currentDate).toDate(),
    viewedByAdmin: true,
    contract: contract,
  });

  function handleChangeFilterable(state) {
    setFilterable(state);
  }

  const [filterItemErrors, setFilterItemErrors] = useState([]);

  const MAX_ITEM_COUNT = 1000;
  const generateRoleFilterError = () => {
    const newState = [...filterItemErrors];
    if (roleFilters?.company?.length > MAX_ITEM_COUNT) {
      newState.push(t("COMPANY"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.orgUnit?.length > MAX_ITEM_COUNT) {
      newState.push(t("ORGUNIT"));
      setFilterItemErrors(newState);
    }

    if (roleFilters?.workingPlace?.length > MAX_ITEM_COUNT) {
      newState.push(t("WORKINGPLACE"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.jobClassGroup?.length > MAX_ITEM_COUNT) {
      newState.push(t("JOBCLASSGROUP"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.payOffice?.length > MAX_ITEM_COUNT) {
      newState.push(t("PAYOFFICE"));
      setFilterItemErrors(newState);
    }
    if (roleFilters?.contract?.length > MAX_ITEM_COUNT) {
      newState.push(t("GET_EMPLOYEE"));
      setFilterItemErrors(newState);
    }

    return newState?.length > 0 ? newState : false;
  };

  const handleLoadQuery = () => {
    const isItemError = generateRoleFilterError();
    if (isItemError) {
      setShowDialog(true);
      handleChangeFilterable(false);
      return;
    }
    loadUsers();
    loadEvents();
    setPrevRoleFilters(roleFilters);
    setPrevShowRemoteWorks(showRemoteWorks);
    setPrevOnlyRequested(onlyRequested);
  };

  const buttonDisable =
    _.isEqual(roleFilters, prevRoleFilters) &&
    showRemoteWorks === prevShowRemoteWorks &&
    onlyRequested === prevOnlyRequested;

  function handleOnlyReqestedChange(state) {
    setPrevOnlyRequested(onlyRequested);
    setOnlyRequested(state);
  }

  function handleCurrentDateChange(date) {
    setPrevCurrentDate(currentDate);
    setCurrentDate(date);
  }

  if (currentDate !== prevCurrentDate) {
    loadUsers();
    loadEvents();
    setPrevCurrentDate(currentDate);
  }

  function handleTableDataChange(state) {
    const keys = Object.keys(state).toString();
    setTableData(state);
    if (
      keys === "searchField" ||
      (keys === "searchText" && state?.searchText?.length < 3)
    ) {
      return;
    }
    loadUsers();
    loadEvents();
  }

  function handleShowRemoteWorksChange(event) {
    setShowRemoteWorks(event.target.checked);
  }

  const [selectedRowId, setSelectedRowId] = useState(null);

  const originalonSelectRow = (o) => {
    history.push(`/my-leave/user-requests/MyLeave/viewer/${o.contract}`);
  };

  const onSelectRow = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
    } else {
      originalonSelectRow(o);
    }
  };

  const handleClearFilters = () => {
    resetRoleFilters();
    handleChangeFilterable(false);
    setFilterItemErrors([]);
  };

  const isTabledataChanged = !_.isEqual(tableData, prevTableData);
  useEffect(() => {
    if (isTabledataChanged) {
      handleLoadQuery();
      setPrevTableData(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabledataChanged]);

  const handleDialogClose = () => {
    setShowDialog(false);
    setFilterItemErrors([]);
  };

  useEffect(() => {
    loadUsers();
    loadEvents();
    setPrevRoleFilters(roleFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  return (
    <OcPageTransition>
      <Box>
        <Dialog aria-labelledby="simple-dialog-title" open={showDialog}>
          <Box width="476px" padding="16px">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              paddingBottom="16px"
            >
              <Box paddingRight="20px">
                <InlineIcon icon={alertIcon} width="30px" color="#cc0033" />
              </Box>
              <Box fontSize="16px" letterSpacing="0.44px" color="#5E6366">
                {t("TOO_MANY_HITS")}
              </Box>
            </Box>
            {filterItemErrors?.length > 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                paddingBottom="16px"
              >
                <Box fontSize="16px" letterSpacing="0.44px" color="#5E6366">
                  ({filterItemErrors?.toString()})
                </Box>
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              onClick={handleDialogClose}
            >
              OK
            </Button>
          </Box>
        </Dialog>

        <Box padding={isMobile ? "16px 0px" : "16px"}>
          <AllLeavesCard
            label={t("LEAVE_AND_ABSENCE_REQUESTS")}
            buttonDisable={buttonDisable}
            currentDate={currentDate}
            data={data}
            filterable={filterable}
            loading={loading}
            monthFiltering={true}
            onAddFilter={handleAddFilter}
            onClearFilters={handleClearFilters}
            onCurrentDateChange={handleCurrentDateChange}
            onFilterableChange={handleChangeFilterable}
            onLoadQuery={handleLoadQuery}
            onlyRequested={onlyRequested}
            onOnlyRequestedChange={handleOnlyReqestedChange}
            onRemoveFilter={handleRemoveFilter}
            onSelectRow={onSelectRow}
            onShowRemoteWorksChange={handleShowRemoteWorksChange}
            onTableDataChange={handleTableDataChange}
            product="MyLeave"
            role="viewer"
            roleFilters={roleFilters}
            showRemoteWorks={showRemoteWorks}
            tableData={tableData}
            tableId={tableId}
            contract={contract}
            examinerDasboard={false}
            selectedRowId={selectedRowId}
            handleMoreButton={originalonSelectRow}
          />
          <Box height="16px" />
          <AllLeavesWithCoverage
            cardTitle={t("LEAVES_AND_REMOTE_WORKS")}
            currentDate={currentDate}
            onCurrentDateChange={handleCurrentDateChange}
            monthFiltering={true}
            roleFilters={roleFilters}
            onEventSelected={onSelectRow}
            showRemoteWorks={showRemoteWorks}
            viewedByAdmin={true}
            leavesData={leavesData}
            eventsByDays={eventsByDays}
            loading={leaveEventsLoading}
          />
        </Box>
      </Box>
    </OcPageTransition>
  );
}
