import React from "react";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import UserDataCollapse from "../user-data-collapse";
import getFullname from "oc/utils/get-fullname";
import AddPermissionForm from "my-hr/components/my-hr-add-permission-form";

export default function AddPermission({
  employee,
  contractsData,
  user,
  setUser,
  dateError,
  isMobile
}) {
  const { t } = useTranslation();

  if (employee?.code !== user.employee) {
    setUser({ employee: employee?.code });
  }

  if (employee && getFullname(employee)) {
    setUser({ fullname: getFullname(employee) });
  }
  return (
    <Box>
      <Box id="user-data-collapse">
        <UserDataCollapse
          id="testIdCollapse"
          employee={employee}
          contractsData={contractsData}
          isMobile={isMobile}
        />
      </Box>
      <OcCard label={t("ADD_PERMISSION")}>
        <Box id="add-permission-form">
          <AddPermissionForm
            user={user}
            setUser={setUser}
            dateError={dateError}
            employee={employee}
            isMobile={isMobile}
          />
        </Box>
      </OcCard>
    </Box>
  );
}
