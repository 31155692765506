import React from "react";
import { Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { InlineIcon } from "@iconify/react";
import alertIcon from "@iconify/icons-mdi/alert-circle-outline";
import Button from "@material-ui/core/Button";

export default function OcInfoDialog({ open, onClose, text, handleDialogClose, isMobile }) {

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box width={isMobile ? "auto" : "476px"} padding="16px">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingBottom="16px"
        >
          <Box paddingRight="20px">
            <InlineIcon icon={alertIcon} width="30px" color="#cc0033" />
          </Box>
          <Box fontSize="16px" letterSpacing="0.44px" color="#5E6366">
            {text}
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          fullWidth
          onClick={handleDialogClose}
        >
          OK
        </Button>
      </Box>
    </Dialog>
  );
}
