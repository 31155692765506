import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "../my-hr-user-data-edit-collapse-header/my-hr-user-data-edit-collapse-header.style";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@material-ui/core/styles";
import MyHrUserDataEditCollapseHeaderBadge from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-user-data-edit-collapse-header-badge";

export default function MyHrUserDataEditCollapseHeader({
  handleExpandClick,
  expanded,
  type,
  createdDateAt,
  approvedAt,
  rejectedAt,
  typeGroup,
  isMobile
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box>
      <Box
        className={
          expanded === true
            ? classes.backgroundColorOnCollapse
            : classes.backgroundColorOffCollapse
        }
        padding="0px 16px"
      >
        <Box
          padding={isMobile ? "16px 0px" : "4px 0px"}
          display="flex"
          flexDirection="row"
          justifyContent={isMobile ? "flex-start" : "space-between"}
          alignItems="center"
        >
          <Box display="contents" className={classes.topBorder} />
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Box height="8px" />
              <Typography variant="h6">
                {t("REQUEST")}
              </Typography>
              <Box width="6px" />
              <Box
                style={{
                  border: `1px solid ${theme.palette.gray80.main}`,
                  borderRadius: 12,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                {typeGroup === "temporaryAddress"
                  ? t("AddressType.temporaryAddress")
                  : typeGroup === "permanentAddress"
                    ? t("AddressType.permanentAddress")
                    : typeGroup === "serviceAddress"
                      ? t("AddressType.serviceAddress")
                      : typeGroup === "name"
                        ? t("NAME")
                        : typeGroup === "mothersName"
                          ? t("MOTHERS_NAME")
                          : t(type)}
              </Box>
              <Box width="15px" />
              <Box paddingRight="28px" />
            </Box>
            {isMobile && <Box height="10px" />}
            <Box
              display="flex"
              alignItems="center"
              width={isMobile ? "100%" : "auto"}
              justifyContent={isMobile ? "space-between" : "unset"}
            >
              <Box>
                {approvedAt === null && rejectedAt === null ? (
                  <MyHrUserDataEditCollapseHeaderBadge
                    badgeName={t("Leave.requested")}
                    border={`1px solid ${theme.palette.yellowBase.main}`}
                  />
                ) : approvedAt !== null && rejectedAt === null ? (
                  <MyHrUserDataEditCollapseHeaderBadge
                    badgeName={t("Leave.approved")}
                    border={`1px solid ${theme.palette.greenBase.main}`}
                  />
                ) : (
                  <MyHrUserDataEditCollapseHeaderBadge
                    badgeName={t("Leave.rejected")}
                    border={`1px solid ${theme.palette.primary.main}`}
                  />
                )}
                <Box
                  style={{
                    fontSize: "10px",
                    marginTop: "3px",
                    color: theme.palette.gray60.main,
                  }}
                >
                  {createdDateAt}
                </Box>
              </Box>
              <Box width="22px" />
            </Box>
          </Box>
          <IconButton
            id={
              typeGroup === "name" ||
                typeGroup === "mothersName"
                ? `collapse-header-button-${typeGroup}`
                : `collapse-header-button-${type}-${expanded}`
            }
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            style={
              expanded
                ? {
                  padding: 0,
                  transform: "rotate(180deg)",
                }
                : {
                  padding: 0,
                }
            }
          >
            <ExpandMoreIcon />
          </IconButton>

        </Box>
      </Box>
    </Box>
  );
}
