import React from "react";
import UserDataCollapse from "../user-data-collapse";
import Box from "@material-ui/core/Box";
import RoleCollapse from "../role-collapse";
import MyHrAddUserRole from "my-hr/components/my-hr-add-user-role";
import OcCard from "oc/components/oc-card";
import _ from "lodash";
import OcErrorBoundary from "oc/components/oc-error-boundary";

export default function AddRole({
  employee,
  contractsData,
  user,
  setUser,
  unsavedRole,
  showUnsavedRole,
  onAddUnsavedRole,
  onRemoveUnsavedRole,
  loading,
  isMobile
}) {
  function handleAddRole({ contract, module, role }) {
    try {
      let existingRoles = _.cloneDeep(user?.roles);
      let newRole = {
        contract: contract,
        product: module,
        role: role,
        roleFilters: {
          company: [],
          orgUnit: [],
          workingPlace: [],
          jobClassGroup: [],
          payOffice: [],
          contract: [],
        },
      };

      existingRoles.unshift(newRole);
      setUser({ roles: existingRoles });
    } catch (error) {
      console.log("error in add-role handler", error);
    }
  }

  function handleUpdateRole(state) {
    let newRoles = [...user?.roles];
    const elementIndex = newRoles.findIndex(
      (element) =>
        element.contract === state.contract &&
        element.role === state.role &&
        element.product === state.product
    );

    newRoles[elementIndex] = state;
    let newUser = _.cloneDeep(user);
    newUser.roles = newRoles;
    setUser(newUser);
  }

  function handleRemoveRole(state) {
    let newRoles = [...user?.roles];
    _.remove(newRoles, function (element) {
      return (
        element.contract === state.contract &&
        element.role === state.role &&
        element.product === state.product
      );
    });
    let newUser = _.cloneDeep(user);
    newUser.roles = newRoles;
    setUser(newUser);
  }

  return (
    <OcErrorBoundary>
      <Box>
        <UserDataCollapse
          id="addRoleUserDataCollapse"
          employee={employee}
          contractsData={contractsData}
          isMobile={isMobile}
        />
        {contractsData?.map((contract, index) => {
          let contractIndex = index;
          let jobClassName =
            contract?.jobClass?.name.charAt(0).toUpperCase() +
            contract?.jobClass?.name.slice(1) || "";

          if (user?.roles?.length === 0) {
            setUser({
              roles: [
                {
                  contract: contract?.code,
                  product: "MyHR",
                  role: "user",
                  roleFilters: {
                    company: [],
                    orgUnit: [],
                    workingPlace: [],
                    jobClassGroup: [],
                    payOffice: [],
                    contract: [],
                  },
                },
              ],
            });
          }
          return (
            <Box key={contract.code}>
              <OcCard
                label={`${jobClassName} - ${contract.code}`}
                loading={loading}
              >
                <Box>
                  <MyHrAddUserRole
                    roles={user?.roles || []}
                    setUser={setUser}
                    contract={contract}
                    onAddRole={handleAddRole}
                    isMobile={isMobile}
                  />
                  {user?.roles?.map((role, index) => {
                    if (role.contract !== contract.code) return null;
                    return (
                      <Box
                        key={`${role?.contract}-${role?.product}-${role?.role}-${index}`}
                        marginBottom="16px"
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <RoleCollapse
                          role={role}
                          roleIndex={index}
                          index={contractIndex}
                          onUpdateRole={handleUpdateRole}
                          onRemoveRole={handleRemoveRole}
                          unsavedRole={unsavedRole}
                          onAddUnsavedRole={onAddUnsavedRole}
                          onRemoveUnsavedRole={onRemoveUnsavedRole}
                          showUnsavedRole={showUnsavedRole}
                        />
                      </Box>
                    );
                  })}
                </Box>
              </OcCard>
              <Box height="20px" />
            </Box>
          );
        })}
      </Box>
    </OcErrorBoundary>
  );
}
