import React from "react";
import Box from "@material-ui/core/Box";
import { allLeaveRequestsWithCoverage, allLeaveRequestsWithCoverageIsMobile } from "my-leave/configs/my-leave-table-defs";
import { useTranslation } from "react-i18next";
import MyLeaveTableToolbar from "my-leave/components/my-leave-table-toolbar";
import OcDataTable from "oc/components/oc-data-table/oc-data-table";
import moment from "moment";
import { allLeaveRequestsDailyTable } from "my-leave/configs/my-leave-table-defs";

export default function MyLeaveOverlapsTables({
  currentDate,
  onCurrentDateChange,
  loading,
  data,
  tableId,
  onSelectRow,
  tableData,
  setTableData,
  handleTable,
  handleViewDayClick,
  toolbarButton,
  isMobile,
  selectedRowId,
  handleMoreButton,
}) {
  const { i18n } = useTranslation();

  let title = moment(currentDate).locale(i18n.language).format("YYYY. MMMM");

  const handlePrev = () => {
    const firstDayOfMonth = moment(currentDate).startOf("month").toDate();
    const date = moment(firstDayOfMonth).subtract(1, "month").toDate();
    onCurrentDateChange(date);
  };

  const handleNext = () => {
    const firstDayOfMonth = moment(currentDate).startOf("month").toDate();
    const date = moment(firstDayOfMonth).add(1, "month").toDate();
    onCurrentDateChange(date);
  };

  const tableDef = isMobile ? allLeaveRequestsWithCoverageIsMobile : allLeaveRequestsWithCoverage;

  const filteredTableDef = {
    id: allLeaveRequestsWithCoverage?.id,
    columns: allLeaveRequestsWithCoverage?.columns?.filter(column =>
      !allLeaveRequestsWithCoverageIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <Box>
      <MyLeaveTableToolbar
        handlePrev={handlePrev}
        handleNext={handleNext}
        handleTable={handleTable}
        handleViewDayClick={handleViewDayClick}
        toolbarButton={toolbarButton}
        title={title}
        onCurrentDateChange={onCurrentDateChange}
        isMobile={isMobile}
      />
      {toolbarButton === "basicTable" && (
        <OcDataTable
          data={data}
          loading={loading && data?.rows?.length === 0 ? true : false}
          tableDef={tableDef}
          tableData={tableData}
          setTableData={setTableData}
          onSelectRow={onSelectRow}
          tableId={tableId}
          filteredTableDef={filteredTableDef}
          selectedRowId={selectedRowId}
          handleMoreButton={handleMoreButton}
        />
      )}
      {toolbarButton === "dailyTable" && (
        <OcDataTable
          data={data}
          loading={loading && data?.rows?.length === 0 ? true : false}
          tableDef={allLeaveRequestsDailyTable}
          tableData={tableData}
          setTableData={setTableData}
          onSelectRow={onSelectRow}
          tableId={tableId}
        />
      )}
    </Box>
  );
}
