import React from "react";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import minusIcon from "@iconify/icons-mdi/minus-thick";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import useStyles from "./oc-filter-item-selected-list.style";

export default function OcFilterItemSelectedList({
  selectedItems,
  removeItem,
  filterType,
  withCode,
  editable = true,
}) {
  const classes = useStyles();

  if (selectedItems?.length === 0) return null;

  return (
    <OcErrorBoundary>
      <Box
        padding={!editable ? "16px 8px" : "16px 8px 0px 8px"}
        style={{
          maxHeight: "180px",
          overflowY: "auto",
          backgroundColor: "#E3E5E5",
          borderRadius: "4px",
          //border: "1px solid #C6CACC",
        }}
      >
        {selectedItems?.map((item, index) => {
          return (
            <Box
              key={`${item.name}-${index}`}
              fontSize="16px"
              lineHeight="24px"
              letterSpacing="0.44px"
              padding="4px 8px"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.filterLine}
              onClick={() => removeItem({ key: filterType, value: item.code })}
            >
              <Box> {withCode ? `${item.name} (${item.code})` : item.name}</Box>
              {editable && (
                <Box
                  style={{
                    padding: "0px 4px",
                    cursor: "pointer",
                  }}
                >
                  <InlineIcon icon={minusIcon} width="12px" />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </OcErrorBoundary>
  );
}
