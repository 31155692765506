import { Box, ButtonGroup, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const OcFormEditorAddressSelector = ({
  addressType,
  isNewAddress,
  data,
  onShowFormEditor,
  isMobile
}) => {
  const { t } = useTranslation();

  if (!isNewAddress) return null;

  const types = ["permanentAddress", "temporaryAddress", "serviceAddress"];

  const handleClick = (type) => {
    onShowFormEditor(type);
  };

  return (
    <Box
      id="button-group"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      paddingTop="36px"
    >
      <ButtonGroup
        orientation={isMobile ? "vertical" : "horizontal"}
        fullWidth={isMobile ? true : false}
      >
        {types.map((item, index) => {
          const isSelectedType = item === addressType;
          const isExist = data[item].city ? true : false;
          return (
            <Button
              key={`${item}-button-${index}`}
              variant={isSelectedType ? "contained" : "outlined"}
              color={isSelectedType ? "primary" : ""}
              disabled={isExist}
              disableElevation
              onClick={() => handleClick(item)}
            >
              {t(`AddressType.${item}`)}
            </Button>
          );
        })}
      </ButtonGroup>
    </Box>
  );
};

export default OcFormEditorAddressSelector;
