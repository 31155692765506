import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

const ApplicationCardInfoItem = ({ label, value, disabled, isMobile }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      paddingBottom="10px"
      justifyContent={isMobile ? "space-between" : "unset"}
    >
      <Box width={isMobile ? "auto" : "221px"} textAlign="right">
        <Typography
          variant="body1"
          style={{ color: disabled ? theme.palette.gray60.main : theme.palette.darkBase.main }}
        >
          {label}
        </Typography>
      </Box>
      <Box width="16px" />
      <Box>
        <Typography
          style={{
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default ApplicationCardInfoItem;
