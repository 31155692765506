import React from "react";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";
import useStyles from "./oc-private-layout-footer.style";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function OcPrivateLayoutFooter() {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  let thisYear = new Date().getFullYear().toString();

  return (
    <footer
      id="oc-private-layout-footer"
      position="sticky"
      bottom="0"
      top="100%"
      className={classes.root}
    >
      <Box
        height="50px"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        className={classes.innerContainer}
      >
        <Box padding="0px 32px" textAlign={isMobile ? "center" : "unset"}>
          <span className={classes.textBold}>Copyright &copy; {thisYear}</span>
          <Link
            href="https://www.orgware.hu"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <span className={clsx(classes.textBold, classes.textRed)}>
              {t("ORGWARE_COMPANY_NAME")}
            </span>
          </Link>
          <span
            style={{
              marginRight: "6px",
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            {t("TEXT_COPYRIGHT")}
          </span>
        </Box>
        {isMobile && <Box height="16px" />}
        <Link href="https://www.orgware.hu" target="_blank" rel="noreferrer">
          <Box paddingRight="20px" height="20px">
            <img alt="orgware-logo" src="/img/owLogoFooter.png"></img>
          </Box>
        </Link>
      </Box>
    </footer>
  );
}
