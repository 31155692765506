import React from "react";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import { allLeaveRequests, examinerDashboardSummary, examinerDashboardSummaryIsMobile, allLeaveRequestsIsMobile } from "my-leave/configs/my-leave-table-defs";
import Table from "oc/components/oc-data-table";
import LeaveFilterCollapse from "./components/leave-filter-collapse";
import MyHrMonthSelector from "my-hr/components/my-hr-month-selector";
import moment from "moment";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function AllLeavesCard({
  currentDate,
  data,
  filterable,
  loading,
  onAddFilter,
  onCurrentDateChange,
  onFilterableChange,
  onlyRequested,
  onOnlyRequestedChange,
  onRemoveFilter,
  onSelectRow,
  onShowRemoteWorksChange,
  onTableDataChange,
  product,
  role,
  roleFilters,
  showRemoteWorks,
  tableData,
  tableId,
  onLoadQuery,
  onClearFilters,
  buttonDisable,
  contract,
  label,
  selectedRowId,
  onRowSelection,
  examinerDasboard,
  handleMoreButton
}) {
  const isMobile = useIsMobile();

  function handlePrev() {
    onCurrentDateChange(moment(currentDate).subtract(1, "month").toDate());
  }

  function handleNext() {
    onCurrentDateChange(moment(currentDate).add(1, "month").toDate());
  }

  let monthLabel = moment(currentDate).format("MMMM");
  let yearLabel = moment(currentDate).format("YYYY");

  let tableDef;
  if (examinerDasboard) {
    if (isMobile) {
      tableDef = examinerDashboardSummaryIsMobile;
    } else {
      tableDef = examinerDashboardSummary;
    }
  } else {
    if (isMobile) {
      tableDef = allLeaveRequestsIsMobile;
    } else {
      tableDef = allLeaveRequests;
    }
  }

  const filteredTableDef = {
    id: allLeaveRequests?.id,
    columns: allLeaveRequests?.columns?.filter(column =>
      !allLeaveRequestsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <OcCard label={label}>
      <>
        <Box height="16px" />
        {role === "viewer" && !examinerDasboard && (
          <>
            <MyHrMonthSelector
              year={yearLabel}
              month={monthLabel}
              backChevron={false}
              previousonClick={handlePrev}
              nextOnClick={handleNext}
              isMobile={isMobile}
            />
            <Box height="32px" />
          </>
        )}
        <LeaveFilterCollapse
          buttonDisable={buttonDisable}
          contract={contract}
          currentDate={currentDate}
          data={data}
          examinerDasboard={examinerDasboard}
          filterable={filterable}
          listCount={data?.total || 0}
          monthFiltering={false}
          onAddFilter={onAddFilter}
          onClearFilters={onClearFilters}
          onCurrentDateChange={onCurrentDateChange}
          onFilterableChange={onFilterableChange}
          onLoadQuery={onLoadQuery}
          onlyRequested={onlyRequested}
          onRemoveFilter={onRemoveFilter}
          onShowRemoteWorksChange={onShowRemoteWorksChange}
          product={product}
          role={role}
          roleFilters={roleFilters}
          setOnlyRequested={onOnlyRequestedChange}
          showRemoteWorks={showRemoteWorks}
          loading={loading && !data.ros}
        />
        <Box paddingTop="32px">
          <Table
            data={data}
            loading={loading && !data.rows}
            tableDef={tableDef}
            tableData={tableData}
            setTableData={onTableDataChange}
            onSelectRow={onSelectRow}
            tableId={tableId}
            pagination={true}
            selectedRowId={selectedRowId}
            onRowSelection={onRowSelection}
            examinerDasboard={examinerDasboard}
            role={role}
            filteredTableDef={examinerDasboard ? examinerDashboardSummary : filteredTableDef}
            handleMoreButton={handleMoreButton}
          />
        </Box>
      </>
    </OcCard>
  );
}
