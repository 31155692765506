import React from "react";
import useMyLeaveAdminBadges from "hooks/use-my-leave-admin-badges";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import bagIcon from "@iconify/icons-mdi/bag-personal";
import { useHistory } from "react-router-dom";
import { StyledBadge, useStyles } from "./my-leave-header-admin-badge.style";
import { narrowMenuState } from "states";
import { useRecoilState } from "recoil";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyLeaveHeaderBadge() {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [narrowMenuOpened, setNarrowMenuOpened] = useRecoilState(narrowMenuState);

  const { data } = useMyLeaveAdminBadges();
  if (!data) return null;

  function handleClick() {
    if (isMobile) {
      setNarrowMenuOpened(!narrowMenuOpened);
    }
    history.push("/my-leave/handle-requests");
  }

  let count = data || 0;

  return (
    <Box onClick={handleClick} className={classes.root}>
      <StyledBadge
        id="my-leave-header-admin-badge-count"
        badgeContent={count}
      >
        <InlineIcon
          id="my-leave-header-admin-badge"
          icon={bagIcon}
          width="30"
          className={classes.icon}
        />
      </StyledBadge>
    </Box>
  );
}
