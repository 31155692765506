export const myFulfilledLeaves = {
  id: "my-fulfilled-leaves-table",
  select: "none",
  columns: [
    {
      align: "left",
      id: "startDate",
      label: "START_DATE",
      sort: true,
      type: "date",
      width: "120px",
    },
    {
      align: "left",
      id: "endDate",
      label: "END_DATE",
      sort: true,
      type: "date",
      width: "120px",
    },
    {
      align: "right",
      id: "effectiveWorkDays",
      label: "ABBREVIATED_WORKDAYS",
      sort: true,
      type: "text",
      width: "80px",
    },
    {
      align: "left",
      id: "type",
      label: "TYPE",
      sort: true,
      filter: true,
      type: "localizedName",
      width: "auto",
      minWidth: "144px",
    },
  ],

};

export const myFulfilledLeavesIsMobile = {
  id: "my-fulfilled-leaves-table",
  select: "row",
  columns: [
    {
      align: "left",
      id: "startDate",
      label: "START_DATE",
      sort: true,
      type: "date",
      width: "120px",
    },
    {
      align: "left",
      id: "endDate",
      label: "END_DATE",
      sort: true,
      type: "date",
      width: "120px",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],

};

export const previousFulfilledLeaves = {
  id: "my-previous-fulfilled-leaves-table",
  select: "row",
  columns: [
    {
      align: "left",
      id: "year",
      label: "YEAR",
      sort: true,
      type: "text",
      width: "60px",
    },
    {
      align: "right",
      id: "fulfilledLeave",
      label: "FULFILLED_BASE_LEAVE",
      sort: true,
      type: "text",
      width: "auto",
    },
    {
      align: "right",
      id: "fulfilledAbsence",
      label: "FULFILLED_ABSENCE",
      sort: true,
      width: "auto",
    },
  ],
};

export const previousFulfilledLeavesisMobile = {
  id: "my-previous-fulfilled-leaves-table",
  select: "row",
  columns: [
    {
      align: "left",
      id: "year",
      label: "YEAR",
      sort: true,
      type: "text",
      width: "60px",
    },
    {
      align: "right",
      id: "fulfilledLeave",
      label: "FULFILLED_BASE_LEAVE",
      sort: true,
      type: "text",
      width: "auto",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const allLeaveRequests = {
  id: "all-leave-requests-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "name",
      type: "pipedName",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: true,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: true,
    },
    {
      id: "leaveBadges",
      type: "leaveBadges",
      label: "REQUEST",
      width: "120px",
    },
  ],
};

export const allLeaveRequestsIsMobile = {
  id: "all-leave-requests-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "52px",
    },
    {
      id: "name",
      type: "pipedName",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "leaveBadges",
      type: "leaveBadges",
      label: "REQUEST",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],

};


export const myLeaveRequests = {
  id: "employees-list-table",
  select: "row",
  columns: [
    {
      id: "leaveTypeName",
      type: "leaveTypeName",
      label: "TYPE",
      sort: true,
    },
    {
      id: "period",
      type: "text",
      label: "PERIOD",
      sort: true,
    },
    {
      id: "effectiveWorkDays",
      type: "text",
      label: "ABBREVIATED_WORKDAYS",
      align: "right",
      sort: true,
      width: "100px",
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
      sort: true,
      filter: true,
    },
    {
      id: "createdAt",
      type: "text",
      label: "REQUEST_CREATED_AT",
      sort: true,
    },
    {
      id: "messages",
      label: "",
      align: "center",
      type: "messageIndicator",
      width: "70px",
    },
  ],
};

export const myLeaveRequestsIsMobile = {
  id: "employees-list-table",
  select: "row",
  columns: [
    {
      id: "period",
      type: "text",
      label: "PERIOD",
      sort: true,
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const allLeaveRequestsWithCoverage = {
  id: "all-leave-requests-with-coverage-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "title",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "typeName",
      type: "typeName",
      label: "TYPE",
      sort: true,
      filter: true,
    },
    {
      id: "period",
      type: "text",
      label: "PERIOD",
      sort: true,
    },
    {
      id: "workDays",
      type: "text",
      label: "ABBREVIATED_WORKDAYS",
      align: "right",
      sort: true,
      width: "100px",
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
      sort: true,
      filter: true,
    },
  ],
};

export const allLeaveRequestsWithCoverageIsMobile = {
  id: "all-leave-requests-with-coverage-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "65px",
    },
    {
      id: "title",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const userLeaveRequests = {
  id: "employees-list-table",
  select: "row",
  columns: [
    {
      id: "leaveTypeName",
      type: "leaveTypeName",
      label: "TYPE",
      sort: true,
      filter: true,
    },
    {
      id: "period",
      type: "text",
      label: "PERIOD",
      sort: true,
    },
    {
      id: "effectiveWorkDays",
      type: "text",
      label: "ABBREVIATED_WORKDAYS",
      align: "right",
      sort: true,
      width: "100px",
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
      sort: true,
    },
    {
      id: "createdAt",
      type: "text",
      label: "REQUEST_CREATED_AT",
      sort: true,
    },
    {
      id: "messages",
      label: "",
      align: "center",
      type: "messageIndicator",
      width: "70px",
    },
  ],
};

export const userLeaveRequestsIsMobile = {
  id: "employees-list-table",
  select: "row",
  columns: [
    {
      id: "period",
      type: "text",
      label: "PERIOD",
      sort: true,
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const orphanedLeaveRequests = {
  id: "orphaned-leave-requests-table",
  select: "none",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "name",
      type: "nameAndJobClass",
      label: "NAME",
      sort: true,
      search: true,
      align: "left",
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      //width: "140px",
      sort: true,
      filter: true,
    },
    {
      id: "type",
      type: "localizedName",
      label: "TYPE",
      //width: "140px",
      sort: true,
    },
    {
      id: "startDate",
      type: "periodAndWorkDays",
      label: "PERIOD",
      sort: true,
    },
    {
      id: "leaveActions",
      type: "leaveActions",
      label: "",
      width: "120px",
    },
  ],
};

export const orphanedLeaveRequestsIsMobile = {
  id: "orphaned-leave-requests-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "50px",
    },
    {
      id: "name",
      type: "nameAndJobClass",
      label: "NAME",
      sort: true,
      search: true,
      align: "left",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const examinerDashboardSummary = {
  id: "examiner-dashboard-summary-table",
  select: "row",
  columns: [
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "40px",
    },
    {
      id: "name",
      type: "pipedName",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: true,
    },
    /*{
      id: "availableRoles",
      type: "availableRoles",
      label: "ROLE",
      sort: true,
      filter: true,
      width: "135px",
      align: "left",
    },*/
    {
      id: "baseLeaveBar",
      type: "baseLeaveBar",
      label: "Leave.leaveBase",
    },
    {
      id: "leaveBadges",
      type: "leaveBadges",
      label: "REQUEST",
      width: "120px",
    },
  ],
};

export const examinerDashboardSummaryIsMobile = {
  id: "examiner-dashboard-summary-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "52px",
    },
    {
      id: "name",
      type: "pipedName",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "leaveBadges",
      type: "leaveBadges",
      label: "REQUEST",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const allLeaveRequestsDailyTable = {
  id: "all-leave-requests-daily-table",
  select: "row",
  rowSpan: true,
  rowSpanField: "date",
  dayFormat: "dddd",
  columns: [
    {
      id: "date",
      label: "CALENDAR",
      type: "dateWithdayName",
      width: "200px",
      sort: true,
      rowSpan: true,
    },
    {
      id: "title",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
      rowSpan: true,
    },
    {
      id: "typeName",
      type: "typeName",
      label: "TYPE",
      sort: true,
      filter: true,
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
      sort: true,
      filter: true,
    },
  ],
};
