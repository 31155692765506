import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Currency from "oc/components/oc-currency";

const MyCafeteriaVbkApplicationTableRow = ({
  benefit,
  months,
  numberOfMonths,
  amount,
  valueOfBenefit,
  cost,
  isMobile
}) => {
  const columnWidth = "156px";
  return (
    <Box
      width={isMobile ? "max-content" : "auto"}
      height="48px"
      paddingLeft="8px"
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{ borderBottom: "1px solid #5E6366" }}
    >
      <Box
        flexGrow={1}
        textAlign="left"
        padding="0px 8px"
        style={{ minWidth: "180px" }}
      >
        <Typography variant="body1">{benefit}</Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="body1">{months}</Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="body1">{numberOfMonths}</Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="body1">
          <Currency value={amount} />
        </Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="body1">
          <Currency value={cost} />
        </Typography>
      </Box>
      <Box width={columnWidth} textAlign="right" padding="0px 8px">
        <Typography variant="body1">
          <Currency value={valueOfBenefit} />
        </Typography>
      </Box>
    </Box>
  );
};

MyCafeteriaVbkApplicationTableRow.propTypes = {
  /** The name of the current benefit*/
  benefit: PropTypes.string,
  /** The amount of the benefit item */
  amount: PropTypes.number,
  /** The type of the benefit */
  dueDate: PropTypes.string,
  /** The monthly amount of the benefit item */
  monthlyAmount: PropTypes.number,
};

MyCafeteriaVbkApplicationTableRow.defaultProps = {
  benefit: "",
  amount: 0,
  dueDate: "",
  monthlyAmount: 0,
};

export default MyCafeteriaVbkApplicationTableRow;
