import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import OcFormSwitch from "oc/components/oc-form-switch/oc-form-switch";
import { useTheme } from "@material-ui/styles";

export default function OcSettingSwitch({ label, info, value, onChange, disabled, id, isMobile }) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box width={isMobile ? "auto" : "200px"} textAlign="right" color={theme.palette.owGray.main}>
          <Typography variant="caption">{label}</Typography>
        </Box>
        <Box width="16px" />
        <OcFormSwitch
          id={id}
          checked={value}
          onChange={onChange}
          inputProps={{ "aria-label": "controlled" }}
          disabled={disabled}
        />
      </Box>
      <Box width="16px" />
      {isMobile && <Box height="16px" />}
      <Box width="300px">
        <Box
          fontSize="12px"
          letterSpacing="0.4px"
          color={theme.palette.owGray.main}
          width="300px"
        >
          {info}
        </Box>
      </Box>
    </Box>
  );
}
