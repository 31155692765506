/**
 * Component to show current cafeteria data
 * in a card with a dougnut chart
 *
 * Component documentation is available in Storybook
 *
 * Pál Pintér <pinter.pal@orgware.hu>
 * Created at: 2021.06.10
 */
import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Doughnut } from "react-chartjs-2";
import Currency from "oc/components/oc-currency";
import { useTranslation } from "react-i18next";
import OcChartLegend from "oc/components/oc-chart-legend";
import LoadingComponent from "@material-ui/lab/Skeleton";

const CHART_SIZE = 200;

const MyCafeteriaSummaryChart = ({
  allAmount,
  allBenefits,
  allCosts,
  availableAmount,
  loading,
  error,
  isMobile
}) => {
  const { t } = useTranslation();

  const data = {
    labels: ["Benefits", "Costs", "Remain"],
    datasets: [
      {
        label: "",
        data: [allBenefits, allCosts, availableAmount],
        backgroundColor: ["#cc0033", "#F3AA18", "#2FA84F"],
        borderWidth: 0,
      },
    ],
  };

  const loadingData = {
    labels: ["Loading"],
    datasets: [
      {
        label: "",
        data: [100],
        backgroundColor: ["#E3E5E5"],
        borderWidth: 0,
      },
    ],
  };

  let summary = allAmount;

  if (error)
    return (
      <Box
        //width="440px"
        height="232px"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        style={{
          border: "1px solid #cc0033",
          color: "#cc0033",
        }}
      >
        <Box>{error.message}</Box>
      </Box>
    );

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      paddingTop="16px"
    >
      <Box
        width={isMobile ? "100%" : "440px"}
        height={isMobile ? "auto" : "216px"}
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="center"
        alignItems={isMobile ? "center" : "unset"}
      >
        <Box position="relative" width={isMobile ? "200px" : "auto"}>
          <Doughnut
            options={{
              cutoutPercentage: 83,
              maintainAspectRatio: true,
              tooltips: { enabled: true },
              animation: {
                duration: 1000,
              },
            }}
            width={CHART_SIZE}
            height={CHART_SIZE}
            data={loading ? loadingData : data}
            legend={null}
          />
          <Box
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                style={{
                  marginTop: "4px",
                  fontFamily: "Mulish",
                  fontSize: summary?.toString().length >= 6 ? "22px" : "26px",
                  lineHeight: "32px",
                  letterSpacing: "0.19px",
                  color: "#232425",
                }}
              >
                {loading ? (
                  <LoadingComponent width={120} height={32} />
                ) : (
                  <Currency value={summary} />
                )}
              </Box>
              <Box
                style={{
                  fontFamily: "Mulish",
                  fontSize: "12px",
                  lineHeight: "16px",
                  letterSpacing: "0.4px",
                  color: "#919699",
                  marginTop: "16px",
                }}
              >
                {t("BUDGET")}
              </Box>
            </Box>
          </Box>
        </Box>
        {isMobile && <Box height="16px" />}
        <Box width="70px" />
        <Box minWidth="200px" width={isMobile ? "100%" : "auto"}>
          <OcChartLegend
            label={t("ALL_AMOUNT")}
            value={<Currency value={allBenefits} />}
            loading={loading}
            color="#cc0033"
            isMobile={isMobile}
          />
          <OcChartLegend
            label={t("ALL_COSTS")}
            value={<Currency value={allCosts} />}
            loading={loading}
            color="#F3AA18"
            isMobile={isMobile}
          />
          <OcChartLegend
            label={t("AVAILABLE_AMOUNTS")}
            showDivider={false}
            value={<Currency value={availableAmount} />}
            loading={loading}
            color="#2FA84F"
            isMobile={isMobile}
          />
        </Box>
      </Box>
    </Box>
  );
};

MyCafeteriaSummaryChart.propTypes = {
  /** Show the loading state of the component */
  loading: PropTypes.bool.isRequired,
  /** Show the all amount of the current cafeteria state*/
  allAmount: PropTypes.number.isRequired,
  /** Show the all costs of the current cafeteria application*/
  allCosts: PropTypes.number.isRequired,
  /** Show the all available amount of the current cafeteria application*/
  availableAmount: PropTypes.number.isRequired,
};

MyCafeteriaSummaryChart.defaultProps = {
  allAmount: 100,
  allCosts: 50,
  availableAmount: 50,
  loading: false,
};

export default MyCafeteriaSummaryChart;
