import React, { useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import MyHrBackToPage from "my-hr/components/my-hr-back-to-page";
import { useTranslation } from "react-i18next";
import { useRecoilState, useResetRecoilState } from "recoil";
import { roleFilterState } from "states";
import { useHistory } from "react-router-dom";
import OcCollapseHeader from "oc/components/oc-collapse-header";
import Collapse from "@material-ui/core/Collapse";
import OcActiveUserSelector from "oc/components/oc-active-user-selector";
import Grid from "@material-ui/core/Grid";
import OcOrgUnitFilter from "oc/components/oc-org-unit-filter";
import OcWorkingPlaceFilter from "oc/components/oc-working-place-filter";
import OcJobClassGroupFilter from "oc/components/oc-job-class-group-filter";
import OcPayofficeFilter from "oc/components/oc-payoffice-filter";
import OcEmployeeFilter from "oc/components/oc-employee-filter";
import _ from "lodash";
import OcFilteredListCount from "oc/components/oc-filtered-list-count";
import useFilteredListCount from "hooks/use-filtered-list-count.js";
import useFilteredList from "hooks/use-filtered-list";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import Button from "@material-ui/core/Button";
import Table from "oc/components/oc-data-table";
import { Typography } from "@material-ui/core";
import plusIcon from "@iconify/icons-mdi/plus";
import { InlineIcon } from "@iconify/react";
import OcSwitch from "oc/components/oc-form-switch";
import { useMutation } from "@apollo/react-hooks";
import { SEND_GROUP_PASSWORDS } from "graphql/mutations";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import MyHrMassRoleDialog from "my-hr/components/my-hr-mass-role-dialog";
import useProperty from "hooks/use-oc-property";
import OcCompanyFilter from "oc/components/oc-company-filter";
import MyLeaveFilterDivider from "my-leave/components/my-leave-filter-divider";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import { sendGroupPasswordEmployeeList, sendGroupPasswordEmployeeListIsMobile } from "my-hr/configs/my-hr-table-defs";

const SendGroupPasswordsPage = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const { data: loginType } = useProperty({
    resource: "system",
    key: "loginType",
  });

  const [expanded, setExpanded] = useState(false);
  const [userActiveState, setUserActiveState] = useState("active");
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [roleFilters, setRoleFilters] = useRecoilState(roleFilterState);
  const [replaceExistingPasswords, setRplaceExistingPasswords] =
    React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [sendGroupPasswords] = useMutation(SEND_GROUP_PASSWORDS, {
    onCompleted: (data) => {
      setResult(data?.sendGroupPasswords);
      setDisabled(false);
    },
    onError: (error) => {
      console.log({ error });
      setDisabled(false);
    },
  });
  const userRoleFilters = {
    company: [],
    orgUnit: [],
    workingPlace: [],
    jobClassGroup: [],
    payOffice: [],
    contract: [],
  };

  let tableId = "filteredListTableData";
  initialTableData.pageSize = 5;
  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const resetRoleFilters = useResetRecoilState(roleFilterState);

  function handleCancel() {
    resetRoleFilters();
    history.push("/my-hr/users");
  }

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function handleUserFilterClick(state) {
    setUserActiveState(state);
  }

  function handleAddFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].concat([value]);
    setRoleFilters(newState);
  }

  function handleRemoveFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].filter((item) => item !== value);
    setRoleFilters(newState);
  }

  const { data: filteredListCount } = useFilteredListCount({
    isActive: userActiveState === "active" ? true : false,
    roleFilters,
  });

  const { data: filteredList, loading } = useFilteredList({
    page: tableData.page,
    pageSize: tableData.pageSize,
    isActive: userActiveState === "active" ? true : false,
    roleFilters,
  });

  const handleSend = () => {
    setDisabled(true);
    const saveParams = {
      roleFilters,
      replaceExistingPasswords,
      locale: i18n.language,
    };
    sendGroupPasswords({ variables: saveParams });
  };

  function generateConfirmationText() {
    if (
      roleFilters.company.length === 0 &&
      roleFilters.orgUnit.length === 0 &&
      roleFilters.workingPlace.length === 0 &&
      roleFilters.jobClassGroup.length === 0 &&
      roleFilters.payOffice.length === 0 &&
      roleFilters.contract.length === 0
    )
      return "SEND_GROUP_PASSWORDS_TO_ALL_DIALOG_TEXT";
    return "SEND_GROUP_PASSWORDS_DIALOG_TEXT";
  }

  const maxWidthStyle = { maxWidth: isMobile ? "100%" : "50%" };

  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);

  function handleOKConfirmationDialog() {
    setShowConfirmationDialog(false);
    setShowDialog(true);
    handleSend();
  }

  function handleCancelConfirmationDialog() {
    setShowConfirmationDialog(false);
  }

  const [showDialog, setShowDialog] = React.useState(false);
  const [result, setResult] = useClientState("sendGroupPasswordsResult", "");

  function handleShowDialog() {
    setShowDialog(!showDialog);
  }

  function handleFinish() {
    setShowDialog(false);
    resetRoleFilters();
    setResult("");
  }

  function handleCloseImportDialog() {
    setShowDialog(false);
  }

  const tableDef = isMobile
    ? sendGroupPasswordEmployeeListIsMobile
    : sendGroupPasswordEmployeeList;

  const filteredTableDef = {
    id: sendGroupPasswordEmployeeList?.id,
    columns: sendGroupPasswordEmployeeList?.columns?.filter(column =>
      !sendGroupPasswordEmployeeListIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  const onSelectRow = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
    }
  };

  if (loginType === "sso") {
    return (
      <OcPageTransition>
        <Box padding={"16px"}>
          <MyHrBackToPage
            label={t("ADD_GROUP_PASSWORD_TITLE")}
            onBack={handleCancel}
          />
          <OcCard label={t("ADD_GROUP_PASSWORD_TITLE")} loading={false}>
            <>
              <Box>
                <Typography>{t("ADD_GROUP_PASSWORD_NOT_ON_SSO")}</Typography>
              </Box>
            </>
          </OcCard>
        </Box>
      </OcPageTransition>
    );
  }

  return (
    <OcPageTransition>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <OcConfirmationDialog
          showDialog={showConfirmationDialog}
          title={t("SEND_GROUP_PASSWORDS_DIALOG_TITLE")}
          text={t(generateConfirmationText())}
          okButtonText={t("SAVE")}
          cancelButtonText={t("CANCEL")}
          onClose={handleOKConfirmationDialog}
          onCancel={handleCancelConfirmationDialog}
        />
        <MyHrMassRoleDialog
          onShowDialog={handleShowDialog}
          showDialog={showDialog}
          onFinish={handleFinish}
          onCloseDialog={handleCloseImportDialog}
          result={result}
          infoText={t("SEND_GROUP_PASSWORDS_INFO")}
          successText={t("SEND_GROUP_PASSWORDS_SUCCEEDED")}
          successInfo={t("PASSWORD_SENT")}
          toolbarLabel={t("ADD_GROUP_PASSWORD_TITLE")}
          isMobile={isMobile}
        />

        <Box padding={isMobile ? "16px 0px" : "16px"}>
          <MyHrBackToPage
            label={t("ADD_GROUP_PASSWORD_TITLE")}
            onBack={handleCancel}
            isMobile={isMobile}
          />
          <Box height="16px" />
          <OcCard label={t("User.users")} loading={disabled}>
            <>
              <Box
                style={{
                  backgroundColor: "#F1F3F4",
                  borderRadius: "4px",
                }}
              >
                <OcCollapseHeader
                  expanded={expanded}
                  onExpandClick={handleExpandClick}
                />
                <Collapse
                  in={expanded}
                  timeout="auto"
                  style={{
                    backgroundColor: "#F1F3F4",
                    borderRadius: "4px",
                  }}
                >
                  <Box
                    id="collapse-body"
                    padding="0px 16px"
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                  >
                    <Box
                      style={{
                        backgroundColor: "#C6CACC",
                        height: "2px",
                        width: "100%",
                      }}
                    />
                    <OcActiveUserSelector
                      filterState={userActiveState}
                      onClick={handleUserFilterClick}
                    />
                    <Box
                      id="selector-container"
                      width={isMobile ? "100%" : "66%"}
                      style={{ paddingBottom: "24px" }}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "unset"
                        }}
                      >
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcCompanyFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcOrgUnitFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcWorkingPlaceFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcJobClassGroupFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcPayofficeFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                      </Grid>
                      <Box height="16px" />
                      <MyLeaveFilterDivider />
                      <Box height="16px" />
                      <Grid item xs={6} style={maxWidthStyle}>
                        <OcEmployeeFilter
                          roleFilters={roleFilters}
                          userRoleFilters={userRoleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product="MyHR"
                          role="admin"
                        />
                      </Grid>
                      <Box minHeight="16px" />
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <OcFilteredListCount listCount={filteredListCount} isMobile={isMobile} />
                        </Grid>
                      </Grid>
                      <Box style={{ paddingTop: "24px" }}>
                        <Button id="reset-button" onClick={resetRoleFilters}>
                          {t("RESET")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </Box>
              <Box paddingTop="32px">
                <Table
                  data={filteredList}
                  loading={loading && !tableData}
                  tableDef={tableDef}
                  tableData={tableData}
                  setTableData={setTableData}
                  pagination={true}
                  onSelectRow={onSelectRow}
                  filteredTableDef={filteredTableDef}
                  selectedRowId={selectedRowId}
                />
              </Box>
              {isMobile && <Box height="16px" />}
              <Box display="flex" flexDirection="row" alignItems="center">
                <OcSwitch
                  id="replaceExistingPasswords"
                  checked={replaceExistingPasswords}
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={(e) => setRplaceExistingPasswords(e.target.checked)}
                  disabled={disabled}
                />
                <Box width="16px" />
                <Typography variant="body1">
                  {t("PASSWORD_SWITCH_TEXT")}
                </Typography>
              </Box>
              <Box paddingTop="32px">
                <Typography variant="body1">
                  {t("SEND_PASSWORD_INFO_TEXT")}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                paddingTop="24px"
              >
                <Button
                  id="new-password-button"
                  variant="contained"
                  color="primary"
                  disableElevation
                  startIcon={<InlineIcon icon={plusIcon} />}
                  disabled={disabled}
                  onClick={() => setShowConfirmationDialog(true)}
                >
                  {t("NEW_PASSWORD")}
                </Button>
              </Box>
            </>
          </OcCard>
        </Box>
      </Box>
    </OcPageTransition>
  );
};

export default SendGroupPasswordsPage;
