import React from "react";
import MyCafeteriaVbkApplicationCardPrint from "my-cafeteria/components/my-cafeteria-vbk-application-card-print";

class MyCafeteriaVbkApplication extends React.PureComponent {
  render() {
    const { applicationDate, employeeName, idNumber, contractCode, currentYear } = this.props;
    return (
      <MyCafeteriaVbkApplicationCardPrint
        applicationDate={applicationDate}
        employeeName={employeeName}
        idNumber={idNumber}
        contractCode={contractCode}
        currentYear={currentYear}
      />
    );
  }
}

export default MyCafeteriaVbkApplication;
