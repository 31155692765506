import gql from "graphql-tag";

export const USERS_TO_MY_DOCUMENTS_PAGES = gql`
  mutation usersToMyDocumentsPages(
    $roleFilters: RoleFilterInput
    $sortField: String
    $sortDirection: String
    $searchField: String
    $searchText: String
    $role: String
    $page: Int
    $pageSize: Int
  ) {
    usersToMyDocumentsPages(
      roleFilters: $roleFilters
      sortField: $sortField
      sortDirection: $sortDirection
      searchField: $searchField
      searchText: $searchText
      role: $role
      page: $page
      pageSize: $pageSize
    ) {
      rows {
        employee
        name
        orgUnit
        jobClass
        contract
        _id
      }
      total
      page
      pageSize
      totalPages
      allUsersCount
    }
  }
`;
