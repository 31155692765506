import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_CUSTOMER_LICENCES } from "graphql/queries";
import usePolling from "hooks/use-polling";
import { useRecoilState } from "recoil";
import { customerLicencesState } from "states";
import _ from "lodash";

const useCustomerLicences = () => {
  const [customerLicences, setCustomerLicences] = useRecoilState(
    customerLicencesState
  );
  const { data, loading, error } = useQuery(GET_CUSTOMER_LICENCES, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
  });

  //Cannot update a component (`App`) error requires useEffect
  useEffect(() => {
    if (data?.getCustomerLicences) {
      const result = data?.getCustomerLicences || {};
      const ocLicences = result?.ocLicences || null;
      if (result && ocLicences) {
        const newState = { ...ocLicences };
        delete newState.__typename;
        if (!_.isEqual(customerLicences, newState)) {
          setCustomerLicences(newState);
        }
      }
    }
  }, [data, customerLicences, setCustomerLicences]);

  return {
    data: data?.getCustomerLicences || {},
    licences: data?.getCustomerLicences?.ocLicences || {},
    loading,
    error,
  };
};

export default useCustomerLicences;
