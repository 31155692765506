import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import useStyles from "./my-hr-attachment-dialog.style";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

const MyHrAttachmentDialog = ({
  showDialog,
  handleCancel,
  fileData,
  pdfSize,
}) => {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  /*const [numPages, setNumPages] = React.useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };*/

  const fileSrc = fileData?.slug || fileData?.filename;

  return (
    <Dialog
      aria-labelledby="dialog-title"
      open={showDialog}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          return false;
        }
      }}
      disableEscapeKeyDown
      scroll="body"
      maxWidth={pdfSize ? "lg" : "sm"}
    >
      <DialogContent className={classes.content}>
        <Box height="16px" />
        <FormControl variant="outlined" className={classes.formControl}>
          {pdfSize && (
            <iframe
              src={fileSrc}
              title="PDF visualization"
              width="100%"
              height="600px"
            />
            /*<Document
            file={fileData?.filename}
             onLoadSuccess={onDocumentLoadSuccess}
            />
            {Array.from(new Array(numPages), (el, index) => (
             <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            </FormControl>   ))}
            </Document>*/
          )}
          {!pdfSize && <img src={fileSrc} alt="Attachment" />}
          <Box height="16px" />
          <Button id="dialog-cancel-button" fullWidth onClick={handleCancel} style={{ pointerEvents: "auto" }}>
            {t("Leave.close")}
          </Button>
          <Box height="16px" />
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};

export default MyHrAttachmentDialog;
