import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const MyHrUserDataCardInfoItem = ({ label, value, isMobile }) => {
  const theme = useTheme();

  return (
    <>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"}>
        <Box width={isMobile ? "auto" : "220px"} textAlign="right">
          <Typography
            variant="body1"
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              color: theme.palette.owGray.main,
            }}
          >
            {label}
          </Typography>
        </Box>
        <Box width="16px" />
        <Box>
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              color: theme.palette.darkBase.main,
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
      <Box height="10px" />
    </>
  );
};

export default MyHrUserDataCardInfoItem;
