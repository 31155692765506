import gql from "graphql-tag";

export const GET_OPEN_REQUEST_LIST = gql`
  query getOpenRequestsList($startDate: Date!, $endDate: Date!) {
    getOpenRequestsList(startDate: $startDate, endDate: $endDate) {
      prefixName
      lastName
      firstName
      middleName
      nameSuffix
      orgUnit
      jobClass
      recordCount
      employee
      startTime
      endTime
      _id
    }
  }
`;
