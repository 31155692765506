import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: ({ isMobile }) => ({
    width: "100%",
    backgroundImage: "url(/img/login-background.png)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: isMobile ? "auto" : "100vh",
  }),
}));

export default useStyles;
