import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  chartContainerRoot: ({ isMobile }) => ({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-between",
    alignItems: isMobile ? "center" : "unset"
  }),
  chartContainer: ({ width, chartSize, isMobile }) => ({
    display: "flex",
    position: "relative",
    width: width,
    height: chartSize,
    padding: 5,
    textAlign: "center",
    marginRight: isMobile ? 0 : 28,
    marginLeft: isMobile ? 0 : 12,
  }),
  legendMainContainer: ({ chartSize, legendWidth }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "auto",
    width: "100%",
    paddingTop: 5,
  }),
});

export default useStyles;
