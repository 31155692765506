import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import MyCafeteriaVbkApplicationTableHeader from "my-cafeteria/components/my-cafeteria-vbk-application-table-header";
import MyCafeteriaVbkApplicationTableRow from "my-cafeteria/components/my-cafeteria-vbk-application-table-row";
import useCurrentCafeteriaStatementItems from "my-cafeteria/hooks/use-current-cafeteria-statement-items";
import useCurrentUserState from "hooks/use-current-user-state";

const MyCafeteriaVbkApplicationTable = ({ isMobile }) => {
  let { contractCode } = useCurrentUserState();
  const { data: statementItems } = useCurrentCafeteriaStatementItems({
    contractCode,
  });

  return (
    <Box>
      <MyCafeteriaVbkApplicationTableHeader isMobile={isMobile} />
      {statementItems?.map((item, index) => (
        <MyCafeteriaVbkApplicationTableRow
          key={`data-row-${index}`}
          benefit={item.menuItem.name}
          months={item.months}
          numberOfMonths={item.numberOfMonths}
          amount={item.amount}
          valueOfBenefit={item.valueOfBenefit}
          cost={item.cost}
          isMobile={isMobile}
        />
      ))}
    </Box>
  );
};

MyCafeteriaVbkApplicationTable.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaVbkApplicationTable.defaultProps = {
  example: "example",
};

export default MyCafeteriaVbkApplicationTable;
