import React, { useRef } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import MyCafeteriaVbkApplicationPrint from "my-cafeteria/components/my-cafeteria-vbk-application-print";
import OcPrintHeader from "oc/components/oc-print-header/oc-print-header";
import { useHistory } from "react-router-dom";
import OcPageTransition from "oc/components/oc-page-transition";
import { useReactToPrint } from "react-to-print";
import Card from "@material-ui/core/Card";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import useCurrentUserState from "hooks/use-current-user-state";
import useEmployee from "hooks/use-myhr-employee";
import getFullname from "oc/utils/get-fullname";
import Typography from "@material-ui/core/Typography";
import MyCafeteriaInfoLine from "my-cafeteria/components/my-cafeteria-info-line";
import MyCafeteriaStatementTitle from "my-cafeteria/components/my-cafeteria-statement-title";
import { useTranslation } from "react-i18next";
import useCurrentCafeteriaStatement from "my-cafeteria/hooks/use-current-cafeteria-statement";
import MyCafeteriaVbkApplicationTable from "my-cafeteria/components/my-cafeteria-vbk-application-table";
import Currency from "oc/components/oc-currency";

const MyCafeteriaVbkApplicationPage = () => {
  const history = useHistory();
  const componentRef = useRef();
  const isMobile = useIsMobile();

  function historyBack() {
    history.goBack();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let { contractCode, employeeCode } = useCurrentUserState();

  const { data: employeeData } = useEmployee({ employeeId: employeeCode });

  let params = {
    applicationDate: new Date().toLocaleDateString(),
    employeeName: getFullname(employeeData),
    idNumber: employeeData?.taxNumber,
    contractCode: contractCode,
    currentYear: new Date().getFullYear(),
  };

  const { t } = useTranslation();
  const { data: statement } = useCurrentCafeteriaStatement({
    contractCode,
  });


  return (
    <OcPageTransition>
      <Box padding={isMobile ? "16px 0px" : "16px"}>
        <OcPrintHeader
          title="VBK Nyilatkozat"
          onBack={historyBack}
          onPrint={handlePrint}
          isMobile={isMobile}
        />
        <Box height="16px" />
        <Card
          id="my-cafeteria-summary-card"
          variant="outlined"
          style={{ borderRadius: "4px", border: "1px solid #E3E5E5" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            padding="16px"
          >
            <Box id="upper-component" paddingBottom="48px">
              <MyCafeteriaStatementTitle label={t("VBKSTATEMENT")} />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                paddingBottom="16px"
              >
                <Box id="data-block" width={isMobile ? "100%" : "auto"}>
                  <MyCafeteriaInfoLine
                    label={t("CALENDAR_MESSAGES_DATE")}
                    value={params?.applicationDate}
                    isMobile={isMobile}
                  />
                  <MyCafeteriaInfoLine
                    label={t("STATEMENT_EMPLOYEE_NAME")}
                    value={params?.employeeName}
                    isMobile={isMobile}
                  />
                  <MyCafeteriaInfoLine
                    label={t("STATEMENT_TAX_ID_SIGN_ID")}
                    value={params?.idNumber}
                    isMobile={isMobile}
                  />
                  <MyCafeteriaInfoLine
                    label={t("STATEMENT_CONTRACT_CODE")}
                    value={contractCode}
                    isMobile={isMobile}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box width={isMobile ? "auto" : "221px"} textAlign="right">
                  <Typography variant="h5">{t("BUDGET")}</Typography>
                </Box>
                <Box width="16px" />
                <Box>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    <Currency value={statement?.allAmount} />
                  </Typography>
                </Box>
              </Box>
              <Box height="45px" />
              <Box
                padding={isMobile ? "0px" : "0px 17px"}
                style={{
                  overflowX: isMobile ? "scroll" : "unset",
                  overflowY: isMobile ? "hidden" : "unset",
                  whiteSpace: isMobile ? "nowrap" : "unset",
                }}
              >
                <MyCafeteriaVbkApplicationTable isMobile={isMobile} />
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box style={{ display: 'none' }}>
        <MyCafeteriaVbkApplicationPrint
          ref={componentRef}
          applicationDate={params.applicationDate}
          employeeName={params.employeeName}
          idNumber={params.idNumber}
          contractCode={params.contractCode}
          currentYear={params.currentYear}
        />
      </Box>
    </OcPageTransition>
  );
};

MyCafeteriaVbkApplicationPage.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaVbkApplicationPage.defaultProps = {
  example: "example",
};

export default MyCafeteriaVbkApplicationPage;
