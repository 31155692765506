import React, { useState, useEffect } from "react";
import { Box, RadioGroup } from "@material-ui/core";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import useOcSnackbar from "hooks/use-oc-snackbar";
import MyTimeTimePeriodOption from "my-time/components/my-time-time-period-options/my-time-time-period-options";

const MyTimeTimePeriodSettingsCard = ({ isMobile }) => {
  const { t } = useTranslation();

  const { setOpenMessage } = useOcSnackbar();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [timePeriod, setTimePeriod] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const { data } = useProperty({
    resource: "myTime",
    key: "timePeriod",
    onCompleted: ({ value }) => {
      const newTimePeriod = parseInt(value);
      setTimePeriod(newTimePeriod);
      if (timePeriod !== newTimePeriod) {
        setDisabled(false);
      }
    },
  });

  const [saveProperty] = useMutation(UPDATE_PROPERTY, {
    onError({ ...err }) {
      console.log(err);
      setDisabled(false);
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
    onCompleted(data) {
      const newTimePeriod = parseInt(data.updateProperty.value);
      setTimePeriod(newTimePeriod);
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
  });

  const handleCancel = () => {
    setDisabled(true);
    setTimePeriod(parseInt(data));
  };

  const handleSave = () => {
    setDisabled(true);
    saveProperty({
      variables: {
        resource: "myTime",
        key: "timePeriod",
        value: timePeriod.toString(),
      },
    });
    setShowConfirmDialog(false);
  };

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  const handleTimePeriodChange = (event) => {
    const selectedValue = event.target.value;
    const currentDataValue = data?.toString();
    if (selectedValue !== currentDataValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setTimePeriod(parseInt(selectedValue));
  };

  useEffect(() => {
    if (data && timePeriod !== undefined) {
      if (timePeriod.toString() === data.toString()) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [data, timePeriod]);

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("MY_TIME_TIME_PERIOD_SETTINGS")}>
        <Box
          padding="8px 0px 24px 0px"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <RadioGroup value={timePeriod} onChange={handleTimePeriodChange}>
            <MyTimeTimePeriodOption
              id="no-time-period"
              value={0}
              label={t("NO_TIME_PERIOD")}
              infoLabel={t("NO_TIME_PERIOD_INFO")}
              isMobile={isMobile}
            />
            <MyTimeTimePeriodOption
              id="10-minutes-time-period"
              value={10}
              label={t("10_MINUTES_TIME_PERIOD")}
              infoLabel={t("10_MINUTES_TIME_PERIOD_INFO")}
              isMobile={isMobile}
            />
            <MyTimeTimePeriodOption
              id="30-minutes-time-period"
              value={30}
              label={t("30_MINUTES_TIME_PERIOD")}
              infoLabel={t("30_MINUTES_TIME_PERIOD_INFO")}
              isMobile={isMobile}
            />
          </RadioGroup>
        </Box>
        <OcCardButtonBar
          handleRightButton={handleCancel}
          rightButtonText={t("CANCEL")}
          handlePrimaryButton={() => setShowConfirmDialog(true)}
          primaryButtonText={t("SAVE")}
          primaryButtonDisabled={disabled}
        />
      </OcCard>
    </>
  );
};

export default MyTimeTimePeriodSettingsCard;
