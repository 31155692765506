import React from "react";
import "moment/locale/hu";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import LeftArrowIcon from "@iconify/icons-mdi/arrow-left";
import Typography from "@material-ui/core/Typography";
import Grow from "@material-ui/core/Grow";
import chevronLeft from "@iconify/icons-mdi/chevron-left";
import chevronRight from "@iconify/icons-mdi/chevron-right";
import { useTheme } from "@material-ui/core/styles";

export default function MyHrMonthSelector({
  year,
  month,
  onClose,
  previousonClick,
  nextOnClick,
  backChevron,
  isMobile
}) {
  const theme = useTheme();

  return (
    <Box>
      <Grow in={true}>
        <Box
          height={isMobile ? "auto" : "64px"}
          bgcolor={`${theme.palette.primary.main}`}
          borderRadius="8px"
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
          padding={isMobile ? "16px" : "0px"}
        >
          {backChevron === true &&
            <>
              <Box
                width="24px"
                height="24px"
                color="white"
                paddingLeft={isMobile ? "0px" : "22px"}
                style={{ cursor: "pointer" }}
                id="my-hr-month-svg-click"
              >
                <InlineIcon
                  icon={LeftArrowIcon}
                  style={{
                    color: "white",
                    width: "24px",
                    height: "24px",
                  }}
                  onClick={onClose}
                />
              </Box>
            </>}
          {isMobile && backChevron && <Box height="16px" />}
          <Box
            flexGrow={1}
            textAlign="center"
            color="white"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "space-between" : "center",
              textTransform: "capitalize",
              width: isMobile ? "100%" : "auto"
            }}
          >
            <InlineIcon
              id="my-hr-month-left"
              icon={chevronLeft}
              width="36px"
              color={`${theme.palette.white.main}`}
              style={{ cursor: "pointer", marginLeft: isMobile ? "-9px" : "0px" }}

              onClick={previousonClick}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              width="300px"
            >
              <Typography
                variant="h4"
                style={{ color: theme.palette.white.main }}
              >
                {year}. {month}
              </Typography>
            </Box>
            <InlineIcon
              id="my-hr-month-right"
              icon={chevronRight}
              width="36px"
              height="36px"
              color={`${theme.palette.white.main}`}
              style={{ cursor: "pointer", marginRight: isMobile ? "-9px" : "0px" }}
              onClick={nextOnClick}
            />
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}
