import React, { useEffect, useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import useClientState from "hooks/use-oc-client-state";
import useClientState2 from "hooks/use-oc-client-state-2";
import { useHistory } from "react-router-dom";
import useEmployee from "hooks/use-myhr-employee";
import EmployeeList from "./components/employee-list";
import AddPermission from "./components/add-permission";
import AddRole from "./components/add-role";
import Menu from "./components/page-menu";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import usePermissionForm from "hooks/use-myhr-permission-form";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import { CREATE_USER } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import { InlineIcon } from "@iconify/react";
import alertIcon from "@iconify/icons-mdi/alert-circle-outline";
import { useLazyQuery } from "@apollo/client";
import { GET_IS_UNIQUE_EMAIL } from "graphql/queries";
import { showDialogState, stepState } from "states";
import { useRecoilState } from "recoil";
import useOcSnackbar from "hooks/use-oc-snackbar";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

const newUserInitialData = {
  employee: "",
  fullname: "",
  email: "",
  phone: "",
  ldapUser: "",
  validFrom: "",
  validTo: "",
  roles: [],
  locate: "",
};

export default function MyHrCreateNewUserPage() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const [showDialog, setShowDialog] = useRecoilState(showDialogState);

  const [loading, setLoading] = useState(false);

  const { setOpenMessage } = useOcSnackbar();


  const [step, setStep] = useRecoilState(stepState);
  const tableId = "employeeList";

  let initialTableDataClone = _.cloneDeep(initialTableData);

  initialTableDataClone.sortField = "fullname";
  initialTableDataClone.pageSize = 25;

  const [tableData, setTableData] = useClientState(
    tableId,
    initialTableDataClone
  );

  const [user, setUser] = useClientState("newUser", newUserInitialData);

  const [unsavedRole, setUnsavedRole] = useClientState2("unsavedRole", {
    type: "unsavedRoles",
    roles: [],
  });
  const [showUnsavedRole, setShowUnsavedRole] = useClientState2(
    "showUnsavedRole",
    false
  );

  const { data: employee } = useEmployee({
    employeeId: tableData?.selectedRowValue,
  });

  const { data: contractsData } = useContractsByEmployee({
    employee: tableData?.selectedRowValue,
  });

  const {
    email,
    setEmail,
    onClear: onPermissionFormClear,
    isValid,
  } = usePermissionForm();

  function handleStep(newStep) {
    if (step !== newStep) {
      setStep(newStep);
    }
  }

  function handleCancel() {
    setStep(1);
    onPermissionFormClear();
    setTableData(initialTableDataClone);
    setUser(newUserInitialData);
    setUnsavedRole({
      type: "unsavedRoles",
      roles: [],
    });
    setShowUnsavedRole(false);
    history.push("/my-hr/users");
  }

  function handleBack() {
    if (step > 1) {
      let newStep = step - 1;
      setStep(newStep);
      setUser(newUserInitialData);
    }
  }

  const [isUniqueEmail] = useLazyQuery(GET_IS_UNIQUE_EMAIL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.isUniqueEmail) {
        setStep(3);
      } else {
        setEmail({
          email: email.value,
          error: true,
          errorText: t("ERROR_MESSAGE_EMAIL_IS_EXIST"),
        });
      }
    },
  });

  function handleForward() {
    if (step === 1 && tableData?.selectedRowValue) {
      setStep(2);
    }
    if (step === 2) {
      if (isValid() && email?.value) {
        isUniqueEmail({ variables: { email: email.value } });
      }
    }
    return;
  }

  function handleSelectRow(o) {
    setUser(newUserInitialData);
    onPermissionFormClear();
    setTableData({ selectedRowValue: o.value });
  }

  const [createUser] = useMutation(CREATE_USER, {
    onError({ ...err }) {
      console.log("error", err);
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: t("APPROVE_LEAVE_REQUEST_FAILED"),
      });

    },
    onCompleted(res) {
      setOpenMessage({
        type: "success",
        message: t("USER_UPDATED"),
      });
      setLoading(false);
      history.push("/my-hr/users");
      setStep(1);
    },
  });

  function handleSaveNewUser(e) {
    e.preventDefault();
    if (unsavedRole?.roles?.length > 0) {
      setShowDialog(true);
      setShowUnsavedRole(true);
      return;
    }

    let newState = { ...user };
    newState.locale = i18n.language;
    setLoading(true);
    createUser({
      variables: newState,
    });
  }

  function handleAddUnsavedRole(role) {
    let tempClone = _.cloneDeep(unsavedRole);
    tempClone.roles.push(role);
    setUnsavedRole(tempClone);
  }

  function handleRemoveUnsavedRole(role) {
    let tempClone = _.cloneDeep(unsavedRole);
    let filtered = unsavedRole.roles.filter(
      (item) =>
        item.contract !== role.contract &&
        item.role !== role.role &&
        item.product !== role.product
    );
    tempClone.roles = filtered;
    setUnsavedRole(tempClone);
  }

  function handleDialogClose() {
    setShowDialog(false);
  }

  function isDisabled() {
    if (step === 2 && user && !user?.validFrom) return true;
    if (
      step === 2 &&
      user?.validFrom &&
      user?.validTo &&
      new Date(user.validFrom) > new Date(user.validTo)
    ) {
      return true;
    }
    if (step === 1 && !tableData?.selectedRowValue) {
      return true;
    }
    if (step === 2 && !email.value) {
      return true;
    }
    return false;
  }

  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    if (
      step === 2 &&
      user?.validFrom &&
      user?.validTo &&
      new Date(user.validFrom) > new Date(user.validTo)
    ) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [step, user]);

  return (
    <React.Fragment>
      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={showDialog}
      >
        <Box width="476px" padding="16px">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            paddingBottom="16px"
          >
            <Box paddingRight="20px">
              <InlineIcon icon={alertIcon} width="30px" color="#cc0033" />
            </Box>
            <Box fontSize="16px" letterSpacing="0.44px" color="#5E6366">
              {t("UNSAVED_ELEMENTS")}
            </Box>
          </Box>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            fullWidth
            onClick={handleDialogClose}
          >
            OK
          </Button>
        </Box>
      </Dialog>
      <OcPageTransition>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Box>
            <Box
              height="80px"
              bgcolor="#cc0033"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              padding="0px 14px"
            >
              <span
                style={{
                  color: "white",
                  fontSize: "24px",
                  lineHeight: "30px",
                }}
              >
                {t("CREATE_USER")}
              </span>
            </Box>
            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <Menu step={step} onChangeStep={handleStep} />
              <Box flexGrow={1} padding={isMobile ? "16px 0px" : "16px"}>
                {step === 1 && (
                  <EmployeeList
                    tableId={tableId}
                    tableData={tableData}
                    setTableData={setTableData}
                    onSelectRow={handleSelectRow}
                    isMobile={isMobile}
                  />
                )}
                {step === 2 && (
                  <AddPermission
                    employee={employee}
                    contractsData={contractsData}
                    user={user}
                    setUser={setUser}
                    dateError={dateError}
                    isMobile={isMobile}
                  />
                )}
                {step === 3 && (
                  <AddRole
                    id="my-hr-create-new-user-page-button"
                    employee={employee}
                    contractsData={contractsData}
                    user={user}
                    setUser={setUser}
                    unsavedRole={unsavedRole}
                    showUnsavedRole={showUnsavedRole}
                    onAddUnsavedRole={handleAddUnsavedRole}
                    onRemoveUnsavedRole={handleRemoveUnsavedRole}
                    loading={loading}
                    isMobile={isMobile}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box
            height={isMobile ? "auto" : "56px"}
            border="1px solid #C6CACC"
            style={{ backgroundColor: "white" }}
            display="flex"
            flexDirection={isMobile ? "column-reverse" : "row"}
            justifyContent="space-between"
            alignItems="center"
            padding={isMobile ? "16px" : "0px"}
          >
            <Box>
              <Button
                id="my-hr-create-new-user-page-cancel-button"
                style={{ marginRight: isMobile ? "0px" : "16px", width: isMobile ? "100%" : "auto" }}
                onClick={handleCancel}
              >
                {t("CANCEL")}
              </Button>
            </Box>
            {isMobile && <Box height="16px" />}
            <Box
              width={isMobile ? "100%" : "auto"}
              display={isMobile ? "flex" : "unset"}
              flexDirection={isMobile ? "column-reverse" : "unset"}
            >
              {step > 1 && (
                <Button
                  id="my-hr-create-new-user-page-back-button"
                  style={{ marginRight: isMobile ? "0px" : "16px", width: isMobile ? "100%" : "auto" }}
                  onClick={handleBack}
                >
                  {t("BACK")}
                </Button>
              )}
              {isMobile && step > 1 && <Box height="16px" />}
              {step < 3 && (
                <Button
                  id="my-hr-create-new-user-page-button"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ marginRight: isMobile ? "0px" : "16px", width: isMobile ? "100%" : "auto" }}
                  onClick={handleForward}
                  disabled={isDisabled()}
                >
                  {t("NEXT")}
                </Button>
              )}
              {step === 3 && (
                <Button
                  id="my-hr-create-new-user-page-save-button"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ marginRight: isMobile ? "0px" : "16px", width: isMobile ? "100%" : "auto" }}
                  onClick={handleSaveNewUser}
                >
                  {t("SAVE")}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </OcPageTransition>
    </React.Fragment>
  );
}
