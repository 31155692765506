import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { InlineIcon } from "@iconify/react";
import arrowLeftIcon from "@iconify/icons-mdi/arrow-left";
import { useTheme } from "@material-ui/core/styles";
import { selectedTaxDeclarationState } from "states/selected-tax-declaration-state";
import { useRecoilState, useResetRecoilState } from "recoil";

const useSelectedTaxDeclaration = () => {
  const [selected, setSelected] = useRecoilState(selectedTaxDeclarationState);
  const reset = useResetRecoilState(selectedTaxDeclarationState);
  return {
    selected,
    setSelected,
    reset,
  };
};

const ApplicationHeader = ({ title, onCancel, noWrap = true, isMobile }) => {
  const theme = useTheme();

  const { reset } = useSelectedTaxDeclaration();

  const handleBack = () => {
    onCancel();
    reset();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      padding="16px"
      style={{
        backgroundColor: theme.palette.primary.main,
        height: isMobile ? "auto" : "40px",
        color: "white",
        borderRadius: "8px",
      }}
    >
      <Box
        color={theme.palette.white.main}
        height="24px"
        lineHeight={isMobile ? "0px" : "24px"}
        onClick={handleBack}
        id="btGoBack"
      >
        <InlineIcon
          icon={arrowLeftIcon}
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
            color: theme.palette.white.main,
          }}
        />
      </Box>
      <Box width={isMobile ? "16px" : "40px"} />
      <Box flexGrow={1} textAlign="center" width={isMobile ? "100%" : "400px"}>
        <Typography
          noWrap={isMobile ? false : noWrap}
          variant="h6"
          style={{ color: theme.palette.white.main }}
        >
          {title}
        </Typography>
      </Box>
      <Box width={isMobile ? "16px" : "40px"} />
      {isMobile && <Box width="36px" />}
    </Box>
  );
};

ApplicationHeader.propTypes = {
  /** Example prop type description*/
  title: PropTypes.string,
};

ApplicationHeader.defaultProps = {
  title: "title",
};

export default ApplicationHeader;
