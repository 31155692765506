import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import useStyles from "./my-hr-mass-role-dialog.styles";
import { useBeforeunload } from "react-beforeunload";
import Collapse from "@material-ui/core/Collapse";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import { removeMassRoleErrors, removeMassRoleErrorsIsMobile, massRoleErrors, massRoleErrorsIsMobile } from "my-hr/configs/my-hr-table-defs";
import Table from "oc/components/oc-data-table";
import _ from "lodash";
import OcMassRoleToolbar from "oc/components/oc-mass-role-toolbar/oc-mass-role-toolbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MyHrMassRoleDialog({
  showDialog,
  onShowDialog,
  onFinish,
  onCloseDialog,
  result,
  infoText,
  successText,
  successInfo,
  toolbarLabel,
  errorText,
  action,
  isMobile
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedRowId, setSelectedRowId] = useState(null);

  const tableId = "massRoleErrorsListTable";
  initialTableData.sortField = "employeeName";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const list = result?.errors || [];
  const sortedList = _.sortBy(list, initialTableData.sortField);
  const searchText = tableData?.searchText;
  const columName = tableData?.searchField;
  const filteredResult = list.filter(
    (item) => searchText && new RegExp(searchText, "i").test(item?.[columName])
  );

  let resultTableData = {
    rows: tableData?.searchText?.length === 0 ? sortedList : filteredResult,
    total: result?.errors?.length || 0,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
    searchField: tableData?.searchField,
    searchText: tableData?.searchText,
  };

  useBeforeunload((event) => {
    event.preventDefault();
    updateProperty({
      variables: {
        resource: "system",
        key: "importLocked",
        value: "false",
      },
    });
  });

  const { data: importProgress } = useProperty({
    resource: "system",
    key: "importProgress",
    pollInterval: 0,
    fetchPolicy: "network-only",
    subscription: true,
  });

  let progressInPercentString = importProgress ? `${importProgress}%` : "0%";
  if (result) {
    progressInPercentString = "100%";
  }

  function handleEnter() {
    updateProperty({
      variables: {
        resource: "system",
        key: "importProgress",
        value: "0",
      },
    });
    updateProperty({
      variables: {
        resource: "system",
        key: "importLocked",
        value: "true",
      },
    });

    updateProperty({
      variables: {
        resource: "system",
        key: "importCancelled",
        value: "false",
      },
    });
  }

  const handleFinish = () => {
    handleEnter();
    onFinish();
  };

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onError(err) {
      console.log(err);
    },
  });

  function handleExit() {
    updateProperty({
      variables: {
        resource: "system",
        key: "importProgress",
        value: "0",
      },
    });
    updateProperty({
      variables: {
        resource: "system",
        key: "importLocked",
        value: "false",
      },
    });
  }

  function cancel() {
    handleEnter();
    handleExit();
    onCloseDialog();
  }

  let tableDef;

  if (action === "add") {
    tableDef = isMobile ? massRoleErrorsIsMobile : massRoleErrors;
  } else {
    tableDef = isMobile ? removeMassRoleErrorsIsMobile : removeMassRoleErrors;
  }

  const filteredTableDefMassRole = {
    id: massRoleErrors?.id,
    columns: massRoleErrors?.columns?.filter(column =>
      !massRoleErrorsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  const filteredTableDefMassRoleErrors = {
    id: removeMassRoleErrors?.id,
    columns: removeMassRoleErrors?.columns?.filter(column =>
      !removeMassRoleErrorsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  const onSelectRow = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
    }
  };

  return (
    <Dialog
      fullScreen
      open={showDialog}
      onClose={onShowDialog}
      TransitionComponent={Transition}
      TransitionProps={{
        onEntered: handleEnter,
        onExited: handleExit,
      }}
    >
      <AppBar
        classes={{
          root: classes.appBar,
          colorSecondary: classes.colorSecondary,
        }}
        color="secondary"
        elevation={0}
      >
        <OcMassRoleToolbar
          handleCancel={cancel}
          handleFinish={handleFinish}
          dialogTitle={toolbarLabel}
          disabled={!result ? true : false}
        />
      </AppBar>
      <Box padding="16px">
        <Collapse in={true} timeout="auto" unmountOnExit>
          <Box
            display="flex"
            alignItems="center"
            marginTop="40px"
            marginBottom="56px"
          >
            <Box width={isMobile ? "100%" : "72%"} paddingLeft={isMobile ? "0px" : "14%"}>
              <LinearProgress
                variant="determinate"
                value={result ? 100 : parseInt(importProgress)}
                classes={{
                  root: classes.linear,
                  colorPrimary: classes.colorPrimary,
                }}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              paddingRight={isMobile ? "0px" : "14%"}
              paddingLeft="8px"
            >
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {progressInPercentString}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Collapse>
        <Box style={{ marginTop: result ? "32px" : "0px" }}>{infoText}</Box>
        <Collapse in={result ? true : false} timeout="auto" unmountOnExit>
          <Box paddingTop="32px">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                variant="h3"
                style={{
                  color: result?.errors?.length > 0 ? "#F3AA18" : "#2FA84F",
                }}
              >
                {result?.errors?.length > 0 ? errorText : successText}
              </Typography>
            </Box>
            {isMobile && <Box height="16px" />}
            <Typography variant="body1">
              {result.processed} {t("ITEM_PROCESSED")}
            </Typography>
            <Typography variant="body1">
              {result.added} {successInfo}
            </Typography>
            <Box height="32px" />
            {result?.errors?.length > 0 && (
              <Table
                data={resultTableData}
                loading={false}
                tableDef={tableDef}
                tableData={tableData}
                setTableData={setTableData}
                pagination={true}
                localPaging={true}
                onSelectRow={onSelectRow}
                filteredTableDef={action === "add" ? filteredTableDefMassRole : filteredTableDefMassRoleErrors}
                selectedRowId={selectedRowId}
              />
            )}
          </Box>
        </Collapse>
      </Box>
    </Dialog>
  );
}
