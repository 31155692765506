import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { Box, Typography } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { useTranslation } from "react-i18next";
import useStyles, { CustomTooltip } from "./dependents-collapse.style";
import useClientState from "hooks/use-oc-client-state";
import ApplicationCollapseHeader from "../application-collapse-header";
import accountChild from "@iconify/icons-mdi/account-child";
import informationOutline from "@iconify/icons-mdi/information-outline";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Icon } from "@iconify/react";
import IconButton from "@material-ui/core/IconButton";
import OcDatePicker from "oc/components/oc-date-picker";
import { NOT_PROVIDED } from "my-hr/components/my-hr-applications-data-panel/constants";
import ApplicationPrintCardInfoItem from "my-hr/components/my-hr-applications-data-panel/components/application-print-card-info-item";
import getDateFormatByLocale from "oc/utils/get-date-format-by-locale";

export default function DependentsCollapse({
  index,
  data,
  id,
  disabled = false,
  onChange,
  isModifiedApplication,
  editable,
  isMobile
}) {
  const { t, i18n } = useTranslation();
  let label = t("PLEASE_SELECT");

  const [expanded, setExpanded] = useClientState(
    `dependentsFilterCollapseExpanded${index}`,
    true
  );

  const classes = useStyles(expanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function DependentQualityInfo() {
    return (
      <Box style={{ padding: "16px" }}>
        <Box textAlign="center">{"Eltartotti minőség kódjai:"}</Box>
        <Box
          style={{
            color: "#919699",
            marginTop: "4px",
            lineHeight: "24px",
          }}
        >
          <ApplicationPrintCardInfoItem
            index="10"
            label="1."
            value="Kedvezményezett eltartott,"
          />
          <ApplicationPrintCardInfoItem label="2." value="Eltartott," />
          <ApplicationPrintCardInfoItem
            label="3."
            value="Felváltva gondozott gyermek,"
          />
          <ApplicationPrintCardInfoItem
            label="4."
            value="Tartósan beteg, illetve súlyosan fogyatékos személy (gyermek),"
          />
          <ApplicationPrintCardInfoItem
            label="5."
            value="Felváltva gondozott tartósan beteg, illetve súlyosan fogyatékos személy (gyermek),"
          />

          <ApplicationPrintCardInfoItem
            label="0."
            value="Kedvezménybe nem számító."
          />
        </Box>
      </Box>
    );
  }

  function EligibilityQualityInfo() {
    return (
      <Box style={{ padding: "16px" }}>
        <Box textAlign="center">{"Jogosultság jogcímei:"}</Box>
        <Box
          style={{
            color: "#919699",
            marginTop: "4px",
            lineHeight: "24px",
          }}
        >
          <ApplicationPrintCardInfoItem
            label="a."
            value="Gyermek után családi pótlékra jogosult vagy ilyen jogosulttal közös háztartásban élő házastárs,"
          />
          <ApplicationPrintCardInfoItem
            label="b."
            value="Várandós nő vagy várandós nő közös háztartásban élő házastársa,"
          />
          <ApplicationPrintCardInfoItem
            label="c."
            value="Családi pótlékra saját jogán jogosult, vagy ilyen jogosulttal közös háztartásban élő hozzátartozó (ideértve a gyermek szüleinek hozzátartozóit is),"
          />
          <ApplicationPrintCardInfoItem
            label="d."
            value="Rokkantsági járadékban részesülő vagy ilyen személlyel közös háztartásban  élő hozzátartozó."
          />
        </Box>
      </Box>
    );
  }

  const handleChange = (props) => {
    onChange(props);
  };

  return (
    <OcErrorBoundary>
      <Box>
        <ApplicationCollapseHeader
          handleExpandClick={handleExpandClick}
          expanded={expanded}
          icon={accountChild}
          dependentName={data?.name}
          dependentTaxNumber={data?.taxNumber ? data?.taxNumber : NOT_PROVIDED}
          taxNumberLabel="Adóazonosító jele"
          isMobile={isMobile}
        />
        <Collapse in={expanded} timeout="auto">
          <Box className={classes.backgroundColor} padding="0px 16px">
            <Box className={classes.topBorder} />
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography
                variant="body1"
                style={{
                  padding: "16px 0px",
                  marginRight: "8px",
                }}
              >
                Eltartotti minőség
              </Typography>
              <CustomTooltip title={<DependentQualityInfo />} placement="right">
                <IconButton aria-label="Info">
                  <Icon
                    icon={informationOutline}
                    width="20px"
                    color="#919699"
                  />
                </IconButton>
              </CustomTooltip>
            </Box>
            <FormControl
              id={`dependents-quality-${index}`}
              variant="outlined"
              className={classes.formControl}
              disabled={disabled || isModifiedApplication}
              style={{
                height: "56px",
                width: isMobile ? "100%" : "467px",
              }}
            >
              <InputLabel htmlFor={id}>{label}</InputLabel>
              <Select
                value={data?.dependentType || ""}
                label={label}
                inputProps={{
                  id: id,
                }}
                onChange={(e) => {
                  handleChange({
                    ...data,
                    dependentType: e.target.value,
                  });
                }}
                disabled={!editable}
              >
                <MenuItem value="">-- {t("NOT_SELECTED")} --</MenuItem>
                <MenuItem value="1">1. Kedvezményezett eltartott</MenuItem>
                <MenuItem value="2">2. Eltartott</MenuItem>
                <MenuItem value="3">3. Felváltva gondozott gyermek</MenuItem>
                <MenuItem value="4">
                  4. Tartósan beteg, illetve súlyosan fogyatékos személy
                  (gyermek)
                </MenuItem>
                <MenuItem value="5">
                  5. Felváltva gondozott tartósan beteg, illetve súlyosan
                  fogyatékos személy (gyermek)
                </MenuItem>
                <MenuItem value="0">0. Kedvezménybe nem számító</MenuItem>
              </Select>
            </FormControl>
            <Box height="18px" />
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography
                variant="body1"
                style={{
                  padding: "16px 0px",
                  marginRight: "8px",
                }}
              >
                Jogosultság jogcíme
              </Typography>
              <CustomTooltip
                title={<EligibilityQualityInfo />}
                placement="right"
              >
                <IconButton aria-label="Info">
                  <Icon
                    icon={informationOutline}
                    width="20px"
                    color="#919699"
                  />
                </IconButton>
              </CustomTooltip>
            </Box>
            <FormControl
              id={`eligibility-title-${index}`}
              variant="outlined"
              className={classes.formControl}
              disabled={disabled}
              style={{
                height: "56px",
                width: isMobile ? "100%" : "467px",
              }}
            >
              <InputLabel htmlFor={id}>{label}</InputLabel>
              <Select
                value={data?.entitlementTitle || ""}
                label={label}
                inputProps={{
                  id: id,
                }}
                onChange={(e) => {
                  handleChange({
                    ...data,
                    entitlementTitle: e.target.value,
                  });
                }}
                disabled={!editable}
              >
                <MenuItem value="">-- {t("NOT_SELECTED")} --</MenuItem>
                <MenuItem value="a">
                  a. Gyermek után családi pótlékra jogosult vagy ilyen
                  jogosulttal közös háztartásban élő házastárs
                </MenuItem>
                <MenuItem value="b">
                  b. Várandós nő vagy várandós nő közös háztartásban élő
                  házastársa.
                </MenuItem>
                <MenuItem value="c">
                  c. Családi pótlékra saját jogán jogosult, vagy ilyen
                  jogosulttal közös háztartásban élő hozzátartozó (ideértve a
                  gyermek szüleinek hozzátartozóit is)
                </MenuItem>
                <MenuItem value="d">
                  d. Rokkantsági járadékban részesülő vagy ilyen személlyel
                  közös háztartásban élő hozzátartozó
                </MenuItem>
              </Select>
            </FormControl>
            <Box height="16px" />
            <Collapse in={isModifiedApplication}>
              <Box>
                <Typography
                  variant="body1"
                  style={{
                    padding: "16px 0px",
                    marginRight: "8px",
                  }}
                >
                  A változás időpontja
                </Typography>
                <Box
                  width={isMobile ? "100%" : "467px"}
                  id={`dependents-collapse-date-picker-${index}`}
                >
                  <OcDatePicker
                    value={data?.changeDate?.length === 0 ? null : data?.changeDate}
                    disabled={!editable || !isModifiedApplication}
                    onChange={(e) => {
                      handleChange({
                        ...data,
                        changeDate: e,
                      });
                    }}
                    format={getDateFormatByLocale(i18n.language)}
                  />
                </Box>
                <Box height="16px" />
              </Box>
            </Collapse>
          </Box>
        </Collapse>
      </Box>
    </OcErrorBoundary>
  );
}
