import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  legendContainer: {
    display: "flex",
    flexDirection: "row",
  },
  bullet: ({ color, loading }) => ({
    height: "8px",
    width: "8px",
    backgroundColor: loading ? theme.palette.gray30.main : color,
    borderRadius: "50%",
    marginRight: "5px",
    marginTop: "10px",
  }),
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  valueTypographyContainer: {
    position: "relative",
    height: 24,
    marginBottom: 3,
    marginTop: 3,
    backgroundColor: theme.palette.gray30.main,
    width: 30,
    "&::after": {
      backgroundColor: theme.palette.gray30.main,
      content: "' '",
      position: "absolute",
      zIndex: "0",
      top: "0",
      right: "-10px",
      width: "20px",
      height: "100%",
      transform: "skew(21deg)",
    },
  },
  valueTypography: {
    color: "#5E6366",
    fontSize: "24px",
    lineHeight: "30px",
  },
  labelTypographyContainer: {
    position: "relative",
    height: 16,
    marginTop: 4,
    marginBottom: 4,
    width: 80,
    backgroundColor: theme.palette.gray30.main,
    "&::after": {
      backgroundColor: theme.palette.gray30.main,
      content: "' '",
      position: "absolute",
      zIndex: "0",
      top: "0",
      right: "-10px",
      width: "20px",
      height: "100%",
      transform: "skew(21deg)",
    },
  },
  labelTypography: {
    color: "#919699",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.44px",
    "&:first-letter": {
      textTransform: "capitalize",
    },
  },
  dividerContainer: ({ isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 21,
    paddingLeft: isMobile ? "0px" : "35px",
  }),
  divider: ({ loading }) => ({
    height: "1px",
    backgroundColor: loading ? theme.palette.gray30.main : "#C6CACC",
  }),
}));

export default useStyles;
