import React, { useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCollapseHeader from "oc/components/oc-collapse-header";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import OcActiveUserSelector from "oc/components/oc-active-user-selector";
import { useRecoilState, useResetRecoilState } from "recoil";
import { roleFilterState, roleFilteringState } from "states";
import _ from "lodash";
import OcCompanyFilter from "oc/components/oc-company-filter";
import OcOrgUnitFilter from "oc/components/oc-org-unit-filter";
import OcWorkingPlaceFilter from "oc/components/oc-working-place-filter";
import OcJobClassGroupFilter from "oc/components/oc-job-class-group-filter";
import OcPayofficeFilter from "oc/components/oc-payoffice-filter";
import OcEmployeeFilter from "oc/components/oc-employee-filter";
import useFilteredListCount from "hooks/use-filtered-list-count.js";
import useFilteredList from "hooks/use-filtered-list";
import OcFilteredListCount from "oc/components/oc-filtered-list-count";
import Button from "@material-ui/core/Button";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import Table from "oc/components/oc-data-table";
import MyHrAddMassRole from "my-hr/components/my-hr-add-mass-role";
import { useHistory } from "react-router-dom";
import MyHrBackToPage from "my-hr/components/my-hr-back-to-page";
import MyLeaveFilterDivider from "my-leave/components/my-leave-filter-divider";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import { addMassRoleEmployeeList, addMassRoleEmployeeListIsMobile } from "my-hr/configs/my-hr-table-defs";

export default function MyHRAdminAddGroupRolesPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const [expanded, setExpanded] = useState(false);
  const [userActiveState, setUserActiveState] = React.useState("active");
  const [roleFilters, setRoleFilters] = useRecoilState(roleFilterState);
  const [roleFiltering, setRoleFiltering] = useRecoilState(roleFilteringState);

  const [selectedRowId, setSelectedRowId] = useState(null);

  const userRoleFilters = {
    company: [],
    orgUnit: [],
    workingPlace: [],
    jobClassGroup: [],
    payOffice: [],
    contract: [],
  };

  let tableId = "filteredListTableData";
  initialTableData.pageSize = 5;
  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const maxWidthStyle = { maxWidth: isMobile ? "100%" : "50%" };

  const { data: filteredListCount } = useFilteredListCount({
    isActive: userActiveState === "active" ? true : false,
    roleFilters,
  });

  const { data: filteredList, loading } = useFilteredList({
    page: tableData.page,
    pageSize: tableData.pageSize,
    isActive: userActiveState === "active" ? true : false,
    roleFilters,
  });

  function handleAddFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].concat([value]);
    setRoleFilters(newState);
  }

  function handleRemoveFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].filter((item) => item !== value);
    setRoleFilters(newState);
  }

  function handleAddFiltering({ key, value }) {
    let newState = _.cloneDeep(roleFiltering);
    newState[key] = newState[key].concat([value]);
    setRoleFiltering(newState);
  }

  function handleRemoveFiltering({ key, value }) {
    let newState = _.cloneDeep(roleFiltering);
    newState[key] = newState[key].filter((item) => item !== value);
    setRoleFiltering(newState);
  }

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function handleUserFilterClick(state) {
    setUserActiveState(state);
  }

  const resetRoleFilters = useResetRecoilState(roleFilterState);

  function handleCancel() {
    resetRoleFilters();
    history.push("/my-hr/users");
  }

  const tableDef = isMobile
    ? addMassRoleEmployeeListIsMobile
    : addMassRoleEmployeeList;


  const filteredTableDef = {
    id: addMassRoleEmployeeList?.id,
    columns: addMassRoleEmployeeList?.columns?.filter(column =>
      !addMassRoleEmployeeListIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  const onSelectRow = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
    }
  };

  React.useEffect(() => {
    return () => {
      resetRoleFilters();
    };
  }, []); // eslint-disable-line

  return (
    <OcPageTransition>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Box padding={isMobile ? "16px 0px" : "16px"}>
          <MyHrBackToPage
            label={t("ADD_GROUP_ROLE_TITLE")}
            onBack={handleCancel}
            isMobile={isMobile}
          />
          <Box height="16px" />
          <OcCard label={t("User.users")}>
            <>
              <Box
                style={{
                  backgroundColor: "#F1F3F4",
                  borderRadius: "4px",
                }}
              >
                <OcCollapseHeader
                  expanded={expanded}
                  onExpandClick={handleExpandClick}
                />
                <Collapse
                  in={expanded}
                  timeout="auto"
                  style={{
                    backgroundColor: "#F1F3F4",
                    borderRadius: "4px",
                  }}
                >
                  <Box
                    id="collapse-body"
                    padding="0px 16px"
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                  >
                    <Box
                      style={{
                        backgroundColor: "#C6CACC",
                        height: "2px",
                        width: "100%",
                      }}
                    />
                    <OcActiveUserSelector
                      filterState={userActiveState}
                      onClick={handleUserFilterClick}
                    />
                    <Box
                      id="selector-container"
                      width={isMobile ? "100%" : "66%"}
                      style={{ paddingBottom: "24px" }}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          flexDirection: isMobile ? "column" : "unset"
                        }}
                      >
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcCompanyFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcOrgUnitFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcWorkingPlaceFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcJobClassGroupFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                        <Grid item xs={6} style={maxWidthStyle}>
                          <OcPayofficeFilter
                            roleFilters={roleFilters}
                            userRoleFilters={userRoleFilters}
                            onAddFilter={handleAddFilter}
                            onRemoveFilter={handleRemoveFilter}
                            product="MyHR"
                            role="admin"
                          />
                        </Grid>
                      </Grid>
                      <Box height="16px" />
                      <MyLeaveFilterDivider />
                      <Box height="16px" />
                      <Grid item xs={6} style={maxWidthStyle}>
                        <OcEmployeeFilter
                          roleFilters={roleFilters}
                          userRoleFilters={userRoleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product="MyHR"
                          role="admin"
                        />
                      </Grid>
                      <Box minHeight="16px" />
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                      >
                        <Grid item xs={12}>
                          <OcFilteredListCount listCount={filteredListCount} isMobile={isMobile} />
                        </Grid>
                      </Grid>
                      <Box style={{ paddingTop: "24px" }}>
                        <Button id="reset-button" onClick={resetRoleFilters}>
                          {t("RESET")}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </Box>
              <Box paddingTop="32px">
                <Table
                  data={filteredList}
                  loading={loading}
                  tableDef={tableDef}
                  tableData={tableData}
                  setTableData={setTableData}
                  pagination={true}
                  onSelectRow={onSelectRow}
                  filteredTableDef={filteredTableDef}
                  selectedRowId={selectedRowId}
                />
              </Box>
              <MyHrAddMassRole
                roleFilters={roleFilters}
                userRoleFilters={userRoleFilters}
                roleFiltering={roleFiltering}
                onAddFiltering={handleAddFiltering}
                onRemoveFiltering={handleRemoveFiltering}
                isMobile={isMobile}
              />
            </>
          </OcCard>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
