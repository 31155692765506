import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import plusIcon from "@iconify/icons-mdi/plus";
import { InlineIcon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import useStyles from "./my-leave-filter-bar.style";
import useProperty from "hooks/use-oc-property";
import useExaminersName from "hooks/use-examiners-name";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useCurrentUserState from "hooks/use-current-user-state";
import { CustomTooltip } from "my-hr/components/my-hr-applications-data-panel/components/dependents-collapse/dependents-collapse.style";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import Box from "@material-ui/core/Box";

const MyLeaveFilterBar = ({
  tableData,
  setTableData,
  loading,
  onShowNewRequestDialog,
  disabledNewRequest,
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  const { data: createLeaveRequestIsEnabled } = useProperty({
    resource: "myLeave",
    key: "createLeaveRequestIsEnabled",
  });

  let selected =
    tableData?.filterSelected?.length === 0 ||
      createLeaveRequestIsEnabled === "0"
      ? "all"
      : "requested";

  const handleButtonGroupState = (state) => {
    if (state === "all") {
      setTableData({ filterSelected: [] });
      return;
    }

    return setTableData({ filterSelected: ["requested"] });
  };

  if (createLeaveRequestIsEnabled === "0") {
    handleButtonGroupState("all");
  }

  const { data: examinersData } = useExaminersName({ locale: i18n.language });

  let { contractCode } = useCurrentUserState();

  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const isExaminer = rolesData?.find(
    (item) => item.product === "MyLeave" && item.role === "examiner"
  )
    ? true
    : false;

  const hasExaminer = examinersData?.length > 0;

  let isEditButtonDisabled = !hasExaminer;
  if (isExaminer && !hasExaminer) {
    isEditButtonDisabled = false;
  }

  return (
    <div className={classes.root}>
      <div className={classes.leftContainer} />
      <ButtonGroup style={{ justifyContent: isMobile ? "center" : "unset" }}>
        <Button
          variant={selected === "requested" ? "contained" : "outlined"}
          color={selected === "requested" ? "primary" : "default"}
          disableElevation
          disabled={loading}
          onClick={() => handleButtonGroupState("requested")}
        >
          {t("Leave.requestedPast")}
        </Button>
        <Button
          variant={selected === "all" ? "contained" : "outlined"}
          color={selected === "all" ? "primary" : "default"}
          disableElevation
          disabled={loading}
          onClick={() => handleButtonGroupState("all")}
        >
          {t("Leave.all")}
        </Button>
      </ButtonGroup>
      {isMobile && <Box height="36px" />}
      <div className={classes.rightContainer}>
        {createLeaveRequestIsEnabled === "1" && !isEditButtonDisabled && (
          <Button
            id="showDialog"
            name="showDialog"
            variant="contained"
            color="primary"
            startIcon={<InlineIcon icon={plusIcon} />}
            onClick={() => onShowNewRequestDialog(true)}
            className={classes.newItemButton}
            disableElevation
            disabled={loading || disabledNewRequest}
          >
            {t("Leave.newRequest")}
          </Button>
        )}
        {isEditButtonDisabled && (
          <CustomTooltip title={t("NOT_SET_EXAMINER_INFO")} placement="top">
            <Button
              id="showDialog"
              name="showDialog"
              variant="contained"
              startIcon={<InlineIcon icon={plusIcon} />}
              className={classes.newItemButton}
              disableElevation
              style={{
                backgroundColor: "#E3E5E5",
                color: "#999999",
              }}
            >
              {t("Leave.newRequest")}
            </Button>
          </CustomTooltip>
        )}
      </div>
    </div>
  );
};

export default MyLeaveFilterBar;
