import React from "react";
import { Box, Dialog, Typography, Button } from "@material-ui/core";
import { InlineIcon } from "@iconify/react";
import alertIcon from "@iconify/icons-mdi/alert-circle-outline";
import { useTranslation } from "react-i18next";

const MyTimeShortInfoDialog = ({ open, handleClose, text, isMobile }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box padding="16px" width={isMobile ? "auto" : "547px"}>
        <Box
          alignItems="center"
          height="36px"
          marginBottom="16px"
          display="flex"
          flexDirection="row"
        >
          <InlineIcon icon={alertIcon} width="36px" color="#cc0033" />
          <Box width="16px" />
          <Typography variant="body1">{t(text)}</Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleClose}
        >
          OK
        </Button>
      </Box>
    </Dialog>
  );
};

export default MyTimeShortInfoDialog;
