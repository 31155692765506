import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import DownloadIcon from "@iconify/icons-mdi/download";
import showIcon from "@iconify/icons-mdi/show";
import { InlineIcon } from "@iconify/react";
import MyHrAttachmentDialog from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-attachment-dialog/my-hr-attachment-dialog";
import Link from "@material-ui/core/Link";
import { useRecoilState } from "recoil";
import { myDocumentsShowDocument } from "my-documents/states/show-document";
import { myDocumentsIsDocument } from "my-documents/states/is-document";

export default function MyDocumentsActionCell({ document, isMobile }) {
  const theme = useTheme();

  const [fullScreenVisible, setFullScreenVisible] = useRecoilState(myDocumentsShowDocument);
  const [/*isDocuments*/, setIsDocuments] = useRecoilState(myDocumentsIsDocument);
  const [selectedFile, setSelectedFile] = useState(null);

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const fileExtension = getFileExtension(document?.filename);

  const allowedExtensions = ["pdf", "jpeg", "png", "jpg"];

  const handleCancel = () => {
    setFullScreenVisible(false);
    setSelectedFile(null);
  };

  const handleShowFile = () => {
    const isPdf = document?.slug.toLowerCase().endsWith(".pdf");
    if (isPdf) {
      window.open(document?.slug, "_blank");
      setIsDocuments(true);
    } else {
      setSelectedFile(document);
      setFullScreenVisible(true);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" paddingRight={isMobile ? "0px" : "15px"}>
      <Box display="flex" alignItems="center" >
        {allowedExtensions?.includes(fileExtension) && (
          <Box>
            <InlineIcon
              id="showIcon"
              icon={showIcon}
              width="24px"
              style={{
                color: theme.palette.gray60.main,
                cursor: "pointer",
                display: "flex"
              }}
              onClick={handleShowFile}
            />
            {selectedFile && (
              <MyHrAttachmentDialog
                showDialog={fullScreenVisible}
                handleCancel={handleCancel}
                fileData={selectedFile}
              />
            )}
          </Box>
        )}
        <Box width="8px" />
        <Link href={document?.slug} target={document?.slug} download={document?.filename}>
          <InlineIcon
            id="downloadIcon"
            icon={DownloadIcon}
            width="24px"
            style={{
              color: theme.palette.gray60.main,
              cursor: "pointer",
              display: "flex"
            }}
            onClick={() => setIsDocuments(true)}
          />
        </Link>
      </Box>
    </Box >
  );
}
