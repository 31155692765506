import React, { useState } from "react";
import "moment/locale/hu";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function PaycardItem({
  paycard,
  index,
  contract,
  collapse,
  slot,
}) {
  const isMobile = useIsMobile();
  const [contentWidth, setContentWith] = useState(0);
  function handlePrint() {
    let iFrame = window.frames[`paycard-${contract}-${index}-${slot}`];
    if (iFrame) {
      window.frames[`paycard-${contract}-${index}-${slot}`].focus();
      window.frames[`paycard-${contract}-${index}-${slot}`].print();
    }
  }

  function iframeLoaded() {
    var iFrameID = document.getElementById(
      `paycard-${contract}-${index}-${slot}`
    );
    if (iFrameID) {
      // here you can make the height, I delete it first, then I make it again
      iFrameID.height = "";
      iFrameID.width = "";
      iFrameID.height =
        iFrameID.contentWindow.document.body.scrollHeight + "px";
      iFrameID.width =
        iFrameID.contentWindow.document.body.scrollWidth + "px";
      const scrollWidth =
        parseInt(iFrameID.contentWindow.document.body.scrollWidth) + 3;
      const scrollWidthString = scrollWidth.toString() + "px";
      if (contentWidth !== scrollWidthString) {
        setContentWith(scrollWidthString);
      }
    }
  }

  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: collapse !== true ? "8px" : "0px 0px 8px 8px",
          backgroundColor:
            collapse !== true
              ? `${theme.palette.gray20.main}`
              : `${theme.palette.gray30.main}`,
          padding: isMobile ? "16px 47px" : "13px 100px",
          alignItems: isMobile ? "unset" : "center",
          overflowX: isMobile ? "scroll" : "unset",
          overflowY: isMobile ? "hidden" : "unset",
          whiteSpace: isMobile ? "nowrap" : "unset"
        }}
      >
        <Box
          width={!isMobile ? contentWidth : "100%"}
          minWidth={isMobile ? "100%" : "718px"}
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop="16px"
        >
          <iframe
            id={`paycard-${contract}-${index}-${slot}`}
            name={`paycard-${contract}-${index}-${slot}`}
            title={`paycard-${index}`}
            srcDoc={paycard}
            onLoad={iframeLoaded}
            style={{
              width: "100%",
              minHeight: "75px",
              border: "none",
              padding: "20px",
              backgroundColor: `${theme.palette.white.main}`,
            }}
          />
          <Box height="36px" />
          <Box width="120%">
            <Button
              id={`paycard-${contract}-${index}-${slot}-print`}
              variant="contained"
              color="primary"
              onClick={handlePrint}
              style={{ float: "right" }}
            >
              {t("PRINT")}
            </Button>
          </Box>{" "}
          <Box height="16px" />
        </Box>
      </Box>
      <Box height="36px" />
    </>
  );
}
