import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import { DropzoneArea } from "material-ui-dropzone";
import useStyles from "oc/components/oc-info-card-item/oc-info-card-item.style";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import Typography from "@material-ui/core/Typography";
import deleteIcon from "@iconify/icons-mdi/delete";
import { InlineIcon } from "@iconify/react";
import { useTheme } from "@material-ui/core/styles";
import useOcSnackbar from "hooks/use-oc-snackbar";
import textBoxIcon from "@iconify/icons-mdi/text-box";
import OcPageTransition from "oc/components/oc-page-transition";
import axios from "axios";
import useCurrentUserState from "hooks/use-current-user-state";
import fileDocument from "@iconify/icons-mdi/file-document";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useParams } from "react-router-dom";
import OcSwitch from "oc/components/oc-form-switch";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import formatTextWidthValue from "oc/utils/get-format-text-width-value";

const MyDocumentsUploadFiles = ({ setShowNewCard }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  let { contract } = useParams();
  const isMobile = useIsMobile();

  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [userView, setUserView] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [axiosError, setAxiosError] = useState(false);
  const { setOpenMessage } = useOcSnackbar();
  let {
    contractCode: uploaderContractCode,
    employeeCode: uploaderEmployeeCode,
  } = useCurrentUserState();

  function handleDropzoneChange(file) {
    if (file?.size > 9000000) {
      setOpenMessage({
        type: "error",
        message: t("FILE_UPLOAD_SIZE_FAILED"),
      });
      return;
    }
    setFile(file);
  }

  async function handleUpload() {
    let apiUrl = "http://localhost:9000/api/upload-document";

    if (process.env.NODE_ENV === "production") {
      apiUrl = "/api/upload-document";
    }

    //A MyDocuments V1-ben nem terveztünk csoportosan feltölteni dokumentumokat.
    //Ha megvalósítsuk, akkor a [contract] rész módosítára vár!
    //TODO
    const users = [contract];

    const formData = new FormData();
    formData.append("description", description);
    formData.append("type", "document");
    formData.append("userView", userView);
    formData.append("uploaderContractCode", uploaderContractCode);
    formData.append("uploaderEmployeeCode", uploaderEmployeeCode);
    formData.append("users", JSON.stringify(users));
    formData.append("fileSize", file.size);
    formData.append("file", file);

    try {
      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      };
      await axios.post(apiUrl, formData, config);
      setOpenMessage({
        type: "success",
        message: t("DOCUMENT_UPLOAD_SUCCESSFUL"),
        severity: "success",
      });
      setShowNewCard(false);
    } catch (error) {
      setAxiosError(true);
      setOpenMessage({
        type: "error",
        message: t("DOCUMENT_UPLOAD_FAILED"),
        severity: "error",
      });
    }
  }

  function removeFile() {
    setFile(null);
  }

  function handleCancel() {
    setFile(null);
    setShowNewCard(false);
  }

  return (
    <OcPageTransition>
      <OcCard label={t("UPLOAD_DOCUMENT")}>
        <Box height="16px" />
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          {!file && (
            <Box width={isMobile ? "100%" : "500px"}>
              <Collapse in={true} timeout="auto" id="dropzone-area">
                <DropzoneArea
                  classes={{
                    root: classes.dropzoneRoot,
                    icon: classes.muiSvgIcon,
                    textContainer: classes.textContainer,
                    text: classes.text,
                  }}
                  dropzoneText={t("DRAG_AND_DROP_FILE")}
                  acceptedFiles={[
                    ".pdf",
                    ".jpeg",
                    ".png",
                    ".jpg",
                    ".PDF",
                    ".JPEG",
                    ".PNG",
                    ".JPG",
                    ".doc",
                    ".docx",
                    ".xls",
                    ".xlsx",
                  ]}
                  filesLimit={1}
                  maxFileSize={100000000}
                  showPreviewsInDropzone={false}
                  showFileNames={true}
                  showAlerts={false}
                  onChange={(files) => handleDropzoneChange(files[0])}
                />
              </Collapse>
            </Box>
          )}
          {!uploadProgress &&
            <Collapse in={file ? true : false} style={{ width: isMobile ? "100%" : "auto" }}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{
                  width: isMobile ? "auto" : "459px",
                  backgroundColor: theme.palette.gray20.main,
                  padding: "16px 20px",
                  borderRadius: "8px",
                  border: "1px solid #C6CACC",
                }}
              >
                <Box>
                  <InlineIcon
                    icon={textBoxIcon}
                    width="24px"
                    style={{ color: "#4d4d4d" }}
                  />
                </Box>
                <Box sx={{ width: "100%", padding: "0px 16px" }}>
                  <Typography variant="body1">{isMobile ? formatTextWidthValue(file?.name, 20) : file?.name}</Typography>
                </Box>
                <Box>
                  <InlineIcon
                    id="delete-icon"
                    icon={deleteIcon}
                    width="24px"
                    style={{
                      color: "#4d4d4d",
                      cursor: "pointer",
                    }}
                    onClick={removeFile}
                  />
                </Box>
              </Box>
            </Collapse>
          }
          <Collapse in={uploadProgress > 1 ? true : false} style={{ width: isMobile ? "100%" : "auto" }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{
                width: isMobile ? "auto" : "459px",
                backgroundColor: theme.palette.gray20.main,
                padding: "16px 20px",
                borderRadius: "8px",
                border: axiosError ? "1px solid #CC0033" : "1px solid #C6CACC",
              }}
            >
              <Box>
                <InlineIcon
                  icon={fileDocument}
                  width="24px"
                  style={{ color: "#4d4d4d" }}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  padding: "0px 16px",
                }}
              >
                <Typography variant="body1">{isMobile ? formatTextWidthValue(file?.name, 20) : file?.name}</Typography>
                {!axiosError ?
                  <>
                    <Box padding="16px 0px">
                      <LinearProgress
                        variant="determinate"
                        value={uploadProgress}
                        color="primary"
                        style={{ borderRadius: "8px" }}
                      />
                    </Box>
                    <Typography variant="caption" style={{ color: "#919699" }}>
                      {uploadProgress}%
                    </Typography>
                  </>
                  :
                  <Typography
                    variant="caption"
                    style={{
                      color: "#CC0033",
                      marginTop: "2px",
                    }}
                  >
                    {t("UPLOAD_FAILED")}
                  </Typography>
                }
              </Box>
              <Box>
                <InlineIcon
                  id="delete-icon"
                  icon={deleteIcon}
                  width="24px"
                  style={{
                    color: "#4d4d4d",
                    cursor: "pointer",
                  }}
                  onClick={removeFile}
                />
              </Box>
            </Box>
          </Collapse>
          <Box height="32px" />
          <TextField
            id="designation"
            value={description}
            variant="outlined"
            label={t("DESIGNATION")}
            type="string"
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: isMobile ? "100%" : "500px" }}
          />
        </Box>
        <Box height="32px" />
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box display="flex" alignItems="center" width="500px">
            <OcSwitch
              id="employeeVisibility"
              checked={userView}
              inputProps={{ "aria-label": "controlled" }}
              onChange={(e) => setUserView(e.target.checked)}
              disabled={false}
            />
            <Box width="8px" />
            <Typography>{t("EMPLOYEE_VISIBILITY")}</Typography>
          </Box>
        </Box>
        <OcCardButtonBar
          handleRightButton={handleCancel}
          rightButtonText={t("CANCEL")}
          handlePrimaryButton={handleUpload}
          primaryButtonText={t("UPLOAD")}
          primaryButtonDisabled={!file || !description}
        />
        <Box height="16px" />
      </OcCard>
    </OcPageTransition>
  );
};

export default MyDocumentsUploadFiles;
