import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import OcPageTransition from "oc/components/oc-page-transition";
import MyHrTaxCreditApplications from "my-hr/components/my-hr-tax-credit-applications/my-hr-tax-credit-applications";
import OcCard from "oc/components/oc-card";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Table from "oc/components/oc-data-table";
import { taxCreditApplicationRequests, taxCreditApplicationRequestsIsMobile } from "my-hr/configs/my-hr-table-defs";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import useTaxDeclarationList from "hooks/use-tax-declaration-list";
import useClientState from "hooks/use-oc-client-state";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useSelectedTaxDeclaration from "hooks/use-selected-tax-declaration";

export default function MyHrApplicationsDataPanel({ label, employee, isMobile }) {
  const {
    selected,
    setSelected,
    reset: resetSelectedState,
  } = useSelectedTaxDeclaration();

  const [type, setType] = React.useState("all");

  const { t } = useTranslation();

  const { data: taxApplicationSave } = useProperty({
    resource: "system",
    key: "taxApplicationSaveEnabled",
    fetchPolicy: "network-only",
  });

  const taxApplicationSaveEnabled =
    taxApplicationSave === "true" ? true : false;

  const tableId = "taxDeclartionListTable";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const {
    data,
    loading: listLoading,
    error,
  } = useTaxDeclarationList({ tableData, type });


  const loading = listLoading && data?.rows?.length === 0 ? true : false;

  const originalonSelectRow = (row) => {
    const data = {
      editable: false,
      tableId: row.tableId,
      year: row.year,
      type: row.type,
      state: row.state,
    };
    setSelected(data);
  };

  const handleSelectRow = (row) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === row?._id ? null : row?._id);
    } else {
      originalonSelectRow(row);
    }
  };

  const tableDef = isMobile
    ? taxCreditApplicationRequestsIsMobile
    : taxCreditApplicationRequests;

  const filteredTableDef = {
    id: taxCreditApplicationRequests?.id,
    columns: taxCreditApplicationRequests?.columns?.filter(column =>
      !taxCreditApplicationRequestsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <OcPageTransition>
      <Box>
        {!selected?.type && (
          <OcCard label={t("MY_APPLICATIONS")} loading={loading} error={error}>
            <Box
              id="button-group"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              padding="18px 0px"
            >
              <ButtonGroup
                orientation={isMobile ? "vertical" : "horizontal"}
                fullWidth={isMobile ? true : false}
              >
                <Button
                  id="my-leave-filter-user-requests-page-to-be-assessed-button"
                  variant={type === "requested" ? "contained" : "outlined"}
                  color={type === "requested" ? "primary" : ""}
                  disableElevation
                  onClick={() => setType("requested")}
                >
                  {t("Leave.requestedPast")}
                </Button>
                <Button
                  id="my-leave-filter-user-requests-page-to-be-assessed-button"
                  variant={type === "invalid" ? "contained" : "outlined"}
                  color={type === "invalid" ? "primary" : ""}
                  disableElevation
                  onClick={() => setType("invalid")}
                >
                  {t("INVALID")}
                </Button>
                <Button
                  id="my-leave-filter-user-requests-page-all-button"
                  variant={type === "all" ? "contained" : "outlined"}
                  color={type === "all" ? "primary" : ""}
                  disableElevation
                  onClick={() => setType("all")}
                >
                  {t("ALL")}
                </Button>
              </ButtonGroup>
            </Box>
            <Box paddingTop="32px">
              <Table
                data={data}
                loading={loading && data?.rows?.length === 0 ? true : false}
                tableDef={tableDef}
                tableData={tableData}
                setTableData={setTableData}
                pagination={true}
                onSelectRow={handleSelectRow}
                filteredTableDef={filteredTableDef}
                selectedRowId={selectedRowId}
                handleMoreButton={originalonSelectRow}
              />
            </Box>
          </OcCard>
        )}
        <MyHrTaxCreditApplications
          label={label}
          employee={employee}
          taxApplicationSaveEnabled={taxApplicationSaveEnabled}
          selected={selected}
          setSelected={setSelected}
          resetSelectedState={resetSelectedState}
        />
      </Box>
    </OcPageTransition>
  );
}
