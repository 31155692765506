import React from "react";
import Box from "@material-ui/core/Box";
import MaterialCard from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import getFullname from "oc/utils/get-fullname";
import useClientState from "hooks/use-oc-client-state";
import CollapseLabel from "../collapse-label";
import Avatar from "oc/components/oc-avatar";
import fingerprint from "@iconify/icons-mdi/fingerprint";
import { InlineIcon } from "@iconify/react";
import Contract from "../contract";

export default function UserDataCollapse({ id, employee, contractsData, isMobile }) {
  let stateId = id || "addPermissionCollapseState";

  const [expanded, setExpanded] = useClientState(stateId, false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MaterialCard
      style={{
        marginBottom: 16,
        borderRadius: "4px",
        borderColor: "white",
      }}
      variant="outlined"
    >
      <CollapseLabel
        expanded={expanded}
        handleExpandClick={handleExpandClick}
        name={getFullname(employee)}
        code={employee?.code}
      />

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        style={{ marginBottom: "18px" }}
      >
        <Box padding="0px 16px">
          <Box style={{ borderTop: "2px solid #C6CACC" }}>
            {isMobile && <Box height="16px" />}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box padding="16px">
                <Avatar
                  employee={employee?.code}
                  style={{
                    width: "75px",
                    height: "75px",
                    marginRight: "32px",
                  }}
                />
              </Box>
              <Box
                height={isMobile ? "100%" : "75px"}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box fontSize="34px" letterSpacing="0.25px">
                  {getFullname(employee)}
                </Box>
                {isMobile && <Box height="16px" />}
                <Box display="flex" flexDirection="row">
                  <Box marginRight="8px">
                    <InlineIcon
                      icon={fingerprint}
                      width="16px"
                      height="16px"
                    />
                  </Box>
                  <Box>{employee?.code}</Box>
                </Box>
              </Box>
            </Box>
            {isMobile && <Box height="16px" />}
          </Box>
        </Box>
        {contractsData?.map((item, index) => {
          return (
            <Contract
              key={`${index}-${item.code}`}
              contract={item}
              employee={employee}
              isMobile={isMobile}
            />
          );
        })}
      </Collapse>
    </MaterialCard>
  );
}
