import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const MyCafeteriaInfoLine = ({ label, value, isMobile }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={isMobile ? "space-between" : "flex-start"}
      marginBottom="16px"
    >
      <Typography
        variant="body1"
        style={{
          width: isMobile ? "auto" : "221px",
          textAlign: "right",
        }}
      >
        {label}
      </Typography>
      <Box width="16px" />
      <Typography
        variant="body1"
        style={{
          fontWeight: "700",
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default MyCafeteriaInfoLine;
