import React from "react";
import { Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@material-ui/core/styles";

export default function MyHrUserDataRequestSubCollapseHeader({
  handleExpandClick,
  expanded,
  label,
  badge,
  className,
  classNameCollapse,
  data,
  isMobile
}) {
  const theme = useTheme();

  return (
    <Box>
      <Box
        className={expanded === true ? classNameCollapse : className}
        padding="16px"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="contents" />
          <Box display="flex"
            flexDirection={isMobile ? "column" : "row"}
            alignItems={isMobile ? "column" : "center"}
          >
            <Box width="16px" />
            <Typography
              variant="body1"
              style={{
                color: theme.palette.gray80.main,
              }}
            >
              {label}
            </Typography>
            <Box width="16px" />
            {isMobile && <Box height="8px" />}
            <Box
              style={{
                border: `1px solid ${theme.palette.gray80.main}`,
                borderRadius: 12,
                paddingLeft: 12,
                paddingRight: 12,
                paddingTop: 2,
                paddingBottom: 2,
                color: theme.palette.gray80.main,
              }}
            >
              {badge}
            </Box>
            <Box width="15px" />
            <Box paddingRight="28px" />
          </Box>
          <IconButton
            onClick={data?.length === 0 ? undefined : handleExpandClick}
            id={`other-collapse-button-${label}`}
            aria-expanded={expanded}
            aria-label="show more"
            style={
              expanded
                ? {
                  padding: 0,
                  transform: "rotate(180deg)",
                }
                : {
                  padding: 0,
                }
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
