import React from "react";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import accountEdit from "@iconify/icons-mdi/account-edit";
import { useHistory } from "react-router-dom";
import { StyledBadge, useStyles } from "./my-hr-header-badge.style";
import useChangeRequests from "hooks/use-change-requests";
import useCurrentUserState from "hooks/use-current-user-state";
import useChangeRequestsBadges from "hooks/use-change-requests-badges";
import { narrowMenuState } from "states";
import { useRecoilState } from "recoil";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyHrHeaderBadge() {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [narrowMenuOpened, setNarrowMenuOpened] = useRecoilState(narrowMenuState);

  let { employeeCode, contractCode } = useCurrentUserState();
  const { data } = useChangeRequests(employeeCode);
  const { data: badgeCount } = useChangeRequestsBadges(contractCode);

  if (!data || badgeCount === 0) return null;

  function handleClick() {
    if (isMobile) {
      setNarrowMenuOpened(!narrowMenuOpened);
    }
    history.push("/my-hr/view-requests");
  }

  return (
    <Box onClick={handleClick} className={classes.root}>
      <StyledBadge id="my-hr-header-badge-count" badgeContent={badgeCount}>
        <InlineIcon
          id="my-hr-header-badge"
          icon={accountEdit}
          className={classes.icon}
        />
      </StyledBadge>
    </Box>
  );
}
