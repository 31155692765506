import React from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import MyHrUserDataCard from "my-hr/components/my-hr-user-data-card";
import MyHrUserPasswordCard from "my-hr/components/my-hr-user-password-card";
import useMyHrUserByEmployee from "hooks/use-myhr-user-by-employee";
import { useTranslation } from "react-i18next";
import MyHrUserProfileRoles from "my-hr/components/my-hr-user-profile-roles";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useCurrentUserState from "hooks/use-current-user-state";
import MyHrUserProfileImage from "my-hr/components/my-hr-user-profile-image";
import useProperty from "hooks/use-oc-property";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyProfile() {
  const { t } = useTranslation();
  let { employeeCode } = useCurrentUserState();
  const { data: userData } = useMyHrUserByEmployee({
    employee: employeeCode,
  });
  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  const { data: loginType } = useProperty({
    resource: "system",
    key: "loginType",
  });

  const isSSO = loginType && loginType === "sso" ? true : false;

  const isMobile = useIsMobile();

  return (
    <OcPageTransition>
      <Box>
        <Box
          height="80px"
          style={{ backgroundColor: "#cc0033" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="14px"
        >
          <Box style={{ color: "white", fontSize: "24px" }}>
            {t("MY_PROFILE")}
          </Box>
        </Box>
        <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
          <Box
            width="240px"
            height="40px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{
              backgroundColor: "white",
              borderLeft: "4px solid #cc0033",
              paddingLeft: "40px",
            }}
          >
            <Box
              style={{
                fontSize: "14px",
                letterSpacing: "0.25px",
              }}
            >
              {t("USER_ACCOUNT")}
            </Box>
          </Box>
          <Box flexGrow={1} padding={isMobile ? "16px 0px" : "16px"}>
            <MyHrUserProfileImage employeeCode={employeeCode} isMobile={isMobile} />
            <Box height="16px" />
            <MyHrUserDataCard userData={userData} isMobile={isMobile} />
            <Box height="16px" />
            {!isSSO && <MyHrUserPasswordCard email={userData?.email} isMobile={isMobile} />}
            <Box height="16px" />
            <MyHrUserProfileRoles contracts={contractsData} />
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
