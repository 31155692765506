import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import ApplicationHeader from "../application-header/application-header";
import ApplicationCardInfoItem from "../application-card-info-item/application-card-info-item";
import { useTranslation } from "react-i18next";
import OcDownload from "oc/components/oc-download";
import UnderThirtyMothersTaxCreditApplicationPrint from "../under-thirty-mothers-tax-credit-application-print";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useCurrentUserState from "hooks/use-current-user-state";
import TaxCreditApplicationCheckboxLine from "../tax-credit-application-checkbox-line/tax-credit-application-checkbox-line";
import OcMonthSelect from "oc/components/oc-month-select";
import { useRecoilState, useResetRecoilState } from "recoil";
import { partDiscountsThirtyMothersState } from "../../states/part-discounts-thirty-mothers-state";
import useDependentsDataDI from "hooks/use-dependents-data";
import TaxCreditApplicationButtonBars from "../tax-credit-application-buttonbars/tax-credit-application-buttonbars";
import ModifiedStatementCheck from "../modified-statement-check/modified-statement-check";
import { GET_MOTHERS_UNDER_THIRTY_DATA } from "graphql/queries/get-mothers-under-thirty-data";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { taxDeclarationMothersUnder30State } from "../../states/tax-declaration-mothers-under-30-state";
import { CREATE_TAX_DECLARATION_MOTHER_UNDER_30 } from "graphql/mutations/create-tax-declaration-mother-under-30";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { applyForTaxReliefAllOrPartAmount } from "../../states/apply-for-tax-relief-all-or-part-amount";
import { underThirtyMothersChildrenEligibleData } from "../../states/under-thirty-mothers-children-eligible-data";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import OcCard from "oc/components/oc-card";
import TaxCreditApplicationErrorBox from "my-hr/components/my-hr-applications-data-panel/components/tax-credit-application-error-box/tax-credit-application-error-box";
import useOcSnackbar from "hooks/use-oc-snackbar";
import { useTheme } from "@material-ui/styles";

const useFormData = ({ _id, setSelected }) => {
  const { t } = useTranslation();
  const [saveErrors, setSaveErrors] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [states, setStates] = useRecoilState(taxDeclarationMothersUnder30State);
  const resetStates = useResetRecoilState(taxDeclarationMothersUnder30State);
  const { data, loading, error } = useQuery(GET_MOTHERS_UNDER_THIRTY_DATA, {
    fetchPolicy: "cache-and-network",
    skip: !_id,
    variables: {
      _id: parseInt(_id),
    },
    onCompleted: (data) => {
      const result = data?.taxDeclarationMotherUnder30;
      const newStates = { ...result };
      newStates.dateOfDeclaration = new Date(result?.dateOfDeclaration);
      delete newStates.__typename;
      setStates(newStates);
    },
  });

  const result = data?.taxDeclarationMotherUnder30 || null;

  const handleChange = ({ key, value }) => {
    const newState = { ...states, [key]: value };
    setStates(newState);
  };

  const handleStateChange = (newState) => {
    setStates(newState);
  };

  const { setOpenMessage } = useOcSnackbar();
  const [saveDeclaration, { loading: mutationLoading }] = useMutation(
    CREATE_TAX_DECLARATION_MOTHER_UNDER_30,
    {
      onCompleted: (data) => {
        const res = data?.createTaxDeclarationMotherUnder30;
        setDisabledButton(false);
        if (res?.result) {
          if (res?.messages?.length > 0) {
            const message = res?.messages[0];
            if (
              message === "A korábban beadott nyilatkozata törlésre került!"
            ) {
              setOpenMessage({
                type: "success",
                message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
              });
              setOpenMessage({
                type: "warning",
                message: t("PREVIOUS_TAX_DECLARATION_DELETED"),
              });
            }
          } else {
            setOpenMessage({
              type: "success",
              message: t("TAX_DECLARATION_CREATED_SUCCESSFULLY"),
            });
          }
          setSaveErrors([]);
          const params = {
            editable: false,
            tableId: res?.tableId,
            year: states?.dateOfDeclaration?.getFullYear().toString(),
            type: "TaxDecarationMotherUnder30",
            state: "K",
          };
          setSelected(params);
        } else {
          setSaveErrors(res?.messages);
          setDisabledButton(false);
        }
      },
      onError: () => {
        setSaveErrors([t("TAX_DECLARATION_CREATED_FAILED")]);
        setDisabledButton(false);
      },
    }
  );

  useEffect(() => {
    if (!states?.isModifiedDeclaration) {
      handleStateChange({ ...states, noRequestReliefFromMonth: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states?.isModifiedDeclaration]);

  const handleSave = () => {
    setDisabledButton(true);
    const params = { ...states };
    saveDeclaration({ variables: params });
  };

  return {
    data: result,
    loading: loading || mutationLoading,
    error,
    states,
    disabledButton,
    handleChange,
    resetStates,
    handleSave,
    handleStateChange,
    saveErrors,
    setSaveErrors,
  };
};

const UnderThirtyMothersTaxCreditApplication = ({
  fullName,
  taxNumber,
  useDependentsData = useDependentsDataDI,
  taxApplicationSaveEnabled,
  handleRemoveRequest,
  selected,
  setSelected,
  isMobile
}) => {
  const editable = selected?.editable;
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const title =
    "Adóelőleg-nyilatkozat a 30 év alatti anyák kedvezményének érvényesítéséről";

  const componentRef = useRef();

  const textStyle = { color: "#cc0733" };

  const {
    states,
    loading,
    error,
    saveErrors,
    disabledButton,
    setSaveErrors,
    resetStates,
    handleChange,
    handleSave,
    handleStateChange,
  } = useFormData({
    _id: selected?.tableId,
    setSelected,
  });

  const isModified = selected?.isModified;
  const showModifiedButton = states?.state === "E" ? true : false;
  const showRemoveButton =
    states?.state === "K" || selected?.sate === "K" ? true : false;

  const dateOfDeclarationString = new Date(
    states?.dateOfDeclaration
  ).toLocaleDateString();

  const [applyForTaxReliefPartAmount, setApplyForTaxReliefPartAmount] =
    useRecoilState(partDiscountsThirtyMothersState);
  const resetApplyForTaxReliefPartAmount = useResetRecoilState(
    partDiscountsThirtyMothersState
  );

  if (
    states?.applyForTaxReliefAmount !== 0 &&
    !applyForTaxReliefPartAmount &&
    !editable
  ) {
    setApplyForTaxReliefPartAmount(true);
  }

  const { data: dependents } = useDependentsData();

  let { contractCode, employeeCode } = useCurrentUserState();
  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  let selectedContract = contractsData?.find(
    (item) => item.code === contractCode
  );

  const print = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    print();
  };

  const handleCancel = () => {
    resetApplyForTaxReliefPartAmount();
    resetRadioGroupState();
    resetRadioGroupStateEligibleData();
    resetStates();
    setSaveErrors([]);
  };

  /* Suitable for child */
  const suitableChild = dependents.find(
    (item) => new Date(item.birthDate) > new Date("2022-12-31T00:00:00.000Z")
  );

  let childName = suitableChild?.name;
  let childTaxNumber = suitableChild?.taxNumber;
  if (!editable) {
    childName = states?.ownChildName;
    childTaxNumber = states?.ownChildTaxNumber;
  }

  const isSuitableForChildText = (
    <>
      a 2022. december 31. után született{" "}
      <span style={childName ? textStyle : {}}>{childName || "____"}</span>{" "}
      nevű,{" "}
      <span style={childTaxNumber ? textStyle : {}}>
        {childTaxNumber || "____"}
      </span>{" "}
      adóazonosító jelű gyermekre tekintettel vagyok jogosult.
    </>
  );

  /* Suitable for fetus */
  const suitableForFetusdList = dependents.filter(
    (item) =>
      new Date(item.conceptionDate) > new Date("2022-05-31T00:00:00.000Z")
  );

  const fetus = dependents.find(
    (item) =>
      new Date(item.conceptionDate) > new Date("2022-05-31T00:00:00.000Z")
  );

  let fetusYear = new Date(fetus?.conceptionDate).getFullYear();
  let fetusMonth = new Date(fetus?.conceptionDate).getMonth() + 1;
  if (!editable) {
    fetusYear = states?.pregnancy91thDayYear;
    fetusMonth = states?.pregnancy91thDayMonth;
  }

  const conceptionDate = new Date(suitableForFetusdList[0]?.conceptionDate);

  const isSuitableForFetusText = (
    <>
      magzat után vagyok jogosult, a várandósság 91. napját{" "}
      <span style={fetusYear ? textStyle : {}}>{fetusYear || "____"}</span> év{" "}
      <span style={fetusMonth ? textStyle : {}}>{fetusMonth || "____"}</span>{" "}
      hónapban töltöttem be.
    </>
  );

  /* Suitable for adopted */
  const adoptedChild = dependents.find((item) => {
    if (
      item.dependentType2 === "O" &&
      new Date(item.adoptedDate) > new Date("2022-12-31T00:00:00.000Z")
    ) {
      const today = new Date();
      const birthDate = new Date(item.birthDate);
      const age = today.getFullYear() - birthDate.getFullYear();
      return age < 18;
    }
    return false;
  });

  let adoptedChildName = adoptedChild?.name;
  let adoptedChildTaxNumber = adoptedChild?.taxNumber;
  if (!editable) {
    adoptedChildName = states?.adoptedChildName;
    adoptedChildTaxNumber = states?.adoptedChildTaxNumber;
  }

  const isSuitableForAdoptedText = (
    <>
      a 2022. december 31. után örökbefogadott{" "}
      <span style={adoptedChildName ? textStyle : {}}>
        {adoptedChildName || "____"}
      </span>{" "}
      nevű,{" "}
      <span style={adoptedChildTaxNumber ? textStyle : {}}>
        {adoptedChildTaxNumber || "____"}
      </span>{" "}
      adóazonosító jelű gyermekre tekintettel vagyok jogosult.
    </>
  );

  const [radioGroupState, setRadioGroupState] = useRecoilState(
    applyForTaxReliefAllOrPartAmount
  );

  const resetRadioGroupState = useResetRecoilState(
    applyForTaxReliefAllOrPartAmount
  );

  const [radioGroupStateEligibleData, setRadioGroupStateEligibleData] =
    useRecoilState(underThirtyMothersChildrenEligibleData);

  const resetRadioGroupStateEligibleData = useResetRecoilState(
    underThirtyMothersChildrenEligibleData
  );

  const handleRadioGroupChange = (e) => {
    if (e.target.value === "allDiscountsThirtyMothers") {
      const newStates = {
        ...states,
        applyForTaxReliefAllAmount: true,
        applyForTaxRelief: false,
        applyForTaxReliefAmount: 0,
      };
      setApplyForTaxReliefPartAmount(false);
      handleStateChange(newStates);
    } else {
      const newStates = {
        ...states,
        applyForTaxReliefAllAmount: false,
        applyForTaxRelief: true,
        applyForTaxReliefAmount: parseFloat(e.target.value),
      };
      setApplyForTaxReliefPartAmount(true);
      handleStateChange(newStates);
    }
    setRadioGroupState(e.target.value);
  };

  if (!editable && states?.applyForTaxReliefAllAmount) {
    setRadioGroupState("allDiscountsThirtyMothers");
  }

  if (!editable && states?.applyForTaxRelief) {
    setRadioGroupState("partDiscountsThirtyMothers");
  }

  const handleRadioGroupChangeEligibleData = (e) => {
    if (e.target.value === "isSuitableForChild") {
      const newStates = {
        ...states,
        eligibleOnOwnChild: true,
        eligibleOnFetus: false,
        eligibleOnAdoptedChild: false,
        ownChildName: childName,
        ownChildTaxNumber: childTaxNumber,
        pregnancy91thDayYear: "",
        pregnancy91thDayMonth: "",
        adoptedChildName: "",
        adoptedChildTaxNumber: "",
      };
      handleStateChange(newStates);
    }
    if (e.target.value === "isSuitableForFetus") {
      const newStates = {
        ...states,
        eligibleOnOwnChild: false,
        eligibleOnFetus: true,
        eligibleOnAdoptedChild: false,
        pregnancy91thDayYear: fetusYear.toString(),
        pregnancy91thDayMonth: fetusMonth.toString(),
        ownChildName: "",
        ownChildTaxNumber: "",
        adoptedChildName: "",
        adoptedChildTaxNumber: "",
      };
      handleStateChange(newStates);
    }
    if (e.target.value === "isSuitableForAdopted") {
      const newStates = {
        ...states,
        eligibleOnOwnChild: false,
        eligibleOnFetus: false,
        eligibleOnAdoptedChild: true,
        adoptedChildName: adoptedChildName,
        adoptedChildTaxNumber: adoptedChildTaxNumber,
        pregnancy91thDayYear: "",
        pregnancy91thDayMonth: "",
        ownChildName: "",
        ownChildTaxNumber: "",
      };
      handleStateChange(newStates);
    }

    setRadioGroupStateEligibleData(e.target.value);
  };

  if (!editable && states?.eligibleOnOwnChild) {
    setRadioGroupStateEligibleData("isSuitableForChild");
  }

  if (!editable && states?.eligibleOnFetus) {
    setRadioGroupStateEligibleData("isSuitableForFetus");
  }

  if (!editable && states?.eligibleOnAdoptedChild) {
    setRadioGroupStateEligibleData("isSuitableForAdopted");
  }

  if (isModified && !states?.isModifiedDeclaration) {
    handleStateChange({ ...states, isModifiedDeclaration: true });
  }

  const handleScrollToError = () => {
    const errorElement = document.getElementById("errorBox");
    if (errorElement) {
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  };
  useLayoutEffect(() => {
    handleScrollToError();
  }, [saveErrors, history.action]);

  useEffect(() => {
    if (history.action === "POP" && editable) {
      return handleCancel();
    }
  }, []); //eslint-disable-line


  return (
    <>
      <Box
        style={{
          marginBottom: "-15px",
        }}
      >
        <Box
          sx={{
            display: "none",
            displayPrint: "block",
            paddingRight: "85px",
          }}
        >
          <UnderThirtyMothersTaxCreditApplicationPrint
            ref={componentRef}
            fullName={fullName}
            taxNumber={taxNumber}
            isSuitable={states?.isEligible}
            childName={childName}
            childTaxNumber={childTaxNumber}
            conceptionDate={conceptionDate}
            suitableForFetusdList={suitableForFetusdList}
            isSuitableForChild={states?.eligibleOnOwnChild}
            isSuitableForFetus={states?.eligibleOnFetus}
            isSuitableForAdopted={states?.eligibleOnAdoptedChild}
            allDiscountsThirtyMothers={states?.applyForTaxReliefAllAmount}
            isNoneApplyStartMonthThirtyMothers={
              states?.noRequestReliefFromMonth
            }
            isModifiedApplication={states?.isModifiedDeclaration}
            underThirtyMothersAmountDiscount={states?.applyForTaxReliefAmount}
            partDiscountsThirtyMothers={applyForTaxReliefPartAmount}
            employerName={selectedContract?.company?.name || ""}
            employerTaxNumber={selectedContract?.company?.taxNumber || ""}
            fetusYear={fetusYear}
            fetusMonth={fetusMonth}
            adoptedChildName={adoptedChildName}
            adoptedChildTaxNumber={adoptedChildTaxNumber}
            applicationDate={dateOfDeclarationString}
          />
        </Box>
        <ApplicationHeader
          title={title}
          onCancel={handleCancel}
          isMobile={isMobile}
        />
        <Box height="16px" />
        <Box display="flex" justifyContent="space-around">
          <Box
            style={{
              padding: "7px",
              width: "260px",
              borderRadius: "4px",
            }}
          >
            <OcDownload
              label="Tájékoztató"
              actionIconType="url"
              href={"#/my-hr/under-thirty-mothers-tax-credit-information"}
              target="_blank"
              isSmall={true}
            />
          </Box>
        </Box>
        <Box height="16px" />
        {saveErrors.length > 0 && (
          <Box id="errorBox">
            <TaxCreditApplicationErrorBox error={saveErrors} />
          </Box>
        )}
        <OcCard
          label="I. A nyilatkozatot adó magánszemély"
          loading={loading}
          error={error}
        >
          <Box height="52px" />
          <ApplicationCardInfoItem
            label="Dátum"
            value={dateOfDeclarationString}
            isMobile={isMobile}
          />
          <ApplicationCardInfoItem
            label="Dolgozó neve"
            value={fullName}
            isMobile={isMobile}
          />
          <ApplicationCardInfoItem
            label="Adóazonosító jele"
            value={taxNumber}
            isMobile={isMobile}
          />
          <Box height="15px" />
          <ModifiedStatementCheck
            label={t("MODIFIED_APPLICATION")}
            name="isModifiedDeclaration"
            value={states?.isModifiedDeclaration}
            onChange={handleChange}
            disabled={!editable || isModified}
            isMobile={isMobile}
          />
          <Box height="15px" />
          <TaxCreditApplicationCheckboxLine
            id="isSuitable"
            prefix="1."
            type="checkbox"
            checked={states?.isEligible}
            checkboxDisabled={!editable}
            onChange={(e) =>
              handleChange({ key: "isEligible", value: e.target.checked })
            }
            text="Nyilatkozom, hogy a 30 év alatti anyák kedvezményére, a 2. pont szerinti gyermekre, magzatra tekintettel jogosult vagyok."
          />
          <Box height="36px" />
          <Box display="flex">
            <Typography variant="body1">2.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              A 30 év alatti anyák kedvezményére
            </Typography>
          </Box>
          <Box height="10px" />
          <Box paddingLeft="30px">
            <FormControl component="fieldset" width="16px" display="flex">
              <Box height="8px" />
              <RadioGroup
                value={radioGroupStateEligibleData}
                aria-label="gender"
                name="controlled-radio-buttons-group"
                onChange={handleRadioGroupChangeEligibleData}
                disabled={!editable}
              >
                <Box display="flex" alignItems="center">
                  a)
                  <Box width="16px" />
                  <FormControlLabel
                    value="isSuitableForChild"
                    control={
                      <Radio
                        id="isSuitableForChild"
                        style={{
                          color:
                            !editable || !childTaxNumber
                              ? theme.palette.gray60.main
                              : theme.palette.darkBase.main,
                        }}
                        disabled={
                          !suitableChild ||
                          !editable ||
                          !childTaxNumber
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{ alignItems: "center" }}
                      >
                        {isSuitableForChildText}
                      </Typography>
                    }
                  />
                </Box>
                <Box height="10px" />
                <Box display="flex" alignItems="center">
                  b)
                  <Box width="16px" />
                  <FormControlLabel
                    value="isSuitableForFetus"
                    control={
                      <Radio
                        id="isSuitableForFetus"
                        style={{
                          color:
                            !editable || suitableForFetusdList?.length === 0
                              ? theme.palette.gray60.main
                              : theme.palette.darkBase.main,
                        }}
                        disabled={
                          suitableForFetusdList?.length === 0 ||
                          !editable
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{ alignItems: "center" }}
                      >
                        {isSuitableForFetusText}
                      </Typography>
                    }
                  />
                </Box>
                <Box height="10px" />
                <Box display="flex" alignItems="center">
                  c)
                  <Box width="16px" />
                  <FormControlLabel
                    value="isSuitableForAdopted"
                    control={
                      <Radio
                        id="isSuitableForAdopted"
                        style={{
                          color:
                            !editable || !adoptedChildTaxNumber
                              ? theme.palette.gray60.main
                              : theme.palette.darkBase.main,
                        }}
                        disabled={
                          !adoptedChild ||
                          !editable ||
                          !adoptedChildTaxNumber
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{ alignItems: "center" }}
                      >
                        {isSuitableForAdoptedText}
                      </Typography>
                    }
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box height="36px" />
          <Box display="flex">
            <Typography variant="body1">3.</Typography>
            <Box width="16px" />
            <Typography variant="body1">
              Kérem a kedvezményt az adóelőleg megállapítása során:
            </Typography>
          </Box>
          <Box height="5px" />
          <Box marginLeft="32px">
            <FormControl component="fieldset" width="16px" display="flex">
              <Box height="8px" />
              <RadioGroup
                value={radioGroupState}
                aria-label="gender"
                name="controlled-radio-buttons-group"
                onChange={handleRadioGroupChange}
                disabled={!editable}
              >
                <Box display="flex" alignItems="center">
                  a)
                  <Box width="16px" />
                  <FormControlLabel
                    value="allDiscountsThirtyMothers"
                    control={
                      <Radio
                        id="allDiscountsThirtyMothers"
                        style={{ color: !editable ? theme.palette.gray60.main : theme.palette.darkBase.main }}
                        disabled={!editable}
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        style={{ alignItems: "center" }}
                      >
                        Teljes összegben figyelembe venni.
                      </Typography>
                    }
                  />
                </Box>
                <Box height="10px" />
                <Box display="flex" alignItems="center">
                  b)
                  <Box width="16px" />
                  <FormControlLabel
                    value="partDiscountsThirtyMothers"
                    control={
                      <Radio
                        id="partDiscountsThirtyMothers"
                        style={{ color: !editable ? theme.palette.gray60.main : theme.palette.darkBase.main }}
                        disabled={!editable}
                      />
                    }
                    label={
                      <TaxCreditApplicationCheckboxLine
                        id="marriageAmount"
                        text="forint összegben kívánom igénybe venni."
                        textInput={true}
                        textInputType="number"
                        value={
                          states?.applyForTaxReliefAmount !== 0
                            ? states?.applyForTaxReliefAmount
                            : ""
                        }
                        label={t("AMOUNT")}
                        onChangeInput={(e) =>
                          handleChange({
                            key: "applyForTaxReliefAmount",
                            value: parseFloat(
                              e.target.value.replace(/[^0-9]/g, "")
                            ),
                          })
                        }
                        textInputDisabled={!editable}
                        isMobile={isMobile}
                      />
                    }
                  />
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
          <Box height="36px" />
          <Box style={{ color: !states?.isModifiedDeclaration && theme.palette.gray60.main }}>
            <Box display="flex" alignItems={isMobile ? "flex-start" : "center"}>
              <Typography variant="body1">4.</Typography>
              <Box width="16px" />
              <Box
                display="flex"
                alignItems={isMobile ? "flex-start" : "center"}
                flexDirection={isMobile ? "column" : "row"}
              >
                <Typography variant="body1">
                  Nyilatkozom, hogy a 30 év alatti anyák kedvezményét
                </Typography>
                <Box
                  marginLeft={isMobile ? "0px" : "16px"}
                  marginRight="16px"
                  width={isMobile ? "100%" : "auto"}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                >
                  <OcMonthSelect
                    id="start-of-application-select"
                    width="160px"
                    label={t("TAXCREDITMONTH")}
                    selectedMonth={states?.noRequestReliefFromMonth}
                    onSelectMonth={(e) =>
                      handleChange({
                        key: "noRequestReliefFromMonth",
                        value: e,
                      })
                    }
                    disabled={!editable || !states?.isModifiedDeclaration}
                  />
                </Box>
                <Typography variant="body1">
                  hónaptól kezdődően nem (erre a hónapra sem) kívánom igénybe
                  venni.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box height="16px" />
        </OcCard >
        <Box height="16px" />
        <TaxCreditApplicationButtonBars
          taxApplicationSaveEnabled={taxApplicationSaveEnabled && editable}
          cancelData={handleCancel}
          handlePrint={handlePrint}
          saveData={handleSave}
          editable={editable}
          disabledButton={disabledButton}
          handleRemoveRequest={handleRemoveRequest}
          showModifiedButton={showModifiedButton}
          showRemoveButton={showRemoveButton}
          isModified={isModified}
          isModifiedDeclaration={states?.isModifiedDeclaration}
          isMobile={isMobile}
        />
      </Box >
    </>
  );
};

export default UnderThirtyMothersTaxCreditApplication;
