import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import useCafeteriaMenu from "my-cafeteria/hooks/use-cafeteria-menu";

const useStyles = makeStyles((theme) => ({
  formControl: ({ width, isMobile }) => ({
    minWidth: isMobile ? "100%" : width,
    width: isMobile ? "100%" : width,
    backgroundColor: "white",
  }),
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const MyCafeteriaMenuSelect = ({
  id = "001",
  width,
  selectedItem,
  onSelectItem,
  contractCode,
  disabled = false,
  currentStatementItems,
  isMobile
}) => {
  const classes = useStyles({ width, isMobile });
  const { t } = useTranslation();
  let label = t("BENEFIT");

  const { data } = useCafeteriaMenu({ contractCode });

  const handleSelectItem = (e) => {
    let menuItemCode = e.target.value;
    let result = data?.find((item) => item.code === menuItemCode);
    onSelectItem(result);
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      disabled={disabled}
    >
      <InputLabel id={id} htmlFor={`menu-select-${id}`}>
        {label}
      </InputLabel>
      <Select
        id={`menu-select-${id}`}
        value={selectedItem}
        onChange={handleSelectItem}
        label={label}
        defaultValue=""
        inputProps={{
          id: id,
          style: {
            backgroundColor: "white",
          },
        }}
      >
        <MenuItem value="">-- {t("NOT_SELECTED")} --</MenuItem>
        {data?.map((item, index) => {
          let isExist = currentStatementItems?.find(
            (filterItem) => filterItem?.menuItem?.code === item?.code
          );
          return (
            <MenuItem
              key={index + "-menu-select-item"}
              value={item.code}
              disabled={isExist && !item.isReusable}
            >
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

MyCafeteriaMenuSelect.propTypes = {
  /** Example prop type description*/
  width: PropTypes.string,
};

MyCafeteriaMenuSelect.defaultProps = {
  width: "290px",
};

export default MyCafeteriaMenuSelect;
