import React, { useState } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import UsersFilterBar from "my-hr/components/my-hr-users-filter-bar";
import Table from "oc/components/oc-data-table";
import { useTranslation } from "react-i18next";
import { users, usersIsMobile } from "my-hr/configs/my-hr-table-defs";
import useUsers from "hooks/use-myhr-users";
import { useHistory } from "react-router-dom";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import MyHrAdminUsersImportWrapper from "my-hr/components/my-hr-admin-users-import-wrapper";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyHrAdminUsersPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();

  const tableId = "usersListTable";

  initialTableData.sortField = "fullname";
  initialTableData.pageSize = 5;
  initialTableData.filterField = "active";
  initialTableData.filterSelected = ["active"];

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const [selectedRowId, setSelectedRowId] = useState(null);

  const { data, loading } = useUsers({
    sortField: tableData?.sortField,
    sortDirection: tableData?.sortDirection,
    filterField: tableData?.filterField,
    filterSelected: tableData?.filterSelected,
    searchField: tableData?.searchField,
    searchText: tableData?.searchText,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
  });

  function handleNewUser() {
    history.push("/my-hr/create-new-user");
  }

  function handleSelectRow(o) {
    history.push(`/my-hr/user-profile/${o.code}`);
  }

  const onSelectRow = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
    } else {
      handleSelectRow(o);
    }
  };

  function handleAddGroupRole() {
    history.push("/my-hr/users/add-group-roles");
  }

  function handleInactivateGroup() {
    history.push("/my-hr/users/inactivate-group");
  }

  function handleRemoveGroup() {
    history.push("/my-hr/users/remove-group");
  }

  function handleSendGroupPassords() {
    history.push("/my-hr/users/send-group-passwords");
  }

  const tableDef = isMobile
    ? usersIsMobile
    : users;

  const filteredTableDef = {
    id: users?.id,
    columns: users?.columns?.filter(column =>
      !usersIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <OcPageTransition>
      <Box padding={isMobile ? "16px 0px" : "16px"}>
        <MyHrAdminUsersImportWrapper />
        <OcCard label={t("GET_USERS")}>
          <>
            <UsersFilterBar
              onNewUser={handleNewUser}
              onAddGroupRole={handleAddGroupRole}
              onInactivateGroup={handleInactivateGroup}
              onRemoveGroupRole={handleRemoveGroup}
              onSendGroupPasswords={handleSendGroupPassords}
              tableData={tableData}
              setTableData={setTableData}
              loading={loading}
              dataLength={data?.rows?.length}
            />
            <Box paddingTop="26px">
              <Table
                data={data}
                loading={loading && data?.rows?.length === 0 ? true : false}
                tableDef={tableDef}
                tableData={tableData}
                setTableData={setTableData}
                pagination={true}
                onSelectRow={onSelectRow}
                filteredTableDef={filteredTableDef}
                selectedRowId={selectedRowId}
                handleMoreButton={handleSelectRow}
              />
            </Box>
          </>
        </OcCard>
      </Box>
    </OcPageTransition>
  );
}
