import React from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import exclamationIcon from "@iconify/icons-mdi/exclamation";
import { InlineIcon } from "@iconify/react";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import checkIcon from "@iconify/icons-mdi/check";
import closeIcon from "@iconify/icons-mdi/close";

export default function MyHrUserDataEditCollapseHeaderBadge({
  badgeName,
  border,
}) {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const getColor = (label) => {
    switch (label) {
      case "Kérelmezve":
        return "#F3AA18";
      case "Elfogadva":
        return "#2FA84F";
      case "Elutasítva":
        return theme.palette.primary.main;
      default:
        return "white";
    }
  };

  const iconMap = {
    Kérelmezve: exclamationIcon,
    Elutasítva: closeIcon,
    Elfogadva: checkIcon,
  };

  const iconColor = getColor(badgeName);
  const IconComponent = iconMap[badgeName];

  return (
    <>
      {isMobile && IconComponent && (
        <InlineIcon
          icon={IconComponent}
          width="24px"
          style={{
            color: iconColor,
            cursor: "pointer",
            border: border,
            borderRadius: 12,
            paddingLeft: isMobile ? 0 : 12,
            paddingRight: isMobile ? 0 : 12,
            paddingTop: isMobile ? 0 : 2,
            paddingBottom: isMobile ? 0 : 2,
            width: isMobile ? "20px" : "auto",
            height: isMobile ? "20px" : "auto",
            display: isMobile ? "flex" : "unset",
            alignItems: isMobile ? "center" : "unset"

          }}
        />
      )}
      {!isMobile && (
        <Box
          style={{
            color: theme.palette.gray80.main,
            border: border,
            borderRadius: 12,
            paddingLeft: 12,
            paddingRight: 12,
            paddingTop: 2,
            paddingBottom: 2,
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          {badgeName}
        </Box>
      )}
    </>
  );
}
