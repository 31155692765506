import React from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import MyLeaveEmailCard from "my-leave/components/my-leave-email-card";
import useOcClientState from "hooks/use-oc-client-state-2";
import OcPageSideMenu from "oc/components/oc-page-side-menu";
import MyLeaveAdminSettingsTypeFilterCard from "my-leave/components/my-leave-admin-settings-type-filter-card";
import MyLeaveGeneralSettingsCard from "my-leave/components/my-leave-general-settings-card";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyLeaveAdminSettingsPage() {
  const menuDef = [
    {
      id: "generalSettings",
      label: "GENERAL_SETTINGS",
      roles: ["MyHR.admin"],
    },
    {
      id: "leaveFilters",
      label: "GRANT_LEAVE_TYPES",
      roles: ["MyHR.admin"],
    },
    {
      id: "administrativeEmail",
      label: "ADMINISTRATIVE_EMAIL",
      roles: ["MyHR.admin"],
    },
    {
      id: "subsequentRequests",
      label: "SUBSEQUENT_REQUESTS",
      roles: ["MyHR.admin"],
    },
  ];

  const stateId = "myLeaveSettingsPageMenuActiveItem";
  const [selectedItem] = useOcClientState(stateId, menuDef[0].id);
  const isMobile = useIsMobile();

  return (
    <OcPageTransition>
      <Box>
        <Box
          height="80px"
          style={{ backgroundColor: "#cc0033" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="14px"
        >
          <Box style={{ color: "white", fontSize: "24px" }}>MyLeave</Box>
        </Box>
        <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
          <OcPageSideMenu
            selectedItem={selectedItem}
            stateId="myLeaveSettingsPageMenuActiveItem"
            menuDef={menuDef}
          />
          <Box flexGrow={1} padding={isMobile ? "16px 0px" : "16px"}>
            {selectedItem === "generalSettings" && (
              <MyLeaveGeneralSettingsCard isMobile={isMobile} />
            )}
            {selectedItem === "leaveFilters" && (
              <MyLeaveAdminSettingsTypeFilterCard />
            )}
            {selectedItem === "administrativeEmail" && (
              <MyLeaveEmailCard type="administrativeEmail" isMobile={isMobile} />
            )}
            {selectedItem === "subsequentRequests" && (
              <MyLeaveEmailCard type="payrollEmail" isMobile={isMobile} />
            )}
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
