import React from "react";
import MyCafeteriaSzepApplicationCardPrint from "my-cafeteria/components/my-cafeteria-szep-application-card-print";
class MyCafeteriaSzepApplication extends React.PureComponent {
  render() {
    const { applicationDate, employeeName, idNumber, contractCode, currentYear, hasPreviousBenefit, thisYear, data } = this.props;

    return <MyCafeteriaSzepApplicationCardPrint
      applicationDate={applicationDate}
      employeeName={employeeName}
      idNumber={idNumber}
      contractCode={contractCode}
      currentYear={currentYear}
      hasPreviousBenefit={hasPreviousBenefit}
      thisYear={thisYear}
      data={data}
    />;
  }
}

export default MyCafeteriaSzepApplication;
