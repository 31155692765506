export const benefits = {
  id: "benefits-table",
  columns: [
    {
      id: "origin",
      label: "ORIGIN",
      type: "text",
    },
    {
      id: "benefit",
      type: "text",
      label: "BENEFIT",
      //width: "85px",
    },
    {
      id: "months",
      type: "text",
      label: "MONTHS_PLURAL",
      align: "right",
    },
    {
      id: "numberOfMonths",
      type: "text",
      label: "NUMBER_OF_MONTHS",
      align: "right",
    },
    {
      id: "amount",
      type: "currency",
      label: "NETTO",
      align: "right",
    },
    {
      id: "cost",
      type: "currency",
      label: "COST",
      align: "right",
    },
    {
      id: "valueOfBenefit",
      type: "currency",
      label: "BRUTTO",
      zeroPadding: true,
      align: "right",
    },
    {
      id: "action",
      type: "editButton",
      //label: "COST",
      align: "right",
    },
  ],
};

export const benefitsIsMobile = {
  id: "benefits-table",
  select: "row",
  columns: [
    {
      id: "benefit",
      type: "text",
      label: "BENEFIT",
    },
    {
      id: "months",
      type: "text",
      label: "MONTHS_PLURAL",
      align: "right",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const archivedBenefits = {
  id: "archived-benefits-table",
  columns: [
    {
      id: "origin",
      label: "ORIGIN",
      type: "text",
    },
    {
      id: "benefit",
      type: "text",
      label: "BENEFIT",
      //width: "85px",
    },
    {
      id: "months",
      type: "text",
      label: "MONTHS_PLURAL",
      align: "right",
    },
    {
      id: "numberOfMonths",
      type: "text",
      label: "NUMBER_OF_MONTHS",
      align: "right",
    },
    {
      id: "amount",
      type: "currency",
      label: "NETTO",
      align: "right",
    },
    {
      id: "cost",
      type: "currency",
      label: "COST",
      align: "right",
    },
    {
      id: "valueOfBenefit",
      type: "currency",
      label: "BRUTTO",
      zeroPadding: true,
      align: "right",
    },
    {
      id: "action",
      type: "copyButton",
      //label: "COST",
      align: "right",
    },
  ],
};

export const archivedBenefitsIsMobile = {
  id: "archived-benefits-table",
  select: "row",
  columns: [
    {
      id: "benefit",
      type: "text",
      label: "BENEFIT",
    },
    {
      id: "months",
      type: "text",
      label: "MONTHS_PLURAL",
      align: "right",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const vbkApplications = {
  id: "benefits-table",
  columns: [
    {
      id: "benefit",
      type: "text",
      label: "BENEFIT",
      //width: "85px",
    },
    {
      id: "months",
      type: "text",
      label: "MONTHS_PLURAL",
      align: "right",
    },
    {
      id: "numberOfMonths",
      type: "text",
      label: "NUMBER_OF_MONTHS",
      align: "right",
    },
    {
      id: "amount",
      type: "currency",
      label: "AMOUNT",
      align: "right",
    },
    {
      id: "valueOfBenefit",
      type: "currency",
      label: "VALUE_OF_BENEFIT",
      zeroPadding: true,
      align: "right",
    },
    {
      id: "cost",
      type: "currency",
      label: "COST",
      align: "right",
    },
  ],
};
