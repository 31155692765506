import React from "react";
import Item from "oc/components/oc-info-card-item";
import { useTranslation } from "react-i18next";
import moment from "moment";
require("moment-precise-range-plugin");

export default function OcInfoCardPeriod({
  validFrom,
  validTo,
  translation = "Period",
}) {
  const { t } = useTranslation();
  if (!validFrom && !validTo) return "";
  let start = moment(validFrom);
  let end = validTo || moment();
  let period = moment.preciseDiff(start, end, true);
  let startString = new Date(validFrom).toLocaleDateString();
  let endString = validTo
    ? new Date(validTo).toLocaleDateString()
    : t("PRESENT");

  let periodString = "";

  period.months += 1;

  if (period?.years === 1) {
    periodString = periodString + period.years + ` ${t("YEAR")}`;
  }
  if (period?.years > 1) {
    periodString = periodString + period.years + ` ${t("YEARS")}`;
  }
  if (period.months === 1) {
    if (period?.years === 0) {
      periodString = periodString + period.months + ` ${t("MONTH")}`;
    } else {
      periodString =
        periodString + ", " + period.months + ` ${t("MONTH")}`;
    }
  }
  if (period.months > 1) {
    if (period?.years === 0) {
      periodString = periodString + period.months + ` ${t("MONTHS")}`;
    } else {
      periodString =
        periodString + ", " + period.months + ` ${t("MONTHS")}`;
    }
  }

  if (periodString !== "") {
    periodString = `(${periodString})`;
  }

  let value = `${startString} - ${endString} ${periodString}`;
  return (
    <Item label={t("VALIDITY")} value={value} translation={translation} />
  );
}
