import React from "react";
import Box from "@material-ui/core/Box";
import useClientState from "hooks/use-oc-client-state";
import useStyles from "./oc-public-layout-background.style";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function OcPublicLayoutBackground({ children }) {
  const isMobile = useIsMobile();
  const [darkBackground] = useClientState("darkBackground", false);
  let transparency = darkBackground ? "rgba(0,0,0,0.7)" : "transparent";

  const classes = useStyles({ isMobile });

  return (
    <Box
      display="flex"
      position="relative"
      width="100%"
      height="100%"
      overflow="auto"
      id="common-picture-background"
      className={classes.root}
    >
      <Box width="100%" height="100%" style={{ backgroundColor: transparency }}>
        {children}
      </Box>
    </Box>
  );
}
