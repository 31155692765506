import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import OcPasswordInputField from "oc/components/oc-password-input-field";
import useOcPasswordInput from "hooks/use-oc-password-input";
import useLastPasswordChange from "hooks/use-myhr-last-password-change";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useMutation } from "@apollo/react-hooks";
import { CHANGE_PASSWORD } from "graphql/mutations";
import useProperty from "hooks/use-oc-property";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import useOcSnackbar from "hooks/use-oc-snackbar";

export default function MyHrUserPasswordCard({ email, isMobile }) {
  const { t } = useTranslation();

  const { data: passwordPolicy } = useProperty({
    resource: "system",
    key: "passwordPolicy",
  });

  const { setOpenMessage } = useOcSnackbar();

  const [loading, setLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { data } = useLastPasswordChange({ email });

  let lastPasswordChange = new Date(data).toLocaleString();

  const {
    value: password,
    setValue: setPassword,
    isValid: isPasswordValid,
    validate: validatePassword,
    error: passwordError,
    setError: setPasswordError,
    helperText: passwordHelperText,
    setHelperText: setPasswordHelperText,
  } = useOcPasswordInput();

  const {
    value: passwordAgain,
    setValue: setPasswordAgain,
    isValid: isPasswordAgainValid,
    validate: validatePasswordAgain,
    error: passwordAgainError,
    setError: setPasswordAgainError,
    helperText: passwordAgainHelperText,
    setHelperText: setPasswordAgainHelperText,
  } = useOcPasswordInput();

  const {
    value: currentPassword,
    setValue: setCurrentPassword,
    isValid: isCurrentPasswordValid,
    validate: validateCurrentPassword,
    error: currentPasswordError,
    setError: setCurrentPasswordError,
    helperText: currentPasswordHelperText,
    setHelperText: setCurrentPasswordHelperText,
  } = useOcPasswordInput();

  let disableSaveButton = true;

  if (password && passwordAgain) {
    disableSaveButton = false;
  }

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (data) => {
      setLoading(false);
      setPassword("");
      setPasswordAgain("");
      setCurrentPassword("");
      setOpenMessage({
        type: "success",
        message: t("PASSWORD_CHANGE_SUCCEEDED"),
      });
    },
    onError: (error) => {
      if (error.message === "Wrong password!") {
        setPasswordError(true);
        setCurrentPasswordError(true);
        setCurrentPasswordHelperText(t("ERROR_MESSAGE_WRONG_PASSWORD"));
        setPasswordHelperText(t("ERROR_MESSAGE_PASSWORD_IS_WRONG"));
      }
      setLoading(false);
    },
  });

  function handleCancel() {
    setPassword("");
    setPasswordAgain("");
    setCurrentPassword("");
    setCurrentPasswordError(false);
    setPasswordAgainError(false);
    setPasswordError(false);
    setCurrentPasswordHelperText("");
    setPasswordHelperText("");
    setPasswordAgainHelperText("");
  }

  function handleSave(e) {
    e.preventDefault();

    let valid = true;

    if (!isPasswordValid({ checkPasswordPolicy: true })) {
      validatePassword({ checkPasswordPolicy: true });
      valid = false;
      setShowConfirmDialog(false);
    }

    if (!isPasswordAgainValid({ checkPasswordPolicy: true })) {
      validatePasswordAgain({ checkPasswordPolicy: true });
      valid = false;
      setShowConfirmDialog(false);
    }

    if (!isCurrentPasswordValid({ checkPasswordPolicy: false })) {
      validateCurrentPassword({ checkPasswordPolicy: false });
      valid = false;
      setShowConfirmDialog(false);
    }

    if (password !== passwordAgain) {
      setPasswordError(true);
      setPasswordHelperText(t("ERROR_MESSAGE_WRONG_PASSWORD"));
      setPasswordAgainError(true);
      setPasswordAgainHelperText(t("ERROR_MESSAGE_TWO_PASSWORD_IS_DIFFERENT"));
      return;
    }

    if (password && passwordAgain === currentPassword) {
      setPasswordError(true);
      setPasswordHelperText(t("Error.NOT_SAME_PASSWORD"));
      disableSaveButton = true;
      setShowConfirmDialog(false);
      return;
    }

    if (!valid) return;

    changePassword({
      variables: {
        email,
        oldPassword: currentPassword,
        newPassword: password,
      },
    });
    setShowConfirmDialog(false);
    setCurrentPasswordError(false);
    setPasswordAgainError(false);
    setPasswordError(false);
  }

  function handleDialogCancel() {
    setShowConfirmDialog(false);
    setPasswordError(false);
    setPasswordAgainError(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("PASSWORD")} loading={loading}>
        <Box padding="16px" width={isMobile ? "auto" : "464px"}>
          <form>
            <Box
              paddingBottom="36px"
              fontSize="12px"
              letterSpacing="0.4px"
              color="#4d4d4d"
            >
              {passwordPolicy === "strong"
                ? t("INFO_TEXT_STRONG_PASSWORD")
                : t("INFO_TEXT_NOT_STRONG_PASSWORD")}
            </Box>

            <OcPasswordInputField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              helperText={passwordHelperText}
              label={t("NEW_PASSWORD")}
              setError={setPasswordError}
              setHelperText={setPasswordHelperText}

            />
            {!passwordError && (
              <Box paddingLeft="14px">
                <FormHelperText>
                  {t("GET_LAST_PASSWORD_CHANGE")}: {lastPasswordChange}
                </FormHelperText>
              </Box>
            )}
            <Box height="36px" />
            <OcPasswordInputField
              value={passwordAgain}
              onChange={(e) => setPasswordAgain(e.target.value)}
              error={passwordAgainError}
              helperText={passwordAgainHelperText}
              label={t("CONFIRM_NEW_PASSWORD")}
              setError={setPasswordAgainError}
              setHelperText={setPasswordAgainHelperText}

            />
            <Box height="36px" style={{ borderBottom: "1px solid #C6CACC" }} />
            <Box height="36px" />
            <OcPasswordInputField
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              error={currentPasswordError}
              helperText={currentPasswordHelperText}
              label={t("CURRENT_PASSWORD")}
              setError={setCurrentPasswordError}
              setHelperText={setCurrentPasswordHelperText}

            />
            <Box height="20px" />
          </form>
        </Box>
        <OcCardButtonBar
          handleRightButton={handleCancel}
          rightButtonText={t("CANCEL")}
          handlePrimaryButton={() => setShowConfirmDialog(true)}
          primaryButtonText={t("SAVE")}
          primaryButtonDisabled={disableSaveButton}
        />
      </OcCard>
    </>
  );
}
