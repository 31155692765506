import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import OcPageTransition from "oc/components/oc-page-transition";
import Table from "oc/components/oc-data-table";
import { orphanedLeaveRequests, orphanedLeaveRequestsIsMobile } from "my-leave/configs/my-leave-table-defs";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import useOrphanedRequestsList from "hooks/use-my-leave-orphaned-requests-list";
import getFilterValues from "oc/utils/get-filter-values";
import _ from "lodash";
import { useMutation } from "@apollo/react-hooks";
import { APPROVE_LEAVE_REQUEST, REJECT_LEAVE_REQUEST } from "graphql/mutations";
import { useTranslation } from "react-i18next";
import useOcSnackbar from "hooks/use-oc-snackbar";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function ViewLeaveRequests() {
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();

  const { data, loading } = useOrphanedRequestsList();

  let tableId = "handleRequestsTable";
  initialTableData.sortField = "name";
  initialTableData.pageSize = 5;
  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const [mutationLoading, setMutationLoading] = useState(false);
  let result = data || [];

  result = _.orderBy(
    result,
    [tableData?.sortField],
    [tableData?.sortDirection]
  );

  if (tableData?.filterField) {
    let filterValues = getFilterValues(tableData?.filterField, result);
    if (!_.isEqual(filterValues && tableData?.filterValues)) {
      setTableData({
        filterValues,
      });
    }
  }

  if (tableData?.filterSelected.length > 0) {
    result = result.filter(function (item) {
      let key = item[tableData?.filterField];
      if (key) {
        return tableData?.filterSelected.includes(key);
      } else {
        return item;
      }
    });
  }

  if (tableData?.searchField && tableData?.searchText) {
    var regex = new RegExp(tableData?.searchText, "i");
    result = result.filter((obj) => regex.test(obj.name));
  }

  let list = {
    rows: result,
    total: result.length,
    page: tableData.page,
    pageSize: tableData.pageSize,
  };

  const { setOpenMessage } = useOcSnackbar();

  const [approveLeaveRequest] = useMutation(APPROVE_LEAVE_REQUEST, {
    onCompleted: (data) => {
      setMutationLoading(false);
      setOpenMessage({
        type: "success",
        message: t("APPROVE_LEAVE_REQUEST_SUCCEEDED"),
      });
    },
    onError: (error) => {
      console.log("an error occured", { error });
      setMutationLoading(false);
      setOpenMessage({
        type: "error",
        message: error?.message
          ? error?.message
          : t("APPROVE_LEAVE_REQUEST_FAILED"),
      });
    },
  });

  const [rejectLeaveRequest] = useMutation(REJECT_LEAVE_REQUEST, {
    onCompleted: (data) => {
      setMutationLoading(false);
      setOpenMessage({
        type: "success",
        message: t("REJECT_LEAVE_REQUEST_SUCCEEDED"),
      });
    },
    onError: (error) => {
      setMutationLoading(false);
      setOpenMessage({
        type: "error",
        message: error?.message
          ? error?.message
          : t("APPROVE_LEAVE_REQUEST_FAILED"),
      });
    },
  });

  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleSelectRequest = (props) => {
    let _id = props.id;
    let message = "";
    let locale = i18n.language.substr(0, 2).toLowerCase();
    let variables = {
      _id,
      message,
      locale,
      closedByAdmin: true,
    };
    setMutationLoading(true);
    if (props.action === "accepted") {
      approveLeaveRequest({ variables });
    } else {
      rejectLeaveRequest({ variables });
    }
  };

  const onSelectRow = (props) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === props?._id ? null : props?._id);
    } else {
      handleSelectRequest(props);
    }
  };

  const tableDef = isMobile
    ? orphanedLeaveRequestsIsMobile
    : orphanedLeaveRequests;

  const filteredTableDef = {
    id: orphanedLeaveRequests?.id,
    columns: orphanedLeaveRequests?.columns?.filter(column =>
      !orphanedLeaveRequestsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <OcPageTransition>
      <Box padding={isMobile ? "16px 0px" : "16px"}>
        <OcCard
          label={t("LEAVE_AND_ABSENCE_REQUESTS")}
          loading={loading || mutationLoading}
        >
          <Table
            data={list}
            loading={loading}
            tableDef={tableDef}
            tableData={tableData}
            setTableData={setTableData}
            pagination={true}
            onSelectRow={onSelectRow}
            localPaging={true}
            filteredTableDef={filteredTableDef}
            selectedRowId={selectedRowId}
            handleMoreButton={handleSelectRequest}
          />
        </OcCard>
      </Box>
    </OcPageTransition>
  );
}
