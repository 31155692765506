import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  text: ({ borderColor, isMobile }) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: 12,
    paddingLeft: isMobile ? 0 : 12,
    paddingRight: isMobile ? 0 : 12,
    paddingTop: isMobile ? 0 : 2,
    paddingBottom: isMobile ? 0 : 2,
    width: isMobile ? "20px" : "auto",
    height: isMobile ? "20px" : "auto",
    display: isMobile ? "flex" : "unset",
    alignItems: isMobile ? "center" : "unset"
  }),
  textLoading: {
    backgroundColor: theme.palette.gray30.main,
    color: theme.palette.gray30.main,
    borderRadius: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 1,
    paddingBottom: 1,
    width: 125,
    height: 22,
  },
}));

export default useStyles;
