import React from 'react';
import { Box, Typography, FormControlLabel, Radio } from "@material-ui/core";

const MyTimeTimePeriodOption = ({ value, label, infoLabel, isMobile, id }) => {
  return (
    <Box width="100%">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
      >
        <Box width={isMobile ? "auto" : "200px"} textAlign={isMobile ? "left" : "right"}>
          <Typography variant="caption" style={{ paddingRight: "16px" }}>
            {label}
          </Typography>
        </Box>
        <Box width={isMobile ? "auto" : "450px"}>
          <FormControlLabel
            value={value}
            control={<Radio id={id} />}
            label={!isMobile &&
              <Typography variant="caption">
                {infoLabel}
              </Typography>
            }
          />
        </Box>
      </Box>
      {isMobile && <Box style={{ marginLeft: isMobile ? "0px" : "36px" }} textAlign="left">
        <Typography variant="caption">{infoLabel}</Typography>
      </Box>
      }
    </Box>
  );
};

export default MyTimeTimePeriodOption;
