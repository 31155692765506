import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import useStyles from "./my-leave-state.style";
import exclamationIcon from "@iconify/icons-mdi/exclamation";
import { InlineIcon } from "@iconify/react";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import checkIcon from "@iconify/icons-mdi/check";
import checkBoldIcon from "@iconify/icons-mdi/check-bold";
import closeIcon from "@iconify/icons-mdi/close";

export const LeaveState = ({ label, state, loading, variant }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getColor(state) {
    switch (state) {
      case "requested":
        return theme.palette.yellowBase.main;
      case "approved":
        return theme.palette.greenBase.main;
      case "rejected":
        return theme.palette.primary.main;
      case "recorded":
        return theme.palette.greenBase.main;
      default:
        return theme.palette.white.main;
    }
  }

  const iconMap = {
    requested: exclamationIcon,
    rejected: closeIcon,
    recorded: checkBoldIcon,
    approved: checkIcon,
  };

  const iconColor = getColor(state);
  const IconComponent = iconMap[state];
  const classes = useStyles({ borderColor: iconColor, isMobile });

  return (
    <div className={classes.root}>
      {isMobile && IconComponent && (
        <InlineIcon
          icon={IconComponent}
          className={classes.text}
          width="24px"
          style={{
            color: iconColor,
            cursor: "pointer",
          }}
        />
      )}

      {!isMobile && (

        <Typography
          className={loading ? classes.textLoading : classes.text}
          variant={variant}
        >
          {label}
        </Typography>
      )}
    </div>
  );
};

export default LeaveState;
