/**
 * Component to show current cafeteria data
 * in a card with a dougnut chart
 *
 * Component documentation is available in Storybook
 *
 * Pál Pintér <pinter.pal@orgware.hu>
 * Created at: 2021.06.10
 */
import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import MyCafeteriaSummaryChart from "my-cafeteria/components/my-cafeteria-summary-chart";
import MyCafeteriaPeriodLabel from "my-cafeteria/components/my-cafeteria-period-label";
import useStyles, { useErrorStyles } from "./my-cafeteria-summary-card.style";
import parseJdDate from "oc/utils/parse-jd-date";

/** Define the default height of the card */
const DIALOG_HEIGHT = "421px";

/** Error component is shown when data fecthing failed with an error state */
export const MyCafeteriaSummaryCardErrorComponent = ({ error }) => {
  const classes = useErrorStyles({ height: DIALOG_HEIGHT });
  return (
    <Card
      id="my-cafeteria-summary-card"
      variant="outlined"
      classes={{ root: classes.root }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        color="#cc0033"
      >
        <Box>{error.toString()}</Box>
      </Box>
    </Card>
  );
};

const MyCafeteriaSummaryCard = ({
  contractCode,
  width,
  data,
  loading,
  error,
  isMobile
}) => {

  /** Apply styles */
  const classes = useStyles({ height: DIALOG_HEIGHT, width: width, isMobile });

  /** Define error handling and error component */
  if (error) return <MyCafeteriaSummaryCardErrorComponent error={error} />;

  return (
    <Card
      id="my-cafeteria-summary-card"
      variant="outlined"
      classes={{ root: classes.root }}
    >
      <Box
        id="my-cafeteria-summary-card-header"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <MyCafeteriaPeriodLabel
          loading={loading}
          startDate={parseJdDate(data?.startDate)}
          endDate={parseJdDate(data?.endDate)}
        />
      </Box>
      <Box id="my-cafeteria-summary-card-divider" className={classes.divider} />
      <MyCafeteriaSummaryChart {...data} loading={loading} isMobile={isMobile} />
    </Card>
  );
};

MyCafeteriaSummaryCard.propTypes = {
  /** The contract code of the current user */
  contractCode: PropTypes.string.isRequired,
};

MyCafeteriaSummaryCard.defaultProps = {
  width: "auto",
};

export default MyCafeteriaSummaryCard;
