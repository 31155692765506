import React, { useEffect } from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { selectedTabState } from "states";
import TabPanel from "oc/components/oc-tab-panel";
import LeaveDashboard from "./components/leave-dashboard/leave-dashboard";
import useCurrentUserState from "hooks/use-current-user-state";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyLeaveExaminerDashboardPage() {
  const { t } = useTranslation();
  let { contractCode } = useCurrentUserState();
  const isMobile = useIsMobile();

  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const isExaminer = rolesData?.find(
    (item) => item.product === "MyLeave" && item.role === "examiner"
  )
    ? true
    : false;

  const isViewer = rolesData?.find(
    (item) => item.product === "MyLeave" && item.role === "viewer"
  )
    ? true
    : false;

  useEffect(() => {
    if (isExaminer) {
      setSelectedTab(0);
    } else if (isViewer) {
      setSelectedTab(1);
    } else {
      setSelectedTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExaminer, isViewer]);

  return (
    <OcPageTransition>
      <Box>
        <Box padding={isMobile ? "16px 0px 0px 0px" : "16px 16px 0px 16px"}>
          <Box bgcolor="#E3E5E5">
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChangeTab}
              aria-label="tabs example"
            >
              {isExaminer ? (
                <Tab label={t("EXAMINERS_CONTROL_PANEL")} />
              ) : (
                <Tab style={{ display: "none" }} />
              )}
              {isViewer && <Tab label={t("VIEWERS_CONTROL_PANEL")} />}
            </Tabs>
          </Box>
          {isExaminer && (
            <TabPanel value={selectedTab} index={0}>
              <LeaveDashboard role="examiner" />
            </TabPanel>
          )}
          {isViewer && (
            <TabPanel value={selectedTab} index={1}>
              <LeaveDashboard role="viewer" />
            </TabPanel>
          )}
        </Box>
      </Box>
    </OcPageTransition>
  );
}
