import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import { archivedBenefits, archivedBenefitsIsMobile } from "my-cafeteria/configs/my-cafeteria-table-defs";
import OcDataTable from "oc/components/oc-data-table";
import { InlineIcon } from "@iconify/react";
import chevronLeft from "@iconify/icons-mdi/chevron-left";
import chevronRight from "@iconify/icons-mdi/chevron-right";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import { GET_ARCHIVED_CAFETERIA_STATEMENT_ITEMS } from "graphql/queries";
import { CAFETERIA_SUBSCRIPTION } from "graphql/subcriptions";
import _ from "lodash";
import moment from "moment";
import parseJdDate from "oc/utils/parse-jd-date";
import { useRecoilState } from "recoil";
import {
  newCafeteriaStatementItemState,
  showNewCafeteriaStatementItemFormState,
} from "my-cafeteria/states";
import useCafeteriaMenu from "my-cafeteria/hooks/use-cafeteria-menu";
import useCurrentCafeteriaStatement from "my-cafeteria/hooks/use-current-cafeteria-statement";
import usePolling from "hooks/use-polling";
import useCafeteriaPeriods from "my-cafeteria/hooks/use-cafeteria-periods";

const useArchivedStatementItems = ({ contractCode }) => {
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useQuery(
    GET_ARCHIVED_CAFETERIA_STATEMENT_ITEMS,
    {
      skip: !contractCode,
      fetchPolicy: "cache-and-network",
      pollInterval: usePolling(1000 * 60),
      variables: { contractCode },
    }
  );

  const { error: subscriptionError } = useSubscription(CAFETERIA_SUBSCRIPTION, {
    onData: () => {
      refetch();
    },
  });

  let parsedData = [];
  parsedData = data?.getArchivedCafeteriaItems?.map((item, index) => {
    let newItem = _.cloneDeep(item);
    let isPlan = item.plan ? t("PLAN") : t("FACT");
    newItem.index = index;
    newItem.periodStartDate = item.periodStartDate;
    newItem.originalStartDate = item.startDate;
    newItem.startDate = moment(parseJdDate(newItem.startDate)).format("MM");
    newItem.endDate = moment(parseJdDate(newItem.endDate)).format("MM");
    newItem.originalEndDate = item.endDate;
    newItem.months = `${newItem.startDate} - ${newItem.endDate}`;
    let numberOfMonths = +newItem.endDate - +newItem.startDate + 1;
    newItem.numberOfMonths = numberOfMonths;
    newItem.valueOfBenefit = item.cost;
    newItem.cost = item.cost - item.amount * numberOfMonths;
    newItem.benefit = item?.menuItem?.name;
    newItem.origin = `${item.origin} - ${isPlan}`;
    newItem.amount = numberOfMonths * item.amount;
    return newItem;
  });

  return {
    data: parsedData,
    loading,
    error: error || subscriptionError,
  };
};

const MyCafeteriaArchiveApplicationsCard = ({ contractCode, isMobile }) => {
  const { t } = useTranslation();

  const [newItem, setNewItem] = useRecoilState(newCafeteriaStatementItemState);
  /** Open and close edit forms state */
  const [showNewItemForm, setShowNewItemForm] = useRecoilState(
    showNewCafeteriaStatementItemFormState
  );

  const { data: menuData } = useCafeteriaMenu({ contractCode });

  const { data: cafeteriaPeriods } = useCafeteriaPeriods();

  const { data, error, loading } = useArchivedStatementItems({
    contractCode,
  });

  const { data: currentStatement } = useCurrentCafeteriaStatement({
    contractCode,
  });

  const disableCopyButton = currentStatement?.state === "closed";

  const archivedPeriods = cafeteriaPeriods?.filter(
    (period) => period.periodStartDate !== currentStatement?.startDate
  );

  const currentItems = data?.filter((item) => {
    if (
      item?.originalStartDate >= currentStatement?.startDate &&
      item.originalEndDate <= currentStatement?.endDate
    )
      return true;
    return false;
  });

  const archivedItems = data?.filter((item) => {
    if (
      item?.originalStartDate >= currentStatement?.startDate &&
      item.originalEndDate <= currentStatement?.endDate
    )
      return false;
    return true;
  });

  const extendedArchivedItems = archivedItems?.map((item) => {
    let newItem = _.cloneDeep(item);
    const isExist = currentItems.find(
      (currentItem) => currentItem?.menuItem?.code === item?.menuItem?.code
    );
    newItem.canCopy = isExist && !isExist.menuItem.isReusable ? false : true;
    return newItem;
  });

  const [selectedRowId, setSelectedRowId] = useState(null);

  function handleSelectRow({ item }) {
    let copyItem = { ...newItem };
    let selectedMenuItem = menuData.find(
      (menuItem) => menuItem?.code === item.menuItem?.code
    );
    let isExistMonth = selectedMenuItem?.availableMonths.includes(
      item.startDate
    );
    copyItem.amount = item.amount;
    copyItem.benefitType = item?.menuItem?.code;
    copyItem.startDate = isExistMonth ? item.startDate : "";
    copyItem.endDate = isExistMonth ? item.endDate : "";
    copyItem.menuItemCode = item?.menuItem?.code;
    copyItem.type = item?.type;
    copyItem.isPeriodical = selectedMenuItem?.isPeriodical;
    copyItem.isPlanable = selectedMenuItem?.planable;
    copyItem.plan = item.plan;
    copyItem.isReusable = selectedMenuItem?.isReusable;
    setNewItem(copyItem);
    setShowNewItemForm(true);
    window.scrollTo({
      top: 436,
      left: 0,
      behavior: "smooth",
    });
  }

  const onSelectRow = (item) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === item?._id ? null : item?._id);
    } else {
      handleSelectRow(item);
    }
  };

  const [selectedPeriodIndex, setSelectedPeriodIndex] = React.useState(0);
  const selectedPeriod = archivedPeriods?.[selectedPeriodIndex];

  if (extendedArchivedItems?.length === 0) return null;

  const handleCanCopy = (item) => {
    if (disableCopyButton) return false;
    if (showNewItemForm) return false;

    const menuCode = item?.menuItem?.code;
    const isInMenu = menuData?.find((menuItem) => menuItem.code === menuCode);
    if (!isInMenu) return false;

    return true;
  };

  const periodStartDate = moment(
    parseJdDate(selectedPeriod?.periodStartDate)
  ).format("YYYY.MM.DD.");
  const periodEndDate = moment(selectedPeriod?.periodEndDate).format(
    "YYYY.MM.DD."
  );

  const isFirstElement = selectedPeriodIndex === archivedPeriods?.length - 1;
  const isLastElement = selectedPeriodIndex === 0;

  const handleNextPeriod = () => {
    if (isLastElement) return;
    setSelectedPeriodIndex(selectedPeriodIndex - 1);
  };

  const handlePreviousPeriod = () => {
    if (isFirstElement) return;
    setSelectedPeriodIndex(selectedPeriodIndex + 1);
  };

  const filteredByPeriod = extendedArchivedItems?.filter((item) => {
    if (
      item?.originalStartDate >= selectedPeriod?.periodStartDate &&
      item?.originalEndDate <= selectedPeriod?.periodEndDate
    )
      return true;
    return false;
  });

  const list = {
    rows: filteredByPeriod,
  };

  const tableDef = isMobile
    ? archivedBenefitsIsMobile
    : archivedBenefits;

  const filteredTableDef = {
    id: archivedBenefits?.id,
    columns: archivedBenefits?.columns?.filter(column =>
      !archivedBenefitsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <Box>
      <OcCard label={t("PREVIOUS_BENEFITS")} error={error ? true : false}>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            paddingBottom="32px"
            paddingTop="8px"
          >
            <InlineIcon
              id="my-cafeteria-archive-app-left"
              icon={chevronLeft}
              width="24px"
              color={isFirstElement ? "#C6CACC" : "#4d4d4d"}
              style={
                isFirstElement ? { cursor: "auto" } : { cursor: "pointer" }
              }
              onClick={handlePreviousPeriod}
            />
            <Box width="12px" />
            <Typography variant="h6" style={{ color: "#4D4D4D", textAlign: "center" }}>
              {periodStartDate} - {periodEndDate}
            </Typography>
            <Box width="12px" />
            <InlineIcon
              id="my-cafeteria-archive-app-right"
              icon={chevronRight}
              width="24px"
              color={isLastElement ? "#C6CACC" : "#4d4d4d"}
              style={isLastElement ? { cursor: "auto" } : { cursor: "pointer" }}
              onClick={handleNextPeriod}
            />
          </Box>
          <OcDataTable
            tableDef={tableDef}
            data={list}
            loading={loading}
            onSelectRow={onSelectRow}
            canCopy={handleCanCopy}
            filteredTableDef={filteredTableDef}
            handleMoreButton={handleSelectRow}
            selectedRowId={selectedRowId}
          />
        </Box>
      </OcCard>
    </Box>
  );
};

MyCafeteriaArchiveApplicationsCard.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaArchiveApplicationsCard.defaultProps = {
  example: "example",
};

export default MyCafeteriaArchiveApplicationsCard;
