import React from "react";
import useMyLeaveBadges from "hooks/use-my-leave-badges";
import Box from "@material-ui/core/Box";
import { InlineIcon } from "@iconify/react";
import bagIcon from "@iconify/icons-mdi/bag-personal";
import { useHistory } from "react-router-dom";
import { StyledBadge, useStyles } from "./my-leave-header-badge.style";
import { narrowMenuState } from "states";
import { useRecoilState } from "recoil";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyLeaveHeaderBadge() {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [narrowMenuOpened, setNarrowMenuOpened] = useRecoilState(narrowMenuState);

  const { data } = useMyLeaveBadges();
  if (!data) return null;

  function handleClick() {
    if (isMobile) {
      setNarrowMenuOpened(!narrowMenuOpened);
    }
    history.push("/my-leave/approve-requests");
  }

  let count = data?.allLeaveRequestsCount || 0;

  if (count === 0) return null;

  return (
    <Box onClick={handleClick} className={classes.root}>
      <StyledBadge
        id="my-leave-header-badge-count"
        badgeContent={count}
        max={99}
      >
        <InlineIcon
          id="my-leave-header-badge"
          icon={bagIcon}
          className={classes.icon}
        />
      </StyledBadge>
    </Box>
  );
}
