import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import useMaritalStatuses from "hooks/use-marital-statuses";

const OcFormEditorMaritalStatusSelector = ({
  name,
  value,
  label,
  onChange,
}) => {
  const { t } = useTranslation();
  const { data: maritalStatuses } = useMaritalStatuses();
  const getMaritalStatus = (code) =>
    maritalStatuses.find((item) => item.code === code);

  return (
    <Box paddingTop="36px">
      <FormControl
        id={"gender-form-control"}
        variant="outlined"
        style={{
          height: "56px",
          width: "100%",
        }}
      >
        <InputLabel htmlFor={"gender-select"}>{t(label)}</InputLabel>
        <Select
          id={name}
          name={name}
          value={maritalStatuses.some(item => item.code === value.code) ? value.code : ""}
          label={t(label)}
          defaultValue=""
          onChange={(e) => onChange(name, getMaritalStatus(e.target.value))}
        >
          {maritalStatuses?.map((item, code) => (
            <MenuItem key={`${item}-${code}`} id={`${code}`} value={item?.code}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default OcFormEditorMaritalStatusSelector;
