import React, { useRef } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import OcPageTransition from "oc/components/oc-page-transition";
import OcPrintHeader from "oc/components/oc-print-header/oc-print-header";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import MyCafeteriaSzepApplicationPrint from "my-cafeteria/components/my-cafeteria-szep-application-print";
import Card from "@material-ui/core/Card";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import useCurrentCafeteriaStatementItems from "my-cafeteria/hooks/use-current-cafeteria-statement-items";
import useCurrentUserState from "hooks/use-current-user-state";
import useEmployee from "hooks/use-myhr-employee";
import getFullname from "oc/utils/get-fullname";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MyCafeteriaInfoLine from "my-cafeteria/components/my-cafeteria-info-line";
import MyCafeteriaStatementTitle from "my-cafeteria/components/my-cafeteria-statement-title";
import MyCafeteriSubaccountsTable from "my-cafeteria/components/my-cafeteria-subaccounts-table/my-cafeteria-subaccounts-table";

const MyCafeteriaSzepApplicationPage = () => {
  const history = useHistory();
  const componentRef = useRef();
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  let thisYear = new Date().getFullYear().toString();

  function historyBack() {
    history.goBack();
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let { contractCode, employeeCode } = useCurrentUserState();

  const { data: employeeData } = useEmployee({ employeeId: employeeCode });
  const { data } = useCurrentCafeteriaStatementItems({
    contractCode,
  });

  const filteredData = data?.filter((item) => item?.menuItem?.isSZEPCardItem);
  const uniqFilteredData = _.uniqBy(filteredData, "benefit");

  let params = {
    applicationDate: new Date().toLocaleDateString(),
    employeeName: getFullname(employeeData),
    idNumber: employeeData?.taxNumber,
    contractCode: contractCode,
    currentYear: new Date().getFullYear(),
    data: uniqFilteredData || [],
  };

  const hasPreviousBenefit = params?.data?.length > 0;

  const currentYear = params?.currentYear;

  return (
    <OcPageTransition>
      <Box padding={isMobile ? "16px 0px" : "16px"}>
        <OcPrintHeader
          title="SZÉP kártya nyilatkozat"
          onBack={historyBack}
          onPrint={handlePrint}
          isMobile={isMobile}
        />
        <Box height="16px" />
        <Card
          id="my-cafeteria-summary-card"
          variant="outlined"
          style={{ borderRadius: "4px", border: "1px solid #E3E5E5" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            padding="16px"
          >
            <Box id="upper-component" paddingBottom="48px">
              <MyCafeteriaStatementTitle
                label={t("SZEPSTATEMENT", { currentYear })}
              />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                paddingBottom="16px"
              >
                <Box id="data-block" width={isMobile ? "100%" : "auto"}>
                  <MyCafeteriaInfoLine
                    label={t("CALENDAR_MESSAGES_DATE")}
                    value={params?.applicationDate}
                    isMobile={isMobile}
                  />
                  <MyCafeteriaInfoLine
                    label={t("STATEMENT_EMPLOYEE_NAME")}
                    value={params?.employeeName}
                    isMobile={isMobile}
                  />
                  <MyCafeteriaInfoLine
                    label={t("STATEMENT_TAX_ID_SIGN_ID")}
                    value={params?.idNumber}
                    isMobile={isMobile}
                  />
                  <MyCafeteriaInfoLine
                    label={t("STATEMENT_CONTRACT_CODE")}
                    value={contractCode}
                    isMobile={isMobile}
                  />
                </Box>
              </Box>
              <Box padding={isMobile ? "0px" : "0px 56px"}>
                {hasPreviousBenefit ? (
                  <Box>
                    <Box paddingBottom="36px">
                      <Typography varian="body1">
                        {t("IHAVEBENEFIT", { thisYear })}
                      </Typography>
                    </Box>
                    <Box
                      width={isMobile ? "100%" : "544px"}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box display="flex" flexDirection="row">
                        <Box
                          flexGrow={1}
                          borderBottom="1px solid #5E6366"
                          paddingBottom="10px"
                        >
                          <Typography variant="h6">{t("SUBACCOUNTS")}</Typography>
                        </Box>
                        <Box
                          width="85px"
                          textAlign="right"
                          borderBottom="1px solid #5E6366"
                          paddingBottom="10px"
                        >
                          <Typography variant="h6">{t("AMOUNT")}</Typography>
                        </Box>
                      </Box>
                      {filteredData?.map((item) => {
                        return (
                          <MyCafeteriSubaccountsTable
                            key={item?._id}
                            label={item?.menuItem?.name}
                            value={item?.szepCardApplicationAmount}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Typography variant="body1">
                      {t("IHAVENOBENEFIT", { thisYear })}
                    </Typography>
                  </Box>
                )}
                <Box height="36px" />
                <Typography variant="body1">{t("SZEPSTATOFRES")}</Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
      <Box style={{ display: 'none' }}>
        <MyCafeteriaSzepApplicationPrint
          ref={componentRef}
          applicationDate={params?.applicationDate}
          employeeName={params?.employeeName}
          idNumber={params?.idNumber}
          contractCode={params?.contractCode}
          currentYear={params?.currentYear}
          hasPreviousBenefit={hasPreviousBenefit}
          thisYear={thisYear}
          data={filteredData}
        />
      </Box>
    </OcPageTransition>
  );
};

MyCafeteriaSzepApplicationPage.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaSzepApplicationPage.defaultProps = {
  example: "example",
};

export default MyCafeteriaSzepApplicationPage;
