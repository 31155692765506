import React, { useState } from "react";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import useOcSnackbar from "hooks/use-oc-snackbar";
import OcSettingSwitch from "oc/components/oc-setting-switch/oc-setting-switch";

export default function MyHrAdminSettingsTaxCreditApplication({
  saveTaxApplicationsEnabled,
  isMobile
}) {
  const { t } = useTranslation();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [enabledTaxSave, setEnabledTaxSave] = useState(true);
  const [prevEnabledTaxSave, setPrevEnabledTaxSave] = useState(enabledTaxSave);

  const { setOpenMessage } = useOcSnackbar();
  const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY, {
    onError({ ...err }) {
      alert("error" + err.toString());
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
    onCompleted(data) {
      if (data?.updateProperty.key === "taxApplicationSaveEnabled") {
        const parsedValue = data?.updateProperty.value === "true";
        setEnabledTaxSave(parsedValue);
        setPrevEnabledTaxSave(parsedValue);
      }
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
  });

  const onCompleted = (property) => {
    if (property?.key === "taxApplicationSaveEnabled") {
      const parsedValue = property?.value === "true";
      setEnabledTaxSave(parsedValue);
      setPrevEnabledTaxSave(parsedValue);
    }
  };

  useProperty({
    resource: "system",
    key: "taxApplicationSaveEnabled",
    onCompleted,
    fetchPolicy: "network-only",
  });

  const handleChangeEnabled = (event) => {
    const newEnabled = event.target.checked;
    setEnabledTaxSave(newEnabled);
  };

  const handleSaveEnabled = () => {
    updateProperty({
      variables: {
        resource: "system",
        key: "taxApplicationSaveEnabled",
        value: enabledTaxSave.toString(),
      },
    });
    setPrevEnabledTaxSave(enabledTaxSave);
    setShowConfirmDialog(false);
  };

  const handleCancel = () => {
    setEnabledTaxSave(prevEnabledTaxSave);
  };

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={() => {
          handleSaveEnabled();
        }}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("TAX_CREDIT_APPLICATIONS")} loading={loading}>
        <OcSettingSwitch
          id="enabledTaxSave"
          label={t("SAVE")}
          info={t("TAX_CREDIT_APPLICATIONS_SETTING_INFO")}
          value={enabledTaxSave}
          onChange={handleChangeEnabled}
          isMobile={isMobile}
          disabled={!saveTaxApplicationsEnabled}
        />
        <OcCardButtonBar
          handleRightButton={handleCancel}
          rightButtonText={t("CANCEL")}
          handlePrimaryButton={() => setShowConfirmDialog(true)}
          primaryButtonText={t("SAVE")}
          primaryButtonDisabled={loading || enabledTaxSave === prevEnabledTaxSave}
        />
      </OcCard>
    </>
  );
}
