import React, { createRef, useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import useEmployee from "hooks/use-myhr-employee";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import StickyPersonalDataHeader from "my-hr/components/my-hr-sticky-personal-data-header";
import UserContractsCard from "my-hr/components/my-hr-user-contract-card";
import useClientState from "hooks/use-oc-client-state";
import MyHrUserProfile from "my-hr/components/my-hr-user-profile-form";
import EditRoleForm from "my-hr/components/my-hr-edit-role-form";
import { useRecoilState } from "recoil";
import { scrollTriggerState } from "states";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyHrAdminUserPage() {
  let { employee } = useParams();
  const isMobile = useIsMobile();

  const { data: employeeData } = useEmployee({ employeeId: employee });
  const { data: contractsData } = useContractsByEmployee({ employee });

  let selectedContract = contractsData && contractsData[0];

  const pageRef = createRef();

  const [scrollTrigger, setScrollTrigger] = useRecoilState(scrollTriggerState);

  function handleScroll() {
    let scrollTriggerPosition = 100;
    let scrollTop = document?.scrollingElement?.scrollTop;
    if (scrollTop >= scrollTriggerPosition) {
      setScrollTrigger("show");
    } else if (scrollTop < scrollTriggerPosition) {
      setScrollTrigger("hide");
    }
  }

  const [pageWidth, setPageWidth] = useClientState("pageWidth", 0);

  useLayoutEffect(() => {
    let width = pageRef?.current?.clientWidth;
    if (width && width !== pageWidth) {
      setPageWidth(width);
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, []);

  return (
    <OcPageTransition>
      <Box ref={pageRef}>
        <StickyPersonalDataHeader
          pageWidth={pageWidth}
          employee={employeeData}
          contract={selectedContract}
          trigger={scrollTrigger === "show" ? true : false}
        />
        <Box id="personal-data-page" display="flex" flexDirection="column">
          <UserContractsCard
            employee={employeeData}
            contract={selectedContract}
          />
          <Box padding={isMobile ? "16px 0px" : "16px"}>
            <MyHrUserProfile />
          </Box>
          {contractsData?.map((contract) => {
            return (
              <EditRoleForm
                key={`${contract?.code}-edit-form`}
                contract={contract}
                isMobile={isMobile}
              />
            );
          })}
        </Box>
      </Box>
    </OcPageTransition>
  );
}
