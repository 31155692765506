import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import useClientState from "hooks/use-oc-client-state";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import styled from "styled-components";
import Select from "@material-ui/core/Select";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_MODULE_ROLES } from "graphql/queries";
import { REMOVE_MASS_ROLE } from "graphql/mutations";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import getModuleIcon from "oc/utils/get-modul-icon";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MyHrMassRoleDialog from "my-hr/components/my-hr-mass-role-dialog";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import usePolling from "hooks/use-polling";

const StyledSelect = styled(Select)`
  .MuiOutlinedInput-input {
    padding: 16px 14px;
    background-color white
  }
`;

export default function MyHrRemoveMassRole({
  roleFilters,
  roleFiltering,
  isMobile
}) {
  const { t } = useTranslation();

  const [selectedProduct, setSelectedProduct] = useClientState(
    "selectedModule",
    "MyHR"
  );

  const [selectedRole, setSelectedRole] = useClientState("selectedRole", "");
  const [result, setResult] = useClientState("RemoveMassRoleResult", "");
  const [showRoleFiltering, setShowRoleFiltering] = React.useState(false);

  const { data: productsData } = useQuery(GET_MODULE_ROLES, {
    fetchPolicy: "cache-and-network",
    pollInterval: usePolling(1000 * 60),
  });

  let selectedModule = productsData?.moduleRoles?.find(
    (item) => item.module === selectedProduct
  );

  let availableRoles = selectedModule?.roles || [];

  const [removeMassRole] = useMutation(REMOVE_MASS_ROLE, {
    onCompleted: (data) => {
      setResult(data.removeMassRole);
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleChange = (event) => {
    setSelectedProduct(event.target.value);
  };

  function handleCancel() {
    setSelectedProduct("MyHR");
    setSelectedRole("");
    setResult("");
  }

  const [showDialog, setShowDialog] = React.useState(false);

  function handleShowDialog() {
    setShowDialog(!showDialog);
  }

  function handleFinish() {
    setShowDialog(false);
    handleCancel();
  }

  function handleCloseImportDialog() {
    setShowDialog(false);
  }

  function handleRemoveRole() {
    let params = {
      roleFilters,
      roleFiltering,
      module: selectedModule?.module,
      role: selectedRole,
    };
    setShowDialog(true);
    setShowRoleFiltering(false);
    removeMassRole({ variables: params });
  }

  const [showConfirmationDialog, setShowConfirmationDialog] =
    React.useState(false);

  function handleShowConfirmationDialog() {
    setShowConfirmationDialog(true);
  }

  function handleOKConfirmationDialog() {
    setShowConfirmationDialog(false);
    handleRemoveRole();
  }

  function handleCancelConfirmationDialog() {
    setShowConfirmationDialog(false);
  }

  function generateConfirmationText() {
    if (
      roleFilters.company.length === 0 &&
      roleFilters.orgUnit.length === 0 &&
      roleFilters.workingPlace.length === 0 &&
      roleFilters.jobClassGroup.length === 0 &&
      roleFilters.payOffice.length === 0 &&
      roleFilters.contract.length === 0
    )
      return "REMOVE_GROUP_ROLE_TO_ALL_DIALOG_TEXT";
    return "REMOVE_GROUP_ROLE_DIALOG_TEXT";
  }

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, []); // eslint-disable-line
  return (
    <Box>
      <OcConfirmationDialog
        showDialog={showConfirmationDialog}
        title={t("REMOVE_GROUP_ROLE_DIALOG_TITLE")}
        text={t(generateConfirmationText())}
        okButtonText={t("SAVE")}
        cancelButtonText={t("CANCEL")}
        onClose={handleOKConfirmationDialog}
        onCancel={handleCancelConfirmationDialog}
      />
      <MyHrMassRoleDialog
        onShowDialog={handleShowDialog}
        showDialog={showDialog}
        onFinish={handleFinish}
        onCloseDialog={handleCloseImportDialog}
        result={result}
        infoText={t("REMOVE_MASS_ROLE_INFO")}
        successText={t("REMOVE_ROLE_SUCCEEDED")}
        successInfo={t("ROLE_REMOVED")}
        toolbarLabel={t("REMOVE_MASS_ROLE")}
        errorText={t("ADD_ROLE_ERROR")}
        action="remove"
        isMobile={isMobile}
      />
      <Collapse in={!showRoleFiltering} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop="24px"
        >
          <Card
            variant="outlined"
            style={{
              width: isMobile ? "100%" : "696px",
              borderRadius: "4px",
              borderColor: "white",
              overflow: "visible",
              marginBottom: "16px",
              backgroundColor: "#F1F3F4",
            }}
          >
            <Box padding="16px" dispaly="flex" flexDirection="column">
              <Box
                fontSize="20px"
                letterSpacing="0.15px"
                padding="15px 0px"
                paddingBottom="30px"
              >
                {t("REMOVE_ROLE")}
              </Box>
              <Box display="flex" flexDirection={isMobile ? "column" : "row"} paddingBottom="8px">
                <Box width={isMobile ? "100%" : "50%"} paddingRight="8px">
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ backgroundColor: "white" }}
                  >
                    <InputLabel id="demo-simple-dsds-outlined-label">
                      {t("MODULE")}
                    </InputLabel>
                    <StyledSelect
                      labelId="demo-s-label"
                      id="remove-mass-role-input"
                      value={selectedProduct}
                      defaultValue=""
                      onChange={handleChange}
                      label={t("MODULE")}
                    >
                      {productsData?.moduleRoles?.map((module) => {
                        return (
                          <MenuItem key={module.module} value={module.module}>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                            >
                              {getModuleIcon(module.module)}
                              <Typography
                                variant="inherit"
                                style={{
                                  marginLeft: "22px",
                                }}
                              >
                                {module.module}
                              </Typography>
                            </Box>
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                </Box>
                {isMobile && <Box height="16px" />}
                <Box width={isMobile ? "100%" : "50%"} paddingLeft={isMobile ? "0px" : "8px"}>
                  <ButtonGroup
                    orientation="vertical"
                    aria-label="vertical outlined primary button group"
                    fullWidth
                    style={{
                      color: "#E3E5E5",
                    }}
                    variant="contained"
                    disableElevation
                  >
                    {availableRoles?.map((role) => {
                      return (
                        <Button
                          id={role}
                          key={role}
                          onClick={() => setSelectedRole(role)}
                          disableElevation
                          variant="contained"
                          disabled={
                            role === "admin" ||
                            (selectedModule?.module === "MyHR" &&
                              role === "user")
                          }
                          style={
                            role === selectedRole
                              ? {
                                backgroundColor: "#cc0033",
                                color: "white",
                              }
                              : {
                                backgroundColor: "white",
                              }
                          }
                        >
                          {t(`ROLE_${role.toUpperCase()}`)}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection={isMobile ? "column-reverse" : "row"}
                justifyContent="flex-end"
                padding={isMobile ? "0px" : "10px 0px 0px 0px"}
              >
                <Button
                  id="my-hr-remove-user-role-button-cancel"
                  onClick={handleCancel}
                >
                  {t("CANCEL")}
                </Button>
                {isMobile && <Box height="16px" />}
                <Button
                  id="my-hr-remove-user-role-button"
                  variant="contained"
                  color="primary"
                  disableElevation
                  style={{ marginLeft: isMobile ? "0px" : "16px" }}
                  disabled={!selectedRole}
                  onClick={() => handleShowConfirmationDialog(true)}
                >
                  {t("REMOVE")}
                </Button>
                {isMobile && <Box height="16px" />}
              </Box>
            </Box>
          </Card>
        </Box>
      </Collapse>
    </Box>
  );
}
