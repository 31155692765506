import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/styles";

const MyTimeWorkTimeBalanceFrameBox = ({ data, loading, minHeight }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const boxSeparator = () => (
    <Box
      style={{
        backgroundColor: theme.palette.white.main,
        width: "auto",
        height: "1px",
      }}
    />
  );

  const formatValue = (value) => {
    if (value % 1 !== 0) {
      const formattedValue = value?.toFixed(3);
      return formattedValue?.endsWith('00') ? parseFloat(formattedValue)?.toFixed(1) : formattedValue;
    } else {
      return value?.toString();
    }
  };

  return (
    <Box
      minHeight={minHeight}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.blueBase.main,
        borderRadius: "8px",
        color: theme.palette.white.main,

      }}
    >
      <Box padding="12px 16px">
        <Typography variant="h6">
          {t("AVAILABLE_WORKING_BALANCE")}
        </Typography>
        <Box height="8px" />
        <Typography variant="body1">
          {t("OPENING_BALANCE")}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <Typography
            variant="body1"
            style={{
              color: theme.palette.white.main,
              fontWeight: 700,
              textTransform: "lowercase",
            }}
          >
            {loading ? t("LOADING") : `${formatValue(data?.startBalance)} ${t("HOUR")}`}
          </Typography>
        </Box>
        <Box height="8px" />
        {boxSeparator()}
        <Box height="8px" />
        <Typography variant="body1">
          {t("CURRENT_BALANCE")}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <Typography
            variant="body1"
            style={{
              color: theme.palette.white.main,
              fontWeight: 700,
              textTransform: "lowercase",
            }}
          >
            {loading ? t("LOADING") : `${formatValue(data?.actualBalance)} ${t("HOUR")}`}
          </Typography>
        </Box>
        <Box height="8px" />
        {boxSeparator()}
        <Box height="8px" />
        <Typography variant="body1">
          {t("CLOSING_BALANCE")}
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <Typography
            variant="h4"
            style={{
              color: theme.palette.white.main,
              fontWeight: 500,
              textTransform: "lowercase",
            }}
          >
            {loading ? t("LOADING") : `${formatValue(data?.endBalance)} ${t("HOUR")}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MyTimeWorkTimeBalanceFrameBox;
