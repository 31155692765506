import React from "react";
import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import ContractCardItem from "my-hr/components/my-hr-contract-card-item";
import cityIcon from "@iconify/icons-mdi/city";
import siteMapIcon from "@iconify/icons-mdi/sitemap";
import creditCardIcon from "@iconify/icons-mdi/credit-card-marker-outline";
import accountPlusIcon from "@iconify/icons-mdi/account-plus";
import accountClockIcon from "@iconify/icons-mdi/account-clock";
import homeAccountIcon from "@iconify/icons-mdi/home-account";
import MyHrPayrollCollapseHeader from "my-hr/components/my-hr-payroll-collapse-header";
import useClientState from "hooks/use-oc-client-state";
import PaycardWrapper from "my-hr/components/my-hr-paycard-wrapper";
import usePaycard from "hooks/use-myhr-paycard";
import { useTranslation } from "react-i18next";
import MyHrPayrollMergedPayrollCollapseHeader from "my-hr/components/my-hr-payroll-merged-payroll-collapse-header";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyHrPayrollCollapse({
  label,
  ContractID,
  index,
  showStartOfContract,
  company,
  workingPlace,
  orgUnit,
  payOffice,
  startOfContract,
  employmentType,
  month,
  year,
  contract,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [expanded, setExpanded] = useClientState(
    `collapseExpanded${index}`,
    true
  );
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [mergedExpanded, setMergedExpanded] = useClientState(
    `collapseMergedExpanded${index}`,
    false
  );
  const handleMergedExpandClick = () => {
    setMergedExpanded(!mergedExpanded);
  };

  const { data } = usePaycard({
    contract,
    year,
    month,
  });

  let source = data?.paycards || [];

  let nonePaycard =
    source[0]?.includes("Lista") || source[0]?.includes("esed&");

  let sourceMerge = data?.paycards.slice(0, 1) || [];

  let sourceWithoutMerge = data?.paycards.slice(1) || [];

  return (
    <Box>
      <MyHrPayrollCollapseHeader
        key={`collapseExpanded-${index}`}
        index={index}
        label={label}
        ContractID={ContractID}
        handleExpandClick={handleExpandClick}
        expanded={expanded}
      />
      <Collapse
        in={expanded}
        style={{
          backgroundColor: theme.palette.white.main,
          padding: "0px 16px",
        }}
      >
        <Box
          style={{
            borderTop: `2px solid ${theme.palette.gray40.main}`,
          }}
        />
        <Box height="24px" />
        <Box
          display="flex"
          flexGrow="1"
          width={isMobile ? "auto" : "696px"}
          margin="auto"
          flexDirection={isMobile ? "column" : "unset"}
        >
          <Box display="flex" flexGrow={1}>
            <Box paddingTop="6px">
              <ContractCardItem icon={cityIcon} label={company} />
              <Box height="10px" />
              <ContractCardItem icon={homeAccountIcon} label={workingPlace} />
              <Box height="10px" />
              <ContractCardItem icon={siteMapIcon} label={orgUnit} />
            </Box>
          </Box>
          <Box paddingTop="6px">
            <ContractCardItem icon={creditCardIcon} label={payOffice} />
            <Box height="10px" />

            {showStartOfContract && (
              <ContractCardItem
                icon={accountPlusIcon}
                label={new Date(startOfContract).toLocaleDateString()}
              />
            )}
            <Box height="10px" />
            <ContractCardItem icon={accountClockIcon} label={employmentType} />
          </Box>
        </Box>
        <Box height="56px" />
        <Card
          style={{
            borderRadius: "8px",
            padding: isMobile ? "unset" : "13px 100px",
            margin: "auto",
            //width: "696px",
            display: "flex",
            flexDirection: "column",
            alignItems: isMobile ? "unset" : "center",
            overflowX: isMobile ? "scroll" : "unset",
            overflowY: isMobile ? "hidden" : "unset",
            whiteSpace: isMobile ? "nowrap" : "unset"

          }}
          elevation={0}
        >
          {nonePaycard === true ? (
            <Box>
              <span>{t("NO_PAYROLL_DATA")}</span>
            </Box>
          ) : (
            <Box width={isMobile ? "auto" : "921px"}>
              <MyHrPayrollMergedPayrollCollapseHeader
                key={`collapseMergedExpanded-${index}`}
                index={index}
                label={t("MERGED")}
                handleExpandClick={handleMergedExpandClick}
                expanded={mergedExpanded}
              />
              <Collapse in={mergedExpanded}>
                <Box
                  style={{
                    borderTop: `2px solid ${theme.palette.gray40.main}`,
                  }}
                />
                <PaycardWrapper
                  contract={ContractID}
                  data={data}
                  source={sourceMerge}
                  collapse={true}
                  slot={1}
                />
              </Collapse>
              <Box height="36px" />
              <PaycardWrapper
                contract={ContractID}
                data={data}
                source={sourceWithoutMerge}
                collapse={false}
                slot={2}
              />
            </Box>
          )}
        </Card>
        <Box height="32px" />
      </Collapse>
      <Box height="16px" />
    </Box>
  );
}
