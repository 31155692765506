import React from "react";
import { Box } from "@material-ui/core";
import MyTimePageHeader from "my-time/components/my-time-page-header";
import OcPageTransition from "oc/components/oc-page-transition";
import useActualDate from "my-time/hooks/use-actual-date";
import UserContractsCard from "my-hr/components/my-hr-user-contract-card";
import useContract from "hooks/use-myhr-contract";
import useEmployee from "hooks/use-myhr-employee";
import MyTimeShowRequestCardExaminer from "my-time/components/my-time-show-request-card-examiner/my-time-show-request-card-examiner";
import { useParams } from "react-router-dom";
import useMyTimeRequest from "my-time/hooks/use-my-time-request";
import useMyTimeMessagesList from "my-time/hooks/use-my-time-messages-list";
import MyTimeAdmissionCardByAdmin from "my-time/components/my-time-admission-card-by-admin";
import MyTimeRemainsFrame from "my-time/components/my-time-remains-frame";
import MyTimeWorkingTimeFrame from "my-time/components/my-time-working-time-frame";
import MyTimeOvertimeFrame from "my-time/components/my-time-overtime-frame";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import MyTimeWorkTimeBalanceFrameBox from "my-time/components/my-time-work-time-balance-frame-box";
import useMonthlyWorkTimeBalance from "my-time/hooks/use-monthly-work-time-balance";

const MyTimeEmployeeRequestPage = () => {
  let { contract, employee, id } = useParams();
  const { date, formattedDate, year, month, handleDateChange } =
    useActualDate();

  const isMobile = useIsMobile();

  const { data: contractData } = useContract({
    contract: contract,
  });

  const { data: employeeData } = useEmployee({
    employeeId: employee,
  });

  const { data, loading, error } = useMyTimeRequest({ _id: id });

  const {
    data: messagesData,
    loading: messagesLoading,
    error: messagesError,
  } = useMyTimeMessagesList({ request: id });

  const { data: monthlyWorkTimeBalanceData, loading: monthlyWorkTimeBalanceLoading } = useMonthlyWorkTimeBalance({
    contractCode: contract,
    year,
    month,
  });

  const minHeight = monthlyWorkTimeBalanceData?.startBalance !== -1 ? "285px" : "178px";

  return (
    <OcPageTransition>
      <Box padding={isMobile ? "16px 0px" : "16px"}>
        <MyTimePageHeader
          formattedDate={formattedDate}
          date={date}
          onDateChange={handleDateChange}
          examinerPage={true}
          isMobile={isMobile}
        />
        <Box height="16px" />
        <UserContractsCard employee={employeeData} contract={contractData} />
        <Box height="16px" />
        <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
          <Box width={isMobile ? "100%" : "584px"}>
            <MyTimeShowRequestCardExaminer
              data={data}
              messages={messagesData}
              loading={loading || messagesLoading}
              error={error || messagesError}
              employeeCode={employee}
              contractCode={contract}
              isMobile={isMobile}
            />
          </Box>
          {isMobile && <Box height="16px" />}
          <Box width="16px" />
          <Box display="flex" flexDirection="column" width={isMobile ? "100%" : "500px"}>
            <MyTimeWorkingTimeFrame
              contractCode={contract}
              year={year}
              month={month}
              minHeight={minHeight}
            />
            <Box height="16px" />
            <MyTimeRemainsFrame
              contractCode={contract}
              year={year}
              month={month}
              minHeight={minHeight}
            />
            <Box height="16px" />
            <MyTimeOvertimeFrame
              contractCode={contract}
              year={year}
              month={month}
              minHeight={minHeight}
            />
            <Box height="16px" />
            {monthlyWorkTimeBalanceData?.startBalance !== -1 &&
              <MyTimeWorkTimeBalanceFrameBox
                data={monthlyWorkTimeBalanceData}
                loading={monthlyWorkTimeBalanceLoading}
              />
            }
          </Box>
        </Box>
        <Box height="16px" />
        <MyTimeAdmissionCardByAdmin
          contractCode={contract}
          employeeCode={employee}
          year={year}
          month={month}
          isMobile={isMobile}
        />
      </Box>
    </OcPageTransition>
  );
};

export default MyTimeEmployeeRequestPage;
