import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/styles";
import { InlineIcon } from "@iconify/react";
import Typography from "@material-ui/core/Typography";
import RoleChip from "my-hr/components/my-hr-role-chip";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const MyHrUserProfilesRolesItemHeader = ({
  role,
  product,
  showCollapseButton,
  onExpand,
  showCollapse,
  isMobile,
  roleIcon
}) => {
  const theme = useTheme();
  return (
    <Box
      height={isMobile ? "auto" : "48px"}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding={isMobile ? "8px 24px" : "0px 24px"}
    >
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"}>
        {!isMobile &&
          <InlineIcon
            icon={roleIcon}
            height="32px"
            style={{
              color: theme.palette.gray80.main,
              marginRight: "24px",
            }}
          />
        }
        <Typography variant="h5">{product}</Typography>
        {isMobile && <Box height="8px" />}
        <Box marginLeft={isMobile ? "0px" : "16px"}>
          <RoleChip role={role} />
        </Box>
      </Box>
      {showCollapseButton && (
        <IconButton
          id={product + role}
          onClick={onExpand}
          aria-expanded={showCollapse}
          aria-label="show more"
          style={
            showCollapse
              ? {
                padding: 0,
                transform: "rotate(180deg)",
              }
              : { padding: 0 }
          }
        >
          <ExpandMoreIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default MyHrUserProfilesRolesItemHeader;
