import React from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import Avatar from "oc/components/oc-avatar";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import ContractCardItem from "my-hr/components/my-hr-contract-card-item";
import cityIcon from "@iconify/icons-mdi/city";
import homeAccountIcon from "@iconify/icons-mdi/home-account";
import siteMapIcon from "@iconify/icons-mdi/sitemap";
import { Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import LeaveBaseCard from "my-leave/components/my-leave-leave-base-card";
import LeaveStudyCard from "my-leave/components/my-leave-leave-study-card";
import LeaveCovidplustenCard from "my-leave/components/my-leave-leave-covid-plus-ten-card";
import LeaveEszjtvCard from "my-leave/components/my-leave-leave-eszjtv-card";
import LeaveEmergencyCard from "my-leave/components/my-leave-leave-emergency-card";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyleaveExaminerDashboardCollapse({ row, role }) {
  let thisYear = new Date().getFullYear().toString();
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useIsMobile();

  const { data: contractsData } = useContractsByEmployee({
    employee: row?.employee,
  });

  let selectedContract = contractsData?.find(
    (item) => item.code === row?.contract
  );

  function handleMoreButton(o) {
    history.push(`/my-leave/user-requests/MyLeave/${role}/${row?.contract}`);
  }

  return (
    <Box padding="16px" display="flex" flexDirection={isMobile ? "column" : "row"}>
      <Box width={isMobile ? "100%" : "50%"} display="flex" flexDirection="row" justifyContent={isMobile ? "center" : "flex-start"}>
        <Avatar
          employee={row?.employee}
          style={{
            width: "100px",
            height: "100px",
          }}
        />
        {isMobile && <Box height="16px" />}
        <Box width="16px" />
        <Box style={{ color: theme.palette.darkBase.main }}>
          <Typography variant="h6" style={{ marginLeft: "10px" }}>
            {selectedContract?.jobClass?.name}
          </Typography>
          <Box height="4px" />
          <ContractCardItem
            icon={cityIcon}
            label={selectedContract?.company?.name}
          />
          <ContractCardItem
            icon={homeAccountIcon}
            label={selectedContract?.workingPlace?.name}
          />
          <ContractCardItem
            icon={siteMapIcon}
            label={selectedContract?.orgUnit?.name}
          />
          <Box height="32px" />
          {!isMobile &&
            <Button
              variant="contained"
              color="primary"
              id="more-button"
              onClick={handleMoreButton}
            >
              {t("MORE")}
            </Button>
          }
        </Box>
      </Box>
      {isMobile &&
        <Button
          variant="contained"
          color="primary"
          id="more-button"
          onClick={handleMoreButton}
        >
          {t("MORE")}
        </Button>
      }
      <Box width="16px" />
      <Box display="flex" flexDirection="column" width={isMobile ? "100%" : "50%"} height="100%">
        <LeaveBaseCard selectedYear={thisYear} contract={row?.contract} />
        <LeaveStudyCard selectedYear={thisYear} contract={row?.contract} />
        <LeaveCovidplustenCard
          selectedYear={thisYear}
          contract={row?.contract}
        />
        <LeaveEszjtvCard selectedYear={thisYear} contract={row?.contract} />
        <LeaveEmergencyCard selectedYear={thisYear} contract={row?.contract} />
      </Box>
      <Box width="16px" />
    </Box>
  );
}
