/**
 * Component to show a chart legend
 *
 * Component documentation is available in Storybook
 *
 * Pál Pintér <pinter.pal@orgware.hu>
 * Created at: 2021.06.10
 */
import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./oc-chart-legend.style";
import LoadingComponent from "@material-ui/lab/Skeleton";

const OcChartLegend = ({ loading, label, value, color, showDivider, isMobile }) => {
  const classes = useStyles({ loading, color, isMobile });
  return (
    <Box className={classes.legendContainer}>
      <span className={classes.bullet}></span>
      <div className={classes.textContainer}>
        <div>
          {!loading && (
            <Typography className={classes.valueTypography}>{value}</Typography>
          )}
          {loading && <LoadingComponent width={124} height={30} />}
        </div>
        <div>
          <Typography className={classes.labelTypography}>{label}</Typography>
        </div>
        {showDivider && (
          <div className={classes.dividerContainer}>
            <div className={classes.divider}></div>
          </div>
        )}
      </div>
    </Box>
  );
};

OcChartLegend.propTypes = {
  /** Show the loading state*/
  loading: PropTypes.bool,
  /** The label part of the component */
  label: PropTypes.string,
  /** The value part of the component */
  value: PropTypes.any,
  /** Color of the legend */
  color: PropTypes.string,
  /** Swicth on/off the showing divider */
  showDivider: PropTypes.bool,
};

OcChartLegend.defaultProps = {
  loading: false,
  label: "label",
  value: "value",
  color: "#cc0033",
  showDivider: true,
};

export default OcChartLegend;
