import React from "react";
import Typography from "@material-ui/core/Typography";
import OcFormSwitch from "oc/components/oc-form-switch/oc-form-switch";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";

const OcGeneralSwitchButtonSetting = ({
  label,
  infoText,
  propertyKey,
  disabled = false,
  useMyTimePropertyHook,
  isMobile

}) => {
  const { t } = useTranslation();

  const { state, loading, handleChange } = useMyTimePropertyHook({
    propertyKey,
  });

  return (
    <Box display="flex"
      flexDirection={isMobile ? "column" : "row"}
      alignItems={isMobile ? "flex-start" : "center"}
      paddingTop="16px"
      paddingBottom={isMobile ? "0px" : "24px"}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box width={isMobile ? "auto" : "200px"} textAlign="right" color="#4D4D4D">
          <Typography variant="caption">{t(label)}</Typography>
        </Box>
        <Box width="16px" />
        <OcFormSwitch
          id={propertyKey}
          checked={state.value}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
          disabled={disabled || loading}
        />
      </Box>
      <Box width="16px" />
      {isMobile && <Box height="16px" />}
      <Box width="300px">
        <Box
          fontSize="12px"
          letterSpacing="0.4px"
          color="#4D4D4D"
          width="300px"
        >
          {t(infoText)}
        </Box>
      </Box>
    </Box>
  );
};

export default OcGeneralSwitchButtonSetting;
