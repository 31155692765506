import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { InlineIcon } from "@iconify/react";
import plusIcon from "@iconify/icons-mdi/plus";
import deleteIcon from "@iconify/icons-mdi/delete";
import minusIcon from "@iconify/icons-mdi/minus";
import keyIcon from "@iconify/icons-mdi/key";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function UsersFilterBar({
  tableData,
  setTableData,
  onNewUser,
  loading,
  dataLength,
  onAddGroupRole,
  onInactivateGroup,
  onRemoveGroupRole,
  onSendGroupPasswords,
}) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  let disabled = loading && dataLength === 0 ? true : false;

  function handleFilterChange({ filterField, filterSelected }) {
    if (filterField && filterField !== tableData.filterField) {
      setTableData({ filterField });
    }
    if (
      filterSelected &&
      !_.isEqual(filterSelected, tableData.filterSelected)
    ) {
      setTableData({ filterSelected });
    }
  }

  return (
    <Box>
      <Box>
        <Box display="flex" flexDirection={isMobile ? "column-reverse" : "row"} justifyContent="space-between">
          <Box>
            <ButtonGroup
              orientation={isMobile ? "vertical" : "horizontal"}
              fullWidth={isMobile ? true : false}
            >
              <Button
                id="addGroupRole"
                name="addGroupRole"
                variant="outlined"
                startIcon={<InlineIcon icon={plusIcon} />}
                onClick={onAddGroupRole}
              >
                {t("ADD_GROUP_ROLE")}
              </Button>
              <Button
                id="RemoveGroupRole"
                name="RemoveGroupRole"
                variant="outlined"
                startIcon={<InlineIcon icon={minusIcon} />}
                onClick={onRemoveGroupRole}
              >
                {t("REMOVE_GROUP_ROLE")}
              </Button>
              <Button
                id="SendGroupPassword"
                name="SendGroupPassword"
                variant="outlined"
                startIcon={<InlineIcon icon={keyIcon} />}
                onClick={onSendGroupPasswords}
              >
                {t("PASSWORD")}
              </Button>
              <Button
                id="accountManagement"
                name="addGroupRole"
                variant="outlined"
                startIcon={<InlineIcon icon={deleteIcon} />}
                onClick={onInactivateGroup}
              >
                {t("INACTIVATE_GROUP")}
              </Button>
            </ButtonGroup>
          </Box>
          {isMobile && <Box height="16px" />}
          <Button
            id="showDialog"
            name="showDialog"
            variant="contained"
            color="primary"
            startIcon={<InlineIcon icon={plusIcon} />}
            disabled={disabled}
            onClick={onNewUser}
            disableElevation
          >
            {t("NEW_USER")}
          </Button>
        </Box>
      </Box>
      <Box
        id="users-filter-bar-container"
        display="flex"
        alignItems="center"
        height="80px"
        paddingTop="16px"
        paddingBottom="16px"
      >
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <ButtonGroup>
              <Button
                id="filter-bar-active"
                variant={
                  tableData?.filterSelected?.includes("active")
                    ? "contained"
                    : "outlined"
                }
                color={
                  tableData?.filterSelected?.includes("active")
                    ? "primary"
                    : "default"
                }
                disableElevation
                disabled={disabled}
                onClick={() =>
                  handleFilterChange({
                    filterField: "active",
                    filterSelected: ["active"],
                  })
                }
              >
                {t("ACTIVE")}
              </Button>
              <Button
                id="filter-bar-all"
                variant={
                  tableData?.filterSelected?.includes("all")
                    ? "contained"
                    : "outlined"
                }
                color={
                  tableData?.filterSelected?.includes("all")
                    ? "primary"
                    : "default"
                }
                disableElevation
                disabled={disabled}
                onClick={() =>
                  handleFilterChange({
                    filterField: "all",
                    filterSelected: ["all"],
                  })
                }
              >
                {t("ALL")}
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
