export const employees = {
  id: "create-employees-list-table",
  columns: [
    {
      id: "selected",
      label: "R",
      type: "radio",
      align: "center",
      width: "85px",
    },
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: true,
      sort: true,
    },
    {
      id: "contractCode",
      type: "text",
      label: "GET_CONTRACT",
      search: false,
      sort: false,
    },
    {
      id: "dateOfBirth",
      type: "text",
      label: "DATE_OF_BIRTH",
      sort: true,
    },
    {
      id: "mothersfullname",
      type: "text",
      label: "MOTHERS_NAME",
      sort: true,
    },
  ],
};

export const employeesIsMobile = {
  id: "create-employees-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: true,
      sort: true,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const users = {
  id: "users-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "email",
      type: "text",
      label: "USER",
      sort: true,
      search: "true",
    },
    {
      id: "lastLogin",
      type: "text",
      label: "LAST_LOGIN",
      sort: true,
    },
    {
      id: "active",
      type: "checkbox",
      label: "ACTIVE",
      align: "center",
      width: "85px",
    },
  ],
};

export const usersIsMobile = {
  id: "users-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const importErrors = {
  id: "import-errors-table",
  //minWidth: "800px",
  columns: [
    {
      id: "selected",
      type: "text",
      label: "",
      width: "0.5%",
    },
    {
      id: "employeeName",
      type: "text",
      label: "NAME",
      search: true,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: true,
    },
    {
      id: "employmentType",
      type: "text",
      label: "NAME",
      search: true,
    },
    {
      id: "employee",
      type: "text",
      label: "EMPLOYEE_CODE",
      search: true,
    },
    {
      id: "error",
      type: "importError",
      label: "ERROR",
    },
  ],
};

export const importErrorsDef = {
  id: "import-errors-table",
  //minWidth: "800px",
  columns: [
    {
      id: "selected",
      type: "text",
      label: "",
      width: "0.5%",
    },
    {
      id: "employeeName",
      type: "text",
      label: "NAME",
      search: true,
      width: "25%",
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: true,
      width: "12%",
    },
    {
      id: "employmentType",
      type: "text",
      label: "Contract.employmentType",
      search: true,
      width: "15%",
    },
    {
      id: "employee",
      type: "text",
      label: "EMPLOYEE_CODE",
      search: true,
      width: "15%",
    },
    {
      id: "error",
      type: "importSSHRError",
      label: "ERROR",
    },
  ],
};

export const importErrorsDefIsMobile = {
  id: "import-errors-table",
  select: "row",
  columns: [
    {
      id: "selected",
      type: "text",
      label: "",
      width: "0.5%",
    },
    {
      id: "employeeName",
      type: "text",
      label: "NAME",
      search: true,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: true,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const massRoleErrors = {
  id: "add-mass-roles-errors-table",
  select: "row",
  columns: [
    {
      id: "selected",
      type: "text",
      label: "",
      width: "0.5%",
    },
    {
      id: "employeeName",
      type: "text",
      label: "NAME",
      search: true,
      width: "25%",
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: true,
      width: "12%",
    },
    {
      id: "employmentType",
      type: "text",
      label: "Contract.employmentType",
      search: true,
      width: "15%",
    },
    {
      id: "employee",
      type: "text",
      label: "EMPLOYEE_CODE",
      search: true,
      width: "15%",
    },
    {
      id: "error",
      type: "importError",
      label: "NOTE",
    },
  ],
};

export const massRoleErrorsIsMobile = {
  id: "add-mass-roles-errors-table",
  select: "row",
  columns: [
    {
      id: "selected",
      type: "text",
      label: "",
      width: "0.5%",
    },
    {
      id: "employeeName",
      type: "text",
      label: "NAME",
      search: true,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: true,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const removeMassRoleErrors = {
  id: "remove-mass-roles-errors-table",
  //minWidth: "800px",
  columns: [
    {
      id: "selected",
      type: "text",
      label: "",
      width: "0.5%",
    },
    {
      id: "employeeName",
      type: "text",
      label: "NAME",
      search: true,
      width: "25%",
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: true,
      width: "12%",
    },
    {
      id: "module",
      type: "text",
      label: "MODULE",
      search: true,
      width: "15%",
    },
    {
      id: "role",
      type: "text",
      label: "ROLE",
      search: true,
      width: "15%",
    },
    {
      id: "error",
      type: "importError",
      label: "NOTE",
    },
  ],
};

export const removeMassRoleErrorsIsMobile = {
  id: "remove-mass-roles-errors-table",
  select: "row",
  columns: [
    {
      id: "selected",
      type: "text",
      label: "",
      width: "0.5%",
    },
    {
      id: "employeeName",
      type: "text",
      label: "NAME",
      search: true,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: true,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const allChangeRequests = {
  id: "all-change-requests-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "name",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
      align: "left",
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: true,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: true,
    },
    {
      id: "requestCount",
      type: "myHRBadges",
      label: "REQUEST",
      width: "120px",
    },
  ],
};

export const allChangeRequestsIsMobile = {
  id: "all-change-requests-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "52px",
    },
    {
      id: "name",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
      align: "left",
    },
    {
      id: "requestCount",
      type: "myHRBadges",
      label: "REQUEST",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const taxCreditApplicationRequests = {
  id: "tax-credit-application-requests-table",
  select: "row",
  columns: [
    {
      id: "type",
      type: "taxDeclarationType",
      label: "SUBMITTED_APPLICATIONS",
      align: "left",
    },
    {
      id: "state",
      type: "taxState",
      label: "STATE",
      align: "center",
      width: "120px",
    },
    {
      id: "createdAt",
      type: "i18nDate",
      label: "TAX_CREDIT_REQUEST_CREATED_AT",
      align: "center",
      width: "140px",
    },
  ],
};

export const taxCreditApplicationRequestsIsMobile = {
  id: "tax-credit-application-requests-table",
  select: "row",
  columns: [
    {
      id: "type",
      type: "taxDeclarationType",
      label: "SUBMITTED_APPLICATIONS",
      align: "left",
    },
    {
      id: "state",
      type: "taxState",
      label: "STATE",
      align: "center",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const addMassRoleEmployeeList = {
  id: "add-mass-role-employees-list-table",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: false,
      sort: false,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: false,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: false,
    },
  ],
};

export const addMassRoleEmployeeListIsMobile = {
  id: "add-mass-role-employees-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const removeMassRoleEmployeeList = {
  id: "remove-role-employees-list-table",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: false,
      sort: false,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: false,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: false,
    },
  ],
};

export const removeMassRoleEmployeeListIsMobile = {
  id: "remove-role-employees-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const sendGroupPasswordEmployeeList = {
  id: "send-group-passord-employees-list-table",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: false,
      sort: false,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: false,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: false,
    },
  ],
};

export const sendGroupPasswordEmployeeListIsMobile = {
  id: "send-group-passord-employees-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const inactiveGroupEmployeeList = {
  id: "inactive-group-employees-list-table",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "contract",
      type: "text",
      label: "GET_CONTRACT",
      search: false,
      sort: false,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: false,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: false,
    },
  ],
};

export const inactiveGroupEmployeeListIsMobile = {
  id: "inactive-group-employees-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "fullname",
      type: "text",
      label: "NAME",
      search: false,
      sort: false,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};
