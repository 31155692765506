import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import MyDocumentsInfoCardItem from "my-documents/components/my-documents-info-card-item/my-documents-info-card-item";
import MyDocumentDocumentVisibility from "my-documents/components/my-documents-document-visibility/my-documents-document-visibility";
import parsePipedName from "oc/utils/get-parse-pipedName";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import formatBytes from "oc/utils/get-format-bytes";
import MyDocumentDocumentEditorButtons from "my-documents/components/my-documents-document-editor-buttons/my-documents-document-editor-buttons";

export default function MyDocumentsCollapse({ row, role, removeUpload, onEditDocument }) {
  const { t, i18n } = useTranslation();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={() => { removeUpload(row); }}
        onCancel={handleDialogCancel}
        title={t("MYDOCUMENTS_DELETE_CONFIRM")}
        text={t("MYDOCUMENTS_DELETE_CONFIRM_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <Box padding="16px">
        <Box>
          <MyDocumentsInfoCardItem title={t("SIZE")} value={formatBytes(row?.size)} />
          <Box height="10px" />
          <MyDocumentsInfoCardItem title={t("UPLOADER")} value={parsePipedName(row?.user, i18n)} />
          {role &&
            <Box>
              <Box height="18px" />
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <MyDocumentDocumentVisibility visibility={row?.userView} />
                <MyDocumentDocumentEditorButtons setShowConfirmDialog={setShowConfirmDialog} onEditDocument={onEditDocument} row={row} />
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </>
  );
}
