import React from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useOcClientState from "hooks/use-oc-client-state-2";
import MyHrAdminSystemSettingsCacheMenu from "./components/my-hr-admin-system-settings-cache-menu/my-hr-admin-system-settings-cache-menu";
import MyHrAdminSystemSettingsLoginMenu from "./components/my-hr-admin-system-settings-login-menu/my-hr-admin-system-settings-login-menu";
import MyHrAdminSystemHelpdeskMenu from "./components/my-hr-admin-system-settings-helpdesk-menu/my-hr-admin-system-settings-helpdesk-menu";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

const menuDef = [
  {
    id: "loginSettings",
    label: "SIGN_IN",
  },
  {
    id: "clearCache",
    label: "CLEAR_CACHE",
  },
  {
    id: "helpdeskContacts",
    label: "HELPDESK_CONTACTS",
  },
];

function PageSideMenu() {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useOcClientState(
    "myHrSettingsPageMenuActiveItem",
    menuDef[0].id
  );

  function handleSelect(itemId) {
    setSelectedItem(itemId);
  }

  return (
    <Box>
      {menuDef.map((item) => (
        <Box
          id={item.id}
          key={item.id}
          width="240px"
          height="40px"
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="40px"
          onClick={() => handleSelect(item.id)}
          style={
            selectedItem === item.id
              ? {
                backgroundColor: "white",
                borderLeft: "4px solid #cc0033",
              }
              : { cursor: "pointer" }
          }
        >
          <Box style={{ fontSize: "14px", letterSpacing: "0.25px" }}>
            {t(item.label)}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default function MyHrAdminSystemSettingsPage() {
  const [selectedItem] = useOcClientState(
    "myHrSettingsPageMenuActiveItem",
    menuDef[0].id
  );

  const isMobile = useIsMobile();

  return (
    <OcPageTransition>
      <Box>
        <Box
          id="my-hr-admin-system-settings-title"
          height="80px"
          style={{ backgroundColor: "#cc0033" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="14px"
        >
          <Box style={{ color: "white", fontSize: "24px" }}>MyHR</Box>
        </Box>
        <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
          <PageSideMenu />
          <Box id="my-hr-admin-system-settings" flexGrow={1} padding={isMobile ? "16px 0px" : "16px"}>
            {selectedItem === "loginSettings" && (
              <MyHrAdminSystemSettingsLoginMenu isMobile={isMobile} />
            )}
            {selectedItem === "clearCache" && (
              <MyHrAdminSystemSettingsCacheMenu />
            )}
            {selectedItem === "helpdeskContacts" && (
              <MyHrAdminSystemHelpdeskMenu isMobile={isMobile} />
            )}
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
