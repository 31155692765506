import gql from "graphql-tag";

export const IMPORT_USERS = gql`
  mutation importUsers(
    $automaticPasswordGeneration: Boolean!
    $roleFilters: RoleFilterInput!
  ) {
    importUsers(
      automaticPasswordGeneration: $automaticPasswordGeneration
      roleFilters: $roleFilters
    ) {
      totalProcessedRecordCount
      importedUsersCount
      failedUsersCount
      importErrors {
        employee
        employeeName
        contract
        employmentType
        error
        _id
        errorPayload {
          existedEmployee
          existedEmployeeName
          existedEmail
        }
      }
    }
  }
`;
