import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { InlineIcon } from "@iconify/react";
import arrowLeftIcon from "@iconify/icons-mdi/arrow-left";
import { useTheme } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useTranslation } from "react-i18next";
import useCurrentUserState from "hooks/use-current-user-state";
import useCurrentCafeteriaStatement from "my-cafeteria/hooks/use-current-cafeteria-statement";
import { CustomTooltip } from "my-hr/components/my-hr-applications-data-panel/components/dependents-collapse/dependents-collapse.style";

const OcPrintHeader = ({ title, onBack, onPrint, isMobile }) => {
  let { contractCode } = useCurrentUserState();
  const { data: currentStatementData } = useCurrentCafeteriaStatement({
    contractCode,
  });

  const disabled = currentStatementData?.state === "editable" ? true : false;

  const theme = useTheme();
  const { t } = useTranslation();

  const handlePrint = () => {
    if (disabled) {
      return;
    }
    onPrint();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding={isMobile ? "16px" : "0px"}
      style={{
        backgroundColor: theme.palette.primary.main,
        height: isMobile ? "100%" : "64px",
        color: "white",
        borderRadius: "8px",
      }}
    >
      <Box
        color={theme.palette.white.main}
        height="24px"
        lineHeight="24px"
        onClick={onBack}
        id="btGoBack"
        paddingLeft={isMobile ? "0px" : "22px"}
      >
        <InlineIcon
          icon={arrowLeftIcon}
          style={{
            width: "24px",
            height: "24px",
            cursor: "pointer",
            color: theme.palette.white.main,
          }}
        />
      </Box>
      <Box
        display="flex"
        width="100%"
        flexDirection={isMobile ? "column" : "row"}
        alignItems={isMobile ? "center" : "unset"}
      >
        <Box flexGrow={1} textAlign="center">
          <Typography variant="h4" style={{ color: theme.palette.white.main }}>
            {title}
          </Typography>
        </Box>
        {isMobile && <Box height="16px" />}
        <Box paddingRight="22px">
          {disabled ? (
            <CustomTooltip
              title={t("DISABLED_APPLICATION_PRINT_BUTTON")}
              placement="bottom"
            >
              <ButtonBase
                id="print-button"
                onClick={handlePrint}
                style={{
                  height: "36px",
                  backgroundColor: "#E3E5E5",
                  fontSize: "14px",
                  letterSpacing: "0.75px",
                  borderRadius: "4px",
                  color: "#999999",
                  textTransform: "uppercase",
                  paddingLeft: "22px",
                  paddingRight: "22px",
                }}
              >
                {t("PRINT")}
              </ButtonBase>
            </CustomTooltip>
          ) : (
            <ButtonBase
              id="print-button"
              onClick={handlePrint}
              style={{
                height: "36px",
                backgroundColor: "#E3E5E5",
                fontSize: "14px",
                letterSpacing: "0.75px",
                borderRadius: "4px",
                color: "#212121",
                textTransform: "uppercase",
                paddingLeft: "22px",
                paddingRight: "22px",
              }}
            >
              {t("PRINT")}
            </ButtonBase>
          )}
        </Box>
      </Box>
    </Box>
  );
};

OcPrintHeader.propTypes = {
  /** Example prop type description*/
  title: PropTypes.string,
};

OcPrintHeader.defaultProps = {
  title: "title",
};

export default OcPrintHeader;
