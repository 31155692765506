import React from "react";
import OcCard from "oc/components/oc-card";
import Box from "@material-ui/core/Box";
import { allChangeRequests, allChangeRequestsIsMobile } from "my-hr/configs/my-hr-table-defs";
import Table from "oc/components/oc-data-table";
import { useTranslation } from "react-i18next";
import OcCollapseHeader from "oc/components/oc-collapse-header";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CompanyFilter from "my-leave/components/company-filter";
import OrgUnitFilter from "my-leave/components/org-unit-filter";
import WorkingPlaceFilter from "my-leave/components/working-place-filter";
import JobClassGroupFilter from "my-leave/components/job-class-group-filter";
import PayofficeFilter from "my-leave/components/payoffice-filter";
import EmployeeFilter from "my-leave/components/employee-filter";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FilteredListCount from "my-leave/components/my-leave-all-leaves-card/components/filtered-list-count";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function AllChangeRequestsCard({
  onAddFilter,
  onRemoveFilter,
  onlyRequested,
  onOnlyRequestedChange,
  data,
  roleFilters,
  loading,
  tableData,
  onTableDataChange,
  onSelectRow,
  tableId,
  buttonDisable,
  onClearFilters,
  contract,
  product = "MyHR",
  role = "examiner",
  onLoadQuery,
  requestsCount,
  usersCount,
  allUserCount,
  selectedRowId,
  handleMoreButton
}) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [expanded, setExpanded] = React.useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const maxWidthStyle = { maxWidth: isMobile ? "100%" : "50%" };

  const tableDef = isMobile
    ? allChangeRequestsIsMobile
    : allChangeRequests;

  const filteredTableDef = {
    id: allChangeRequests?.id,
    columns: allChangeRequests?.columns?.filter(column =>
      !allChangeRequestsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <OcCard
      label={
        tableId === "allChangeRequestsTableData"
          ? t("CHANGE_REQUESTS")
          : t("LEAVE_AND_ABSENCE_REQUESTS")
      }
    >
      <>
        <Box
          style={{
            backgroundColor: "#F1F3F4",
            borderRadius: "4px",
          }}
        >
          <OcCollapseHeader
            expanded={expanded}
            onExpandClick={handleExpandClick}
          />
          <Collapse
            in={expanded}
            timeout="auto"
            style={{
              backgroundColor: "#F1F3F4",
              borderRadius: "4px",
            }}
          >
            <Box
              id="collapse-body"
              padding="0px 16px"
              display="flex"
              flexDirection={"column"}
              alignItems="center"
            >
              <Box
                style={{
                  backgroundColor: "#C6CACC",
                  height: "2px",
                  width: "100%",
                }}
              />
              <Box width={isMobile ? "100%" : "66%"}>
                <Box
                  id="state-slector"
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  padding="25px 0px 0px 0px"
                >
                  <ButtonGroup>
                    <Button
                      id="leave-filter-collapse-filtered"
                      variant={onlyRequested ? "contained" : "outlined"}
                      color={onlyRequested ? "primary" : ""}
                      disableElevation
                      onClick={() => onOnlyRequestedChange(true)}
                    >
                      {t("TO_BE_ASSESSED")}
                    </Button>
                    <Button
                      id="leave-filter-collapse-all"
                      variant={!onlyRequested ? "contained" : "outlined"}
                      color={!onlyRequested ? "primary" : ""}
                      disableElevation
                      onClick={() => onOnlyRequestedChange(false)}
                    >
                      {t("ALL")}
                    </Button>
                  </ButtonGroup>
                </Box>
                <Box height="25px" />
                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "unset"
                  }}
                >
                  <Grid item xs={6} style={maxWidthStyle}>
                    <CompanyFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={"MyHR"}
                      role={"examiner"}
                      contract={contract}
                    />
                  </Grid>
                  <Grid item xs={6} style={maxWidthStyle}>
                    <OrgUnitFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                  <Grid item xs={6} style={maxWidthStyle}>
                    <WorkingPlaceFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                  <Grid item xs={6} style={maxWidthStyle}>
                    <JobClassGroupFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                  <Grid item xs={6} style={maxWidthStyle}>
                    <PayofficeFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                </Grid>
                <Box height="16px" />
                <Box
                  style={{
                    backgroundColor: "#C6CACC",
                    height: "1px",
                    width: "100%",
                  }}
                />
                <Box height="16px" />
                <Grid container spacing={2} style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "unset"
                }}
                >
                  <Grid item xs={6} style={maxWidthStyle}>
                    <EmployeeFilter
                      roleFilters={roleFilters}
                      onAddFilter={onAddFilter}
                      onRemoveFilter={onRemoveFilter}
                      product={product}
                      role={role}
                      contract={contract}
                    />
                  </Grid>
                </Grid>
                <Box height="16px" />
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <FilteredListCount
                      listCount={usersCount || 0}
                      type="filter"
                      requetsCount={requestsCount}
                      allUsersCount={allUserCount}
                      isMobile={isMobile}
                    />
                  </Grid>
                </Grid>
                <Box
                  id="buttons-container"
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  padding="26px 0px 26px 0px"
                >
                  <Button id="cancel-button" onClick={onClearFilters}>
                    {t("RESET")}
                  </Button>
                  <Box width="16px" />
                  <Button
                    id="filter-button"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={buttonDisable}
                    onClick={() => {
                      onLoadQuery();
                    }}
                  >
                    {t("FILTERING")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </Box>
        <Box paddingTop="32px">
          <Table
            data={data}
            loading={loading}
            tableDef={tableDef}
            tableData={tableData}
            setTableData={onTableDataChange}
            onSelectRow={onSelectRow}
            tableId={tableId}
            pagination={true}
            localPaging={true}
            filteredTableDef={filteredTableDef}
            selectedRowId={selectedRowId}
            handleMoreButton={handleMoreButton}
          />
        </Box>
      </>
    </OcCard >
  );
}
