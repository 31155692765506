import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import MessageOutlineIcon from "@iconify/icons-mdi/message-outline";
import MessageTextIcon from "@iconify/icons-mdi/message-text";
import { InlineIcon } from "@iconify/react";
import State from "oc/components/oc-data-table-body-cell-state";
import parsePipedName from "oc/utils/get-parse-pipedName";
import ApplicationState from "my-hr/components/my-hr-application-state";
import formatBytes from "oc/utils/get-format-bytes";
import MyDocumentDocumentVisibility from "my-documents/components/my-documents-document-visibility/my-documents-document-visibility";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBold from "@iconify/icons-mdi/check-bold";
import CloseThick from "@iconify/icons-mdi/close-thick";
import pencilIcon from "@iconify/icons-mdi/pencil";
import MyDocumentsActionCell from "my-documents/components/my-documents-action-cell/my-documents-action-cell";
import MyDocumentDocumentEditorButtons from "my-documents/components/my-documents-document-editor-buttons/my-documents-document-editor-buttons";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import formatTextWidthValue from "oc/utils/get-format-text-width-value";
import moment from "moment";
import plusIcon from "@iconify/icons-mdi/plus";
import arrowIcon from "@iconify/icons-mdi/arrow-right";
import OcInfoDialog from "oc/components/oc-info-dialog/oc-info-dialog";

export default function OcIsMobileTableCollapse({
  row,
  myDocumentsOperator,
  filteredTableDef,
  handleMoreButton,
  inlineEdit,
  onEditDocument,
  removeUpload,
  isNotMyTimeExaminer,
  matchingMyTimeitems,
  isMobile
}) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  let tableColumns = filteredTableDef?.columns?.map(column => column?.id) || [];
  let rowEntries = Object.entries(row);

  if (filteredTableDef?.id === "employee-my-time-admissions-by-admin-table") {
    tableColumns = tableColumns.filter(column => column !== "icons");
    rowEntries = rowEntries.filter(([key]) => key !== "icons");
  }
  if (tableColumns.includes("messages") && !row.hasOwnProperty("messages")) {
    rowEntries.push(["messages", []]);
  }

  const hideMoreButton =
    ["my-fulfilled-leaves-table", "document-list-table", "orphaned-leave-requests-table",
      "benefits-table", "archived-benefits-table", "employee-without-my-time-examiner-request-table",
      "employee-my-time-closing-period-request-table", "add-mass-roles-errors-table", "add-mass-role-employees-list-table",
      "remove-mass-roles-errors-table", "remove-role-employees-list-table", "send-group-passord-employees-list-table",
      "inactive-group-employees-list-table", "import-errors-table", "create-employees-list-table", "employee-my-time-admissions-table", "employee-my-time-admissions-by-admin-table"]
      .includes(filteredTableDef?.id);

  function addColumnEntriesForTableDef() {
    const tableDefColumns = {
      "archived-benefits-table": ["copyButton"],
      "orphaned-leave-requests-table": ["actionButtons"],
      "benefits-table": ["editButton"],
      "document-list-table": ["documentActionButtons", "documentEditorButtons"],
    };

    const specificColumns = tableDefColumns[filteredTableDef?.id];
    if (specificColumns) {
      specificColumns.forEach((column) => {
        rowEntries.push([column, true]);
        tableColumns.push(column);
      });
    }
  }

  function addCommonColumns() {
    const commonColumns = [
      { key: "createdAt", condition: row?.createdAt && filteredTableDef?.id !== "document-list-table" },
      { key: "size", condition: row?.size },
      { key: "user", condition: row?.user },
      { key: "date", condition: row?.date },
      { key: "myTimeType", condition: row?.myTimeType },
      { key: "userView", condition: row?.userView },
      { key: "icons", condition: row?.icons },
    ];

    commonColumns.forEach(({ key, condition }) => {
      if (condition && !tableColumns.includes(key)) {
        tableColumns.push(key);
      }
    });
  }

  addColumnEntriesForTableDef();
  addCommonColumns();

  function capitalizeFirstWord(displayKey) {
    if (!displayKey) return displayKey;
    return displayKey.charAt(0).toUpperCase() + displayKey.slice(1);
  }

  function handleCafeteriaAction() {
    handleMoreButton({ item: row, action: "copy" });
  }

  function handleLeaveAction(action) {
    handleMoreButton({ id: row?._id, action });
  }

  const hasOvertime = matchingMyTimeitems?.some(item => item?.myTimeType === "OVERTIME");

  function renderDocumentEditorButtons() {
    return (
      <>
        <OcConfirmationDialog
          showDialog={showConfirmDialog}
          onClose={() => { removeUpload(row); }}
          onCancel={handleDialogCancel}
          title={t("MYDOCUMENTS_DELETE_CONFIRM")}
          text={t("MYDOCUMENTS_DELETE_CONFIRM_INFO")}
          okButtonText={t("ALL_RIGHT")}
          cancelButtonText={t("CANCEL")}
        />
        <MyDocumentDocumentEditorButtons setShowConfirmDialog={setShowConfirmDialog} onEditDocument={onEditDocument} row={row} />
      </>
    );
  }

  function renderStartDate() {
    const startDate = new Date(row?.startDate)?.toLocaleDateString();
    const endDate = new Date(row?.endDate)?.toLocaleDateString();
    return (
      <Box display="flex">
        <Box>{startDate} - {endDate}</Box>
      </Box>
    );
  }

  function renderDescription() {
    return (
      <Box display="flex">
        <Box>{row?.description?.includes(" ") ? row?.description : formatTextWidthValue(row?.description, 15)}</Box>
      </Box>
    );
  }

  const buttonStyle = {
    border: "1px solid #919699",
    borderRadius: "50%",
    cursor: "pointer",
    width: "36px",
    height: "36px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#919699",
  };

  function renderIcons() {
    return (
      <Box display="flex" flexDirection="row">
        {row?.icons?.add && !isNotMyTimeExaminer && (
          <Box
            onClick={() => {
              if (hasOvertime) {
                setIsDialogOpen(true);
              } else {
                handleMoreButton({ action: "add", row: row });
              }
            }}
            style={row?.icons?.arrow || row?.icons?.edit ? { ...buttonStyle, marginRight: "8px" } : buttonStyle}
          >
            <InlineIcon width="24px" height="24px" id={`add-${new Date(row?.date).getDate()}`} icon={plusIcon} />
          </Box>
        )}
        {row?.icons?.arrow && !isNotMyTimeExaminer && (
          <Box onClick={() => handleMoreButton({ action: "show", row: row })} style={buttonStyle}>
            <InlineIcon width="24px" height="24px" id={`show-${new Date(row?.date).getDate()}`} icon={arrowIcon} />
          </Box>
        )}
        {row?.icons?.edit && !isNotMyTimeExaminer && (
          <Box onClick={() => handleMoreButton({ action: "edit", row: row })} style={buttonStyle}>
            <InlineIcon width="24px" height="24px" id={`edit-${new Date(row?.date).getDate()}`} icon={pencilIcon} />
          </Box>
        )}
      </Box>
    );
  }

  function renderBoxWithText(value, maxLength = 20) {
    return (
      <Box display="flex">
        <Box>{formatTextWidthValue(value, maxLength)}</Box>
      </Box>
    );
  }

  function renderDate() {
    const startTime = new Date(row?.startTime)?.toLocaleDateString() + " " + moment(row?.startTime)?.format("HH:mm");
    const endTime = new Date(row?.endTime)?.toLocaleDateString() + " " + moment(row?.endTime)?.format("HH:mm");
    const period = row.startTime ? `${startTime} - ${endTime}` : "";
    return (
      <Box display="flex">
        <Box>{period}</Box>
      </Box>
    );
  }

  function renderDayType() {
    return (
      <Box display="flex">
        <Box>{t(row?.dayType)}</Box>
      </Box>
    );
  }

  function renderDocumentActionButtons() {
    return (
      <Box display="flex">
        <MyDocumentsActionCell document={row} isMobile={isMobile} />
      </Box>
    );
  }

  function renderEditButton() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box
          id={`menu-edit-${row ? row.index : "zero"}`}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          style={{
            border: `1px solid ${inlineEdit ? "#919699" : "#C6CACC"}`,
            width: "36px",
            height: "36px",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={inlineEdit ? () => handleCafeteriaAction("edit") : null}
        >
          <InlineIcon width="18px" height="18px" icon={pencilIcon} color={inlineEdit ? "#919699" : "#C6CACC"} />
        </Box>
      </Box>
    );
  }

  function renderActionButtons() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="36px"
          height="36px"
          style={{
            backgroundColor: "#2FA84F",
            borderRadius: "4px",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => handleLeaveAction("accepted")}
        >
          <InlineIcon width="20px" height="20px" icon={CheckBold} />
        </Box>
        <Box width="4px" />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="36px"
          height="36px"
          style={{
            backgroundColor: "#cc0033",
            borderRadius: "4px",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => handleLeaveAction("rejected")}
        >
          <InlineIcon width="20px" height="20px" icon={CloseThick} />
        </Box>
      </Box>
    );
  }

  function renderCopyButton() {
    return (
      <Button
        variant="contained"
        color="primary"
        disableElevation
        disabled={!row?.canCopy}
        onClick={handleCafeteriaAction}
      >
        {t("COPY")}
      </Button>
    );
  }

  function renderRequestedCount(value) {
    return (
      <Box
        display="inline-block"
        width="22px"
        height="22px"
        style={{
          backgroundColor: "#cc0033",
          color: "white",
          borderRadius: "2px",
          textAlign: "center",
          fontSize: "12px",
          letterSpacing: "0.4px",
          paddingTop: "1px",
        }}
      >
        {value}
      </Box>
    );
  }

  function renderPipedName(value) {
    return <Box>{parsePipedName(value, i18n)}</Box>;
  }

  function renderDocumentVisibility() {
    return <MyDocumentDocumentVisibility visibility={row?.userView} />;
  }

  function renderSize() {
    return <Box>{formatBytes(row?.size)}</Box>;
  }

  function renderAmount(value) {
    return <Box>{`${value} Ft`}</Box>;
  }

  function renderCreatedAt(value, format = "localeDate") {
    let formattedDate;

    if (format === "localeDate") {
      formattedDate = new Date(value)?.toLocaleDateString();
    } else if (format === "fullDate" && row?.__typename !== "LeaveRequest") {
      formattedDate = new Date(value)
        ?.toLocaleString("hu-HU")
        ?.replace(/,/g, "")
        ?.replace(/\//g, ".");
    } else {
      formattedDate = value;
    }

    return <Box>{formattedDate}</Box>;
  }

  function renderState(value) {
    return filteredTableDef?.id !== "tax-credit-application-requests-table" ? (
      <State label={t(value)} state={value} isMobile={isMobile} />
    ) : (
      <ApplicationState label={t(value)} state={value} />
    );
  }

  function renderType() {
    return (
      <Box>{filteredTableDef?.id !== "tax-credit-application-requests-table" ? t(`Leave.${row?.type}`) : t(row?.type)}</Box>
    );
  }

  function renderError() {
    return <Box>{t(row?.error)}</Box>;
  }

  function renderRole() {
    return <Box>{t(`Role.${row?.role}`).toLowerCase()}</Box>;
  }

  function renderActiveCheckbox() {
    return (
      <Box>
        <Checkbox checked={row?.active} style={{ padding: "5px" }} />
      </Box>
    );
  }

  function renderMessagesIcon(value) {
    return (
      <InlineIcon width="20px" height="20px" icon={value?.length > 0 ? MessageTextIcon : MessageOutlineIcon} />
    );
  }

  function correctedValue(key, value) {
    switch (key) {
      case "documentEditorButtons":
        return myDocumentsOperator ? renderDocumentEditorButtons() : null;
      case "startDate":
        return filteredTableDef?.id === "orphaned-leave-requests-table" ? renderStartDate() : value;
      case "description":
        return renderDescription();
      case "icons":
        return renderIcons();
      case "filename":
      case "email":
        return renderBoxWithText(value, key === "filename" ? 20 : 15);
      case "date":
        return renderDate();
      case "dayType":
        return renderDayType();
      case "documentActionButtons":
        return renderDocumentActionButtons();
      case "editButton":
        return renderEditButton();
      case "actionButtons":
        return renderActionButtons();
      case "copyButton":
        return renderCopyButton();
      case "requestedLeavesCount":
      case "requestCount":
      case "recordCount":
        return value === 0 ? null : renderRequestedCount(value);
      case "name":
        return value.includes("|") ? renderPipedName(row?.name) : value;
      case "userView":
        return myDocumentsOperator ? renderDocumentVisibility() : null;
      case "user":
        return renderPipedName(row?.user);
      case "size":
        return renderSize();
      case "amount":
      case "cost":
      case "valueOfBenefit":
        return renderAmount(value);
      case "createdAt":
        return renderCreatedAt(value, filteredTableDef?.id === "tax-credit-application-requests-table" ? "localeDate" : "fullDate");
      case "state":
        return renderState(value);
      case "type":
        return renderType();
      case "myTimeType":
        return <Box>{t(row?.myTimeType)}</Box>;
      case "typeName":
        return <Box>{t(row?.type)}</Box>;
      case "error":
        return renderError();
      case "role":
        return renderRole();
      case "active":
        return renderActiveCheckbox();
      case "messages":
        return renderMessagesIcon(value);
      default:
        return value;
    }
  }

  function getDisplayKey(key) {
    if (key === "user" && filteredTableDef?.id === "document-list-table") {
      return "uploader";
    }
    if (key === "createdAt" && filteredTableDef?.id === "document-list-table") {
      return "uploaded";
    }
    if (["copyButton", "actionButtons", "editButton", "documentActionButtons", "documentEditorButtons", "icons"].includes(key)) {
      return "";
    }
    if (key === "myTimeType" && filteredTableDef?.id === "employee-my-time-admissions-table") {
      return "CATEGORY";
    }
    if (filteredTableDef?.id === "orphaned-leave-requests-table" && key === "startDate") {
      return "Period";
    }
    if ((filteredTableDef?.id === "benefits-table" || filteredTableDef?.id === "archived-benefits-table") && key === "amount") {
      return "NETTO";
    }
    if (filteredTableDef?.id === "employee-my-time-admissions-table" && key === "date") {
      return "Period";
    }
    if (filteredTableDef?.id === "employee-my-time-admissions-table" && key === "timeDataTypeName") {
      return "MY_TIME_TYPE";
    }
    if (filteredTableDef?.id === "my-previous-fulfilled-leaves-table" && key === "fulfilledAbsence") {
      return "FULFILLEDLEAVE";
    }
    return key;
  }

  function getJustifyContent(key) {
    const centeredKeys = [
      "copyButton",
      "actionButtons",
      "editButton",
      "documentActionButtons",
      "documentEditorButtons",
      "icons",
      "requestedLeavesCount",
      "requestCount",
      "recordCount"
    ];
    return centeredKeys.includes(key) ? "center" : "unset";
  }

  rowEntries.sort((a, b) => {
    const specialKeys = ["documentActionButtons", "messages", "documentEditorButtons"];
    const indexA = tableColumns.indexOf(a[0]);
    const indexB = tableColumns.indexOf(b[0]);

    if (specialKeys.includes(a[0]) && !specialKeys.includes(b[0])) return 1;
    if (!specialKeys.includes(a[0]) && specialKeys.includes(b[0])) return -1;

    return indexA - indexB;
  });

  return (
    <Box padding="0px 8px" display="flex" flexDirection="column">
      {rowEntries
        ?.filter(([key]) => tableColumns?.includes(key))
        ?.map(([key, value], index) => {
          if ((key === "userView" || key === "documentEditorButtons") && !myDocumentsOperator) return null;

          const displayKey = getDisplayKey(key);
          const translatedDisplayKey = t(displayKey?.toUpperCase());
          const capitalizedDisplayKey = capitalizeFirstWord(translatedDisplayKey);

          return (
            <Box
              key={index}
              display="flex"
              flexDirection="column"
              borderBottom={`1px solid ${theme.palette.divider}`}
            >
              <Box display="flex" alignItems="center" padding="8px 0">
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  style={{ width: '50%' }}
                >
                  {capitalizedDisplayKey}
                </Typography>
                <Box
                  display="flex"
                  justifyContent={getJustifyContent(key)}
                  style={{ width: '50%' }}
                >
                  <Typography variant="body2" component="span">
                    {correctedValue(key, value)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          );
        })}
      {!hideMoreButton &&
        <Box mt={2}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => handleMoreButton(row)}
          >
            {t("MORE")}
          </Button>
        </Box>
      }
      <Box height="8px" />
      <OcInfoDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        text={t("ONE_OVERTIME_ALLOWED_TEXT")}
        handleDialogClose={handleDialogClose}
        isMobile={isMobile}
      />
    </Box>
  );
}
