import React, { useState } from "react";
import MaterialCard from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useClientState from "hooks/use-oc-client-state";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import MyHrAdminImportCollapseLabel from "my-hr/components/my-hr-admin-users-import-collapse-label";
import useProperty from "hooks/use-oc-property";
import ImportDialog from "my-hr/components/my-hr-admin-users-import-dialog";
import OcSwitch from "oc/components/oc-form-switch";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import OcOrgUnitFilter from "oc/components/oc-org-unit-filter";
import OcWorkingPlaceFilter from "oc/components/oc-working-place-filter";
import OcJobClassGroupFilter from "oc/components/oc-job-class-group-filter";
import OcPayofficeFilter from "oc/components/oc-payoffice-filter";
import OcEmployeeFilter from "oc/components/oc-employee-import-filter";
import _ from "lodash";
import OcCollapseHeader from "oc/components/oc-collapse-header";
import OcFilteredListCount from "oc/components/oc-filtered-list-count";
import OcCompanyFilter from "oc/components/oc-company-filter";
import { useQuery } from "@apollo/client";
import { GET_IMPORT_USERS_COUNT } from "graphql/queries";
import MyLeaveFilterDivider from "my-leave/components/my-leave-filter-divider";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyHrAdminUsersPage() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [showDialog, setShowDialog] = useClientState(
    "showJDolBerUsersImportDialog",
    false
  );

  const initialRoleFilters = {
    company: [],
    orgUnit: [],
    workingPlace: [],
    jobClassGroup: [],
    payOffice: [],
    contract: [],
  };

  const userRoleFilters = { ...initialRoleFilters };

  const [roleFilters, setRoleFilters] = useState(initialRoleFilters);
  const [expanded, setExpanded] = React.useState(false);
  function handleAddFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].concat([value]);
    setRoleFilters(newState);
  }

  function handleRemoveFilter({ key, value }) {
    let newState = _.cloneDeep(roleFilters);
    newState[key] = newState[key].filter((item) => item !== value);
    setRoleFilters(newState);
  }

  function handleExpandFilterClick() {
    setExpanded(!expanded);
  }

  const { data } = useQuery(GET_IMPORT_USERS_COUNT, {
    fetchPolicy: "cache-and-network",
    variables: { roleFilters },
  });

  const { data: importLockedData } = useProperty({
    resource: "system",
    key: "importLocked",
  });

  const { data: loginType } = useProperty({
    resource: "system",
    key: "loginType",
  });

  let importLocked = importLockedData === "false" ? false : true;

  const maxWidthStyle = { maxWidth: isMobile ? "100%" : "50%" };

  const [showImportUsersCollapse, setShowImportUsersCollapse] = useClientState(
    "showImportJDolBerUsersRoleCollapse",
    false
  );

  function handleShowDialog() {
    setShowDialog(!showDialog);
  }

  const handleExpandClick = () => {
    setShowImportUsersCollapse(!showImportUsersCollapse);
  };

  function handleShowImportDialog() {
    handleShowDialog();
  }

  function handleCloseImportDialog() {
    setShowDialog(false);
  }

  function handleFinishImport() {
    setShowDialog(false);
  }

  const [automaticPasswordGeneration, setAutomaticPasswordGeneration] =
    React.useState(false);

  return (
    <Box>
      <ImportDialog
        onShowDialog={handleShowDialog}
        showDialog={showDialog}
        finishImport={handleFinishImport}
        importType="jdolber"
        onCloseDialog={handleCloseImportDialog}
        automaticPasswordGeneration={automaticPasswordGeneration}
        roleFilters={roleFilters}
      />
      <MaterialCard elevation={0}>
        <MyHrAdminImportCollapseLabel
          expanded={showImportUsersCollapse}
          handleExpandClick={handleExpandClick}
          label={t("IMPORT_USERS_FROM_JDOLBER")}
        />
        <Collapse in={showImportUsersCollapse} timeout="auto" unmountOnExit>
          <Box padding="0px 32px">
            <Box style={{ borderTop: "2px solid #C6CACC" }} />
            <Box
              style={{
                backgroundColor: "#F1F3F4",
                borderRadius: "4px",
                marginTop: "24px",
              }}
            >
              <OcCollapseHeader
                expanded={expanded}
                onExpandClick={handleExpandFilterClick}
              />
              <Collapse
                in={expanded}
                timeout="auto"
                style={{
                  backgroundColor: "#F1F3F4",
                  borderRadius: "4px",
                }}
              >
                <Box
                  id="collapse-body"
                  padding="0px 16px"
                  display="flex"
                  flexDirection={"column"}
                  alignItems="center"
                >
                  <Box
                    style={{
                      backgroundColor: "#C6CACC",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  <Box
                    id="selector-container"
                    width={isMobile ? "100%" : "66%"}
                    style={{ paddingBottom: "24px", paddingTop: "32px" }}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{
                        display: "flex",
                        flexDirection: isMobile ? "column" : "unset"
                      }}
                    >
                      <Grid item xs={6} style={maxWidthStyle}>
                        <OcCompanyFilter
                          roleFilters={roleFilters}
                          userRoleFilters={userRoleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                        />
                      </Grid>
                      <Grid item xs={6} style={maxWidthStyle}>
                        <OcOrgUnitFilter
                          roleFilters={roleFilters}
                          userRoleFilters={userRoleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                          product="MyHR"
                          role="admin"
                        />
                      </Grid>
                      <Grid item xs={6} style={maxWidthStyle}>
                        <OcWorkingPlaceFilter
                          roleFilters={roleFilters}
                          userRoleFilters={userRoleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                        />
                      </Grid>
                      <Grid item xs={6} style={maxWidthStyle}>
                        <OcJobClassGroupFilter
                          roleFilters={roleFilters}
                          userRoleFilters={userRoleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                        />
                      </Grid>
                      <Grid item xs={6} style={maxWidthStyle}>
                        <OcPayofficeFilter
                          roleFilters={roleFilters}
                          userRoleFilters={userRoleFilters}
                          onAddFilter={handleAddFilter}
                          onRemoveFilter={handleRemoveFilter}
                        />
                      </Grid>
                    </Grid>
                    <Box height="16px" />
                    <MyLeaveFilterDivider />
                    <Box height="16px" />
                    <Grid item xs={6} style={maxWidthStyle}>
                      <OcEmployeeFilter
                        roleFilters={roleFilters}
                        userRoleFilters={userRoleFilters}
                        onAddFilter={handleAddFilter}
                        onRemoveFilter={handleRemoveFilter}
                        product="MyHR"
                        role="admin"
                        isImport={true}
                      />
                    </Grid>
                    <Box minHeight="16px" />
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                    >
                      <Grid item xs={12}>
                        <OcFilteredListCount
                          listCount={data?.importUsersCount || 0}
                          isMobile={isMobile}
                        />
                      </Grid>
                    </Grid>
                    <Box style={{ paddingTop: "24px" }}>
                      <Button id="reset-button">{t("RESET")}</Button>
                    </Box>
                  </Box>
                </Box>
              </Collapse>
            </Box>

            {loginType === "legacy" && (
              <>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  padding="36px 0px 0px 0px"
                  paddingBottom="12px"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    paddingBottom="12px"
                  >
                    <OcSwitch
                      id="automaticPasswordGeneration"
                      checked={automaticPasswordGeneration}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={(e) =>
                        setAutomaticPasswordGeneration(e.target.checked)
                      }
                      disabled={false}
                    />
                    <Box width="16px" />
                    <Typography variant="body1">
                      {t("AUTOMATIC_PASSWORD_GENERATION")}
                    </Typography>
                  </Box>
                  <Typography variant="caption">
                    {t("AUTOMATIC_PASSWORD_GENERATION_INFO")}
                  </Typography>
                </Box>
              </>
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              padding="38px 0px"
              style={{
                position: "relative",
              }}
            >
              <Button
                id="import-buttom"
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleShowImportDialog}
                disabled={importLocked}
              >
                {t("IMPORT_USERS")}
              </Button>
            </Box>
            {importLocked ? (
              <Box display="flex" justifyContent="space-around">
                <Typography variant="caption">
                  {t("IMPORT_SSHR_USERS_LOCKED_INFO")}
                </Typography>
              </Box>
            ) : (
              <Box display="flex" justifyContent="space-around">
                <Typography variant="caption">
                  {t("IMPORT_USERS_TEXT_INFO")}
                </Typography>
              </Box>
            )}
            <Box height="32px" />
          </Box>
        </Collapse>
      </MaterialCard>
    </Box>
  );
}
