import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "8px 16px",
  },
  formControl: ({ isMobile }) => ({
    minWidth: isMobile ? "auto" : 410,
    width: "100%",
  }),
}));

export default useStyles;
