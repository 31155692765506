import gql from "graphql-tag";

export const USERS_WITH_CHANGE_REQUEST = gql`
  mutation usersWithChangeRequest(
    $roleFilters: RoleFilterInput!
    $onlyRequested: Boolean!
    $searchText: String
  ) {
    usersWithChangeRequest(
      roleFilters: $roleFilters
      onlyRequested: $onlyRequested
      searchText: $searchText
    ) {
      employee
      name
      orgUnit {
        code
        name
      }
      jobClass {
        code
        name
      }
      requestCount
      allUserCount
      _id
    }
  }
`;
