import React, { useState, useEffect } from "react";
import OcCard from "oc/components/oc-card/oc-card";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { employeeMyTimeRequests, employeeMyTimeRequestsIsMobile } from "my-time/configs/tableDefs";
import OcDataTable from "oc/components/oc-data-table/oc-data-table";
import useMyTimeRequests from "my-time/hooks/use-my-time-requests";
import moment from "moment";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import { useHistory } from "react-router-dom";
import useStyles from "my-leave/components/my-leave-filter-bar/my-leave-filter-bar.style";
import { InlineIcon } from "@iconify/react";
import checkAll from "@iconify/icons-mdi/check-all";
import { useMutation } from "@apollo/react-hooks";
import { APPROVE_ALL_MY_TIME_REQUESTS } from "my-time/graphql/mutations/approve-all-my-time-requests";
import useOcSnackbar from "hooks/use-oc-snackbar";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

const MyTimeEmployeeRequestsCard = ({
  contractCode,
  date,
  label,
  employeeCode,
  examinerView,
  showApproveAllButton,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  let url = "";

  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleSelectRow = (row) => {
    if (examinerView) {
      url = `/my-time/employee-request/${employeeCode}/${contractCode}/${row._id}`;
    } else {
      url = `/my-time/show-request/${employeeCode}/${contractCode}/${row._id}`;
    }
    history.push(url);
  };

  const onSelectRow = (row) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === row?._id ? null : row?._id);
    } else {
      handleSelectRow(row);
    }
  };

  const [requested, setRequested] = useState(true);

  const [allApproveAction, setAllApproveAction] = useState(false);


  const { data, loading, error } = useMyTimeRequests({
    contractCode,
    startDate: moment(date).startOf("month").toDate(),
    endDate: moment(date).endOf("month").toDate(),
    requested,
    page: 1,
    pageSize: 5,
  });

  const list = {
    rows: data,
    total: data?.length,
  };

  let tableId = "myTimeEmployeeRequestsTableData";
  initialTableData.sortField = "title";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  const requestCounter = data?.filter((item) => item?.state === "requested");

  const { setOpenMessage } = useOcSnackbar();

  const [approveAllRequests] = useMutation(APPROVE_ALL_MY_TIME_REQUESTS, {
    onCompleted: (data) => {
      const results = data?.approveAllMyTimeRequests;
      let isOK = false;

      results.forEach((result) => {
        if (result.result) {
          isOK = true;
        } else {
          setOpenMessage({
            type: "error",
            message: t("MY_TIME_ALL_APPROVE_FAILED"),
          });
        }
      });
      if (isOK) {
        setOpenMessage({
          type: "success",
          message: t("MY_TIME_ALL_APPROVE_SUCEEDED"),
        });
      }
    },
    onError: () => {
      setOpenMessage({
        type: "error",
        message: t("MY_TIME_APPROVE_FAILED"),
      });
    },
  });

  const handleApproveAllRequests = () => {
    const params = { contractCode };
    approveAllRequests({ variables: params });
    setAllApproveAction(true);
  };

  const tableDef = isMobile
    ? employeeMyTimeRequestsIsMobile
    : employeeMyTimeRequests;

  const filteredTableDef = {
    id: employeeMyTimeRequests?.id,
    columns: employeeMyTimeRequests?.columns?.filter(column =>
      !employeeMyTimeRequestsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  useEffect(() => {
    if (allApproveAction) {
      const url = `/my-time/approve-requests`;
      history.push(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allApproveAction]);


  return (
    <OcCard label={label} loading={loading} error={error}>
      <Box className={classes.root}>
        <Box
          className={
            !examinerView && !showApproveAllButton
              ? classes.leftContainerExaminerView
              : classes.leftContainer
          }
        />
        <ButtonGroup style={{ justifyContent: isMobile ? "center" : "unset" }}>
          <Button
            id="requests-button"
            onClick={() => setRequested(true)}
            variant={!requested ? "outlined" : "contained"}
            color={!requested ? "default" : "primary"}
            disableElevation
          >
            {t("Leave.requestedPast")}
          </Button>
          <Button
            id="all-button"
            onClick={() => setRequested(false)}
            variant={requested ? "outlined" : "contained"}
            color={requested ? "default" : "primary"}
            disableElevation
          >
            {t("Leave.all")}
          </Button>
        </ButtonGroup>
        {isMobile && <Box height="36px" />}
        <Box className={classes.rightContainer}>
          {examinerView && showApproveAllButton ? (
            <Button
              id="all-approve"
              variant="contained"
              color="default"
              startIcon={<InlineIcon icon={checkAll} />}
              onClick={handleApproveAllRequests}
              className={classes.allApprove}
              disableElevation
              disabled={loading || requestCounter?.length === 0}
            >
              {t("ALL_APPROVE")}
            </Button>
          ) : (
            <Box>{examinerView && <Box width="180px" />}</Box>
          )}
        </Box>
      </Box>
      <Box padding="0px 0px 16px 0px">
        <OcDataTable
          tableId={tableId}
          tableData={tableData}
          setTableData={setTableData}
          tableDef={tableDef}
          data={list}
          loading={loading}
          onSelectRow={onSelectRow}
          pagination={true}
          localPaging={true}
          filteredTableDef={filteredTableDef}
          handleMoreButton={handleSelectRow}
          selectedRowId={selectedRowId}
        />
      </Box>
    </OcCard>
  );
};

export default MyTimeEmployeeRequestsCard;
