import React, { useState, useEffect } from "react";
import OcCard from "oc/components/oc-card/oc-card";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useAdmission from "my-time/hooks/use-admission";
import OcDataTable from "oc/components/oc-data-table/oc-data-table";
import { employeeAdmissions, employeeAdmissionsIsMobile } from "my-time/configs/tableDefs";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import useClientState from "hooks/use-oc-client-state";
import MyTimeNewRequestDialog from "my-time/components/my-time-new-request-dialog";
import MyTimeInfoDialog from "my-time/components/my-time-info-dialog";
import { useHistory } from "react-router-dom";
import MyTimeCalendar from "../my-time-calendar/my-time-calendar";
import useEmployee from "hooks/use-myhr-employee";
import getFullname from "oc/utils/get-fullname";
import useProperty from "hooks/use-oc-property";
import useActualDate from "my-time/hooks/use-actual-date";
import { parseActions } from "my-time/utils";
import useRecordingPeriod from "my-time/hooks/use-recording-period";
import MyTimeShortInfoDialog from "my-time/components/my-time-short-info-dialog";
import { countDailyHours } from "my-time/utils";
import moment from 'moment';

const MyTimeAdmissionCard = ({
  contractCode,
  employeeCode,
  year,
  month,
  changeMyTimeEnabled,
  changeWorkingTimeEnabled: editWorkingTimeEnabled,
  isNotExaminer,
  isMobile
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const recordingPeriod = useRecordingPeriod();

  const [showCalendar, setShowCalendar] = useState(!isMobile);

  const [selectedRowId, setSelectedRowId] = useState(null);

  const [matchingMyTimeitems, setMatchingMyTimeitems] = useState([]);

  useEffect(() => {
    if (isMobile) {
      setShowCalendar(false);
    }
  }, [isMobile]);

  const { date, handleDateChange } = useActualDate();

  const { data: employeeData } = useEmployee({ employeeId: employeeCode });
  const employeeName = getFullname(employeeData);

  const { data: myTimeEnabledData } = useProperty({
    resource: "myTime",
    key: "changeMyTimeEnabled",
  });

  const requestsEnabled = myTimeEnabledData === "true" ? true : false;

  const [openNewRequestDialog, setOpenNewRequestDialog] = useState({
    state: false,
    action: null,
    data: null,
  });

  const [openInfoDialog, setOpenInfoDialog] = useState({
    state: false,
    data: null,
  });

  const [openShortInfoDialog, setOpenShortInfoDialog] = useState({
    state: false,
    text: "",
  });

  const handleCloseNewRequestDialog = () => {
    setOpenNewRequestDialog({ state: false, action: null, data: null });
  };

  const { data, loading, error, events } = useAdmission({
    contractCode,
    year,
    month,
    changeMyTimeEnabled,
    recordingPeriod,
  });

  const countTimeData = (date) => {
    const result = countDailyHours({ date, list: data });
    return result;
  };

  const parsedData = data?.map((item) => {
    const newItem = { ...item };
    newItem.countTimeData = countTimeData;
    return newItem;
  });

  const list = {
    rows: parsedData,
    total: data?.length,
  };

  const handleSelectRow = ({ action, row }) => {
    setOpenInfoDialog({ state: false, data: null });
    setOpenNewRequestDialog({ state: false, action: null, data: null });
    setOpenShortInfoDialog({ state: false, text: "" });
    parseActions({
      requestsEnabled,
      editWorkingTimeEnabled,
      action,
      row,
      list,
      role: "user",
      setOpenNewRequestDialog,
      setOpenInfoDialog,
      setOpenShortInfoDialog,
      history,
      employeeCode,
      contractCode,
      recordingPeriod,
      isNotExaminer,
      matchingMyTimeitems
    });
    return;
  };

  const [selectedRowData, setSelectedRowData] = useState(null);

  const onSelectRow = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
      setSelectedRowData(o);

      const selectedDate = moment(o?.date).format('YYYY.MM.DD');

      const foundMyTimeItems = list?.rows?.filter((item) =>
        moment(item?.date).format('YYYY.MM.DD') === selectedDate
      );

      if (foundMyTimeItems?.length > 1) {
        setMatchingMyTimeitems(foundMyTimeItems);
      } else {
        setMatchingMyTimeitems([]);
      }
    }
  };

  let tableId = "employeeAdmissionsTableData";
  initialTableData.sortField = "title";
  initialTableData.pageSize = 5;

  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  if (employeeAdmissions && employeeAdmissions?.columns) {
    const defValues = Object.values(employeeAdmissions?.columns);
    let myTimeActions = null;
    const newDef = defValues.map((item) => {
      if (item?.id === "myTimeActions" || item?.id === "myTimeAdminActions") {
        myTimeActions = { ...item };
        myTimeActions.changeMyTimeEnabled = changeMyTimeEnabled;
        myTimeActions.changeWorkingTimeEnabled = editWorkingTimeEnabled;
        return myTimeActions;
      }
      return item;
    });
    employeeAdmissions.columns = newDef;
  }

  const handleMoreClick = (date) => {
    setShowCalendar(false);
    const foundItem = list?.rows?.find(
      (item) =>
        new Date(item?.date).toISOString() === new Date(date).toISOString()
    );

    const selectedDate = new Date(foundItem?.start).toLocaleDateString();

    setTimeout(() => {
      const element = document.getElementById(selectedDate);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  };

  const tableDef = isMobile
    ? employeeAdmissionsIsMobile
    : employeeAdmissions;

  const filteredTableDef = {
    id: employeeAdmissions?.id,
    columns: employeeAdmissions?.columns?.filter(column =>
      !employeeAdmissionsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <OcCard label={t("MY_WORK_TIME_REPORTS")} loading={loading} error={error}>
      <MyTimeShortInfoDialog
        open={openShortInfoDialog?.state}
        text={openShortInfoDialog?.text}
        handleClose={() => setOpenShortInfoDialog({ state: false, text: "" })}
        isMobile={isMobile}
      />
      <MyTimeInfoDialog
        open={openInfoDialog?.state}
        handleClose={() => setOpenInfoDialog({ state: false, data: null })}
        data={openInfoDialog?.data}
        employeeName={employeeName}
        isMobile={isMobile}
      />
      <MyTimeNewRequestDialog
        contractCode={contractCode}
        open={openNewRequestDialog?.state}
        action={openNewRequestDialog?.action}
        data={openNewRequestDialog?.data}
        rawData={openNewRequestDialog?.rawData}
        protectedTimeRanges={openNewRequestDialog?.protectedTimeRanges}
        handleClose={handleCloseNewRequestDialog}
        list={data}
        isMobile={isMobile}
        selectedRowData={selectedRowData}
      />
      {!isMobile &&
        <Box
          id="button-group"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding="18px 0px"
        >
          <ButtonGroup>
            <Button
              id="calendar-button"
              onClick={() => setShowCalendar(true)}
              variant={!showCalendar ? "outlined" : "contained"}
              color={!showCalendar ? "default" : "primary"}
              disableElevation
            >
              {t("CALENDAR")}
            </Button>
            <Button
              id="table-button"
              onClick={() => setShowCalendar(false)}
              variant={showCalendar ? "outlined" : "contained"}
              color={showCalendar ? "default" : "primary"}
              disableElevation
            >
              {t("TABLE")}
            </Button>
          </ButtonGroup>
        </Box>
      }
      <Box padding="16px 0px">
        {!showCalendar && (
          <OcDataTable
            tableDef={tableDef}
            tableId={tableId}
            tableData={tableData}
            setTableData={setTableData}
            data={list}
            loading={loading}
            onSelectRow={isMobile ? onSelectRow : handleSelectRow} //For some reason it didn't want to work based on the established logic, that's why I decided on ternary.
            userRole="user"
            isNotExaminer={isNotExaminer}
            filteredTableDef={filteredTableDef}
            selectedRowId={selectedRowId}
            handleMoreButton={handleSelectRow}
            matchingMyTimeitems={matchingMyTimeitems}
          />
        )}
        {showCalendar && (
          <MyTimeCalendar
            date={date}
            onDateChange={handleDateChange}
            contract={contractCode}
            events={events}
            handleSelectRow={handleSelectRow}
            userRole="user"
            handleMoreClick={handleMoreClick}
            isNotExaminer={isNotExaminer}
            countTimeData={countTimeData}
          />
        )}
      </Box>
    </OcCard>
  );
};

export default MyTimeAdmissionCard;
