import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import MyDocumentsInfoCard from "my-documents/components/my-documents-info-card/my-documents-info-card";
import MyDocumentsUploaders from "my-documents/components/my-documents-uploaders/my-documents-uploaders";
import useExaminers from "my-documents/hooks/use-my-documents-uploaders";
import { Button } from "@material-ui/core";
import { InlineIcon } from "@iconify/react";
import plusIcon from "@iconify/icons-mdi/plus";
import MyDocumentsUploadFiles from "my-documents/components/my-documents-upload-files/my-documents-upload-files";
import OcPageTransition from "oc/components/oc-page-transition";

export default function MyHrDocumentsDataPanel({ isExaminer, isUser, contractCode }) {
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useExaminers({
    contractCode: contractCode,
    locale: i18n.language,
  });

  const [showNewCard, setShowNewCard] = useState(false);
  const [editDocument, setEditDocument] = useState(null);

  const handleButtonClick = () => {
    setShowNewCard(true);
  };

  const handleEditDocument = (document) => {
    setEditDocument(document);
  };

  const handleCloseEdit = () => {
    setEditDocument(null);
  };

  return (
    <OcPageTransition>
      <Box id="MyHrDocumentsDataPanel">
        {showNewCard ? (
          <Box>
            <MyDocumentsUploadFiles setShowNewCard={setShowNewCard} />
          </Box>
        ) : editDocument ? (
          <Box>
            <Box>
              <MyDocumentsInfoCard
                isExaminer={isExaminer}
                contractCode={contractCode}
                editDocument={editDocument}
                onCloseEdit={handleCloseEdit}
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <OcCard label={t("DOCUMENTS")} loading={loading}>
              <Box height="16px" />
              {isExaminer && (
                <Box display="flex" justifyContent="flex-end">
                  <Box>
                    <Button
                      id="addNewDocument"
                      name="addNewDocument"
                      variant="contained"
                      color="primary"
                      startIcon={<InlineIcon icon={plusIcon} />}
                      onClick={handleButtonClick}
                      disableElevation
                    >
                      {t("NEW_DOCUMENTS")}
                    </Button>
                    <Box height="32px" />
                  </Box>
                </Box>
              )}
              <MyDocumentsInfoCard isExaminer={isExaminer} contractCode={contractCode} onEditDocument={handleEditDocument} />
              <Box height="16px" />
            </OcCard>
            {isUser && (
              <>
                <Box height="16px" />
                <Box width="240px">
                  <OcCard
                    label={t("UPLOADERS")}
                    loading={!data && loading}
                    error={error}
                  >
                    <Box>
                      <MyDocumentsUploaders uploadersData={data} loading={loading} />
                    </Box>
                  </OcCard>
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </OcPageTransition>
  );
}
