import React from "react";
import Box from "@material-ui/core/Box";
import ChevronLeft from "@iconify/icons-mdi/chevron-left";
import ChevronRight from "@iconify/icons-mdi/chevron-right";
import CalendarToday from "@iconify/icons-mdi/calendar-today";
import ViewModuleOutline from "@iconify/icons-mdi/view-module-outline";
import ViewDayOutline from "@iconify/icons-mdi/view-day-outline";
import { InlineIcon } from "@iconify/react";
import useStyles from "./my-leave-table-toolbar.style";

const MyLeaveTableToolbar = ({
  handlePrev,
  handleNext,
  title,
  onCurrentDateChange,
  handleTable,
  handleViewDayClick,
  toolbarButton,
  isMobile
}) => {
  const iconSize = 30;
  const classes = useStyles({ isMobile });

  return (
    <Box className={classes.toolbarMainContainer}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="auto"
      >
        <InlineIcon
          icon={ChevronLeft}
          width={iconSize}
          height={iconSize}
          className={classes.leftChevron}
          onClick={handlePrev}
        />
        <Box className={classes.title}>{title}</Box>
        <InlineIcon
          icon={ChevronRight}
          width={iconSize}
          height={iconSize}
          className={classes.rightChevron}
          onClick={handleNext}
        />
      </Box>
      {!isMobile &&
        <Box display="flex">
          <InlineIcon
            icon={CalendarToday}
            width={iconSize}
            height={iconSize}
            onClick={() => {
              if (toolbarButton !== "basicTable") {
                onCurrentDateChange(new Date());
              }
            }}
            className={
              toolbarButton === "basicTable" ? classes.disabled : classes.icon
            }
            disabled={true}
          />
          <Box width="140px" />
          <InlineIcon
            icon={ViewModuleOutline}
            width={iconSize}
            height={iconSize}
            onClick={handleTable}
            className={
              toolbarButton !== "basicTable" ? classes.icon : classes.iconDisabled
            }
          />
          <Box width="24px" />
          <InlineIcon
            icon={ViewDayOutline}
            width={iconSize}
            height={iconSize}
            onClick={handleViewDayClick}
            className={
              toolbarButton !== "dailyTable" ? classes.icon : classes.iconDisabled
            }
          />
        </Box>
      }
    </Box>
  );
};

export default MyLeaveTableToolbar;
