import React from "react";
import Box from "@material-ui/core/Box";
import _ from "lodash";
import OcRequestsMessagesCard from "oc/components/oc-requests-messages-card/oc-requests-messages-card";

export default function LeaveCardMessageBox({
  leaveRequest,
  loading,
  disabled,
}) {
  let messages = leaveRequest?.messages
    ? _.cloneDeep(leaveRequest.messages)
    : [];

  return (
    <Box>
      <OcRequestsMessagesCard
        messages={messages}
        module="MyLeave"
        loading={loading}
        data={leaveRequest}
        disabled={disabled}
      />
    </Box>
  );
}
