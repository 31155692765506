import React from "react";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InlineIcon } from "@iconify/react";
import useStyles from "./application-collapse-header.style";
import { useTheme } from "@material-ui/styles";

export default function ApplicationCollapseHeader({
  handleExpandClick,
  expanded,
  icon,
  dependentName,
  dependentTaxNumber,
  taxNumberLabel,
  isMobile
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <OcErrorBoundary>
      <Box
        className={classes.filterContainer}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
          justifyContent="flex-start"
          marginLeft={isMobile ? "28px" : "0px"}
        >
          <Box
            padding={isMobile ? "10px 0px" : "10px 28px"}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <InlineIcon icon={icon} height="32px" color={theme.palette.gray60.main} />
          </Box>
          <Box fontSize="24px">{dependentName}</Box>
          <Box width="32px" />
          <Box fontSize="16px" paddingTop="4px">
            {taxNumberLabel}
          </Box>
          <Box width="16px" />
          <Box
            fontSize="16px"
            style={{ fontWeight: "bold" }}
            paddingTop="5px"
          >
            {dependentTaxNumber ? dependentTaxNumber : ""}
          </Box>
          {isMobile && <Box height="10px" />}
        </Box>
        <Box paddingRight="28px">
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            style={
              expanded
                ? {
                  padding: 0,
                  transform: "rotate(180deg)",
                }
                : {
                  padding: 0,
                }
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
    </OcErrorBoundary >
  );
}
