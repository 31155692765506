import React from "react";
import Box from "@material-ui/core/Box";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InlineIcon } from "@iconify/react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItem";
import Fade from "@material-ui/core/Fade";
import useStyles from "./oc-private-layout-sidebar-item.style";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function OcPrivateLayoutSidebarItem({
  id,
  label,
  path,
  translation,
  icon,
  badge,
  onMenuChange,
  narrowMenu,
  narrowMenuIcon,
  onChangeTemporaryDrawer,
  narrowMenuOpened,
}) {
  const { t } = useTranslation();
  let match = useRouteMatch(path);
  let history = useHistory();
  let backgroundColor = match?.isExact ? "#cc0033" : "transparent";
  let menuLabel = t(translation) || label;
  const classes = useStyles({ backgroundColor });
  const isMobile = useIsMobile();

  function handleClick() {
    if (onChangeTemporaryDrawer) {
      onChangeTemporaryDrawer(false);
    }
    history.push(path);
  }

  return (
    <Box id={id} onClick={handleClick} className={classes.link}>
      <ListItem
        id={id}
        button
        className={narrowMenu ? classes.link : classes.linkItem}
        style={{ backgroundColor: backgroundColor }}
        onClick={() => onMenuChange(id)}
      >
        {icon && (narrowMenuOpened || isMobile) && (
          <Box minWidth="24px" maxWidth="24px">
            <InlineIcon icon={icon} width="24px" />
          </Box>
        )}
        {narrowMenu && narrowMenuIcon && !narrowMenuOpened && (
          <Box minWidth="24px" maxWidth="24px">
            <Fade in={narrowMenu && !narrowMenuOpened}>
              <InlineIcon icon={narrowMenuIcon} width="24px" color="#A6ADAF" />
            </Fade>
          </Box>
        )}
        {!icon && !narrowMenu && (
          <Box width="24px" className={classes.emptyBox} />
        )}
        <ListItemText>{menuLabel}</ListItemText>
        {badge && <Box marginRight="28px">{badge}</Box>}
      </ListItem>
    </Box>
  );
}
