import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Currency from "oc/components/oc-currency/oc-currency";

const MyCafeteriSubaccountsTable = ({ label, value }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Box
        flexGrow={1}
        borderBottom="1px solid #5E6366"
        paddingTop="10px"
        paddingBottom="10px"
      >
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box
        width="85px"
        textAlign="right"
        borderBottom="1px solid #5E6366"
        paddingTop="10px"
        paddingBottom="10px"
      >
        <Typography variant="body1">
          <Currency value={value} />
        </Typography>
      </Box>
    </Box>
  );
};


export default MyCafeteriSubaccountsTable;
