import { useQuery } from "@apollo/react-hooks";
import { GET_FILTERED_LIST } from "graphql/queries";

export default function useFilteredList({
  page,
  pageSize,
  isActive,
  roleFilters,
}) {
  const { data, error, loading, refetch } = useQuery(GET_FILTERED_LIST, {
    fetchPolicy: "cache-and-network",
    variables: { page, pageSize, isActive, roleFilters },
  });

  const list = data?.filteredList?.rows || [];

  const parsedList = list.map((item) => {
    const newItem = {
      employee: item.employee.code,
      contract: item.code,
      fullname: `${item.employee.prefixName} ${item.employee.lastName} ${item.employee.firstName} ${item.employee.middleName} ${item.employee.nameSuffix}`.trim(),
      orgUnit: item.orgUnit.name,
      jobClass: item.jobClass.name,
      _id: item._id
    };
    return newItem;
  });

  const result = {
    page: data?.filteredList?.page,
    pageSize: data?.filteredList?.pageSize,
    total: data?.filteredList?.total,
    totalPages: data?.filteredList?.totalPages,
    rows: parsedList,
  };

  return {
    data: result,
    error,
    loading,
    refetch,
  };
}
