import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MyCafeteriaVbkApplicationTable from "my-cafeteria/components/my-cafeteria-vbk-application-table";
import useCurrentCafeteriaStatement from "my-cafeteria/hooks/use-current-cafeteria-statement";
import Currency from "oc/components/oc-currency";
import PartnerLogo from "oc/components/oc-private-layout-sidebar-partner-logo";
import { useTranslation } from "react-i18next";
import MyCafeteriaInfoLine from "../my-cafeteria-info-line";
import MyCafeteriaStatementTitle from "../my-cafeteria-statement-title";
import MyCafeteriaSignatureBlock from "../my-cafeteria-signature-block";

const MyCafeteriaVbkApplicationCardPrint = ({
  applicationDate,
  employeeName,
  idNumber,
  contractCode,
  currentYear,
}) => {
  const { t } = useTranslation();
  const { data: statement } = useCurrentCafeteriaStatement({
    contractCode,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box id="upper-component" paddingBottom="48px">
        <MyCafeteriaStatementTitle label={t("VBKSTATEMENT")} />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Box id="data-block">
            <MyCafeteriaInfoLine
              label={t("CALENDAR_MESSAGES_DATE")}
              value={applicationDate}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_EMPLOYEE_NAME")}
              value={employeeName}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_TAX_ID_SIGN_ID")}
              value={idNumber}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_CONTRACT_CODE")}
              value={contractCode}
            />
          </Box>
          <Box
            sx={{
              display: "none",
              displayPrint: "block",
              paddingRight: "85px",
            }}
          >
            <PartnerLogo />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box width="221px" textAlign="right">
            <Typography variant="h5">{t("BUDGET")}</Typography>
          </Box>
          <Box width="16px" />
          <Box>
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
              }}
            >
              <Currency value={statement?.allAmount} />
            </Typography>
          </Box>
        </Box>
        <Box height="45px" />
        <Box padding="0px 17px">
          <MyCafeteriaVbkApplicationTable />
        </Box>
      </Box>
      <MyCafeteriaSignatureBlock statement="vbk" />
    </Box>
  );
};

export default MyCafeteriaVbkApplicationCardPrint;
