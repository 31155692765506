import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
  },
  innerContainer: ({ isMobile }) => ({
    backgroundColor: theme.palette.white.main,
    height: isMobile ? "unset" : "50px",
    display: "flex",
    flexDirection: isMobile ? "column" : "unset",
  }),
  textBold: {
    marginRight: "6px",
    fontWeight: 700,
    fontSize: "14px",
  },
  textRed: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
