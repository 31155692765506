import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useContractsByEmployee from "hooks/use-myhr-contracts-by-employee";
import useClientState from "hooks/use-oc-client-state";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useStyles from "./oc-private-layout-app-bar-contract-select.style";
import useCurrentUserState from "hooks/use-current-user-state";

export default function OcPrivateLayoutAppBarContractSelect({ isMobile }) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles({ isMobile });

  let {
    employeeCode,
    setData: setCurrentUser,
  } = useCurrentUserState();

  const { data: contractsData } = useContractsByEmployee({
    employee: employeeCode,
  });

  const [, setActiveMenu] = useClientState("activeMenu", "mainMenu");

  const { setData: setCurrentUserState } = useCurrentUserState();

  const [contractCode, setContractCode] = useState("");

  useEffect(() => {
    if (contractsData && contractsData.length >= 1) {
      let sortedContracts = [...contractsData].sort((a, b) => {
        return (a?.employmentType?.code || Infinity) - (b?.employmentType?.code || Infinity);
      });

      let selectedId = sortedContracts[0].code;

      if (!contractCode) {
        setContractCode(selectedId);
        setCurrentUser({ contractCode: selectedId });
        setCurrentUserState({ contractCode: selectedId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractsData]);

  const handleChange = event => {
    setContractCode(event.target.value);
    setCurrentUser({ contractCode: event.target.value });
    setActiveMenu("mainMenu");
    history.push("/");
  };

  if (!contractsData || contractsData.length === 0)
    return (
      <Box style={{ paddingRight: "16px", color: "#CC0033" }}>
        {t("CONTRACT_DATA_NOT_AVAIABLE")}
      </Box>
    );

  if (contractsData && contractsData?.length < 2) return <Box></Box>;

  return (
    <Box marginRight={isMobile ? "24px" : "16px"} marginLeft={isMobile ? "16px" : "0px"}>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.formControl}
      >
        <InputLabel id="demo-simple-select-outlined-label">
          {t("GET_CONTRACT")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          defaultValue=""
          value={contractCode}
          onChange={handleChange}
          label={t("Contract.contract")}
          inputProps={{
            classes: {
              root: classes.selectInput,
            },
          }}
        >
          {contractsData?.map((item, index) => {
            let label = item?.jobClass?.name;
            if (
              item?.employmentType?.code === "5" ||
              item?.employmentType?.code === "6"
            ) {
              label = t("CONTRACT_OF_SERVICES");
            }
            return (
              <MenuItem
                key={`${item?.code} ${index}`}
                value={item.code}
              >
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
