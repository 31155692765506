import React from "react";
import Box from "@material-ui/core/Box";

export default function OcTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          style={{
            padding: "16px 0px",
          }}
          p={3}
        >
          {children}
        </Box>
      )}
    </div>
  );
}
