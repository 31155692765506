import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import useCompanies from "data/use-companies";
import useEmailsByCompany from "hooks/use-emails-by-company";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import TextField from "@material-ui/core/TextField";
import { InlineIcon } from "@iconify/react";
import emailIcon from "@iconify/icons-mdi/email";
import InputAdornment from "@material-ui/core/InputAdornment";
import useProperty from "hooks/use-oc-property";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";
import { UPSERT_EMAILS_BY_COMPANY } from "graphql/mutations";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import useOcSnackbar from "hooks/use-oc-snackbar";

const EmailByCompanyForm = ({
  type,
  setLoading,
  emailByCompanyState,
  emailByCompany,
  isMobile
}) => {
  const { t } = useTranslation();
  const { setOpenMessage } = useOcSnackbar();
  const [values, setValues] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [upsertEmails] = useMutation(UPSERT_EMAILS_BY_COMPANY, {
    onCompleted: () => {
      setLoading(false);
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
    onError: (error) => {
      console.log(error);
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
  });

  const { data: emailProperty } = useProperty({
    resource: "myLeave",
    key: type,
  });

  const { data: emailList } = useEmailsByCompany();
  const { companies } = useCompanies();

  const emails = emailList.filter((item) => item.type === type);

  const emailsParsed = emails.map((item) => {
    let company = companies.find(
      (companyItem) => companyItem.code === item.companyCode
    );
    return {
      code: item.companyCode,
      address: item.address,
      name: company ? company.name : "",
    };
  });

  const list = companies.map((item) => {
    const isExist = emailsParsed.find(
      (emailItem) => emailItem.code === item.code
    );
    if (isExist) return isExist;
    return {
      code: item.code,
      address: emailProperty || "",
      name: item.name,
    };
  });

  const clearValues = (list) => {
    return list.map((item) => {
      const value = {
        code: item.code,
        value: item.address,
        error: false,
        errorText: "",
        valid: false,
      };
      const isExist = values.find((valueItem) => valueItem.code === item.code);
      if (!isExist) {
        setValues((prevState) => [...prevState, value]);
      }
      return item;
    });
  };

  clearValues(list);
  const handleCancel = () => {
    setValues([]);
    clearValues(list);
  };

  const handleChange = (e) => {
    let newState = [...values];
    newState.map((item) => {
      if (item.code === e.code) {
        item.value = e.value;
      }
      return item;
    });
    setValues(newState);
  };

  const clearErrors = (e) => {
    let newState = [...values];
    newState.map((item) => {
      if (item.code === e.code) {
        item.error = e.error;
        item.errorText = e.errorText;
      }
      return item;
    });
    setValues(newState);
  };

  function validateEmail(mail) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }

  const validateValues = () => {
    let isValid = true;
    let newState = [...values];
    newState.map((item) => {
      if (!item.value) {
        isValid = false;
        item.error = true;
        item.errorText = t("ERROR_MESSAGE_EMAIL_IS_REQUIRED");
      }
      if (!validateEmail(item.value)) {
        isValid = false;
        item.error = true;
        item.errorText = t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT");
      }
      return item;
    });
    setValues(newState);
    return isValid;
  };

  const handleSave = () => {
    const isValid = validateValues();
    if (!isValid) {
      setShowConfirmDialog(false);
      return;
    }

    const params = values.map((item) => {
      return {
        companyCode: item.code,
        type: type,
        address: item.value,
      };
    });
    setLoading(true);
    upsertEmails({
      variables: {
        emails: params,
      },
    });
    setShowConfirmDialog(false);
    return;
  };

  let disabled = true;
  if (emailByCompanyState !== emailByCompany) {
    disabled = false;
  }
  if (emailByCompanyState && emailsParsed.length === 0) {
    disabled = false;
  }
  list.map((item) => {
    const isChanged = values.find(
      (value) => value.code === item.code && value.value !== item.address
    );
    if (isChanged) {
      disabled = false;
    }
    return item;
  });

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <Box>
        {list.map((item, index) => {
          const isLastIndex = index === list.length - 1;
          let valueItem = values.find(
            (valueItem) => valueItem.code === item.code
          );
          let value = valueItem?.value || "";
          let error = valueItem?.error;
          let errorText = valueItem?.errorText;
          return (
            <Box key={item.code}>
              <TextField
                style={{ width: isMobile ? "100%" : "432px", paddingLeft: isMobile ? "0px" : "16px" }}
                id={"email-input-field-" + item.code}
                label={item.code + " " + item.name}
                variant="outlined"
                value={value}
                onChange={(e) =>
                  handleChange(
                    {
                      ...valueItem,
                      code: item.code,
                      value: e.target.value,
                    },
                    index
                  )
                }
                error={error}
                helperText={error && errorText}
                onFocus={() => {
                  clearErrors({
                    ...valueItem,
                    error: false,
                    errorText: "",
                  });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" height="24px">
                      <InlineIcon icon={emailIcon} height="24px" />
                    </InputAdornment>
                  ),
                }}
              />
              {!isLastIndex && <Box height="36px" />}
            </Box>
          );
        })}
      </Box>
      <OcCardButtonBar
        handleRightButton={handleCancel}
        rightButtonText={t("CANCEL")}
        handlePrimaryButton={() => setShowConfirmDialog(true)}
        primaryButtonText={t("SAVE")}
        primaryButtonDisabled={disabled}
      />
    </>
  );
};

export default EmailByCompanyForm;
