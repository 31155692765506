import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import useProperty from "hooks/use-oc-property";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import useCustomerLicences from "hooks/use-customer-licences";
import useOcSnackbar from "hooks/use-oc-snackbar";
import OcSettingsDivider from "oc/components/oc-settings-divider/oc-settings-divider";
import OcSettingSwitch from "oc/components/oc-setting-switch/oc-setting-switch";

const MyLeaveGeneralSettingsCard = ({ isMobile }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { setOpenMessage } = useOcSnackbar();

  const { licences } = useCustomerLicences();
  const myLeaveEmployeeRequestsEnabled = licences?.myLeaveEmployeeRequests;

  const { data: createLeaveRequestIsEnabledProperty, refetch: refetchCreateLeaveRequest } = useProperty({
    resource: "myLeave",
    key: "createLeaveRequestIsEnabled",
  });

  const { data: approveAllLeaveRequestsEnabledData, refetch: refetchApproveAllLeaveRequests } = useProperty({
    resource: "myLeave",
    key: "approveAllLeaveRequestsEnabled",
  });

  const [settings, setSettings] = useState({
    createLeaveRequestIsEnabled: false,
    approveAllLeaveRequestsEnabled: false,
  });

  const [prevSettings, setPrevSettings] = useState(settings);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    setSettings({
      createLeaveRequestIsEnabled: createLeaveRequestIsEnabledProperty === "1",
      approveAllLeaveRequestsEnabled: approveAllLeaveRequestsEnabledData === "true",
    });
    setPrevSettings({
      createLeaveRequestIsEnabled: createLeaveRequestIsEnabledProperty === "1",
      approveAllLeaveRequestsEnabled: approveAllLeaveRequestsEnabledData === "true",
    });
  }, [createLeaveRequestIsEnabledProperty, approveAllLeaveRequestsEnabledData]);

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onError({ ...err }) {
      setLoading(false);
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
    onCompleted() {
      refetchCreateLeaveRequest();
      refetchApproveAllLeaveRequests();
      setLoading(false);
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
  });

  const handleChange = (key) => (event) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: event.target.checked,
    }));
  };

  const handleSave = () => {
    setLoading(true);
    const updatePromises = [];

    if (settings.createLeaveRequestIsEnabled !== prevSettings.createLeaveRequestIsEnabled) {
      updatePromises.push(
        updateProperty({
          variables: {
            resource: "myLeave",
            key: "createLeaveRequestIsEnabled",
            value: settings.createLeaveRequestIsEnabled ? "1" : "0",
          },
        })
      );
    }

    if (settings.approveAllLeaveRequestsEnabled !== prevSettings.approveAllLeaveRequestsEnabled) {
      updatePromises.push(
        updateProperty({
          variables: {
            resource: "myLeave",
            key: "approveAllLeaveRequestsEnabled",
            value: settings.approveAllLeaveRequestsEnabled ? "true" : "false",
          },
        })
      );
    }

    Promise.all(updatePromises).then(() => {
      setPrevSettings(settings);
      setShowConfirmDialog(false);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleCancel = () => {
    setSettings(prevSettings);
  };

  const handleDialogCancel = () => {
    setShowConfirmDialog(false);
  };

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("GENERAL_SETTINGS")} loading={loading}>
        <OcSettingSwitch
          id="createLeaveRequestIsEnabled"
          label={t("ENABLE_LEAVE_REQUESTS")}
          info={t("ENABLE_LEAVE_REQUESTS_INFO")}
          value={settings.createLeaveRequestIsEnabled}
          onChange={handleChange("createLeaveRequestIsEnabled")}
          disabled={loading || !myLeaveEmployeeRequestsEnabled}
          isMobile={isMobile}
        />
        <Box height={isMobile ? "0px" : "24px"} />
        <OcSettingsDivider />
        <Box height="12px" />
        <OcSettingSwitch
          id="approveAllLeaveRequestsEnabled"
          label={t("MY_TIME_APPROVE_ALL_REQUESTS_ALLOWED")}
          info={t("MY_TIME_APPROVE_ALL_REQUESTS_ALLOWED_INFO")}
          value={settings.approveAllLeaveRequestsEnabled}
          onChange={handleChange("approveAllLeaveRequestsEnabled")}
          disabled={loading || !myLeaveEmployeeRequestsEnabled}
          isMobile={isMobile}
        />
        <OcCardButtonBar
          handleRightButton={handleCancel}
          rightButtonText={t("CANCEL")}
          handlePrimaryButton={() => setShowConfirmDialog(true)}
          primaryButtonText={t("SAVE")}
          primaryButtonDisabled={
            (settings.createLeaveRequestIsEnabled === prevSettings.createLeaveRequestIsEnabled &&
              settings.approveAllLeaveRequestsEnabled === prevSettings.approveAllLeaveRequestsEnabled) ||
            loading
          }
          buttonLabel={t("SAVE")}
        />
      </OcCard>
    </>
  );
};

export default MyLeaveGeneralSettingsCard;
