import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Dialog,
  DialogTitle,
  Box,
  Button,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/react-hooks";
import { v4 as uuid } from "uuid";
import MyHrDropzoneEditor from "my-hr/components/my-hr-dropzone-editor";
import OcFormEditorTextInput from "./components/oc-form-editor-text-input";
import OcFormEditorValidFromSelector from "./components/oc-form-editor-valid-from-selector";
import OcFormEditorMessageTextInput from "./components/oc-form-editor-message-text-input";
import OcFormEditorGenderSelector from "./components/oc-form-editor-gender-selector";
import OcFormEditorMaritalStatusSelector from "./components/oc-form-editor-marital-status-selector";
import OcFormEditorCountrySelector from "./components/oc-form-editor-country-selector";
import OcFormEditorNationalitySelector from "./components/oc-form-editor-nationality-selector";
import OcFormEditorAddressSelector from "./components/oc-form-editor-address-selector";
import OcFormEditorStreetTypeSelector from "./components/oc-form-editor-street-type-selector";
import OcFormEditorZipSelector from "./components/oc-form-editor-zip-selector";
import { getAddressType, getFormData, getFormValues } from "./utils";
import _ from "lodash";
import useOcSnackbar from "hooks/use-oc-snackbar";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import { InlineIcon } from "@iconify/react";
import CloseIcon from "@iconify/icons-mdi/close";

const OcFormEditor = ({
  open,
  def,
  data,
  onClose,
  onShowFormEditor,
  modifier,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { isAddressType, isNewAddress } = getAddressType({ def, data });

  const { setOpenMessage } = useOcSnackbar();

  let { dialogLabel } = def;
  if (def?.type === "invalidateAddress" && modifier) {
    dialogLabel = t(`invalidate_${modifier}`.toUpperCase());
  }

  const { initialValues, validationSchema } = getFormValues({
    def,
    data,
    isAddressType,
  });

  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (
        modifier &&
        (modifier === "permanentAddress" ||
          modifier === "temporaryAddress" ||
          modifier === "serviceAddress")
      ) {
        const uploadData = {
          locale: i18n.language,
          typeGroup: "invalidateAddress",
          typeGroupId: uuid(),
          values: [
            {
              type: modifier,
              oldValue: "",
              newValue: "",
            },
          ],
          initialMessage: values.initialMessage,
          validFrom: values.validFrom,
        };
        createChangeRequest({ variables: uploadData });
        return;
      }

      const isSendTypeGroup =
        def.type === "name" ||
        def.type === "mothersName" ||
        def.type === "phones" ||
        def.type === "emails" ||
        isAddressType;

      const { data: formValues } = getFormData({ def, data, values });
      const uploadData = {
        locale: i18n.language,
        typeGroup: isSendTypeGroup ? def.type : "",
        typeGroupId: isSendTypeGroup ? uuid() : "",
        values: formValues,
        initialMessage: values.initialMessage,
        validFrom: values.validFrom,
      };
      createChangeRequest({ variables: uploadData });
    },
    validationSchema: Yup.object(validationSchema),
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  const [createChangeRequest] = useMutation(def.mutation, {
    onCompleted: (data) => {
      formik.setSubmitting(false);
      //setSaveButtonDisabled(false);
      setOpenMessage({
        type: "success",
        message: t("USER_UPDATED"),
      });
      onClose(false);
      //reset();
    },
    onError: (error) => {
      console.log({ error });
      formik.setSubmitting(false);
      //setSaveButtonDisabled(false);
      setOpenMessage({
        type: "error",
        message: t("APPROVE_LEAVE_REQUEST_FAILED"),
      });
      //reset();
    },
  });

  const { dirty, isSubmitting } = formik;

  const getChangedValues = (values, initialValues) => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value;

      if (hasChanged) {
        acc[key] = value;
      }

      return acc;
    }, {});
  };

  const changedValues = Object.keys(
    getChangedValues(formik.values, initialValues)
  );

  let saveButtonDisabled =
    !dirty ||
    isSubmitting ||
    _.isEqual(changedValues, ["validFrom", "initialMessage"]);
  if (def?.type === "invalidateAddress") {
    saveButtonDisabled = false;
  }

  return (
    <Dialog
      fullScreen={isMobile ? true : false}
      open={open}
      onClose={
        (onClose,
          (event, reason) => {
            if (reason !== "backdropClick") {
              return false;
            }
          })
      }
      aria-labelledby="oc-form-editor"
      disableEscapeKeyDown
      scroll="body"
      PaperProps={{
        style: {
          padding: "0px 24px 26px 24px",
          width: isMobile ? "auto" : 552,
        },
      }}
    >
      {isMobile && (
        <Box
          position="absolute"
          right="16px"
          top="16px"
        >
          <InlineIcon
            icon={CloseIcon}
            width="24px"
            onClick={() => {
              onClose(false);
            }}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
      )}
      <DialogTitle id="oc-form-editor-title" style={{ padding: "16px 0px" }}>
        <Box
          style={{
            fontSize: "20px",
            letterSpacing: "0.15px",
            padding: "10px 16px",
            color: theme.palette.darkBase.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            textAlign: isMobile ? "center" : "unset"
          }}
        >
          <Typography variant="h6">
            {isNewAddress ? t("ADD_NEW_ADDRESS") : t(dialogLabel)}
          </Typography>
        </Box>
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <Box display="flex" flexDirection="column" width="100%">
          {def?.items?.map((item) => {
            const { type, name, label } = item;
            const { values, errors, handleChange, setFieldValue } = formik;
            const props = {
              key: name,
              name,
              label,
              value: values[name],
              onChange:
                type === "datePicker" ||
                  type === "country" ||
                  type === "nationality" ||
                  type === "gender" ||
                  type === "maritalStatus" ||
                  type === "streetType" ||
                  type === "zip" ||
                  type === "city" ||
                  modifier === "phones"
                  ? setFieldValue
                  : handleChange,
              error: errors[name],
            };
            if (item.inputAdornment) {
              props.inputAdornment = item.inputAdornment;
            }
            const isHungary = formik?.values?.country === "HUN" ? true : false;

            switch (type) {
              case "datePicker":
                return <OcFormEditorValidFromSelector {...props} def={def} />;
              case "textarea":
                return <OcFormEditorMessageTextInput {...props} />;
              case "gender":
                return <OcFormEditorGenderSelector {...props} />;
              case "maritalStatus":
                return <OcFormEditorMaritalStatusSelector {...props} />;
              case "nationality":
                return <OcFormEditorNationalitySelector {...props} />;
              case "country":
                return <OcFormEditorCountrySelector {...props} />;
              case "addressSelector":
                const addressType = def?.type;
                return (
                  <OcFormEditorAddressSelector
                    addressType={addressType}
                    isNewAddress={isNewAddress}
                    data={data}
                    onShowFormEditor={onShowFormEditor}
                    {...props}
                    isMobile={isMobile}
                  />
                );
              case "streetType":
                if (isHungary) {
                  return (
                    <OcFormEditorStreetTypeSelector
                      isHungary={isHungary}
                      {...props}
                      isMobile={isMobile}
                    />
                  );
                } else {
                  props.value = values[name].name;
                  const handleStreetTypeChange = (e) => {
                    setFieldValue("streetType", e.target.value);
                  };
                  props.onChange = handleStreetTypeChange;
                  return <OcFormEditorTextInput {...props} />;
                }
              case "districtCode":
                const isBudapest = formik?.values?.city.substring(0, 8) === "Budapest";
                if (isBudapest) {
                  return (
                    <OcFormEditorTextInput
                      {...props}
                      modifier={modifier || null}
                    />);
                } else {
                  return null;
                }
              case "postalCodeSelector":
                if (isHungary) {
                  const handleChange = (key, item) => {
                    setFieldValue("city", item?.name);
                    setFieldValue("zip", item?.code);
                  };
                  props.onChange = handleChange;
                  props.isNewAddress = def?.isNewAddress;
                  return (
                    <OcFormEditorZipSelector isHungary={isHungary} {...props} />
                  );
                } else {
                  const handleTextChange = (e) => {
                    setFieldValue(e.target.name, e.target.value);
                  };
                  props.onChange = handleTextChange;
                  return <OcFormEditorTextInput {...props} />;
                }

              default:
                return (
                  <OcFormEditorTextInput
                    {...props}
                    modifier={modifier || null}
                  />
                );
            }
          })}
        </Box>
        <Box height="20px" />
        <MyHrDropzoneEditor type={def?.type} />
        <Box
          style={{
            borderBottom: "1px solid #C6CACC",
            width: isMobile ? "auto" : "551px",
          }}
        />
        <Box width="100%">
          <Box paddingTop="36px">
            <Button
              id="my-hr-user-name-data-editor-save-button"
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              disabled={saveButtonDisabled}
              type="submit"
            >
              {t("SAVE")}
            </Button>
          </Box>
          {/*
          {isAddressType && !def?.isNewAddress && (
            <Box paddingTop="36px">
              <Button
                fullWidth
                style={{
                  color: theme.palette.primary.main,
                }}
                onClick={() => {
                  handleCancel();
                  onShowFormEditor("invalidateAddress", def?.type);
                }}
              >
                {t("ADDRESS_INVALIDATION")}
              </Button>
            </Box>
          )}
              */}
          <Box paddingTop="36px">
            <Button
              id="my-hr-user-name-data-editor-cancel-button"
              text={t("Common.cancel")}
              fullWidth
              onClick={handleCancel}
            >
              {t("Common.cancel")}
            </Button>
          </Box>
        </Box>
      </form>
    </Dialog>
  );
};

export default OcFormEditor;
