import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import useEmployees from "hooks/use-myhr-employees";
import Table from "oc/components/oc-data-table";
import { employees, employeesIsMobile } from "my-hr/configs/my-hr-table-defs";

export default function EmployeeList({
  tableId,
  tableData,
  setTableData,
  onSelectRow,
  isMobile
}) {
  const { t } = useTranslation();
  const [selectedRowId, setSelectedRowId] = useState(null);

  const { data, loading } = useEmployees({
    sortField: tableData?.sortField,
    sortDirection: tableData?.sortDirection,
    searchField: tableData?.searchField,
    searchText: tableData?.searchText,
    page: tableData?.page,
    pageSize: tableData?.pageSize,
  });

  const tableDef = isMobile ? employeesIsMobile : employees;

  const filteredTableDef = {
    id: employees?.id,
    columns: employees?.columns?.filter(column =>
      !employeesIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  const onSelectRowHandler = (row) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === row?._id ? null : row?._id);
      setTableData({
        ...tableData,
        selectedRowValue: row?.code
      });
    } else {
      onSelectRow(row);
    }
  };

  return (
    <OcCard label={t("SELECT_EMPLOYEE")}>
      <Table
        data={data}
        loading={loading && data?.rows?.length === 0}
        tableDef={tableDef}
        tableData={tableData}
        setTableData={setTableData}
        onSelectRow={onSelectRowHandler}
        tableId={tableId}
        pagination={true}
        filteredTableDef={filteredTableDef}
        selectedRowId={selectedRowId}
      />
    </OcCard>
  );
}
