import React from "react";
import { useParams } from "react-router-dom";
import useLeaveRequest from "hooks/use-my-leave-leave-request";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CalendarDay from "my-leave/components/my-leave-calendar-day";
import { InlineIcon } from "@iconify/react";
import ArrowIcon from "@iconify/icons-mdi/arrow-right-bold";
import LeaveState from "my-leave/components/my-leave-state";
import _ from "lodash";
import ConfirmDialog from "my-leave/components/my-leave-confirm-dialog";
import { useMutation } from "@apollo/react-hooks";
import useClientState from "hooks/use-oc-client-state";
import { useHistory } from "react-router-dom";
import OcPageTransition from "oc/components/oc-page-transition";
import useStyles from "./my-leave-my-leave-request-page.style";
import { DELETE_LEAVE_REQUEST } from "graphql/mutations";
import useEmployee from "hooks/use-myhr-employee";
import getFullName from "oc/utils/get-fullname";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import OcRequestsMessagesCard from "oc/components/oc-requests-messages-card/oc-requests-messages-card";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";

export default function MyLeaveMyLeaveRequestPage() {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  let { id } = useParams();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  const [newMessageText] = useClientState("newMessageText", "");


  const { leaveRequest, leaveRequestLoading } = useLeaveRequest({
    _id: id,
  });

  const { data: approvedCode } = useEmployee({ employeeId: leaveRequest?.approvedBy });
  const { data: rejectedCode } = useEmployee({ employeeId: leaveRequest?.rejectedBy });


  const [removeRequest] = useMutation(DELETE_LEAVE_REQUEST);

  let messages = leaveRequest?.messages
    ? _.cloneDeep(leaveRequest.messages)
    : [];

  const [showConfirmDialog, setShowConfirmDialog] = useClientState(
    "confirmDialogState",
    false
  );
  const handleOpenConfirmDialog = () => {
    setShowConfirmDialog(true);
  };
  const handleCloseConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const handleRemoveRequest = async () => {
    let variables = {
      _id: id,
      locale: i18n.language,
    };
    if (newMessageText) {
      variables.message = newMessageText;
    }

    await removeRequest({ variables });
    handleClose();
  };

  const handleClose = () => {
    history.push("/my-leave/my-requests");
  };

  let loading = leaveRequestLoading && !leaveRequest;

  let showNewMessageBox = leaveRequest?.state !== "recorded";

  const getCardTitle = ({ leaveRequest }) => {
    if (
      leaveRequest?.type === "leave" ||
      leaveRequest?.leaveType === "A" ||
      leaveRequest?.leaveType === "N"
    ) {
      return t("LEAVE");
    }
    if (
      leaveRequest?.leaveType === "O" ||
      leaveRequest?.leaveType === "J" ||
      leaveRequest?.leaveType === "I"
    ) {
      return t("ABSENCE_WORK");
    }
    if (leaveRequest?.type === "absence") {
      return t("ABSENCE");
    }
  };

  return (
    <OcPageTransition>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          padding={isMobile ? "16px 0px 0px 0px" : "16px"}
        >
          <Box width={isMobile ? "100%" : "600px"}>
            <Card elevation={0} style={{ borderRadius: "8px" }}>
              <Box
                className={classes.title}
                id="show-leave-request-dialog-title"
              >
                {getCardTitle({ leaveRequest })}
              </Box>
              <Box className={classes.divider} />
              <Box padding="8px 24px">
                <Box className={classes.requestData}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: isMobile ? "space-evenly" : "space-between",
                      alignItems: "center"
                    }}
                  >
                    <CalendarDay
                      date={leaveRequest?.startDate}
                      loading={loading}
                    />
                    <Box className={classes.arrowContainer}>
                      <InlineIcon
                        icon={ArrowIcon}
                        color={
                          loading
                            ? theme.palette.gray30.main
                            : theme.palette.gray60.main
                        }
                      />
                    </Box>
                    <CalendarDay
                      date={leaveRequest?.endDate}
                      loading={loading}
                    />
                  </Box>
                  {isMobile && <Box height="24px" />}
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-around"
                  >
                    <Box className={classes.leaveTypeContainer}>
                      <Box>
                        <span
                          className={
                            loading
                              ? classes.leaveTypeTextLoading
                              : classes.leaveTypeText
                          }
                        >
                          {t(`Leave.${leaveRequest?.leaveType}`)}
                        </span>
                      </Box>
                      <Box>
                        <span
                          className={
                            loading
                              ? classes.workDaysTextLoading
                              : classes.workDaysText
                          }
                        >
                          {leaveRequest?.effectiveWorkDays.toLocaleString()}{" "}
                          {t("Leave.workday")}
                        </span>
                      </Box>
                    </Box>
                    <Box className={classes.rightContainer}>
                      <Box className={classes.stateContainer}>
                        <Box
                          className={
                            loading
                              ? classes.createdAtTextLoading
                              : classes.createdAtText
                          }
                        >
                          {new Date(leaveRequest?.createdAt).toLocaleDateString()}
                        </Box>
                        {leaveRequest?.state === "approved" &&
                          <Box fontSize="9px" paddingTop="1px" style={{ color: "#919699" }}>
                            {getFullName(approvedCode)}
                          </Box>}
                        {leaveRequest?.state === "rejected" &&
                          <Box fontSize="9px" paddingTop="1px" style={{ color: "#919699" }}>
                            {getFullName(rejectedCode)}
                          </Box>
                        }
                        <Box height="2px" />
                        <LeaveState
                          label={t(`Leave.${leaveRequest?.state}`)}
                          state={leaveRequest?.state}
                          loading={loading}
                          variant="body2"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {showNewMessageBox && (
                  <OcRequestsMessagesCard
                    messages={messages}
                    module="MyLeave"
                    loading={loading}
                    data={leaveRequest}
                  />
                )}
                <OcCardButtonBar
                  leftButtonText={t("Leave.removeRequest")}
                  leftButtonColor={leaveRequest?.state === "requested" && "primary"}
                  leftButtonDisabled={loading}
                  handleLeftButton={handleOpenConfirmDialog}
                  leftButtonId="my-leave-my-leave-request-page-delete-button"
                  handleRightButton={handleClose}
                  rightButtonText={t("CANCEL")}
                  handlePrimaryButton={() => setShowConfirmDialog(true)}
                  rightButtonId="my-leave-my-leave-request-page-cancel-button"
                />
              </Box>
              <Box height="16px" />
            </Card>
            <Box height="16px" />
          </Box>
        </Box>
        <ConfirmDialog
          showDialog={showConfirmDialog}
          closeDialog={handleCloseConfirmDialog}
          onConfirm={handleRemoveRequest}
          title={
            leaveRequest?.type === "leave"
              ? t("REVOKE_LEAVE_REQUEST")
              : t("REVOKE_REMOTE_WORK_REQUEST")
          }
          children={t("REVOKE_LEAVE_REQUEST_CONFIRM")}
          confirmLabel={t("Leave.removeRequest")}
          cancelLabel={t("Common.cancel")}
        />
      </Box>
    </OcPageTransition >
  );
}
