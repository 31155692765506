import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import useStyles from "../my-leave-leave-base-card/my-leave-leave-base-card.style";

const MyLeaveBar = ({ label, id, requested, fulfilled, available, all }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  function RequestableTooltip({ label }) {
    return (
      <Box className={classes.leaveTooltip}>
        <span>{`${t("Leave.requestable")}: `}</span>
        {available?.toLocaleString()}
      </Box>
    );
  }

  function RequestedTooltip({ label }) {
    return (
      <Box className={classes.leaveTooltip}>
        <span>{`${label}: `}</span>
        {requested?.toLocaleString()}
      </Box>
    );
  }

  function FulfilledTooltip({ label }) {
    return (
      <Box className={classes.leaveTooltip}>
        <span>{`${label}: `}</span>
        {fulfilled?.toLocaleString()}
      </Box>
    );
  }

  let availableLeavePercentage = (
    (100 * Math.abs(available || 0)) /
    all
  ).toFixed(2);

  let requestedLeavePercentage = (
    (100 * Math.abs(requested || 0)) /
    all
  ).toFixed(2);

  let fulfilledLeavePercentage = (
    (100 * Math.abs(fulfilled || 0)) /
    all
  ).toFixed(2);

  return (
    <Box>
      <Box height="16px" />
      <Grid
        item
        sm={6}
        md={6}
        lg={12}
        style={{
          padding: 8,
          backgroundColor: "#E3E5E5",
          borderRadius: 8,
          maxWidth: "100%"
        }}
      >
        <Box className={classes.leaveTitle}>
          {t(label)} ({all?.toLocaleString()})
        </Box>

        <Box id={id} className={classes.leaveLineChart}>
          <Tooltip
            label={t(label)}
            title={<RequestableTooltip label={t(label)} value={available} />}
            placement="top"
          >
            <Box
              className={classes.leaveTooltipLeaveTitleLine}
              width={`${availableLeavePercentage}%`}
            >
              <Box className={classes.leaveTooltipLeaveTitle}>
                {t("Leave.requestable")}
              </Box>
              <Box
                className={
                  requested === 0 && fulfilled === 0
                    ? classes.availableLeaveDaysEdited
                    : classes.availableLeaveDays
                }
              >
                {available?.toLocaleString()}
              </Box>
            </Box>
          </Tooltip>
          <Tooltip
            title={<RequestedTooltip label={t("Leave.requested")} />}
            placement="top"
          >
            <Box
              className={classes.leaveTooltipLeaveTitleLine}
              width={`${requestedLeavePercentage}%`}
            >
              <Box className={classes.leaveTooltipLeaveTitle}>
                {t("Leave.requested")}
              </Box>
              <Box
                className={
                  available === 0 && fulfilled === 0
                    ? classes.requestedLeaveDaysEditedTLTR
                    : fulfilled === 0
                      ? classes.requestedLeaveDaysEditedTR
                      : available === 0
                        ? classes.requestedLeaveDaysEditedTL
                        : requested === 0
                          ? classes.fulfilledLeaveDaysEditedTR
                          : classes.requestedLeaveDays
                }
              >
                {requested?.toLocaleString()}
              </Box>
            </Box>
          </Tooltip>
          <Tooltip
            title={<FulfilledTooltip label={t("Leave.fulfilled")} />}
            placement="top"
          >
            <Box
              className={classes.leaveTooltipLeaveTitleLine}
              width={`${fulfilledLeavePercentage}%`}
            >
              <Box className={classes.leaveTooltipLeaveTitle}>
                {t("Leave.fulfilled")}
              </Box>

              <Box
                className={
                  available === 0 && requested === 0
                    ? classes.fulfilledLeaveDaysEditedTLTR
                    : classes.fulfilledLeaveDays
                }
              >
                {fulfilled?.toLocaleString()}
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </Grid>
      <Box height="16px" />
    </Box>
  );
};

export default MyLeaveBar;
