import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  text: ({ borderColor, isMobile }) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: 12,
    paddingLeft: isMobile ? 0 : 12,
    paddingRight: isMobile ? 0 : 12,
    paddingTop: isMobile ? 0 : 2,
    paddingBottom: isMobile ? 0 : 2,
    width: isMobile ? "20px" : "auto",
    height: isMobile ? "20px" : "auto",
    display: isMobile ? "flex" : "unset",
    alignItems: isMobile ? "center" : "unset"
  }),
}));

export default useStyles;
