import React from "react";
import "moment/locale/hu";
import Box from "@material-ui/core/Box";
import usePaycard from "hooks/use-myhr-paycard";
import moment from "moment";
import useContractsByEmployeeByPeriod from "hooks/use-contracts-by-employee-by-period";
import useCurrentUserState from "hooks/use-current-user-state";
import MyHrPayrollCollapse from "my-hr/components/my-hr-payroll-collapse";
import MyHrMonthSelector from "my-hr/components/my-hr-month-selector";
import { useTranslation } from "react-i18next";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyHrPaycard({
  contract,
  year,
  month,
  onClose,
  previousPaycard,
  nextPaycard,
}) {
  const { i18n } = useTranslation();
  const isMobile = useIsMobile();

  let { employeeCode } = useCurrentUserState();

  let startDate = moment(`${year}-${month}`)
    .startOf("month")
    .format("YYYYMMDD");

  let endDate = moment(`${year}-${month}`).endOf("month").format("YYYYMMDD");

  const { data: contractsData } = useContractsByEmployeeByPeriod({
    employee: employeeCode,
    startDate,
    endDate,
  });

  const { error } = usePaycard({
    contract,
    year,
    month,
  });

  let showStartOfContract =
    contractsData?.employmentType?.code === "2" ? false : true;

  let monthName = moment(`${year}-${month}`)
    .locale(i18n.language)
    .format("MMMM");

  return (
    <Box>
      <MyHrMonthSelector
        year={year}
        month={monthName}
        onClose={onClose}
        previousonClick={previousPaycard}
        nextOnClick={nextPaycard}
        backChevron={true}
        isMobile={isMobile}
      />
      {error ? (
        <Box padding="16px">
          <span>
            JDolBerAPIGateway error (contractCode: {contract}, year: {year},
            month: {month})
          </span>
        </Box>
      ) : (
        <>
          <Box height="16px" />
          {contractsData?.map((item, index) => (
            <Box key={`${item}-${index}`}>
              <MyHrPayrollCollapse
                index={index}
                label={item?.jobClass?.name}
                ContractID={item?.code}
                showStartOfContract={showStartOfContract}
                company={item?.company?.name}
                workingPlace={item?.workingPlace?.name}
                orgUnit={item?.orgUnit?.name}
                payOffice={item?.payOffice?.name}
                startOfContract={item?.startOfContract}
                employmentType={item?.employmentType?.name}
                month={month}
                year={year}
                contract={item?.code}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
}
