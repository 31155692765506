import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import useEmployeeDI from "hooks/use-myhr-employee";
import MyLeaveHeaderBadge from "my-leave/components/my-leave-header-badge";
import MyLeaveHeaderAdminBadge from "my-leave/components/my-leave-header-admin-badge";
import MyTimeAppbarBadge from "my-time/components/my-time-appbar-badge";
import MyHrHeaderBadge from "my-hr/pages/my-hr-user-data-edit-page/components/my-hr-header-badge";
import OcAvatar from "oc/components/oc-avatar";
import getFullname from "oc/utils/get-fullname";
import OcPrivateLayoutAppBarAccountMenu from "oc/components/oc-private-layout-app-bar-account-menu";
import OcPrivateLayoutAppBarContractSelect from "oc/components/oc-private-layout-app-bar-contract-select";
import useStyles from "./oc-private-layout-app-bar.style";
import useCurrentUserStateDI from "hooks/use-current-user-state";
import OcAppBarCounter from "oc/components/oc-app-bar-counter";
import useGetVersionDI from "hooks/use-get-version";
import useProperty from "hooks/use-oc-property";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function OcPrivateLayoutAppBar({
  onLogout,
  useGetVersion = useGetVersionDI,
  useCurrentUserState = useCurrentUserStateDI,
  useEmployee = useEmployeeDI,
}) {
  const isMobile = useIsMobile();
  const classes = useStyles();
  let { employeeCode } = useCurrentUserState();
  const { data: employeeData } = useEmployee({ employeeId: employeeCode });
  const [enabled, setEnabled] = useState(true);

  const {
    hasToRefresh,
    timeToRefresh,
    showRefreshDialog,
    onRefresh,
    currentVersion,
  } = useGetVersion();

  const onCompleted = (property) => {
    let parsedValue = property?.value === "true" ? true : false;
    setEnabled(parsedValue);
  };

  useProperty({
    resource: "system",
    key: "changeRequestsEnabled",
    onCompleted,
    fetchPolicy: "network-only",
  });

  return (
    <AppBar
      position="sticky"
      elevation={0}
      id="headerAppBar"
      className={classes.root}
    >
      <Box
        height="64px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingRight="18px"
        fontSize="14px"
        letterSpacing="0.25px"
        className={classes.container}
      >
        <Box paddingLeft="16px">
          {hasToRefresh && !showRefreshDialog && (
            <OcAppBarCounter
              timeToRefresh={timeToRefresh}
              onRefresh={onRefresh}
            />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          //justifyContent="space-between"
          alignItems="center"
        >
          <Box marginRight="16px" display="flex" flexDirection="row">
            <MyLeaveHeaderBadge />
            <MyLeaveHeaderAdminBadge />
            {enabled && <MyHrHeaderBadge />}
            <MyTimeAppbarBadge />
          </Box>
          <Box
            id="app-bar-menu-container"
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {!isMobile && <OcPrivateLayoutAppBarContractSelect isMobile={isMobile} />}
            <Box marginRight="16px">
              <OcAvatar
                employee={employeeData?.code}
                style={{
                  width: "45px",
                  height: "45px",
                }}
              />
            </Box>
            {!isMobile &&
              <Box marginRight="6px" id="header-app-bar-full-name">
                {getFullname(employeeData)}
              </Box>
            }
            <OcPrivateLayoutAppBarAccountMenu
              onLogout={onLogout}
              currentVersion={currentVersion}
              isMobile={isMobile}
            />
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
}
