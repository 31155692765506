export const employeeMyTimeRequests = {
  id: "employee-my-time-requests-table",
  select: "row",
  columns: [
    {
      id: "myTimeType",
      label: "TYPE",
      type: "i18nText",
      width: "120px",
    },
    {
      id: "period",
      label: "PERIOD",
      type: "period",
    },
    {
      id: "hours",
      type: "text",
      label: "HOUR",
      align: "right",
      width: "85px",
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
    },
    {
      id: "createdAt",
      type: "dateTime",
      label: "REQUEST_CREATED_AT",
      width: "180px",
    },
    {
      id: "messages",
      label: "",
      align: "center",
      type: "myTimeMessageIndicator",
      width: "70px",
    },
  ],
};

export const employeeMyTimeRequestsIsMobile = {
  id: "employee-my-time-requests-table",
  select: "row",
  columns: [
    {
      id: "myTimeType",
      label: "TYPE",
      type: "i18nText",
      width: "80px",
    },
    {
      id: "period",
      label: "PERIOD",
      type: "period",
    },
    {
      id: "state",
      type: "state",
      label: "STATE",
      align: "center",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};


export const employeeAdmissions = {
  id: "employee-my-time-admissions-table",
  rowSpan: true,
  rowSpanField: "startTime",
  columns: [
    {
      id: "date",
      label: "DAY",
      type: "myTimeDate",
      width: "180px",
      rowSpan: true,
      colorize: true,
    },
    {
      id: "dayType",
      label: "WORK_ORDER",
      type: "dayType",
      width: "140px",
      rowSpan: true,
      colorize: true,
    },
    {
      id: "myTimeType",
      label: "CATEGORY",
      type: "myTimeType",
      width: "140px",
    },
    {
      id: "timeDataTypeName",
      label: "MY_TIME_TYPE",
      type: "text",
      colorize: true,
      //width: "120px",
    },
    {
      id: "period",
      label: "PERIOD",
      type: "period",
      //width: "380px",
      colorize: true,
    },
    {
      id: "myTimeActions",
      label: "",
      type: "myTimeActions",
      align: "center",
      width: "90px",
    },
  ],
};

export const employeeAdmissionsIsMobile = {
  id: "employee-my-time-admissions-table",
  select: "row",
  columns: [
    {
      id: "date",
      label: "DAY",
      type: "myTimeDate",
      rowSpan: true,
      colorize: true,
    },
    {
      id: "dayType",
      label: "WORK_ORDER",
      type: "dayType",
      rowSpan: true,
      colorize: true,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const employeeAdmissionsByAdmin = {
  id: "employee-my-time-admissions-by-admin-table",
  rowSpan: true,
  rowSpanField: "startTime",
  columns: [
    {
      id: "date",
      label: "DAY",
      type: "myTimeDate",
      width: "180px",
      rowSpan: true,
      colorize: true,
    },
    {
      id: "dayType",
      label: "WORK_ORDER",
      type: "dayType",
      width: "140px",
      rowSpan: true,
      colorize: true,
    },
    {
      id: "myTimeType",
      label: "CATEGORY",
      type: "myTimeType",
      width: "140px",
    },
    {
      id: "timeDataTypeName",
      label: "MY_TIME_TYPE",
      type: "text",
      colorize: true,
      //width: "120px",
    },
    {
      id: "period",
      label: "PERIOD",
      type: "period",
      width: "380px",
      colorize: true,
    },
    {
      id: "myTimeAdminActions",
      label: "",
      type: "myTimeAdminAction",
      align: "center",
      width: "70px",
    },
  ],
};

export const employeeAdmissionsByAdminIsMobile = {
  id: "employee-my-time-admissions-by-admin-table",
  select: "row",
  columns: [
    {
      id: "date",
      label: "DAY",
      type: "myTimeDate",
      rowSpan: true,
      colorize: true,
    },
    {
      id: "dayType",
      label: "WORK_ORDER",
      type: "dayType",
      rowSpan: true,
      colorize: true,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const employeeMyTimeClosingPeriodRequest = {
  id: "employee-my-time-closing-period-request-table",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "examinerFullname",
      type: "text",
      label: "ROLE_EXAMINER",
      search: true,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
    },
    {
      id: "recordCount",
      type: "myTimeBadges",
      label: "REQUEST",
      width: "120px",
      align: "center",
    },
  ],
};

export const employeeMyTimeClosingPeriodRequestIsMobile = {
  id: "employee-my-time-closing-period-request-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "52px",
    },
    {
      id: "examinerFullname",
      type: "text",
      label: "ROLE_EXAMINER",
      search: true,
    },
    {
      id: "recordCount",
      type: "myTimeBadges",
      label: "REQUEST",
      align: "center",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const employeeWithoutMyTimeExaminerRequest = {
  id: "employee-without-my-time-examiner-request-table",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "employeeName",
      type: "text",
      label: "STATEMENT_EMPLOYEE_NAME",
      search: true,
    },
    {
      id: "contractCode",
      type: "text",
      label: "GET_CONTRACT",
      search: false,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
    },
    {
      id: "recordCount",
      type: "myTimeBadges",
      label: "REQUEST",
      width: "120px",
      align: "center",
    },
  ],
};

export const employeeWithoutMyTimeExaminerRequestIsMobile = {
  id: "employee-without-my-time-examiner-request-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "52px",
    },
    {
      id: "employeeName",
      type: "text",
      label: "STATEMENT_EMPLOYEE_NAME",
      search: true,
    },
    {
      id: "recordCount",
      type: "myTimeBadges",
      label: "REQUEST",
      align: "center",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const allMyTimeRequests = {
  id: "all-my-time-requests-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "name",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: true,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: true,
    },
    {
      id: "requestCount",
      type: "myTimeBadges",
      label: "REQUEST",
      width: "120px",
      align: "center",
    },
  ],
};

export const allMyTimeRequestsIsMobile = {
  id: "all-my-time-requests-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "52px",
    },
    {
      id: "name",
      type: "text",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "requestCount",
      type: "myTimeBadges",
      label: "REQUEST",
      align: "center",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

