import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useUserByEmployee from "hooks/use-myhr-user-by-employee";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import Button from "@material-ui/core/Button";
import useProperty from "hooks/use-oc-property";
import { UPDATE_USER, SEND_TEMPORARY_PASSWORD } from "graphql/mutations";
import { useMutation } from "@apollo/react-hooks";
import OcEmailInputField from "oc/components/oc-email-input-field";
import OcUsernameInputField from "oc/components/oc-username-input-field";
import OcValidToInputField from "oc/components/oc-valid-to-input-field";
import useOcSnackbar from "hooks/use-oc-snackbar";
import useOcEmailInput from "hooks/use-oc-email-input";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";

export default function MyHrUserProfile() {
  const { t, i18n } = useTranslation();
  const { setOpenMessage } = useOcSnackbar();
  const isMobile = useIsMobile();
  let { employee } = useParams();
  const { data: userData } = useUserByEmployee({ employee });

  const { data: loginType } = useProperty({
    resource: "system",
    key: "loginType",
  });

  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const [updateUser] = useMutation(UPDATE_USER, {
    onError({ ...err }) {
      console.log("error", err);
      setLoading(false);
    },
    onCompleted(res) {
      setLoading(false);
      setOpenMessage({
        type: "success",
        message: t("USER_UPDATED"),
      });
    },
  });

  const [sendTemporaryPassword] = useMutation(SEND_TEMPORARY_PASSWORD, {
    onError({ ...err }) {
      console.log("error", err);
      setLoading(false);
    },
    onCompleted(res) {
      setLoading(false);
      setOpenMessage({
        type: "success",
        message: t("TEMPORARY_PASSWORD_EMAIL_SENT"),
      });
    },
  });

  const {
    value: email,
    setValue: setEmail,
    isValid: isEmailValid,
    validate: validateEmail,
    error: emailError,
    setError: setEmailError,
    helperText: emailHelperText,
    setHelperText: setEmailHelperText,
  } = useOcEmailInput();

  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [usernameHelperText, setUsernameHelperText] = useState("");

  const [validTo, setValidTo] = useState(null);
  const [validToError, setValidToError] = useState(false);
  const [validToHelperText, setValidToHelperText] = useState("");

  useEffect(() => {
    if (userData && !initialized) {
      if (userData.email) setEmail(userData.email);
      if (userData.ldapUser) setUsername(userData.ldapUser);
      if (userData.validTo) setValidTo(userData.validTo);

      setInitialized(true);
    }
  }, [userData, initialized, setEmail]);

  function handleUpdate(e) {
    e.preventDefault();

    let valid = true;

    if (!isEmailValid()) {
      validateEmail();
      valid = false;
    }

    if (loginType === "sso" && !username) {
      setUsernameError(true);
      setUsernameHelperText(t("ERROR_MESSAGE_INVALID_USERNAME"));
      valid = false;
    }

    if (validTo && isNaN(Date.parse(validTo))) {
      setValidToError(true);
      setValidToHelperText(t("Leave.notSelectable"));
      valid = false;
    }

    if (!valid) return;

    const params = {
      _id: userData?._id,
      email: email,
    };

    if (loginType === "sso") {
      params.ldapUser = username;
    }

    if (userData?.validTo !== validTo) {
      params.validTo = validTo;
    }

    setLoading(true);

    updateUser({
      variables: params,
    });
    setValidToError(false);
    setValidToHelperText("");
  }

  function handleCancel() {
    setEmail(userData?.email || "");
    setUsername(userData?.ldapUser || "");
    setValidTo(userData?.validTo || null);
    setValidToError(false);
    setValidToHelperText("");
  }

  function handleSendTemporaryPassword() {
    let email = userData?.email;
    if (email) {
      setLoading(true);
      sendTemporaryPassword({
        variables: { email, locale: i18n.language },
      });
    }
  }

  let disabled = true;

  if (userData?.email !== email) {
    disabled = false;
  }

  if (loginType === "sso" && userData?.ldapUser !== username) {
    disabled = false;
  }

  if (
    (userData?.validTo && validTo && new Date(userData.validTo).toLocaleDateString() !== new Date(validTo).toLocaleDateString()) ||
    (!userData?.validTo && validTo) ||
    (userData?.validTo && !validTo)
  ) {
    disabled = false;
  }

  return (
    <React.Fragment>
      <OcCard label={t("USER_PROFILE")} loading={loading}>
        <>
          <Box id="my-hr-user-profile-data">
            <Box paddingTop="24px" width={isMobile ? "auto" : "432px"}>
              {loginType === "sso" && (
                <Box>
                  <OcUsernameInputField
                    value={username}
                    setValue={setUsername}
                    error={usernameError}
                    setError={setUsernameError}
                    helperText={usernameHelperText}
                    setHelperText={setUsernameHelperText}
                  />
                  <Box height="36px" />
                </Box>
              )}
              <OcEmailInputField
                value={email}
                setValue={setEmail}
                error={emailError}
                setError={setEmailError}
                helperText={emailHelperText}
                setHelperText={setEmailHelperText}
              />
            </Box>
            {loginType !== "sso" && (
              <Box paddingTop="36px">
                <Box width={isMobile ? "auto" : "432px"} borderBottom="1px solid #C6CACC" />
                <Box
                  fontSize="16px"
                  letterSpacing="0.44px"
                  color="#4D4D4D"
                  padding="36px 0px"
                >
                  {t("SEND_TEMPORARY_PASSWORD_TEXT")}
                </Box>
                <Box
                  width={isMobile ? "auto" : "432px"}
                  paddingBottom="36px"
                  borderBottom="1px solid #C6CACC"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth
                    onClick={handleSendTemporaryPassword}
                  >
                    {t("SEND_NEW_TEMPORARY_PASSWORD")}
                  </Button>
                </Box>
              </Box>
            )}
            <Box width={isMobile ? "auto" : "432px"} padding="36px 0px 48px 0px">
              <OcValidToInputField
                value={validTo}
                setValue={setValidTo}
                error={validToError}
                setError={setValidToError}
                helperText={validToHelperText}
                setHelperText={setValidToHelperText}
              />
            </Box>
            <OcCardButtonBar
              handleRightButton={handleCancel}
              rightButtonText={t("CANCEL")}
              rightButtonId="handleUpdate-cancel-button"
              handlePrimaryButton={handleUpdate}
              primaryButtonText={t("SAVE")}
              primaryButtonDisabled={disabled}
              primaryButtonId="handleUpdate-save-button"
            />
          </Box>
        </>
      </OcCard>
    </React.Fragment>
  );
}
