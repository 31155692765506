export const myDocumentsUserList = {
  id: "uploader-user-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "name",
      type: "pipedName",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "orgUnit",
      type: "text",
      label: "ORGUNIT",
      sort: true,
      search: true,
    },
    {
      id: "jobClass",
      type: "text",
      label: "JOB_CLASS",
      sort: true,
    },
  ],
};

export const myDocumentsUserListIsMobile = {
  id: "uploader-user-list-table",
  select: "row",
  columns: [
    {
      id: "avatar",
      type: "avatar",
      label: "IMAGE",
      align: "center",
      width: "85px",
    },
    {
      id: "name",
      type: "pipedName",
      label: "NAME",
      sort: true,
      search: true,
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};

export const myDocumentsDocumentList = {
  id: "document-list-table",
  select: "row",
  columns: [
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
    {
      id: "description",
      type: "designation",
      label: "DESIGNATION",
      align: "left",
    },
    {
      id: "filename",
      type: "filename",
      label: "FILE_NAME",
    },
    {
      id: "uploaded",
      type: "uploaded",
      label: "UPLOADED",
    },
    {
      id: "actionButton",
      type: "actionButton",
      label: "",
      align: "right",
      width: "70px",

    },
  ],
};

export const myDocumentsDocumentListIsMobile = {
  id: "document-list-table",
  select: "row",
  columns: [
    {
      id: "description",
      type: "designation",
      label: "DESIGNATION",
      align: "left",
    },
    {
      id: "uploaded",
      type: "uploaded",
      label: "UPLOADED",
    },
    {
      id: "actionArrow",
      type: "actionArrow",
      label: "",
      align: "center",
      width: "40px",
    },
  ],
};
