import gql from "graphql-tag";

export const GET_USERS = gql`
  query users(
    $searchField: String
    $searchText: String
    $sortField: String
    $sortDirection: String
    $filterField: String
    $filterSelected: [String]
    $page: Int
    $pageSize: Int
    $contractCode: String
  ) {
    users(
      searchField: $searchField
      searchText: $searchText
      sortField: $sortField
      sortDirection: $sortDirection
      filterField: $filterField
      filterSelected: $filterSelected
      page: $page
      pageSize: $pageSize
      contractCode: $contractCode
    ) {
      rows {
        employee
        email
        validTo
        fullname
        lastLogin
        _id
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
