import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import OcEmailInputField from "oc/components/oc-email-input-field";
import useOcEmailInput from "hooks/use-oc-email-input";
import OcPasswordInputField from "oc/components/oc-password-input-field";
import usePasswordInput from "hooks/use-oc-password-input";
import useProperty from "hooks/use-oc-property";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_USER_EMAIL } from "graphql/mutations";
import useCurrentUserState from "hooks/use-current-user-state";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import useOcSnackbar from "hooks/use-oc-snackbar";

export default function MyHrUserDataCard({ userData, isMobile }) {
  const { t } = useTranslation();

  let currentUserState = useCurrentUserState();

  const { setOpenMessage } = useOcSnackbar();

  const [loading, setLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { data: communicationChannel } = useProperty({
    resource: "system",
    key: "communicationChannel",
  });

  const { data: loginType } = useProperty({
    resource: "system",
    key: "loginType",
  });

  const {
    value: email,
    setValue: setEmail,
    isValid: isEmailValid,
    validate: validateEmail,
    error: emailError,
    setError: setEmailError,
    helperText: emailHelperText,
    setHelperText: setEmailHelperText,
  } = useOcEmailInput();

  /*const {
    value: phone,
    setValue: setPhone,
    isValid: isPhoneValid,
    validate: validatePhone,
  } = usePhoneInput({ stateId: "myProfilePhone" });*/

  const {
    value: password,
    setValue: setPassword,
    isValid: isPasswordValid,
    validate: validatePassword,
    error: passwordError,
    setError: setPasswordError,
    helperText: passwordHelperText,
    setHelperText: setPasswordHelperText,
  } = usePasswordInput();

  const [emailInputFilled, setEmailInputFilled] = useState(false);
  //const [phoneInputFilled, setPhoneInputFilled] = useState(false);

  useEffect(() => {
    if (userData?.email && !emailInputFilled) {
      setEmail(userData?.email);
      setEmailInputFilled(true);
    }

    /*if (userData?.phone && !phoneInputFilled) {
      setPhone(userData?.phone);
      setPhoneInputFilled(true);
    }*/
  }, [emailInputFilled, setEmail, userData?.email]);

  const [updateUserEmail] = useMutation(UPDATE_USER_EMAIL, {
    onCompleted: (data) => {
      setLoading(false);
      setPassword("");
      setOpenMessage({
        type: "success",
        message: t("USER_UPDATED"),
      });
    },
    onError: (error) => {
      if (error.message === "Wrong password!") {
        setPasswordError(true);
        setPasswordHelperText(t("ERROR_MESSAGE_WRONG_PASSWORD"));
        setShowConfirmDialog(false);
      }
      setLoading(false);
    },
  });

  function handleCancel() {
    setEmail(userData?.email);
    //setPhone(userData?.phone);
    setPassword("");
    setPasswordError(false);
  }

  function handleSave(e) {
    e.preventDefault();

    if (!isEmailValid()) {
      validateEmail();
      setShowConfirmDialog(false);
    }

    /*if (communicationChannel === "sms") {
      if (!isPhoneValid()) {
        validatePhone();
      }
    }*/

    if (!isPasswordValid({ checkPasswordPolicy: false })) {
      validatePassword({ checkPasswordPolicy: false });
      setShowConfirmDialog(false);
    }

    if (!isEmailValid()) return;
    if (!isPasswordValid({ checkPasswordPolicy: false })) return;

    //if (communicationChannel === "sms" && !isPhoneValid()) return;

    if (userData?.email === email && communicationChannel !== "sms") {
      return;
    }

    /*if (userData?.email === email && userData?.phone === phone) {
      return;
    }*/

    let queryParams = {
      _id: userData?._id,
      ldapUser: loginType === "sso" ? currentUserState?.ldapUser : "",
      email: email,
      password: password,
    };

    /*if (communicationChannel === "sms") {
      queryParams.phone = phone;
    }*/

    setLoading(true);
    updateUserEmail({
      variables: queryParams,
    });
    setShowConfirmDialog(false);
  }

  let disabled = userData?.email === email;
  /*if (communicationChannel === "sms" && userData?.phone !== phone) {
    disabled = false;
  }*/

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <Box>
      <OcCard label={t("USER_ACCOUNT")} loading={loading}>
        <OcConfirmationDialog
          showDialog={showConfirmDialog}
          onClose={handleSave}
          onCancel={handleDialogCancel}
          title={t("CONFIRM_SETTING")}
          text={t("CONFIRM_SETTING_INFO")}
          okButtonText={t("ALL_RIGHT")}
          cancelButtonText={t("CANCEL")}
        />
        <Box padding="16px" width={isMobile ? "auto" : "464px"}>
          <form>
            <OcEmailInputField
              value={email}
              setValue={setEmail}
              error={emailError}
              setError={setEmailError}
              helperText={emailHelperText}
              setHelperText={setEmailHelperText}
              label={t("EMAIL")}
            />
            {/*communicationChannel === "sms" && (
              <Box>
                <Box height="36px" />
                <OcPhoneInputField stateId="myProfilePhone" />
              </Box>
            )*/}
            <Box height="36px" style={{ borderBottom: "1px solid #C6CACC" }} />
            <Box height="36px" />
            <OcPasswordInputField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              helperText={passwordHelperText}
              label={t("CURRENT_PASSWORD")}
              setError={setPasswordError}
              setHelperText={setPasswordHelperText}
            />
            <Box height="20px" />
          </form>
        </Box>
        <OcCardButtonBar
          handleRightButton={handleCancel}
          rightButtonText={t("CANCEL")}
          handlePrimaryButton={() => setShowConfirmDialog(true)}
          primaryButtonText={t("SAVE")}
          primaryButtonDisabled={disabled}
        />
      </OcCard>
    </Box>
  );
}
