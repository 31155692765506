import gql from "graphql-tag";

export const GET_EMPLOYEES = gql`
  query employees(
    $searchField: String
    $searchText: String
    $sortField: String
    $sortDirection: String
    $filterField: String
    $filterSelected: [String]
    $page: Int
    $pageSize: Int
    $contractCode: String
  ) {
    employees(
      searchField: $searchField
      searchText: $searchText
      sortField: $sortField
      sortDirection: $sortDirection
      filterField: $filterField
      filterSelected: $filterSelected
      page: $page
      pageSize: $pageSize
      contractCode: $contractCode
    ) {
      rows {
        code
        contractCode
        prefixName
        lastName
        firstName
        middleName
        nameSuffix
        dateOfBirth
        mothersNamePrefix
        mothersLastName
        mothersFirstName
        mothersFirstName2
        mothersNameSuffix
        _id
      }
      total
      page
      pageSize
      totalPages
    }
  }
`;
