import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import useStyles from "./oc-data-table-body-cell-state.style";
import exclamationIcon from "@iconify/icons-mdi/exclamation";
import { InlineIcon } from "@iconify/react";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';
import checkIcon from "@iconify/icons-mdi/check";
import checkBoldIcon from "@iconify/icons-mdi/check-bold";
import closeIcon from "@iconify/icons-mdi/close";
import Typography from "@material-ui/core/Typography";

export default function State({ label }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useIsMobile();

  const getColor = (label) => {
    switch (label) {
      case "requested":
        return "#F3AA18";
      case "approved":
        return "#2FA84F";
      case "recorded":
        return "#2FA84F";
      case "rejected":
        return theme.palette.primary.main;
      case "invalidated":
        return theme.palette.primary.main;
      default:
        return "white";
    }
  };

  const iconMap = {
    requested: exclamationIcon,
    rejected: closeIcon,
    recorded: checkBoldIcon,
    approved: checkIcon,
  };

  const iconColor = getColor(label);
  const IconComponent = iconMap[label];
  const classes = useStyles({ borderColor: iconColor, isMobile });

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" >
      {isMobile && IconComponent && (
        <InlineIcon
          icon={IconComponent}
          className={classes.text}
          width="24px"
          style={{
            color: iconColor,
            cursor: "pointer",
          }}
        />
      )}

      {!isMobile && (
        <Typography className={classes.text} variant="body2">
          {t(`Leave.${label}`)}
        </Typography>
      )}
    </Box>
  );
}
