import gql from "graphql-tag";

export const GET_ARCHIVED_CAFETERIA_STATEMENT_ITEMS = gql`
  query getArchivedCafeteriaItems($contractCode: String!) {
    getArchivedCafeteriaItems(contractCode: $contractCode) {
      origin
      contractCode
      menuItem {
        code
        name
        isReusable
      }
      startDate
      endDate
      itemSerial
      type
      amount
      cost
      plan
      periodStartDate
      _id
    }
  }
`;
