import React from "react";
import { useQuery, useSubscription } from "@apollo/react-hooks";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useClientState from "hooks/use-oc-client-state";
import moment from "moment";
import NewRequestDialog from "my-leave/components/my-leave-new-request-dialog";
import { GET_LEAVE_REQUESTS_BY_CONTRACT } from "graphql/queries";
import { LEAVE_REQUESTS_SUBSCRIPTION } from "graphql/subcriptions";
import { initialTableData } from "my-leave/configs/my-leave-initial-table-data";
import getSortedList from "oc/utils/get-sorted-list";
import { myLeaveRequests, myLeaveRequestsIsMobile } from "my-leave/configs/my-leave-table-defs";
import Table from "oc/components/oc-data-table";
import getFilterValues from "oc/utils/get-filter-values";
import MyLeavesFilterBar from "my-leave/components/my-leave-filter-bar";
import OcErrorBoundary from "oc/components/oc-error-boundary";
import { useHistory } from "react-router-dom";
import useCurrentUserState from "hooks/use-current-user-state";
import usePolling from "hooks/use-polling";

const MyLeaveMyLeaveRequestsTable = ({
  selectedYear,
  disabledNewRequest,
  isMobile
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  let { contractCode } = useCurrentUserState();

  let requestedLeaveDays = {
    base: 0,
    study: 0,
    covid: 0,
    eszjtv: 0,
  };

  const [, setRequestedLeaveDays] = useClientState(
    "requestedLeaveDays",
    requestedLeaveDays
  );
  const [showNewItemDialog, setShowNewItemDialog] = useClientState(
    "showNewLeaveRequestDialog",
    false
  );

  const handleShowNewItemDialog = (newState) => {
    setShowNewItemDialog(newState);
  };

  const [selectedRowId, setSelectedRowId] = React.useState(null);

  const originalonSelectRow = (o) => {
    history.push(`/my-leave/my-request/${o?._id}`);
  };

  const handleSelectRequest = (o) => {
    if (isMobile) {
      setSelectedRowId(selectedRowId === o?._id ? null : o?._id);
    } else {
      originalonSelectRow(o);
    }
  };

  const { data, loading, error, refetch } = useQuery(
    GET_LEAVE_REQUESTS_BY_CONTRACT,
    {
      fetchPolicy: "cache-and-network",
      pollInterval: usePolling(1000 * 60),
      skip: !contractCode,
      variables: { contract: contractCode, year: selectedYear },
    }
  );

  if (error) {
    console.log({
      component: "MyLeaveRequests",
      errorDescription:
        "Error occured when fetch data with GET_LEAVE_REQUESTS_BY_CONTRACT query",
      error: error,
    });
  }

  const { error: subscriptionError } = useSubscription(
    LEAVE_REQUESTS_SUBSCRIPTION,
    {
      onData: (data) => {
        refetch();
      },
    }
  );

  if (subscriptionError) {
    console.log({
      component: "MyLeaveRequests",
      errorDescription:
        "Error occured when subscribe data with LEAVE_REQUESTS_SUBSCRIPTION query",
      error: error,
    });
  }

  let resultData = data?.leaveRequestsByContract || [];

  let result = _.cloneDeep(resultData);

  result.map((item) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
    let startDate = new Date(item.startDate).toLocaleDateString(
      i18n.language,
      options
    );
    let endDate = new Date(item.endDate).toLocaleDateString(
      i18n.language,
      options
    );

    const now = new Date();
    const itemDate = new Date(item?.createdAt);
    const inCurrentYear = now.getFullYear() === itemDate.getFullYear();

    if (item.type === "leave" && item.state === "requested" && inCurrentYear) {
      if (item.leaveType === "Q") {
        requestedLeaveDays.covid = requestedLeaveDays.covid + item.workDays;
      } else if (item.leaveType === "D") {
        requestedLeaveDays.eszjtv = requestedLeaveDays.eszjtv + item.workDays;
      } else if (item.leaveType === "T") {
        requestedLeaveDays.study = requestedLeaveDays.study + item.workDays;
      } else {
        requestedLeaveDays.base = requestedLeaveDays.base + item.workDays;
      }
    }
    let createdAt = new Date(item.createdAt).toLocaleString(i18n.language);
    item.createdAt = createdAt;
    if (item._id.startsWith("jd-")) {
      item.createdAt = t(`RECORDED_IN_JDOLBER`);
    }
    item.period = `${startDate} - ${endDate}`;
    item.type = t(`Leave.${item.type}`);
    return item;
  });

  React.useEffect(() => {
    setRequestedLeaveDays(requestedLeaveDays);
  });

  const handleCoverage = ({ startDate, endDate, leaveType }) => {
    let start = moment(startDate).startOf("day");
    let end = moment(endDate).endOf("day");
    let coverage = false;

    const checkCoverage = (date) => {
      return result.some(item => {
        let itemStart = moment(item.startDate).startOf("day");
        let itemEnd = moment(item.endDate).endOf("day");
        let sameType = ["A", "B", "C", "D", "E", "F", "G", "H", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V"].includes(item.leaveType);
        let exceptionalCoverage = (leaveType === "O" || leaveType === "I" || leaveType === "J") && item.leaveType === "S";

        if (
          date.isBetween(itemStart, itemEnd, undefined, "[]") &&
          item.state !== "rejected" &&
          (sameType || exceptionalCoverage)
        ) {
          return true;
        }
        return false;
      });
    };

    for (let date = start.clone(); date.isSameOrBefore(end); date.add(1, 'day')) {
      if (checkCoverage(date)) {
        coverage = true;
        break;
      }
    }
    return coverage;
  };

  let tableId = "myLeaveRequestsTable";
  initialTableData.sortField = "period";
  initialTableData.pageSize = 5;
  initialTableData.filterField = "state";
  initialTableData.filterSelected = ["requested"];
  const [tableData, setTableData] = useClientState(tableId, initialTableData);

  result.sort(
    getSortedList(
      tableData?.sortDirection === "desc"
        ? tableData?.sortField
        : `-${tableData?.sortField}`
    )
  );

  let list = {};
  list.total = result?.length || 0;
  list.page = tableData?.page;
  list.pageSize = tableData?.pageSize || initialTableData.pageSize;
  list.rows = result || [];

  if (tableData?.filterField && tableData?.filterValues.length === 0) {
    let filterValues = getFilterValues(tableData?.filterField, list.rows);
    setTableData({
      filterValues,
    });
  }

  if (tableData?.filterSelected.length > 0) {
    list.rows = list.rows.filter(function (item) {
      let key = item[tableData?.filterField];
      return tableData?.filterSelected.includes(key);
    });
  }

  list.total = list?.rows.length || 0;

  let loadingState = loading && !data;

  let tableDef;
  if (isMobile) {
    tableDef = myLeaveRequestsIsMobile;
  } else {
    tableDef = myLeaveRequests;
  }

  const filteredTableDef = {
    id: myLeaveRequests?.id,
    columns: myLeaveRequests?.columns?.filter(column =>
      !myLeaveRequestsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  return (
    <React.Fragment>
      <OcErrorBoundary>
        <MyLeavesFilterBar
          tableData={tableData}
          setTableData={setTableData}
          loading={loadingState}
          onShowNewRequestDialog={handleShowNewItemDialog}
          disabledNewRequest={disabledNewRequest}
        />
      </OcErrorBoundary>
      <OcErrorBoundary>
        <Table
          data={list}
          loading={loadingState}
          tableDef={tableDef}
          tableData={tableData}
          setTableData={setTableData}
          pagination={true}
          onSelectRow={handleSelectRequest}
          localPaging={true}
          selectedRowId={selectedRowId}
          filteredTableDef={filteredTableDef}
          handleMoreButton={originalonSelectRow}
        />
      </OcErrorBoundary>
      <OcErrorBoundary>
        <NewRequestDialog
          onShowDialog={handleShowNewItemDialog}
          showDialog={showNewItemDialog}
          onCoverage={handleCoverage}
        />
      </OcErrorBoundary>
    </React.Fragment>
  );
};

export default MyLeaveMyLeaveRequestsTable;
