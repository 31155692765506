import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PartnerLogo from "oc/components/oc-private-layout-sidebar-partner-logo";
import MyCafeteriaInfoLine from "../my-cafeteria-info-line";
import MyCafeteriaStatementTitle from "../my-cafeteria-statement-title";
import MyCafeteriaSignatureBlock from "../my-cafeteria-signature-block";
import MyCafeteriSubaccountsTable from "my-cafeteria/components/my-cafeteria-subaccounts-table/my-cafeteria-subaccounts-table";

const MyCafeteriaSzepApplicationCardPrint = ({
  applicationDate,
  employeeName,
  idNumber,
  contractCode,
  currentYear,
  hasPreviousBenefit,
  thisYear,
  data
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box id="upper-component" paddingBottom="48px">
        <MyCafeteriaStatementTitle
          label={t("SZEPSTATEMENT", { currentYear })}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingBottom="16px"
        >
          <Box id="data-block">
            <MyCafeteriaInfoLine
              label={t("CALENDAR_MESSAGES_DATE")}
              value={applicationDate}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_EMPLOYEE_NAME")}
              value={employeeName}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_TAX_ID_SIGN_ID")}
              value={idNumber}
            />
            <MyCafeteriaInfoLine
              label={t("STATEMENT_CONTRACT_CODE")}
              value={contractCode}
            />
          </Box>
          <Box
            sx={{
              display: "none",
              displayPrint: "block",
              paddingRight: "85px",
            }}
          >
            <PartnerLogo />
          </Box>
        </Box>
        <Box padding="0px 56px">
          {hasPreviousBenefit ? (
            <Box>
              <Box paddingBottom="36px">
                <Typography varian="body1">
                  {t("IHAVEBENEFIT", { thisYear })}
                </Typography>
              </Box>
              <Box
                width="400px"
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" flexDirection="row">
                  <Box
                    flexGrow={1}
                    borderBottom="1px solid #5E6366"
                    paddingBottom="10px"
                  >
                    <Typography variant="h6">{t("SUBACCOUNTS")}</Typography>
                  </Box>
                  <Box
                    width="85px"
                    textAlign="right"
                    borderBottom="1px solid #5E6366"
                    paddingBottom="10px"
                  >
                    <Typography variant="h6">{t("AMOUNT")}</Typography>
                  </Box>
                </Box>
                {data?.map((item) => {
                  return (
                    <MyCafeteriSubaccountsTable
                      key={item?._id}
                      label={item?.menuItem?.name}
                      value={item?.szepCardApplicationAmount}
                    />
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography variant="body1">
                {t("IHAVENOBENEFIT", { thisYear })}
              </Typography>
            </Box>
          )}
          <Box height="36px" />
          <Typography variant="body1">{t("SZEPSTATOFRES")}</Typography>
        </Box>
      </Box>
      <MyCafeteriaSignatureBlock statement="szep" />
    </Box>
  );
};

MyCafeteriaSzepApplicationCardPrint.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaSzepApplicationCardPrint.defaultProps = {
  applicationDate: () => new Date(),
  currentYear: "",
  employeeName: "",
  idNumber: "",
  contractCode: "",
};

export default MyCafeteriaSzepApplicationCardPrint;
