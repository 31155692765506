import React from "react";
import { Box, Dialog, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CalendarDay from "my-leave/components/my-leave-calendar-day";
import moment from "moment";
import MyTimeRequestTypeInterface from "my-time/components/my-time-request-type-interface";

const MyTimeInfoDialog = ({ open, handleClose, data, employeeName, isMobile }) => {
  const { t } = useTranslation();
  const startTime = moment(data?.startTime).format("YYYY.MM.DD HH:mm");
  const endTime = moment(data?.endTime).format("YYYY.MM.DD HH:mm");

  const timeDifference = ({ startTime, endTime }) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    let start, end;

    if (isSafari) {
      start = data?.startTime;
      end = data?.endTime;
    } else {
      start = moment(startTime);
      end = moment(endTime);
    }
    const duration = moment.duration(moment(end).diff(moment(start)));
    const minutes = duration.asMinutes();
    return minutes;
  };

  function timeConvert(n) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return (
      rhours.toString().padStart(2, "0") +
      ":" +
      rminutes.toString().padStart(2, "0")
    );
  }

  const minutes = timeDifference({ startTime, endTime });
  const timeSummary = timeConvert(minutes - data?.workBreakInMinutes);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box padding="24px" width={isMobile ? "auto" : "547px"}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          style={{
            fontSize: "34px",
            letterSpacing: "0.25px",
            padding: "0px 16px 10px 16px",
            color: "#5E6366",
            borderBottom: "2px solid #C6CACC",
          }}
        >
          {t("WORKING_SCHEDULE")}
        </Box>
        <Box paddingTop="24px">
          <Box
            style={{
              fontSize: "20px",
              lineHeight: "28px",
              letterSpacing: "0.15px",
            }}
          >
            {employeeName}
          </Box>
          <Box height="22px" />
          <Box display="flex" flexDirection="row" alignItems="center">
            <CalendarDay date={timeSummary} hours="true" />
            <Box width="16px" />
            <Box>
              <Typography>{t("PERIOD")}</Typography>
              <Box>
                {startTime} - {endTime}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box paddingTop="24px" paddingBottom="24px">
          <MyTimeRequestTypeInterface data={data} />
        </Box>
        <Box
          style={{
            height: "1px",
            backgroundColor: "#E3E5E5",
          }}
        />
        <Box height="24px" />
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button
            id="myleave-new-request-dialog-cancel-button"
            text={t("Common.cancel")}
            onClick={handleClose}
          >
            {t("Common.cancel")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MyTimeInfoDialog;
