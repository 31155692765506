import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import { benefits, benefitsIsMobile } from "my-cafeteria/configs/my-cafeteria-table-defs";
import OcDataTable from "oc/components/oc-data-table";
import useCurrentCafeteriaStatementItems from "my-cafeteria/hooks/use-current-cafeteria-statement-items";
import MyCafeteriaBenefitForm from "my-cafeteria/components/my-cafeteria-benefit-form";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import {
  newCafeteriaStatementItemState,
  showNewCafeteriaStatementItemFormState,
  showNewCafeteriaStatementItemInlineFormState,
} from "my-cafeteria/states";
import { useRecoilState } from "recoil";
import { useMutation } from "@apollo/react-hooks";
import { APPROVE_CURRENT_CAFETERIA_STATEMENT } from "graphql/mutations";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";

const MyCafeteriaBenefitsCard = ({ contractCode, currentStatement, isMobile }) => {
  /** Translation */
  const { t } = useTranslation();

  /** Used states in the component */
  /** Store new end edited items */
  const [newItem, setNewItem] = useRecoilState(newCafeteriaStatementItemState);
  /** Open and close edit forms state */
  const [showNewItemForm, setShowNewItemForm] = useRecoilState(
    showNewCafeteriaStatementItemFormState
  );
  /** If form is inline edit store the opned/closed state*/
  const [showInlineEditForm, setShowInlineEditForm] = useRecoilState(
    showNewCafeteriaStatementItemInlineFormState
  );

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

  const { data, loading, error } = useCurrentCafeteriaStatementItems({
    contractCode,
  });

  const list = {
    rows: data,
  };

  /** Define editable state to aloow/disallow editing an item */
  const editable = currentStatement?.state === "editable" ? true : false;

  /** Mutation for closing the current application by the user */
  const [approveCurrentCafeteriaStatement] = useMutation(
    APPROVE_CURRENT_CAFETERIA_STATEMENT,
    {
      onError: (error) => {
        console.log({ error });
      },
    }
  );

  /** Event handlers */
  function handleOpenShowNewItemForm() {
    setShowNewItemForm(true);
  }

  function handleCloseShowNewItemForm() {
    setShowNewItemForm(false);
  }

  const [selectedRowId, setSelectedRowId] = useState(null);

  function handleSelectRow({ item }) {
    const {
      amount,
      contractCode,
      startDate,
      endDate,
      menuItem,
      type,
      index,
      szepCardApplicationAmount,
      numberOfMonths,
    } = item;
    let parsedAmount = amount / numberOfMonths;
    let newState = {
      index,
      amount: parsedAmount,
      contractCode,
      startDate,
      endDate,
      menuItemCode: menuItem.code,
      benefitType: menuItem.code,
      type,
      isPeriodical: menuItem.isPeriodical,
      szepCardApplicationAmount,
    };
    setNewItem((state) => {
      let result = Object.assign({}, state, newState);
      return result;
    });
    setShowInlineEditForm(true);
  }

  const onSelectRow = (item) => {
    if (isMobile) {
      if (item?.action === "edit") {
        handleSelectRow(item);
        setTimeout(() => {
          setSelectedRowId(null);
        }, 0);
      } else {
        setSelectedRowId(selectedRowId === item?._id ? null : item?._id);
      }
    } else {
      handleSelectRow(item);
    }
  };

  const tableDef = isMobile
    ? benefitsIsMobile
    : benefits;

  const filteredTableDef = {
    id: benefits?.id,
    columns: benefits?.columns?.filter(column =>
      !benefitsIsMobile?.columns?.some(mobileColumn => mobileColumn?.id === column?.id)
    )
  };

  function handleCloseInlineEditingForm() {
    setShowInlineEditForm(false);
  }

  function handleShowInlineEditForm(row) {
    const { index } = row;
    if (newItem?.index === index) {
      return true && showInlineEditForm;
    }

    return false;
  }

  function handleClose() {
    setShowConfirmDialog(false);
    let params = {
      contractCode,
      currentItemStartDate: currentStatement?.startDate,
    };
    approveCurrentCafeteriaStatement({ variables: params });
  }

  /** Define inline edit form */
  const InlineEditForm = (
    <MyCafeteriaBenefitForm
      onCloseForm={handleCloseInlineEditingForm}
      contractCode={contractCode}
      inlineEdit={true}
      currentStatement={currentStatement}
      currentStatementItems={data}
      isMobile={isMobile}
    />
  );

  function handleCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <Box id="my-cafeteria-benefits-card">
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleClose}
        onCancel={handleCancel}
        title={t("CONFIRM_TITLE")}
        text={t("CONFIRM_TEXT")}
        okButtonText={t("FINISH")}
        cancelButtonText={t("CANCEL")}
      />
      <OcCard label={t("ALL_AMOUNT")} error={error ? true : false}>
        <Box>
          <Box padding="15px 0px 41px 0px" textAlign="right">
            <Button
              id="my-cafeteria-benefits-card-new-benefit-add-button"
              variant="contained"
              color="primary"
              onClick={handleOpenShowNewItemForm}
              disabled={showNewItemForm || showInlineEditForm || !editable}
              disableElevation
            >
              {t("NEW_BENEFIT")}
            </Button>
          </Box>
          <Collapse
            id="my-cafeteria-new-benefits-card"
            in={showNewItemForm}
            timeout="auto"
            unmountOnExit
          >
            <MyCafeteriaBenefitForm
              onCloseForm={handleCloseShowNewItemForm}
              contractCode={contractCode}
              currentStatement={currentStatement}
              currentStatementItems={data}
              isMobile={isMobile}
            />
            <Box height="32px" />
          </Collapse>
          <OcDataTable
            tableDef={tableDef}
            data={list}
            loading={showInlineEditForm ? false : loading}
            error={error}
            onSelectRow={onSelectRow}
            inlineEditForm={InlineEditForm}
            inlineEdit={!showNewItemForm && !showInlineEditForm && editable}
            showInlineEditForm={showInlineEditForm}
            closeInlineEditingForm={handleCloseInlineEditingForm}
            onShowInlineEditForm={handleShowInlineEditForm}
            filteredTableDef={filteredTableDef}
            handleMoreButton={handleSelectRow}
            selectedRowId={selectedRowId}
          />
          {error && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              color="#cc0033"
              marginTop="16px"
              marginBottom="0px"
            >
              <Typography>{t("DATA_FETCHING_ERROR")}</Typography>
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            padding="44px 0px 8px 0px"
          >
            <Button
              id="my-cafeteria-completion-button"
              variant="contained"
              color="primary"
              onClick={() => setShowConfirmDialog(true)}
              disabled={
                !editable ||
                (data && data?.length === 0) ||
                showInlineEditForm ||
                showNewItemForm
              }
              disableElevation
            >
              {t("FINISH_APPLICATION")}
            </Button>
          </Box>
        </Box>
      </OcCard>
    </Box>
  );
};

MyCafeteriaBenefitsCard.propTypes = {
  /** Example prop type description*/
  example: PropTypes.string,
};

MyCafeteriaBenefitsCard.defaultProps = {
  example: "example",
};

export default MyCafeteriaBenefitsCard;
