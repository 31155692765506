import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./my-hr-payroll-collapse-header.style";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTheme } from "@material-ui/core/styles";
import { InlineIcon } from "@iconify/react";
import fileAccount from "@iconify/icons-mdi/file-account";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function MyHrPayrollCollapseHeader({
  label,
  ContractID,
  handleExpandClick,
  expanded,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <Box>
      <Box
        className={
          expanded === true
            ? classes.backgroundColorOnCollapse
            : classes.backgroundColorOffCollapse
        }
        padding="0px 16px"
      >
        <Box
          className={classes.filterContainer}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="contents" className={classes.topBorder} />
          <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center" marginLeft="16px"
          >
            <Typography
              variant="h6"
              style={{
                padding: "16px 0px",
                marginRight: "28px",
                color: `${theme.palette.gray80.main}`,
              }}
            >
              {label}
            </Typography>
            <Box display="flex" alignItems="center" width={isMobile ? "100%" : "auto"}>
              <InlineIcon
                icon={fileAccount}
                width="24px"
                style={{ color: `${theme.palette.gray80.main}` }}
              />
              <Box
                style={{
                  marginLeft: "12px",
                  color: `${theme.palette.gray80.main}`,
                }}
              >
                {ContractID}
              </Box>
            </Box>
            {isMobile && <Box height="16px" />}
            <Box width="6px" />
            <Box paddingRight="28px" />
          </Box>
          <Box display="flex" alignItems="center" />
          <Box width="22px" />
          <IconButton
            id={ContractID}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            style={
              expanded
                ? {
                  padding: 0,
                  marginRight: "20px",
                  transform: "rotate(180deg)",
                }
                : {
                  padding: 0,
                  marginRight: "20px",
                }
            }
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      </Box>
    </Box >
  );
}
