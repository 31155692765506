import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./oc-info-card-item.style";
import { useTheme } from "@material-ui/styles";
import { InlineIcon } from "@iconify/react";
import pencilIcon from "@iconify/icons-mdi/pencil";
import Tooltip from "@material-ui/core/Tooltip";
import useIsMobile from 'hooks/use-is-mobile/use-is-mobile';

export default function OcInfoCardItem({
  label,
  value,
  translation,
  type,
  onEdit,
  editable = false,
  isEditButtonDisabled,
}) {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const theme = useTheme();

  let effectiveValue = value || "";
  if (value === "not_defined") {
    effectiveValue = "";
  }

  const handleEdit = () => {
    if (isEditButtonDisabled) return null;
    onEdit(type);
  };

  function getTooLongValueTooltip() {
    if (value?.length > 30) {
      return (
        <Tooltip title={value} placement="top">
          <Box className={classes.value}>{effectiveValue}</Box>
        </Tooltip>
      );
    }
    return <Box className={classes.value}>{effectiveValue}</Box>;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.label}>{label}</Box>
      {getTooLongValueTooltip()}
      <Box width="10px" />
      {editable ? (
        <Box
          style={
            isEditButtonDisabled ? { cursor: "auto" } : { cursor: "pointer" }
          }
          onClick={handleEdit}
        >
          <InlineIcon
            id={`pencilIcon-${translation}-${type}`}
            width="18px"
            height="18px"
            icon={pencilIcon}
            color={
              isEditButtonDisabled
                ? theme.palette.gray40.main
                : theme.palette.gray80.main
            }
          />
        </Box>
      ) : (
        <Box width="50px" /> //Due to responsive display
      )}
    </Box>
  );
}
